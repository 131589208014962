import { Box, SxProps } from '@mui/system'
import logos from 'common/libs/logos'

/**
 *
 * @param {Object} props
 * @param {import("common/types/logos_types").LogoVariant} props.logoType = "Large" - The variant for the logo
 * @param {SxProps} [props.sx = {}] - The variant for the logo
 */
const Logo = ({ sx = {}, logoType = 'Large' }) => {
  const Variant = logos[logoType]
  return (
    <Box sx={{ widht: '100%', ...sx }}>
      <Variant style={{ width: '100%', height: '100%' }} />
    </Box>
  )
}

export default Logo
