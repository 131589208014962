// @ts-check
import { PlayArrow, Stop } from '@mui/icons-material'
import { Button, ListItemIcon, ListItemText, Menu, MenuItem, Stack } from '@mui/material'
import { Box } from '@mui/system'
import { ModalTypes } from 'common/hooks/useCustomModal'
import { enqueueSnackbar } from 'notistack'
import { useState } from 'react'
import useFetchTipoSalida from '../hooks/useFetchTipoSalida'
import FichajesController, { PresenceControlActions } from '../libs/FichajesController'
import { tiposSalidaMap } from '../libs/tiposSalidaMap'

/**
 * @module ControlPresencia
 */

/**
 * Buttons to mark enter or leave of
 * the presence control
 *
 * @param {Object} props
 * @param {boolean} props.loading
 * @param {boolean} props.modal
 * @param {function} props.setLastIteration
 * @param {number} props.lastIteration
 * @param {import('common/contexts/CustomModalContext').CustomModalHandler} props.handleModal
 * @example
 * ...
 * <PresenceActions
 *  loading={loading}
 *  setLastIteration={setLastIteration}
 *  lastIteration={lastIteration}
 *  handleModal={handleModal}
 *  modal={modal}
 * />
 * ...
 * @return {JSX.Element}
 */
const PresenceActions = ({ loading, modal, setLastIteration, lastIteration, handleModal }) => {
  const { response: tiposSalida } = useFetchTipoSalida()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleExit = (
    /**
     * @type {React.MouseEvent<HTMLButtonElement, MouseEvent>}
     */
    event,
  ) => {
    if (tiposSalida?.length >= 1) {
      // @ts-ignore
      setAnchorEl(event.currentTarget)
      return
    }
    if (tiposSalida?.length === 1) {
      markAction(PresenceControlActions.Salida, tiposSalida[0].codigo)
      return
    }

    markAction(PresenceControlActions.Salida)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  /**
   * Mark a presence control registry
   * @async
   * @param {PresenceControlActions} type - The tipe of action to perform
   * @param {string} [exitCode] - If is a exit type this param must be provided
   * @returns {Promise<void>}
   * */
  async function markAction(type, exitCode) {
    try {
      handleModal({ type: ModalTypes.Loading, enabled: true })
      await FichajesController.create(type, exitCode)
      setLastIteration(lastIteration + 1)
    } catch (error) {
      enqueueSnackbar('Ha ocurrido un error', {
        variant: 'error',
      })
      console.error(error)
    } finally {
      handleModal({ type: ModalTypes.Loading, enabled: false })
    }
  }

  function TiposSalida() {
    return (
      <Box>
        {tiposSalida.map((exit, index) => {
          const Icon = tiposSalidaMap[exit.codigo] || tiposSalidaMap.DEFAULT
          return (
            <MenuItem
              key={index}
              onClick={() => {
                markAction(PresenceControlActions.Salida, exit.codigo)
              }}
            >
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText>{exit.descripcion}</ListItemText>
            </MenuItem>
          )
        })}
      </Box>
    )
  }

  return (
    <Stack spacing={2} direction="row">
      <Button
        startIcon={<PlayArrow />}
        disableElevation
        disabled={loading || modal}
        color="success"
        onClick={async () => {
          markAction(PresenceControlActions.Entrada)
        }}
        size="large"
        variant="contained"
        sx={{ width: '100%', fontSize: '1.2em' }}
      >
        Entrada
      </Button>
      <Button
        startIcon={<Stop />}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disabled={loading || modal}
        onClick={async (event) => {
          handleExit(event)
        }}
        disableElevation
        color="error"
        size="large"
        variant="contained"
        sx={{ width: '100%', fontSize: '1.2em' }}
      >
        Salida
      </Button>
      {tiposSalida?.length >= 1 ? (
        <Menu
          sx={{ width: '100%' }}
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <TiposSalida />
        </Menu>
      ) : (
        <></>
      )}
    </Stack>
  )
}

export default PresenceActions
