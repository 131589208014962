import { CircularProgress } from '@mui/material'
import { PREVIOUS_ROUTE_STORE } from 'api/axios'
import Layout from 'common/layout/Layout'
import Logout from 'common/pages/Logout'
import TokenAuth from 'common/pages/TokenAuth'
import ResetPassword from 'features/auth/pages/ResetPassword'
import noticias_routes from 'features/noticias/noticias.routes'
import { selectUsuario } from 'features/users/stores/userSlice'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import ThemeManager from 'theming/ThemeManager'
import { fetchMenus, getMenusStatus, reloadMenus, selectMenus } from '../../portal/menusSlice'
import { iterar_menus } from '../libs/iterateMenu'
import { useRouteIsActive } from '../libs/routeIsActive'
import ROUTE_COMPONENTS from '../libs/routes'
const LOGGED_REDIRECTION = 'logged_redirect'

/** @type {MenuDtoArray | null}  */
const initial = null
const RoutesController = () => {
  /**@type {MenuDtoArray}*/
  const menus = useSelector(selectMenus)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const routeIsActive = useRouteIsActive()
  const previousRouteActive = useRouteIsActive(sessionStorage.getItem(PREVIOUS_ROUTE_STORE))
  const loggedRedirectActive = useRouteIsActive(sessionStorage.getItem(LOGGED_REDIRECTION))
  const menusStatus = useSelector(getMenusStatus)
  const usuario = useSelector(selectUsuario)
  const SettingsComponent = ROUTE_COMPONENTS['settings']

  /**
   * @property {MenuDto} 0
   * @property {string} 1
   */
  const [StartRoute, setStartRoute] = useState(initial)
  const [MainComponent, setMainComponent] = useState(null)
  const [showContainer, setShowContainer] = useState(false)
  const themeController = new ThemeManager()
  const CustomLayout = themeController?.components
    ? themeController?.components['Layout'] || null
    : null

  useEffect(() => {
    // @ts-ignore
    if (menusStatus === 'idle') dispatch(fetchMenus)
  }, [menusStatus, dispatch])

  useEffect(() => {
    const ROUTE_TO_LOGIN = sessionStorage.getItem(PREVIOUS_ROUTE_STORE)
    const LOGGED_REDIRECT = sessionStorage.getItem(LOGGED_REDIRECTION)

    if (
      !LOGGED_REDIRECT &&
      window.location.pathname !== '/' &&
      window.location.pathname !== '/recuperar' &&
      usuario &&
      menus.length <= 1
    )
      sessionStorage.setItem(LOGGED_REDIRECTION, window.location.pathname + window.location.search)

    if (
      !ROUTE_TO_LOGIN &&
      window.location.pathname !== '/' &&
      window.location.pathname !== '/recuperar' &&
      !usuario
    )
      sessionStorage.setItem(
        PREVIOUS_ROUTE_STORE,
        window.location.pathname + window.location.search,
      )
    if (!routeIsActive) navigate('/')
  }, [routeIsActive])

  useEffect(() => {
    const PREVIOUS_ROUTE = sessionStorage.getItem(PREVIOUS_ROUTE_STORE)
    const LOGGED_REDIRECT = sessionStorage.getItem(LOGGED_REDIRECTION)

    if (
      LOGGED_REDIRECT &&
      location.pathname === '/' &&
      usuario &&
      loggedRedirectActive &&
      menus?.length > 1
    ) {
      sessionStorage.removeItem(LOGGED_REDIRECTION)
      navigate(LOGGED_REDIRECT)
    }

    if (
      PREVIOUS_ROUTE &&
      location.pathname === '/' &&
      usuario &&
      previousRouteActive &&
      menus?.length > 1
    ) {
      sessionStorage.removeItem(PREVIOUS_ROUTE_STORE)
      navigate(PREVIOUS_ROUTE)
    }
  }, [location, navigate, usuario, previousRouteActive, menus])

  const changeVariables = () => {
    if (menus && menus.length > 0) {
      setStartRoute(
        menus.find(
          (
            /** @type {MenuDto} */
            menu,
          ) => menu.activo === 'S' && menu.programa && menu.programa.substring(0, 4) !== 'http',
        ),
      )
    }
  }

  useEffect(() => {
    const Element = StartRoute?.programa ? ROUTE_COMPONENTS[StartRoute.programa] : null
    setMainComponent(Element ? <Element /> : <></>)
    setShowContainer(StartRoute?.programa !== 'login')
  }, [StartRoute])

  useEffect(() => {
    if (
      showContainer &&
      location?.state?.url &&
      menusStatus === 'succeeded' &&
      menus.length > 1 &&
      !location?.state?.stop
    ) {
      navigate(location?.state?.url, { state: { redirected: true } })
    }
  }, [showContainer, navigate, menusStatus, menus, location?.state])

  useEffect(() => {
    changeVariables()
  })

  useEffect(() => {
    if (usuario) {
      dispatch(reloadMenus())
    }
  }, [usuario, dispatch])

  useEffect(() => {
    if (menusStatus === 'idle') dispatch(fetchMenus())
  }, [menusStatus, dispatch])

  const DocumentacionComponent = ROUTE_COMPONENTS['documentos']
  const ComunicadosChildComponent = ROUTE_COMPONENTS['comunicados']
  const HojasGastoLinPage = ROUTE_COMPONENTS['hojasgastolin']

  return (
    <>
      {menus?.length > 0 ? (
        <Routes>
          <Route exact key={'recuperar'} path="/recuperar" element={<ResetPassword />} />

          <Route
            path="/"
            element={
              CustomLayout ? (
                <CustomLayout container={showContainer} />
              ) : (
                <Layout container={showContainer} />
              )
            }
          >
            {MainComponent ? (
              <Route key="inicio" exact path="/" element={MainComponent} />
            ) : (
              <Route key="no-inicio" />
            )}
            {menus.map((menu) => {
              const menus = iterar_menus(menu)
              return menus
            })}
            <Route exact key={'settings'} path="/ajustes" element={<SettingsComponent />} />
            <Route exact key={'logout'} path="/logout" element={<Logout />} />
            <Route
              exact
              key={'comunicadosHijos'}
              path="/comunicados/:id"
              element={<ComunicadosChildComponent />}
            />
            <Route
              exact
              key={'documentos_hijos'}
              path="/documentos/:id"
              element={<DocumentacionComponent />}
            />
            <Route exact key={'auth'} path="/auth" element={<TokenAuth />} />
            {themeController.routes ?? <></>}
            {noticias_routes}

            <Route
              exact
              key={'hojasGastoLin'}
              path="/hojasgasto/:id_hoja"
              element={<HojasGastoLinPage />}
            />
          </Route>
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      ) : (
        <>
          <CircularProgress />
        </>
      )}
    </>
  )
}

export default RoutesController
