// @ts-check
import { Box, CircularProgress } from '@mui/material'
import back from 'assets/images/wallpaper.login.png'
import { selectPortal } from 'features/portal/portalSlice'
import { useSelector } from 'react-redux'

const LoginBackground = ({ children, loading = true }) => {
  const portal = useSelector(selectPortal)

  const backgrounImage = portal?.portal?.fondo_login
    ? `/assets/images/${portal?.portal?.fondo_login}`
    : back

  const imageBg = {
    backgroundColor: 'background.default',
    backgroundImage: `url('${backgrounImage}')`,
    background: `linear-gradient(rgba(0, 0, 0, 0.30), rgba(0, 0, 0, 0.40)), url('${backgrounImage}') no-repeat`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  }
  const white = {
    backgroundColor: 'background.default',
  }

  const bg = loading ? white : imageBg
  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        margin: 0,
        padding: 1,
        transition: '.3s',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...bg,
      }}
    >
      <>{loading ? <CircularProgress /> : <>{children}</>}</>
    </Box>
  )
}

export default LoginBackground
