import { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

import news from 'assets/images/news.png'
import { Alert, LinearProgress } from '@mui/material'
import LinksController from '../libs/LinksController'

const VisorEnlaces = () => {
  const { response, loading: isLoading } = LinksController.get()
  const [enlaces, setEnlaces] = useState(response)
  const [loading, setLoading] = useState(isLoading)

  useEffect(() => {
    setEnlaces(response)
  }, [response])

  useEffect(() => {
    setLoading(isLoading)
  }, [isLoading])

  return (
    <Box sx={{ flexGrow: 1 }}>
      {loading ? (
        <LinearProgress variant="query" />
      ) : !enlaces.length > 0 ? (
        <Alert severity="info">No se han encontrado enlaces</Alert>
      ) : (
        <Grid container spacing={2}>
          {enlaces.map((link, index) => {
            return (
              <Grid key={`${link.id} ${index}`} xs={12} sm={6} md={4}>
                <Link href={link.url} target="_blank" rel="noreferrer" underline="none">
                  <Card>
                    <CardMedia component="img" height="194" image={news} alt="Imagen noticia" />
                    <CardContent>
                      <Typography variant="body2" color="text.secondary">
                        {link.descripcion}
                      </Typography>
                    </CardContent>
                    <CardActions disableSpacing>
                      <Button size="small">Visitar</Button>
                    </CardActions>
                  </Card>
                </Link>
              </Grid>
            )
          })}
        </Grid>
      )}
    </Box>
  )
}

export default VisorEnlaces
