import { Box, Button, Chip, Divider, TextField } from '@mui/material'
import PasswordForm from 'features/auth/components/PasswordForm'

const AccountSettings = () => {
  return (
    <>
      <h1 style={{ marginBottom: '1em' }}>Ajustes de la cuenta</h1>
      <PasswordForm />
    </>
  )
}

export default AccountSettings
