/**
 * @module Documents
 */

import { CustomModalContext } from 'common/contexts/CustomModalContext'
import { ModalTypes } from 'common/hooks/useCustomModal'
import { createContext, useContext, useState } from 'react'
import ThemeManager from 'theming/ThemeManager'
import LockDocumentNotification from '../components/LockDocumentNotification'
import useFetchLaboral from '../hooks/useFetchLaboral'
import DocumentsController from '../libs/CorporateController'

/**
 * @typedef DocumentsNotificationContext
 * @prop {function} forceRefresh - Force the refresh of the notifications
 * @prop {import('common/types/connections_types').PaginatedResponse<LaboralDocDTO>} response - The request full response
 * @prop {boolean} loading - Is currently loading the request
 * @prop {AxiosErr} error - The error of the request
 * @prop {number} count - The total ammount of unread documents
 * @prop {function} launchModal - Launch The last Locking document modal
 * @example
 * const { count, response, loading, errror, forceRefresh} ) = useContext(NotificationsContext)
 */

/**
 * @type {import('react').Context<DocumentsNotificationContext>}
 */
let DocumentNotificationContext

/**
 * @type {import('react').Context<DocumentsNotificationContext>}
 */
let { Provider } = (DocumentNotificationContext = createContext())

let DocumentNotificationProvider = ({ children }) => {
  const themeController = new ThemeManager()
  const { handleModal, modal } = useContext(CustomModalContext)
  const [iteration, setIteration] = useState(0)
  const { response, loading, error } = useFetchLaboral({
    unforce_year: true,
    iteration: iteration,
    tipo_documento: themeController?.options?.notifications?.laboral_document_type || null,
    only_unread: true,
    paginate: true,
  })

  function forceRefresh() {
    setIteration(iteration + 1)
  }

  async function launchModal() {
    const Theme = new ThemeManager()
    const NotificationComponent = Theme.components?.LockDocumentNotification
    try {
      if (modal) return
      const data = await DocumentsController.getLastUnreaded()

      if (data) {
        handleModal({
          enabled: true,
          type: ModalTypes.Custom,
          content: NotificationComponent ? (
            <NotificationComponent data={data} />
          ) : (
            <LockDocumentNotification data={data} />
          ),
          closable: false,
        })
      }
    } catch (error) {
      handleModal({ enabled: false, type: ModalTypes.Loading })
      return
    }
  }

  return (
    <Provider
      value={{ launchModal, forceRefresh, response, loading, error, count: response?.count }}
    >
      {children}
    </Provider>
  )
}

export { DocumentNotificationContext, DocumentNotificationProvider }
