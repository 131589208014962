import { Chip } from '@mui/material'
import { Box } from '@mui/system'

/**
 * Dibuja y selecciona las etiquetas de las noticias
 *
 * @param {Object} props
 * @param {Array<NewsTags>} props.tags - Array of the tags to show
 * @param {Array<NewsTags>} [props.selectedTags] - Array of the selected tags
 * @param {onTagSelected} [props.onTagSelected] - Event triggered when the user selects a tag
 */
const TagsFilter = ({ tags, selectedTags, onTagSelected }) => {
  return (
    <Box>
      {tags ? (
        tags.map((tag, idx) => (
          <Chip
            key={idx}
            onClick={() => {
              if (onTagSelected) {
                onTagSelected(tag)
              }
            }}
            sx={{ borderRadius: '5px', mr: 1, cursor: 'pointer' }}
            color={selectedTags?.includes(tag) ? 'primary' : 'default'}
            label={tag.nombre}
          ></Chip>
        ))
      ) : (
        <></>
      )}
    </Box>
  )
}

export default TagsFilter
