import CustomTable from 'common/components/CustomTable'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import useFetchMisSolicitudes from '../hooks/useFetchMisSolicitudes'

import { setSolicitudSeleccionada } from '../solicitudesSlice'

import { getLocaleFromFullDate } from 'common/libs/fechas'
import { getChipSolicitud } from '../libs/SolicitudesHelpers'

const MisSolicitudes = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { response, loading, error } = useFetchMisSolicitudes()
  const [solicitudes, setSolicitudes] = useState([])

  useEffect(() => {
    setSolicitudes(response)
  }, [response])

  const handleSelectSolicitud = (id) => {
    const solicitud = solicitudes.filter((s) => s.id === Number(id))[0]
    solicitud.fecha = null
    solicitud.fecha_validacion = null
    solicitud.estado = null

    dispatch(setSolicitudSeleccionada(solicitud))
    navigate(`/solicitud/`, { state: { solicitud } })
  }

  return (
    <CustomTable
      dataSource={solicitudes}
      showToolbar={true}
      initialPageSize={15}
      fields={[
        { key: 'ID', value: 'codigo_solicitud', flex: 0, sortable: true },
        { key: 'Tipo', value: 'd_tipo', flex: 1, sortable: true },
        {
          key: 'Fecha',
          value: 'fecha',
          flex: 1,
          sortable: true,
          customGetter: (s) =>
            s.row.fecha_creacion ? getLocaleFromFullDate(s.row.fecha_creacion) : '',
        },
        {
          key: 'Validación',
          value: 'fecha_validacion',
          flex: 1,
          customGetter: (s) =>
            s.row.status_fecha ? getLocaleFromFullDate(s.row.status_fecha) : '',
        },
        {
          value: 'estado',
          key: 'Estado',
          width: 150,
          filterable: false,
          sortable: false,
          renderCell: (s) => {
            return getChipSolicitud(s.status, s.d_status)
          },
        },
      ]}
      onClickEvent={(event) => handleSelectSolicitud(event.row.id)}
      loading={loading}
    />
  )
}

export default MisSolicitudes
