import { Box, Chip } from '@mui/material'
import TeamCalendarDates from './TeamCalendarDates'

const TeamCalendarList = ({ team }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {team?.map((group, i) => {
        return (
          <Box key={`cal_group_${i}`}>
            <Chip
              key={`chip_group_${i}`}
              label={group.name}
              size="small"
              sx={{
                justifyContent: 'flex-start',
                borderRadius: '0px',
                mt: 2,
                width: '100%',
              }}
            />
            {group.subteams.map((subteam, k) => {
              return (
                <Box key={`sub_group_${k}`}>
                  {subteam.name && subteam.name !== 'NO_SUBTEAM' ? (
                    <Chip
                      key={`sub_chip_${k}`}
                      label={subteam.name}
                      size="small"
                      variant="outlined"
                      sx={{
                        justifyContent: 'flex-start',
                        borderRadius: '0px',
                        borderTopWidth: '0px',
                        borderLeftWidth: '0px',
                        borderRightWidth: '0px',
                        mb: 1,
                        width: '100%',
                      }}
                    />
                  ) : null}

                  {subteam.employees.map((e, j) => {
                    return <TeamCalendarDates key={`sub_dat_${j}`} employee={e} />
                  })}
                </Box>
              )
            })}
          </Box>
        )
      })}
    </Box>
  )
}

export default TeamCalendarList
