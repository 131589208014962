import dayjs from 'dayjs'

const CODE_LENGTHS = {
  AD: 24,
  AE: 23,
  AT: 20,
  AZ: 28,
  BA: 20,
  BE: 16,
  BG: 22,
  BH: 22,
  BR: 29,
  CH: 21,
  CR: 21,
  CY: 28,
  CZ: 24,
  DE: 22,
  DK: 18,
  DO: 28,
  EE: 20,
  ES: 24,
  FI: 18,
  FO: 18,
  FR: 27,
  GB: 22,
  GI: 23,
  GL: 18,
  GR: 27,
  GT: 28,
  HR: 21,
  HU: 28,
  IE: 22,
  IL: 23,
  IS: 26,
  IT: 27,
  JO: 30,
  KW: 30,
  KZ: 20,
  LB: 28,
  LI: 21,
  LT: 20,
  LU: 20,
  LV: 21,
  MC: 27,
  MD: 24,
  ME: 22,
  MK: 19,
  MR: 27,
  MT: 31,
  MU: 30,
  NL: 18,
  NO: 15,
  PK: 24,
  PL: 28,
  PS: 29,
  PT: 25,
  QA: 29,
  RO: 24,
  RS: 22,
  SA: 24,
  SE: 24,
  SI: 19,
  SK: 24,
  SM: 27,
  TN: 24,
  TR: 26,
}

/**
 * Calcula el checksum del valor de entrada
 * @param {string} string - valor a calcular el módulo 97
 * @returns {string} checksum - módulo 97 de la entrada
 */
const mod97 = (string) => {
  var checksum = string.slice(0, 2),
    fragment
  for (var offset = 2; offset < string.length; offset += 7) {
    fragment = String(checksum) + string.substring(offset, offset + 7)
    checksum = parseInt(fragment, 10) % 97
  }
  return checksum
}

/**
 * Comprueba si el dato recibido es un DNI, NIE o Pasaporte válido
 * @param {string} data - DNI, NIE o pasaporte a validar
 * @throws Error si el valor no es válido
 * @returns {Boolean}
 */
const dniNiePassportValidator = (data) => {
  const validChars = 'TRWAGMYFPDXBNJZSQVHLCKET'
  const nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]$/i
  const nieRexp = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]$/i
  var str = data.toUpperCase()

  if (!nifRexp.test(str) && !nieRexp.test(str)) {
    throw new Error('El campo no es un NIF, NIE o Pasaporte válido')
  }

  var nie = str.replace(/^[X]/, '0').replace(/^[Y]/, '1').replace(/^[Z]/, '2')

  var letter = str.substr(-1)
  var charIndex = parseInt(nie.substr(0, 8)) % 23

  if (validChars.charAt(charIndex) === letter) return true

  if (/^[a-z]{3}[0-9]{6}[a-z]?$/i.test(data)) return true

  throw new Error('El campo no es un NIF, NIE o Pasaporte válido')
}

/**
 * Comprueba si el dato recibido es un IBAN válido
 * @param {string} data - IBAN a validar
 * @throws Error si el valor no es válido
 * @returns {Boolean}
 */
const iBan = (data) => {
  var iban = String(data)
      .toUpperCase()
      .replace(/[^A-Z0-9]/g, ''), // keep only alphanumeric characters
    code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
    digits

  if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
    throw new Error('El campo no es un IBAN válido')
  }

  digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
    return letter.charCodeAt(0) - 55
  })

  if (mod97(digits) != 1) {
    throw new Error('El campo no es un IBAN válido')
  }

  return true
}

/**
 * Comprueba si el dato recibido es un email válido
 * @param {string} data - email a validar
 * @throws Error si el valor no es válido
 * @returns {Boolean}
 */
const email = (data) => {
  var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/

  if (regex.test(data)) return true

  throw new Error('El campo no es un email válido')
}

const numeroSS = (data) => {
  if (!data || (data.length != 11 && data.length != 12)) {
    throw new Error('El campo no es un número de seguridad social válido')
  }
  if (data.substr(2, 1) == 0) data = '' + data.substr(0, 2) + data.substr(3, data.length - 1)

  if (mod97(data.substr(0, data.length - 2), 97) == data.substr(data.length - 2, 2)) return true

  throw new Error('El campo no es un número de seguridad social válido')
}

/**
 * Function to get or validate first available date
 * @param {CampoSolicitud} campo - Campo que se va a validar
 * @param {'Dayjs'|'boolean'} value - Valor del campo que se va a validar o true si se quiere recuperar el valor mínimo del campo
 * @returns {Object | Boolean}
 * @throws {Error}
 */
const fechaMinimaHoy = (field, value) => {
  const today = dayjs()
  if (value !== true) {
    const currentValue = dayjs(value, field.mascara)
    if (currentValue.isSame(today, 'day') || currentValue.isAfter(today, 'day')) return true
    throw new Error(`Solo se admiten fechas a partir de ${today.format('DD/MM/YYYY')}`)
  }
  return { minDate: today, maxDate: null }
}

/**
 * Function to get or validate first available date
 * @param {CampoSolicitud} campo - Campo que se va a validar
 * @param {'Dayjs'|'boolean'} value - Valor del campo que se va a validar o true si se quiere recuperar el valor mínimo del campo
 * @returns {Object | Boolean}
 * @throws {Error}
 */
const fechaMinimaMes = (field, value) => {
  const today = dayjs()
  const firstDayOfMonth = today.startOf('month')
  if (value !== true) {
    const currentValue = dayjs(value, field.mascara)
    if (currentValue.isSame(firstDayOfMonth, 'day') || currentValue.isAfter(firstDayOfMonth, 'day'))
      return true
    throw new Error(`Solo se admiten fechas a partir de ${currentValue.format('DD/MM/YYYY')}`)
  }
  return { minDate: firstDayOfMonth, maxDate: null }
}

/**
 * Function to get or validate first available date
 * @param {CampoSolicitud} campo - Campo que se va a validar
 * @param {'Dayjs'|'boolean'} value - Valor del campo que se va a validar o true si se quiere recuperar el valor mínimo del campo
 * @returns {Object | Boolean}
 * @throws {Error}
 */
const fechaMinimaLimite = (field, value) => {
  const today = dayjs()
  const firstDayOfNextMonth = today.month(today.month() + 1).date(1)

  if (today.day() >= Number(field.id_validacion.split('|')[1])) {
    if (value !== true) {
      const currentValue = dayjs(value, field.mascara)
      if (
        currentValue.isSame(firstDayOfNextMonth, 'day') ||
        currentValue.isAfter(firstDayOfNextMonth, 'day')
      )
        return true
      throw new Error(`Solo se admiten fechas a partir de ${currentValue.format('DD/MM/YYYY')}`)
    }
    return { minDate: firstDayOfNextMonth, maxDate: null }
  }
  return null
}

/**
 * Function to get or validate the available date range of the current month
 * @param {CampoSolicitud} campo - Campo que se va a validar
 * @param {'Dayjs'|'boolean'} value - Valor del campo que se va a validar o true si se quiere recuperar el valor mínimo del campo
 * @returns {Object | Boolean}
 * @throws {Error}
 */
const fechaMesActual = (field, value) => {
  const today = dayjs()
  const firstDayOfMonth = today.startOf('month')
  const lastDayOfMonth = today.endOf('month')

  if (value !== true) {
    const currentValue = dayjs(value, field.mascara)
    if (
      (currentValue.isSame(firstDayOfMonth, 'day') ||
        currentValue.isAfter(firstDayOfMonth, 'day')) &&
      (currentValue.isSame(lastDayOfMonth, 'day') || currentValue.isBefore(lastDayOfMonth, 'day'))
    )
      return true
    throw new Error(`Solo se admiten fechas a partir de ${currentValue.format('DD/MM/YYYY')}`)
  }
  return { minDate: firstDayOfMonth, maxDate: lastDayOfMonth }
}

/**
 * Function to get or validate first available date
 * @param {CampoSolicitud} campo - Campo que se va a validar
 * @param {'Dayjs'|'boolean'} value - Valor del campo que se va a validar o true si se quiere recuperar el valor mínimo del campo
 * @returns {Object | Boolean}
 * @throws {Error}
 */
const fechaMesActualLimite = (field, value) => {
  const today = dayjs()
  const firstDayOfMonth = today.startOf('month')
  const lastDayOfMonth = today.endOf('month')
  const firstDayOfNextMonth = today.month(today.month() + 1).date(1)

  if (today.day() >= Number(field.id_validacion.split('|')[1])) {
    if (value !== true) {
      const currentValue = dayjs(value, field.mascara)
      if (currentValue.isSame(firstDayOfNextMonth, 'day')) return true
      throw new Error(`Solo se admite la fecha ${firstDayOfNextMonth.format('DD/MM/YYYY')}`)
    }
    return { minDate: firstDayOfNextMonth, maxDate: firstDayOfNextMonth }
  }

  if (value !== true) {
    const currentValue = dayjs(value, field.mascara)
    if (
      (currentValue.isSame(firstDayOfMonth, 'day') ||
        currentValue.isAfter(firstDayOfMonth, 'day')) &&
      (currentValue.isSame(lastDayOfMonth, 'day') || currentValue.isBefore(lastDayOfMonth, 'day'))
    )
      return true
    throw new Error(
      `Solo se admiten fechas entre el ${firstDayOfMonth.format(
        'DD/MM/YYYY',
      )} y ${lastDayOfMonth.format('DD/MM/YYYY')}`,
    )
  }
  return { minDate: firstDayOfMonth, maxDate: lastDayOfMonth }
}

const sinAcentos = function (value) {
  return value.normalize('NFD').replace(/\p{Diacritic}/gu, '')
}

/**
 * Comprueba si se debe validar el campo y ejecuta la validación
 * @param {CampoSolicitud} campo - Campo que se va a validar
 * @param {'string'|'number'} campo - Campo que se va a validar
 * @returns {Boolean}
 */
export const validate = (campo, valor) => {
  if (!valor || valor === '') return true

  switch (campo.id_validacion) {
    case 'NIF_NIE_PASAPORTE':
      return dniNiePassportValidator(valor)
    case 'IBAN':
      return iBan(valor)
    case 'EMAIL':
      return email(valor)
    case 'NUMERO_SS':
      return numeroSS(valor)
    case 'SIN_ACENTOS':
      return sinAcentos(valor)
    case 'FECHA_MINIMA_HOY':
      return fechaMinimaHoy(campo, valor)
    case 'FECHA_MINIMA_MES':
      return fechaMinimaMes(campo, valor)
    case 'FECHA_MES_ACTUAL':
      return fechaMesActual(campo, valor)
  }

  if (campo.id_validacion?.includes('FECHA_MINIMA_LIMITE')) {
    return fechaMinimaLimite(campo, valor)
  }
  if (campo.id_validacion?.includes('FECHA_MES_ACTUAL_LIMITE')) {
    return fechaMesActualLimite(campo, valor)
  }

  return true
}

/**
 * Sanitize and validates the numeric value of a field against its maskº
 * @param {CampoSolicitud} campo
 * @param {'string'|'number'} valor
 * @returns {Number}
 */
export const numericValidation = (campo, valor, blurValidation = false) => {
  const value = valor.replace(/[^0-9,.]/g, '').replace(',', '.')

  if (isNaN(value)) {
    /** Returns all but last character */
    return value.slice(0, -1)
  }

  const has_decimals = value.includes('.')
  const no_decimals_allowed = campo.decimals === 0

  const error = `Se permiten ${campo.integers} posiciones enteras como máximo con un mínimo de ${campo.required_integers} obligatorias y ${campo.decimals} posiciones decimales con un mínimo de ${campo.required_decimals} obligatorias`

  let integers = ''
  let decimals = ''
  const parts = value.split('.')
  if (parts) {
    integers = parts[0]
    if (parts.length > 1) {
      decimals = parts[1]
    }
  }

  if (campo.integers && integers.length > campo.integers) {
    integers = integers.substring(0, campo.integers)
    throw new Error(error)
  }

  if (campo.decimals && decimals.length > campo.decimals) {
    decimals = decimals.substring(0, campo.decimals)
    throw new Error(error)
  }

  if (blurValidation) {
    if (campo.required_integers && integers.length < campo.required_integers) {
      integers = integers.padStart(campo.required_integers, '0')
      throw new Error(error)
    }
    if (campo.required_decimals && decimals.length < campo.required_decimals) {
      decimals = decimals.padEnd(campo.required_decimals, '0')
      throw new Error(error)
    }
  }

  const masked_value = no_decimals_allowed
    ? `${integers}`
    : decimals || has_decimals
    ? `${integers}.${decimals}`
    : `${integers}`

  return masked_value
}
