import { Box, Button, LinearProgress } from '@mui/material'
import SimpleTable from 'common/components/SimpleTable'
import { CustomModalContext } from 'common/contexts/CustomModalContext'
import FilesHelper from 'common/libs/Files'
import { enqueueSnackbar } from 'notistack'
import { useContext } from 'react'
import ThemeManager from 'theming/ThemeManager'
import { deleteHojaGastoLin } from '../lib/HojasGastoLinController'
import { HojasGastoLinAddModal } from './HojasGastoLinAddModal'
import { LinEditModalBody } from './LinEditModalBody'

export const HojasGastoLinTable = ({
  id_hoja,
  showLin = true,
  showButton = true,
  handleChangeState,
  fechaInicio,
  fechaFin,
  disableAll,
  lineas,
  setLineas,
  loading,
}) => {
  const Theme = new ThemeManager()
  const { handleModal } = useContext(CustomModalContext)

  const handleDeleteClick = async ({ id_hoja, id_lin }) => {
    try {
      await deleteHojaGastoLin({ id_hoja, id_lin })
      enqueueSnackbar('Línea borrada correctamente', { variant: 'success' })
      handleChangeState()
    } catch (error) {
      enqueueSnackbar('No se ha podido borrar la línea')
      console.log(error.message)
    }
  }
  const handleDownloadClick = async ({ url }) => {
    try {
      FilesHelper.downloadFile(1, 0, url)
    } catch (error) {
      console.log(error.message)
    }
  }

  return !loading ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: '10px',
        width: '100%',
      }}
    >
      <HojasGastoLinAddModal
        id_hoja={id_hoja}
        showButton={showButton}
        updateState={(linea) => {
          setLineas([...lineas, linea])
        }}
        fechaInicio={fechaInicio}
        fechaFin={fechaFin}
      />
      <SimpleTable
        dataSource={lineas}
        header_style={{
          backgroundColor: Theme.pallete?.primary?.main,
          color: Theme.pallete.primary.contrastText,
        }}
        header_cell_style={{ padding: '10px' }}
        table_style={{ width: '100%', borderCollapse: 'collapse' }}
        container_style={{ borderRadius: '5px', marginBottom: '5px', marginTop: '10px' }}
        generic_cell_style={{
          textAlign: 'center',
          padding: '15px 10px',
        }}
        loading={loading}
        emptyText={'No hay líneas de hojas de gasto'}
        stripped={true}
        fields={[
          {
            field: 'fecha',
            title: 'FECHA',
          },
          {
            field: 'concepto',
            title: 'CONCEPTO',
          },
          {
            field: 'descripcion',
            title: 'DESCRIPCIÓN',
          },
          {
            field: 'observaciones',
            title: 'OBSERVACIONES',
          },
          {
            field: 'cantidad',
            title: 'CANTIDAD',
          },
          {
            field: 'precio',
            title: 'PRECIO',
          },
          {
            field: 'importe_interno',
            title: 'IMPORTE',
          },
          {
            field: 'ficheros',
            title: 'FICHEROS',
            customGetter: (lin) => (
              <Box>
                {lin.documentos.map((doc) => (
                  <Button onClick={() => handleDownloadClick({ url: doc.url })} key={doc.url}>
                    {doc.descripcion}
                  </Button>
                ))}
              </Box>
            ),
          },
          {
            field: 'FUNCIONES',
            title: 'FUNCIONES ',
            customGetter: (item) =>
              showButton && !disableAll ? (
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px', flexWrap: 'wrap' }}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      const modalBody = (
                        <LinEditModalBody
                          id_hoja={id_hoja}
                          updateState={(linea) => {
                            let lin = []
                            lineas.forEach((line) => {
                              if (line.id_lin !== linea.id_lin) lin.push(line)
                            })
                            lin.push(linea)
                            setLineas([...lin])
                          }}
                          editObject={item}
                          fechaInicio={fechaInicio}
                          fechaFin={fechaFin}
                        />
                      )
                      handleModal({
                        closable: true,
                        content: modalBody,
                      })
                    }}
                  >
                    Editar
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() =>
                      handleDeleteClick({ id_hoja: item.id_hoja, id_lin: item.id_lin })
                    }
                    sx={{ background: 'red', ':hover': { background: 'darkred' } }}
                  >
                    Borrar
                  </Button>
                </Box>
              ) : null,
          },
        ]}
      />
    </Box>
  ) : (
    <Box sx={{ mt: 1, width: '100%' }}>
      <LinearProgress />
    </Box>
  )
}
