import { Box } from '@mui/system'
import { CustomModalContext } from 'common/contexts/CustomModalContext'
import { useContext, useEffect } from 'react'
import { InformesContext } from '../providers/InformesProvider'
import InformeDataTable from './InformeDataTable'
import InformesFilter from './InformesFilter'

const InformeData = ({ forced }) => {
  const { active, menu } = useContext(InformesContext)
  const { handleModal } = useContext(CustomModalContext)
  useEffect(() => {
    if (forced) active.setCurrent({ nombre_informe: forced })
    else if (!active.current?.codigo_menu && !active.current?.nombre_informe) {
      active.setCurrent(null)
    }
  }, [forced])

  return (
    <>
      {active.current ? <InformesFilter /> : <></>}
      {active.current?.nombre_informe && active?.structure?.response?.permitir_excel ? (
        <Box
          sx={{
            mb: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        ></Box>
      ) : (
        <></>
      )}
      <>{menu?.response && !active?.current?.nombre_informe ? <></> : <InformeDataTable />}</>
    </>
  )
}

export default InformeData
