import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { fetchOrganigrama, selectOrganigrama, selectStatusOrganigrama } from '../organigramaSlice'

import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import TreeView from '@mui/lab/TreeView'
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem'
import Typography from '@mui/material/Typography'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { Alert, LinearProgress, ListItem, ListItemIcon, ListItemText, Paper } from '@mui/material'
import { Person, BusinessOutlined } from '@mui/icons-material'
import { useLocation } from 'react-router-dom'
import useFetchOrganigrama from '../hooks/useFetchOrganigrama'

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '&.Mui-expanded': {
      fontWeight: theme.typography.fontWeightRegular,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: 'var(--tree-view-color)',
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: '40px',
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2),
    },
  },
}))

function StyledTreeItem(props) {
  const { bgColor, color, labelInfo, labelText, ...other } = props

  return (
    <StyledTreeItemRoot
      label={
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', p: 0.5, pr: 0 }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <BusinessOutlined
              sx={{
                marginRight: 1,
              }}
              fontSize="inherit"
            />
            <Typography variant="body2">{labelText}</Typography>
          </div>
          {labelInfo.map((employee, index) => (
            <div
              key={`${employee.dni}${index}`}
              style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <Person
                sx={{
                  color: 'primary.main',
                  marginRight: 1,
                }}
                fontSize="inherit"
                color="primary.main"
              />
              <Typography variant="caption" color="primary.main">
                {`${employee.nombre} ${employee.primer_apellido} ${employee.segundo_apellido}`}
              </Typography>
            </div>
          ))}
        </Box>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      {...other}
    />
  )
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelInfo: PropTypes.array,
  labelText: PropTypes.string.isRequired,
}

const Estructura = () => {
  const location = useLocation()
  const { response, loading: isLoading, error } = useFetchOrganigrama(location?.state?.user ?? null)
  const [organigrama, setOrganigrama] = useState(response)
  const [loading, setLoading] = useState(isLoading)

  useEffect(() => {
    setOrganigrama(response)
  }, [response])

  useEffect(() => {
    setLoading(isLoading)
  }, [isLoading])

  const nodos = (datos, iniciar = false) => {
    const listaNodos = datos.map((rama, idx) => {
      return rama.hijos ? (
        <StyledTreeItem
          key={`id_${idx}${rama.id.toString()}`}
          nodeId={rama.id.toString()}
          labelText={rama.descripcion}
          labelInfo={rama.empleados}
        >
          {nodos(rama.hijos, false)}
        </StyledTreeItem>
      ) : (
        <StyledTreeItem
          key={`id_${idx}${rama.id.toString()}`}
          nodeId={rama.id.toString()}
          labelText={rama.descripcion}
          labelInfo={rama.empleados}
        />
      )
    })
    return listaNodos
  }

  const arbolOrga =
    organigrama && organigrama.length > 0 ? (
      nodos(organigrama, true)
    ) : (
      <>
        {loading ? (
          <LinearProgress />
        ) : (
          <>
            {error ? (
              <Alert severity="warning">
                No hemos podido obtener el organigrama para este usuario. Puede que se encuentre mal
                parametrizado
              </Alert>
            ) : (
              <></>
            )}
          </>
        )}
      </>
    )

  return (
    <TreeView
      aria-label="organigrama"
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      defaultEndIcon={<div style={{ width: 24 }} />}
      sx={{
        backgroundColor: 'background.paper',
        borderRadius: '5px',
        flexGrow: 1,
        mt: 1,
        overflowY: 'auto',
      }}
    >
      {arbolOrga}
    </TreeView>
  )
}

export default Estructura
