import styled from '@emotion/styled'
import { CssBaseline } from '@mui/material'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import AutomationController from 'automations/AutomationController'
import ProvidersNest from 'common/components/ProvidersNest'
import SnackbarCloseButton from 'common/components/SnackbarCloseButton'
import RoutesController from 'features/routing/components/RoutesController'
import { MaterialDesignContent, SnackbarProvider } from 'notistack'
import { useState } from 'react'
import ThemeManager from 'theming/ThemeManager'

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent': {
    fontSize: '1em',
  },
}))

function App() {
  const themeController = new ThemeManager()

  if (themeController.activeTheme.options.fullHideMenu)
    localStorage.setItem('NO_EXPAND_MENU', 'true')

  const [themePallete] = useState(
    createTheme({
      palette: themeController.activePallete(),
      components: themeController?.activeTheme?.components_styles ?? {},
      typography: themeController?.typography,
    }),
  )

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={themePallete}>
        <CssBaseline enableColorScheme={true}>
          <ProvidersNest>
            <SnackbarProvider
              action={(key) => <SnackbarCloseButton snackbarKey={key} />}
              autoHideDuration={2000}
              Components={{
                default: StyledMaterialDesignContent,
                info: StyledMaterialDesignContent,
                error: StyledMaterialDesignContent,
                success: StyledMaterialDesignContent,
                warning: StyledMaterialDesignContent,
              }}
              anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
              maxSnack={3}
              SnackbarProps={{}}
            >
              <AutomationController />
              <RoutesController />
            </SnackbarProvider>
          </ProvidersNest>
        </CssBaseline>
      </ThemeProvider>
    </LocalizationProvider>
  )
}

export default App
