import axios from 'api/axios'
import { useCallback } from 'react'
const { useState, useEffect } = require('react')

/**
 * @module FetchHooks
 */

/**
 * Fetch the calendar data for the user
 * @param {Object} props - The component props
 * @param {number} props.year - The year to see the calendar
 * @param {UserToHandle} [props.user] - The user to make the request
 * @returns {UseFetchHookResponse<any>}
 */
const useFetchWidget = ({ year, user = null }) => {
  const [response, setResponse] = useState({})
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const fetchData = useCallback(() => {
    setLoading(true)
    setError(null)
    axios
      .get(`/calendariowidgets/?y=${year}&user=${user?.usuario_web ?? ''}`)
      .then((request_response) => {
        const data = request_response.data.computo_vacaciones
        const response = {
          autorizadas: data.autorizadas,
          pendientes: data.pendientes,
          disfrutados: data.disfrutados,
          pendientes_real: data.pendientes_real,
          tipo_dias: data.tipo_dias,
          permisos: data.permisos,
          solicitadas: data.solicitadas,
          solicitud_vacaciones_activa: data.solicitud_vacaciones_activa
        }
        setResponse(response)
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
  }, [year, user?.usuario_web])

  useEffect(() => {
    fetchData()
  }, [year, user, fetchData])

  return { response, error, loading }
}

export default useFetchWidget
