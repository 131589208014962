// @ts-check
import { DownloadForOfflineOutlined, SimCardDownloadOutlined } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import React from 'react'
import downloadNewsFile from '../libs/downloadNewsFile'

/**
 * Show the files and the Video
 * of the passed news
 *
 * @param {Object} props
 * @param {NewsDetailsDTO} props.news - The instance of the news
 */
const NewsDetailsMedia = ({ news }) => {
  return (
    <Box sx={{ mt: 1 }}>
      {news.ficheros.map((file, index) => (
        <Box
          onClick={() => {
            downloadNewsFile(file.id)
          }}
          key={index}
          sx={{
            py: 1,
            display: 'flex',
            cursor: 'pointer',
            placeItems: 'center',
            borderRadius: '5px',
            transition: '.3s',
            '&:hover': {
              backgroundColor: 'background.lighter',
            },
          }}
        >
          <Box sx={{ mr: 1, display: 'grid', placeItems: 'center' }}>
            <SimCardDownloadOutlined sx={{ fontSize: '2.5em' }} />
          </Box>
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', align: 'center' }}>
            <Typography
              sx={{
                width: '100%',
                fontWeight: 'bold',
                color: 'alt.main',
                fontSize: '1.1rem',
                m: 0,
                lineHeight: '1.1rem',
              }}
            >
              Descargar
            </Typography>
            <Typography sx={{ width: '100%', fontSize: '0.8rem', m: 0, lineHeight: '0.9rem' }}>
              {file.nombre}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  )
}

export default NewsDetailsMedia
