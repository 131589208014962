/* eslint-disable react-hooks/exhaustive-deps */
import { Menu as MenuIcon } from '@mui/icons-material'
import { AppBar, Box, IconButton, Toolbar, createTheme } from '@mui/material/'
import { useEffect, useState } from 'react'
import { Link as LinkNav } from 'react-router-dom'

import { selectUsuario } from 'features/users/stores/userSlice'
import { useSelector } from 'react-redux'

import ThemesManager from 'theming/ThemeManager'
import Logo from 'common/components/Logo'
import HeaderActions from './HeaderActions'
import { NotificationsProvider } from 'features/notifications/providers/NotificationsProvider'

const Header = ({ handleDrawerToggle }) => {
  const usuario = useSelector(selectUsuario)
  const themeController = new ThemesManager()
  const [theme, setTheme] = useState(
    createTheme({
      palette: themeController.activePallete(),
    }),
  )

  useEffect(() => {
    setTheme(theme)
  }, [])

  if (!usuario) return <></>

  return (
    <AppBar
      position="static"
      sx={{
        boxShadow: 'none',
        px: 0,
        zIndex: 98,
        position: { xs: 'fixed', md: 'initial' },
      }}
    >
      <Toolbar
        sx={{
          height: {
            xs: '50px',
            md: '50px',
          },
          px: { xs: 0, sm: 1 },
          maxHeight: '80px',
          minHeight: '70px !important',
          backgroundColor: {
            xs: 'background.alternative',
            md: 'background.default',
          },

          paddingTop: {
            xs: '0px',
            md: '10px',
          },
        }}
      >
        <IconButton
          sx={{
            display: {
              xs: 'flex',
              md: themeController.activeTheme.options.fullHideMenu ? 'flex' : 'none',
            },
            color: { xs: 'background.alternativeContrastText', md: 'text.primary' },
          }}
          aria-label="delete"
          size="large"
          onClick={handleDrawerToggle}
        >
          <MenuIcon color="background.alternativeContrastText" fontSize="inherit" />
        </IconButton>
        <HeaderActions />
        <Box sx={{ flexGrow: 1 }}></Box>
        <Box
          sx={{
            display: { xs: 'none', md: 'flex' },
            // padding: 2,
            // width: '100%',
            // maxWidth: '20%',
            height: '100%',
          }}
        >
          <LinkNav to="/" style={{ width: 'auto', height: '100%' }}>
            <Logo logoType="Header" sx={{ height: '100%', width: 'auto' }} />
          </LinkNav>
        </Box>
      </Toolbar>
    </AppBar>
  )
}
export default Header
