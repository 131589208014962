import ThemeManager from 'theming/ThemeManager'
import { useEffect, useState } from 'react'

/**
 * @typedef {Object} useIntersectionObserverResult
 * @property {boolean} isVisible
 * @property {IntersectionObserverEntry} entry
 */

/**
 * A hook for the IntersectionObserver usefull when you need
 * to watch for the visibility of an element on the viewport
 *
 * @param {import('react').MutableRefObject<Element>} elementRef - The element to watch
 * @returns {useIntersectionObserverResult}
 */
export function useIntersectionObserver(elementRef) {
  const [entry, setEntry] = useState()

  const updateEntry = ([entry]) => {
    setEntry(entry)
  }

  useEffect(() => {
    const node = elementRef?.current // DOM Ref
    const hasIOSupport = !!window.IntersectionObserver

    if (!hasIOSupport || !node) return

    const observer = new IntersectionObserver(updateEntry)

    observer.observe(node)

    return () => observer.disconnect()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef.current])

  return { isVisible: entry?.isIntersecting, entry }
}
