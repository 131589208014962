import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import AdjustIcon from '@mui/icons-material/Adjust'

const CommentsStepper = ({ commentsChat, commentsObs }) => {
  let allObs = []
  if (commentsChat !== undefined) {
    allObs = commentsChat?.concat(commentsObs)
  } else if (commentsObs !== undefined) {
    allObs = commentsObs?.concat(commentsChat)
  }

  if (allObs.length > 0) {
    allObs = allObs.sort((a, b) => new Date(a.fecha_grabacion) - new Date(b.fecha_grabacion))
  }

  return (
    <Stepper nonLinear orientation="vertical">
      {allObs.map((step, index) =>
        step?.observaciones !== undefined ? (
          <Step key={index} active>
            <StepLabel icon={<AdjustIcon />}>
              <p style={{ fontWeight: 'initial' }}>
                <span style={{ color: 'gray' }}>
                  {new Date(step?.fecha_grabacion).toLocaleString('es-ES')}
                </span>
                <br />
                <span style={{ fontWeight: 'bold' }}>{step?.nombre + ': '}</span>
                {step?.observaciones}
              </p>
            </StepLabel>
          </Step>
        ) : null
      )}
    </Stepper>
  )
}

export default CommentsStepper
