/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from 'react'
import axios from 'api/axios'

/**
 * @module FetchHooks
 */
/**
 * @module Informes
 */

/**
 * [TODO:description]
 *
 * @param {Object} props
 * @param {string} props.nombre_informe
 * @returns {import('common/types/connections_types').UseFetchHookResponse<InformeStructureDTO>} [TODO:description]
 */
const useFetchInformeStructure = ({ nombre_informe }) => {
  const [response, setResponse] = useState()
  const [error, setError] = useState()
  const [loading, setLoading] = useState()

  const fetchData = useCallback(() => {
    if (loading || !nombre_informe) return
    setLoading(true)

    axios
      .get(`/informes/estructura?informe=${nombre_informe}`)
      .then((request_response) => {
        setResponse(request_response.data)
        setError(null)
      })
      .catch((request_error) => setError(request_error))
      .finally(() => setLoading(false))
  }, [nombre_informe])

  useEffect(() => {
    if (!loading) fetchData()
  }, [fetchData, nombre_informe])

  return { response, error, loading }
}

export default useFetchInformeStructure
