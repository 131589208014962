/**
 * Allow to make a map a static number of times
 *
 * @param {number} n - The ammount of times will be iterated
 * @param {any} returnValue - What will be returned
 * @returns {Array<any>} - An array size n filled with the return value
 */
export function numericMap(n, returnValue) {
  return [...Array(n)].map(() => returnValue)
}

/**
 * @callback PropsGetterCallback
 * @param {number} idx - The current iteration index
 * @returns {Object}
 */

/**
 * Allow to make a map a static number of times
 *
 * @param {number} n - The ammount of times will be iterated
 * @param {React.ComponentType} Element - What will be returned
 * @param {PropsGetterCallback} propsGetter - Function to make custom element parameters
 * @returns {Array<any>} - An array size n filled with the return value
 */
export function numericElementMap(n, Element, propsGetter = () => {}) {
  return [...Array(n)].map((_, idx) => <Element key={idx} {...propsGetter(idx)} />)
}
