//@ts-check
import axios from 'api/axios'
const { useState, useEffect } = require('react')

/**
 * @module FetchHooks
 */

/**
 * Hook that fetch the my_users_data
 * @function
 * @param {string} [level=null]
 * @param {UserToHandle} [user=null]
 * {@link http://localhost:8000/api/v1/docpersonal/?n=1}
 * @returns {import('common/types/connections_types').UseFetchHookResponse<PersonalDocumentDtoArray>}
 */
const useFetchLevelDocs = (level = null, user = null) => {
  const [response, setResponse] = useState([])
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const fetchData = () => {
    if (loading) {
      return
    }
    setLoading(true)
    axios
      .get(`/docpersonal/?n=${level ?? ''}&user=${user?.id ?? ''}`)
      .then(
        (/**@type {import('axios').AxiosResponse<PersonalDocumentDtoArray>}*/ request_response) => {
          const data = request_response.data
          setResponse(data)
        },
      )
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (!level) {
      setResponse([])
      setLoading(false)
      setError(null)
      return
    }
    if (!loading) fetchData()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [level, user])

  return { response, error, loading }
}

export default useFetchLevelDocs
