// @ts-check
/* eslint-disable react-hooks/exhaustive-deps */
import {
  BottomNavigation,
  BottomNavigationAction,
  Grid,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  Stack,
  Alert,
  AlertTitle,
  LinearProgress,
} from '@mui/material'
import CustomMap from '../components/CustomMap'
import FavoriteIcon from '@mui/icons-material/Favorite'
import AlarmIcon from '@mui/icons-material/Alarm'
import { Map, PlayArrow, Stop } from '@mui/icons-material'
import useFetchLastFichajes from '../hooks/useFetchLastFichajes'
import React, { useContext, useEffect, useState } from 'react'
import { CustomModalContext } from 'common/contexts/CustomModalContext'
import { ModalTypes } from 'common/hooks/useCustomModal'
import LastFichajesTable from '../components/LastFichajesTable'
import PresenceActions from '../components/PresenceActions'
import useFetchTipoSalida from 'features/control_presencia/hooks/useFetchTipoSalida'
import FichajesController, { PresenceControlActions } from '../libs/FichajesController'
import { enqueueSnackbar } from 'notistack'
import { tiposSalidaMap } from '../libs/tiposSalidaMap'

/**
 * @module ControlPresencia
 */

/**
 * This component works as a page for the Presence Control
 * to mark enters and leave
 * @example
 *
 * <Fichajes />
 *
 * @returns {JSX.Element}
 */
const Fichajes = () => {
  /**
   * The tipes of modal
   * @readonly
   * @enum {string}
   */
  const MovilView = {
    Map: 'Map',
    Records: 'Records',
  }
  const { response: tiposSalida } = useFetchTipoSalida()
  const [lastIteration, setLastIteration] = useState(0)
  const { response, loading, error } = useFetchLastFichajes(lastIteration)
  const { handleModal, modal } = useContext(CustomModalContext)
  const [recordToShow, setRecordToShow] = useState()
  const [mobileSection, setMobileSection] = useState(MovilView.Map)
  /**
   * Mark a presence control registry
   * @async
   * @param {PresenceControlActions} type - The tipe of action to perform
   * @param {string} [exitCode] - If is a exit type this param must be provided
   * @returns {Promise<void>}
   * */
  async function markAction(type, exitCode) {
    try {
      handleModal({ type: ModalTypes.Loading, enabled: true })
      await FichajesController.create(type, exitCode)
      setLastIteration(lastIteration + 1)
      enqueueSnackbar('Guardado correctamente', {
        variant: 'success',
      })
      console.error(error)
    } catch (error) {
      enqueueSnackbar('Ha ocurrido un error', {
        variant: 'error',
      })
      console.error(error)
    } finally {
      handleModal({ type: ModalTypes.Loading, enabled: false })
    }
  }

  useEffect(() => {
    handleModal({ type: ModalTypes.Loading, enabled: loading })
  }, [loading])

  return (
    <Grid container spacing={2} sx={{ height: '100%', position: 'relative' }}>
      <Grid
        item
        xs={12}
        sm={0}
        sx={{
          display: {
            sm: 'none',
            xs: mobileSection === MovilView.Records ? 'initial' : 'none',
          },
        }}
      >
        <LastFichajesTable
          // @ts-ignore
          setRecord={setRecordToShow}
          response={response}
        />
      </Grid>
      <Grid
        item
        xs={mobileSection === MovilView.Map ? 12 : 0}
        sm={6}
        sx={{
          '&  .map': {
            // width: "100%",
            height: '100%',
            zIndex: 2,
          },
        }}
      >
        <CustomMap />
      </Grid>
      <Grid
        item
        xs={0}
        sm={6}
        sx={{
          display: {
            xs: 'none',
            sm: 'initial',
          },
        }}
      >
        <Stack
          spacing={2}
          sx={{
            height: '100%',
            '& .map': {
              height: '100%',
            },
          }}
        >
          <PresenceActions
            loading={loading}
            setLastIteration={setLastIteration}
            lastIteration={lastIteration}
            handleModal={handleModal}
            modal={modal}
          />

          {response && !error ? (
            <LastFichajesTable
              // @ts-ignore
              setRecord={setRecordToShow}
              response={response}
            />
          ) : (
            <LinearProgress />
          )}
          {error ? (
            <Alert severity="error">
              <AlertTitle>Error de conexión</AlertTitle>
              No se han podido obtener los fichajes anteriores
            </Alert>
          ) : (
            <></>
          )}
          {/**recordToShow ? (
            <Box
              sx={{
                height: '100%',
                border: '1px solid',
                borderColor: 'primary.main',
              }}
            >
              <CustomMap forced_position={[recordToShow.latitud, recordToShow.longitud]} />
            </Box>
          ) : (
            <></>
          )*/}
        </Stack>
      </Grid>
      <BottomNavigation
        showLabels
        onChange={(event, value) => {
          console.log(value)
          setMobileSection(value)
        }}
        value={mobileSection}
        sx={{
          display: {
            xs: 'flex',
            sm: 'none',
          },
          width: '100%',
          position: 'fixed',
          justifyContent: 'space-evenly',
          bottom: 0,
          left: 0,
          zIndex: 3,
        }}
      >
        <SpeedDial
          ariaLabel="SpeedDial basic example"
          sx={{ position: 'absolute', bottom: 25, left: '50%', transform: 'translate(-50%, 0)' }}
          icon={<SpeedDialIcon />}
        >
          <SpeedDialAction
            icon={<PlayArrow />}
            tooltipOpen
            onClick={async () => {
              markAction(PresenceControlActions.Entrada)
            }}
            tooltipTitle={'Entrada'}
          />
          {tiposSalida ? (
            tiposSalida.map((tipo) => {
              const Icon = tiposSalidaMap[tipo.codigo] || tiposSalidaMap.DEFAULT
              return (
                <SpeedDialAction
                  icon={<Icon />}
                  sx={{
                    '& .MuiSpeedDialAction-staticTooltipLabel': {
                      whiteSpace: 'nowrap',
                    },
                  }}
                  tooltipOpen
                  onClick={async () => {
                    markAction(PresenceControlActions.Salida, tipo.codigo)
                  }}
                  tooltipTitle={tipo.descripcion}
                />
              )
            })
          ) : (
            <SpeedDialAction
              icon={<Stop />}
              tooltipOpen
              onClick={async () => {
                markAction(PresenceControlActions.Salida)
              }}
              tooltipTitle={'Salida'}
            />
          )}
        </SpeedDial>
        <BottomNavigationAction
          sx={{ width: '100%' }}
          label="Mapa"
          value={MovilView.Map}
          icon={<Map />}
        />
        <BottomNavigationAction
          sx={{ width: '100%' }}
          label="Fichajes"
          value={MovilView.Records}
          icon={<AlarmIcon />}
        />
      </BottomNavigation>
    </Grid>
  )
}

export default Fichajes
