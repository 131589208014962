import { CheckRounded, Download, Folder } from '@mui/icons-material'
import { Grid, Box, Typography, Button, Divider } from '@mui/material'
import useCurrentMenu from 'common/hooks/useCurrentMenu'
import PersonalDocCard from './PersonalDocCard'

/**
 * @component
 * @param {Object} props
 * @param {PersonalDocumentDtoArray} props.documents
 * @param {string} props.levelName
 * @param {Function} props.setCurrentLevel
 * @returns {JSX.Element}
 */
const DocumentsView = ({ documents, setCurrentLevel, hideNav = false }) => {
  const { currentMenu, actualMatch } = useCurrentMenu()
  return (
    <Box sx={{ p: 2 }}>
      {hideNav ? (
        <></>
      ) : (
        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
          <Folder color="primary" sx={{ transform: 'translateY(-2px)', mr: 2 }} />
          <Typography>
            {currentMenu?.nombre}: {actualMatch?.nombre}
          </Typography>
        </Box>
      )}
      <Grid spacing={1} container>
        {documents?.length > 0 ? (
          documents.map((document, idx) => {
            return (
              <Grid item key={idx} md={2} sm={6} xs={12} xl={3}>
                <PersonalDocCard document={document} />
              </Grid>
            )
          })
        ) : (
          <></>
        )}
      </Grid>
    </Box>
  )
}

export default DocumentsView
