// @ts-check
import axios from 'api/axios'
import { useEffect, useState } from 'react'

/**
 * @module FetchHooks
 */

/**
 * @module Noticias
 */

/**
 * @typedef {Object} fetchNewsMeta
 * @property {Array<NewsTags>} tags
 * @property {Array<NewsCategories>} categories
 * @property {import('common/types/connections_types').UseFetchHookResponse<NewsMetaDTO>} response
 * @property {import("axios").AxiosError} error
 * @property {boolean} loading
 */

/**
 * Hook that fetch the news meta (categories | tags)
 * @function FetuseFetchNewsMeta
 * @link http://localhost:8000/api/v1/noticias
 * @example
 *
 * const {tags, categories, response, error, loading} = useFetchNewsMeta()
 *
 * @returns {fetchNewsMeta}
 */
const useFetchNewsMeta = (tipo = 'N') => {
  const [response, setResponse] = useState(null)
  const [categories, setCategories] = useState(null)
  const [tags, setTags] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const fetchData = () => {
    if (loading) {
      return
    }
    setLoading(true)

    const endpoint = tipo === 'N' ? 'noticias/meta' : 'comunicados/meta'

    axios
      .get(`/${endpoint}`)
      .then((/**@type {import('axios').AxiosResponse<NewsMetaDTO>}*/ request_response) => {
        const data = request_response.data
        setTags(data.etiquetas)
        setCategories(data.categorias)
        setResponse(data)
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (!loading) fetchData()
  }, [])

  return { categories: categories ?? [], tags: tags ?? [], response, error, loading }
}

export default useFetchNewsMeta
