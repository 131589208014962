import { Box } from '@mui/material'
import { useEffect, useState } from 'react'

const TeamCalendarHeader = function ({ year, month }) {
  const [days, setDays] = useState([])

  useEffect(() => {
    let daysTitle = []
    const lastDayOfMonth = new Date(year, month, 0).getDate()
    var options = {
      weekday: 'short',
    }
    for (let d = 1; d <= lastDayOfMonth; d++) {
      const day = new Date(year, month, d)
      daysTitle.push(day.toLocaleDateString('es', options).toUpperCase()[0])
    }
    setDays(daysTitle)
  }, [year, month])

  return (
    <Box sx={{ display: 'flex' }}>
      <Box key={1} sx={{ padding: '5px', flex: '1 0 25%' }}>
        &nbsp;
      </Box>
      <Box
        key={2}
        sx={{
          display: 'flex',
          flex: '1 0 75%',
          overflowX: 'auto',
          justifyContent: 'space-between',
        }}
      >
        {days.map((d, i) => (
          <Box
            key={i}
            sx={{
              padding: '5px',
              width: '25px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            {d}
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default TeamCalendarHeader
