import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material'
import { ArrowUpward, Person2 } from '@mui/icons-material'
import { Box, display } from '@mui/system'
import React from 'react'
import ScrollHelper from 'common/libs/ScrollHelper'

/**
 *
 * @param {Object} props
 * @param {Model145DTO} props.data - The user to show the data
 */
export default function Model145PerceptorData({ data }) {
  const family_situation = data?.irpf_fv?.sit_familiar ?? ''
  const disability = data?.irpf_fv?.discapacidad ?? ''
  const name = data?.datos_trabajador?.nombre ?? ''
  const dni = data?.datos_trabajador?.dni ?? ''
  const year_of_birth = data?.datos_trabajador?.fecha_nacimiento
    ? new Date(data?.datos_trabajador?.fecha_nacimiento).getFullYear()
    : '---'

  return (
    <Paper>
      <Card>
        <CardHeader
          avatar={<Person2 />}
          sx={{
            backgroundColor: 'background.lighter',
          }}
          title="Datos del perceptor"
          action={
            <IconButton onClick={() => ScrollHelper.scrollTo({ id: 'model145-intro' })}>
              <ArrowUpward />
            </IconButton>
          }
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="subtitle1">NIF</Typography>
                <TextField variant="outlined" value={dni} />
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="subtitle1">Apellidos y Nombre</Typography>
                <TextField variant="outlined" value={name} />
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="subtitle1">Año de nacimiento</Typography>
                <TextField variant="outlined" value={year_of_birth} />
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ p: 2, pl: 5 }}>
            <ul>
              <li>
                <Typography variant="subtitle1">Situación familiar</Typography>
                <RadioGroup
                  sx={{
                    py: 2,
                    pointerEvents: 'none',
                    '& .MuiFormControlLabel-root': {
                      '& .MuiButtonBase-root': {
                        p: 0,
                        pr: 1,
                      },
                      alignItems: 'start',
                      marginBottom: '1em',
                      '& .Mui-': {
                        color: 'text.primary',
                        opacity: 1,
                      },
                    },
                  }}
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    checked={family_situation === '1'}
                    control={<Radio />}
                    label="Soltero/a, viudo/a, divorciado/a o separado/a legalmente con hijos solteros menores de 18 años o incapacitados judicialmente y sometidos a patria potestad prorrogada o rehabilitada que conviven exclusivamente con Vd. sin convivir también con el otro progenitor, siempre que proceda consignar al menos un hijo o descendiente en el apartado 2 de este documento..."
                  />
                  <FormControlLabel
                    checked={family_situation === '2'}
                    control={<Radio />}
                    label="Casado/a y no separado/a legalmente cuyo cónyuge no obtiene rentas superiores a 1.500 euros anuales, excluidas las exentas.
NIF del cónyuge (si ha marcado la casilla 2, deberá consignar en esta casilla el NIF de su cónyuge):"
                  />
                  <FormControlLabel
                    checked={family_situation === '3'}
                    control={<Radio />}
                    label="Situación familiar distinta de las dos anteriores (soltero sin hijos, casados cuyo cónyuge obtiene rentas superiores a 1.500 euros anuales, ... , etc.)."
                  />
                </RadioGroup>
              </li>
              <li>
                <Typography variant="subtitle1">Discapacidad</Typography>
                <RadioGroup
                  sx={{
                    pointerEvents: 'none',
                    py: 2,
                    '& .MuiFormControlLabel-root': {
                      '& .MuiButtonBase-root': {
                        p: 0,
                        pr: 1,
                      },
                      alignItems: 'start',
                      marginBottom: '1em',
                      '& .Mui-': {
                        color: 'text.primary',
                        opacity: 1,
                      },
                    },
                  }}
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    checked={disability === '1'}
                    control={<Radio />}
                    label="Igual o superior al 30% e inferior al 65%."
                  />
                  <FormControlLabel
                    checked={disability === '2'}
                    control={<Radio />}
                    label="Igual o superior al 66%"
                  />
                  <FormControlLabel
                    checked={disability === '3'}
                    control={<Checkbox />}
                    label="Además, tengo acreditada la necesidad de ayuda de terceras personas o movilidad reducida."
                  />
                </RadioGroup>
              </li>
            </ul>
          </Box>
        </CardContent>
      </Card>
    </Paper>
  )
}
