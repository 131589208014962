import { Box, Button } from '@mui/material'
import SimpleTable from 'common/components/SimpleTable'
import { CustomModalContext } from 'common/contexts/CustomModalContext'
import { ModalTypes } from 'common/hooks/useCustomModal'
import DataTableSkeleton from 'features/solicitudes/components/skeletons/DataTableSkeleton'
import { useContext, useEffect, useState } from 'react'
import useFetchInformeData from '../hooks/useFetchInformeData'
import { InformesContext } from '../providers/InformesProvider'
import InformePluginButton from './InformePluginButton'

const InformeDataTable = () => {
  const { handleModal } = useContext(CustomModalContext)
  const {
    active: {
      current,
      structure,
      activeFilter,
      setActiveFilter,
      iteration,
      page,
      setPage,
      hasEmptyForced,
    },
  } = useContext(InformesContext)
  const [lastPage, setLastPage] = useState(page - 1)
  const [tableStructure, setTableStructure] = useState()

  const { response, loading, error } = useFetchInformeData({
    nombre_informe: current?.nombre_informe ?? null,
    filtro: activeFilter,
    limit: 12,
    page: page,
    iteration: iteration,
  })

  useEffect(() => {
    if (!error) return
    setDataSource([])
  }, [error])

  const [dataSource, setDataSource] = useState([])

  useEffect(() => {
    setLastPage(page - 1)
    if (page === 1) {
      setDataSource([])
    }
  }, [page])

  useEffect(() => {
    setPage(1)
    setLastPage(0)
  }, [iteration, current?.nombre_informe])

  useEffect(() => {
    const recordsNumber = parseInt(response?.numero_registros)

    if (!response?.registros && recordsNumber && recordsNumber > 0) {
      return
    } else if (!recordsNumber || recordsNumber === 0) {
      setDataSource([])
      return
    }

    if (lastPage !== page && JSON.stringify(response.registros) !== JSON.stringify(dataSource)) {
      setDataSource(page === 1 ? response.registros : [...dataSource, ...response.registros])
      setLastPage(page - 1)
    }
  }, [response])

  useEffect(() => {
    handleModal({ enabled: loading, type: ModalTypes.Loading })
  }, [loading])

  useEffect(() => {
    setTableStructure(null)
    if (!structure?.response?.columnas) return

    /** @type {Array<import('common/components/SimpleTable').SimpleTableField>} **/
    const newFields = structure.response.columnas.filter((item) => {
      return item.ancho_web > 0
    })

    const maxCount = structure?.response?.plugins?.length ?? 0

    let fields = newFields.map((item, index) => {
      const width =
        maxCount !== 0
          ? `calc(${item.ancho_web}% - ${Math.ceil(35 / (newFields.length + 1))}px)`
          : `${item.ancho_web}`

      return {
        title: item.titulo_columna,
        field: item.alias_columna,
        cellStyle: {
          padding: '5px',
          fontSize: '.9em',
          width,
          maxWidth: width,
        },
        headerStyle: {
          textAlign: 'left',
          // whiteSpace: 'nowrap',
          padding: '5px',
          fontSize: '.9em',
          width,
          maxWidth: width,
        },
      }
    })

    if (maxCount !== 0) {
      fields = [
        {
          cellStyle: {
            padding: '10px 5px',
            display: 'block',
            fontSize: '.9em',
            width: `35px`,
          },
          headerStyle: {
            textAlign: 'left',
            // whiteSpace: 'nowrap',
            padding: '5px',
            fontSize: '.9em',
            width: `35px`,
            display: 'block',
          },
          customGetter: (row) => (
            <>
              <InformePluginButton plugins={structure?.response?.plugins ?? []} element={row} />
            </>
          ),
          title: ' ',
        },
        ...fields,
      ]
    }

    setTableStructure(fields)
  }, [structure.response])

  return (
    <>
      {tableStructure && !structure.loading ? (
        <Box sx={{ display: tableStructure && !structure?.loading ? 'initial' : 'none' }}>
          <SimpleTable
            fields={tableStructure}
            dataSource={dataSource ?? []}
            stripped
            header_cell_style={{ p: 2 }}
            emptyText={
              error && hasEmptyForced
                ? 'Por favor rellena los filtros obligatorios (*)'
                : error
                ? 'Ha ocurrido un error'
                : loading
                ? 'Cargando'
                : 'No hay datos'
            }
          />
          {loading ? (
            <Box sx={{ my: 3 }}></Box>
          ) : (
            <>
              {Math.ceil(parseInt(response?.numero_registros) / 12) > page ? (
                <Button
                  disabled={loading}
                  onClick={() => {
                    setPage(page + 1)
                    if (activeFilter === null) setActiveFilter({})
                  }}
                  sx={{ px: 5, mt: 2, mx: 'auto', display: 'block' }}
                  variant="outlined"
                >
                  Cargar Más
                </Button>
              ) : (
                <></>
              )}
            </>
          )}
        </Box>
      ) : current?.nombre_informe ? (
        <DataTableSkeleton />
      ) : (
        <></>
      )}
    </>
  )
}

export default InformeDataTable
