import { useSelector } from 'react-redux'
import React, { useEffect } from 'react'
import { selectUsuario } from 'features/users/stores/userSlice'
import '../styles/menu.scss'

import Collapse from '@mui/material/Collapse'

import Box from '@mui/material/Box'

import ThemeManager from 'theming/ThemeManager'
import { Drawer } from '@mui/material'

import { createTheme } from '@mui/system'
import { selectTheme } from 'default_themes'
import DefaultDrawer from './MenuDrawer'
import { useLocation } from 'react-router-dom'

let DRAWER_WIDTH = '275px'
let COLLAPSED_DRAWER_WIDTH = '58px'

const Menu = ({ window, mobileOpen, handleDrawerToggle }) => {
  const ALLWAYS_EXPANDED = !(localStorage.getItem('NO_EXPAND_MENU') === 'true')
  const themeController = new ThemeManager()
  const location = useLocation()

  let MenuDrawer = DefaultDrawer

  if (
    themeController?.activeTheme?.components &&
    themeController.activeTheme.components['MenuDrawer']
  ) {
    MenuDrawer = themeController.activeTheme?.components
      ? themeController.activeTheme?.components['MenuDrawer'] ?? DefaultDrawer
      : DefaultDrawer
  }

  if (themeController.activeTheme.options['fullHideMenu']) COLLAPSED_DRAWER_WIDTH = '0px'

  if (themeController.activeTheme.options['drawerWidth'])
    DRAWER_WIDTH = themeController.activeTheme.options['drawerWidth']

  const [expanded, setExpanded] = React.useState(true)
  const [open, setOpen] = React.useState(0)
  const theme = createTheme({
    palette: selectTheme(localStorage.getItem('theme') ?? 'light'),
  })

  useEffect(() => {
    setExpanded(false)
  }, [])

  useEffect(() => {
    setOpen(0)
  }, [location])

  const handleClick = (e) => {
    const el = Number(e.currentTarget.id)
    if (el === open) {
      setOpen(0)
    } else {
      setOpen(el)
    }
  }

  const usuario = useSelector(selectUsuario)

  const container = window !== undefined ? () => window().document.body : undefined

  if (!usuario) return <></>
  return (
    <Collapse
      in={ALLWAYS_EXPANDED || (ALLWAYS_EXPANDED === false && expanded)}
      orientation="horizontal"
      className="main-collapse"
      collapsedSize={COLLAPSED_DRAWER_WIDTH}
      sx={{
        minWidth: `${ALLWAYS_EXPANDED ? 'initial' : COLLAPSED_DRAWER_WIDTH} !important`,
        [theme.breakpoints.down('md')]: {
          minWidth: '0px !important',
          width: '0px !important',
        },
      }}
    >
      <Box
        onMouseEnter={() => {
          if (!ALLWAYS_EXPANDED) {
            setOpen(0)
            setExpanded(true)
          }
        }}
        onMouseLeave={() => {
          if (!ALLWAYS_EXPANDED) {
            setOpen(0)
            setExpanded(false)
          }
        }}
        sx={{
          position: ALLWAYS_EXPANDED ? 'initial' : 'fixed',
          width: {
            xs: 0,
            md:
              ALLWAYS_EXPANDED || (ALLWAYS_EXPANDED === false && expanded)
                ? DRAWER_WIDTH
                : COLLAPSED_DRAWER_WIDTH,
          },
          display: 'flex',
          zIndex: 99,
        }}
      >
        <Box
          component="nav"
          sx={{
            transition: '.3s',
            flexShrink: { sm: 0 },
          }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: DRAWER_WIDTH,
                border: 'none',
              },
            }}
          >
            <MenuDrawer
              expanded={expanded}
              DRAWER_WIDTH={DRAWER_WIDTH}
              ALLWAYS_EXPANDED={ALLWAYS_EXPANDED}
              COLLAPSED_DRAWER_WIDTH={COLLAPSED_DRAWER_WIDTH}
              mobileOpen={mobileOpen}
              open={open}
              handleClick={handleClick}
              toggleDrawer={handleDrawerToggle}
            />
          </Drawer>
          {themeController.activeTheme.options['fullHideMenu'] ? (
            <></>
          ) : (
            <Drawer
              variant="permanent"
              sx={{
                transition: '.3s',
                display: { xs: 'none', sm: 'none', md: 'block' },
                '& .MuiDrawer-paper': {
                  transition: '.3s',
                  boxSizing: 'border-box',
                  overflow: expanded ? 'initial' : 'hidden',
                  border: 'none',
                  padding: 0,
                  backgroundColor: 'background.default',
                },
              }}
              open={true}
            >
              <MenuDrawer
                expanded={expanded}
                DRAWER_WIDTH={DRAWER_WIDTH}
                ALLWAYS_EXPANDED={ALLWAYS_EXPANDED}
                COLLAPSED_DRAWER_WIDTH={COLLAPSED_DRAWER_WIDTH}
                mobileOpen={mobileOpen}
                open={open}
                handleClick={handleClick}
              />
            </Drawer>
          )}
        </Box>
      </Box>
    </Collapse>
  )
}

export default Menu
