import axios from 'api/axios'
import { useContext } from 'react'
import { NotificationsContext } from '../providers/NotificationsProvider'

const { default: useFetchNotifications } = require('../hooks/useFetchNotifications')

/**
 * @module Notifications
 */

/**
 * A simple class to manage the connections for the notifications
 */
class NotificationsController {
  /**
   * This is only a link to the useFetchNotifications custom hook
   */
  static get = useFetchNotifications

  /**
   * Mark one notification as readed
   *
   * @static
   * @async
   * @param {number | string} id - The identifier of the Notification to mark as readed
   * @returns {Promise<void>}
   */
  static async markAsRead(id) {
    try {
      await axios.patch(`/notificaciones/marcarleido/${id}/`)
    } catch (error) {}
  }

  /**
   * Mark all the notifications as readed
   *
   * @static
   * @async
   * @returns {Promise<void>}
   */
  static async readInBulk() {
    try {
      await axios.put('/notificaciones/marcarleido/')
    } catch (error) {
      throw error
    }
  }
}

export default NotificationsController
