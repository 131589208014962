import axios from 'api/axios'
/**
 * PasswordController.
 * A class to handle the password change functionality.
 */
class PasswordController {
  static REQUEST_RESET_URL = '/usuario/resetPassword/'
  static CHANGE_PASSWORD_URL = '/usuario/changePassword/'
  static CHECK_HAS_RESET = '/usuario/hasToResetPassword/'

  /**
   * changePassword.
   *
   * @param {string} newPassword
   * @param {string} newPasswordRepeat
   * @param {string} [currentPassword]
   * @param {string} [token]
   * @returns {Promise<import('axios').AxiosResponse<string>>}
   */
  static async changePassword(
    newPassword,
    newPasswordRepeat,
    currentPassword = null,
    token = null,
  ) {
    if (!newPassword) throw new Error('Debe introducir la contraseña')

    if (newPassword !== newPasswordRepeat) {
      throw new Error('Las contraseñas no coinciden')
    }

    if (!token && !currentPassword) {
      throw new Error('No se ha provehido la contraseña actual')
    }

    try {
      if (token) {
        return await PasswordController.changePasswordWithToken(
          token,
          newPassword,
          newPasswordRepeat,
        )
      }

      return await PasswordController.changePasswordCurrentUser(
        currentPassword,
        newPassword,
        newPasswordRepeat,
      )
    } catch (error) {
      throw error
    }
  }

  /**
   * changePasswordCurrentUser.
   *
   * @param {string} currentPassword
   * @param {string} newPassword
   * @param {string} newPasswordRepeat
   */
  static async changePasswordCurrentUser(currentPassword, newPassword, newPasswordRepeat) {
    // password = body["password"]
    // nuevo_password = body["nuevo_password"]
    try {
      const response = await axios.put(PasswordController.CHANGE_PASSWORD_URL, {
        password: currentPassword,
        nuevo_password: newPassword,
        repeat_nuevo_password: newPassword,
      })
      if (response.data.resultado === 'ERROR') throw new Error(response.data.mensaje)
      return response
    } catch (error) {
      console.log({ error })
      throw error
    }
  }

  /**
   * changePasswordWithToken.
   *
   * @param {string} token
   * @param {string} newPassword
   * @param {string} newPasswordRepeat
   * @returns {Promise<import('axios').AxiosResponse<string>>}
   */
  static async changePasswordWithToken(token, newPassword, newPasswordRepeat) {
    try {
      const response = await axios.post(`${PasswordController.CHANGE_PASSWORD_URL}`, {
        password: newPassword,
        token,
        repeatPassword: newPasswordRepeat,
      })
      return response
    } catch (error) {
      throw error
    }
  }

  static async requestPasswordReset(usuario_web) {
    try {
      const response = await axios.get(`${PasswordController.REQUEST_RESET_URL}${usuario_web}/`, {
        username: usuario_web,
      })
      return response
    } catch (error) {
      throw error
    }
  }

  /**
   * createQuerySet.
   *
   * @param {number} passwordLength
   * @param {boolean} forceCaps
   * @param {boolean} forceNumber
   * @param {boolean} forceSymbol
   * @returns {RegExp}
   */
  static createQuerySet(passwordLength, forceCaps, forceNumber, forceSymbol) {
    function escapeRegExp(string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
    }

    // ^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])[a-zA-Z\d@$!%*?&]{8,}$
    let querySet = '^'
    if (forceCaps) querySet += '(?=.*[A-Z])'
    if (forceNumber) querySet += '(?=.*\\d)'
    if (forceSymbol) querySet += '(?=.*[@$!%*?&"·$/()=¿+.:,;#·\\-\\_])'
    if (passwordLength) querySet += `[a-zA-Z\\d@$!%*?&+"·$/()=¿.:,;#·\\-\\_]{${passwordLength},}`
    querySet += '$'
    return new RegExp(querySet)
  }

  /**
   * hasToResetPassword.
   *
   * @param {string} username
   * @returns {Promise<boolean>}
   */
  static async hasToResetPassword(username) {
    try {
      const response = await axios.get(`${PasswordController.CHECK_HAS_RESET}${username}`)
      return response.data.must_change
    } catch (error) {
      throw error
    }
  }
}

export default PasswordController
