import axios from 'api/axios'
import settings from 'config'

export const uploadImage = async (file) => {
  const BASE_URL = window.location.origin.replace(':3000', ':8000')
  try {
    const data = new FormData()
    data.append('file', file)
    data.append('filename', file.name)
    const response = await axios.post(`${BASE_URL}/noticias/upload_image/`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return { url: `${BASE_URL}/media/${response.data.name}` }
  } catch (error) {
    throw error
  }
}
