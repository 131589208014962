import axios from 'api/axios'
const { useState, useEffect } = require('react')

const useFetchSolicitudesPendientes = () => {
  const [response, setResponse] = useState([])
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const fetchData = () => {
    if (loading) {
      return
    }

    setLoading(true)
    axios
      .get(`/solicitudespendientes`)
      .then((request_response) => {
        const data = request_response.data
        setResponse(data)
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    fetchData()
  }, [])

  return { response, error, loading }
}

export default useFetchSolicitudesPendientes
