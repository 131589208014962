/**
 * @module Notifications
 */

import { createContext, useState } from 'react'
import useFetchNotifications from '../hooks/useFetchNotifications'

/**
 * @typedef NotificationsContextType
 * @prop {number} count - The ammount of notifications
 * @prop {function} forceRefresh - Force the refresh of the notifications
 * @prop {NotificationsResponse} response - The request full response
 * @prop {boolean} loading - Is currently loading the request
 * @prop {AxiosErr} error - The error of the request
 * @example
 * const { count, response, loading, errror, forceRefresh} ) = useContext(NotificationsContext)
 */

/**
 * @type {import('react').Context<NotificationsContextType>}
 */
let NotificationsContext

/**
 * @type {import('react').Context<NotificationsContextType>}
 */
let { Provider } = (NotificationsContext = createContext())

let NotificationsProvider = ({ children }) => {
  const [page] = useState(1)
  const [iteration, setIteration] = useState(1)

  const { response, loading, error } = useFetchNotifications({ page, iteration, page_size: 6 })

  function forceRefresh() {
    setIteration(iteration + 1)
  }

  return (
    <Provider value={{ count: response?.count ?? 0, forceRefresh, response, loading, error }}>
      {children}
    </Provider>
  )
}

export { NotificationsProvider, NotificationsContext }
