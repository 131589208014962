import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { Button, Typography } from '@mui/material'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import { SnackbarProvider, closeSnackbar, enqueueSnackbar } from 'notistack'
import { useState } from 'react'
import { fetchSSO } from '../libs/fetchSSO'

const SSOButton = ({
  sso,
  texto,
  startIcon = null,
  variant = 'text',
  size = 'medium',
  target = '_self',
  sx = {},
}) => {
  const [openBackdrop, setOpenBackdrop] = useState(false)

  const handleClick = async () => {
    try {
      setOpenBackdrop(true)
      const response = await fetchSSO(sso)
      setOpenBackdrop(false)
      window.open(response.url, target)
    } catch (error) {
      console.error(error)
      setOpenBackdrop(false)
      enqueueSnackbar(`No se ha podido conseguir el acceso`)
    }
  }

  return (
    <>
      <SnackbarProvider
        maxSnack={1}
        autoHideDuration={6000}
        action={(snackbarId) => (
          <IconButton onClick={() => closeSnackbar(snackbarId)} aria-label="cerrar alerta">
            <CloseRoundedIcon />
          </IconButton>
        )}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        variant={'error'}
      />
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          flexDirection: 'column',
          gap: '20px',
        }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
        <Typography>Abriendo enlace</Typography>
      </Backdrop>
      <Button onClick={handleClick} startIcon={startIcon} variant={variant} size={size} sx={sx}>
        {texto}
      </Button>
    </>
  )
}

export default SSOButton
