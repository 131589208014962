import { CustomModalContext } from 'common/contexts/CustomModalContext'
import { CheckRounded, Download, Folder } from '@mui/icons-material'
import { Grid, Box, Typography, Button, Divider } from '@mui/material'
import { ModalTypes } from 'common/hooks/useCustomModal'
import { getLocaleFromFullDate } from 'common/libs/fechas'
import FilesHelper from 'common/libs/Files'
import { useContext, useState } from 'react'
import ConfirmReadModal from './ConfirmReadModal'
import GenericDocumentCard from './GenericDocumentCard'
import { DocumentMarkType } from '../libs/markDocumentAsReaded'

const PersonalDocCard = ({ document }) => {
  const [displayDocument, setDisplayDocument] = useState(document)
  const NeedReading = displayDocument.obligatorio && !displayDocument.leido
  const { handleModal } = useContext(CustomModalContext)
  return (
    <GenericDocumentCard
      forced={displayDocument.obligatorio}
      readDate={displayDocument.leido}
      downloaded={displayDocument.descargado}
      MIMEType={displayDocument.mime_type}
      title={displayDocument.descripcion}
      document_id={displayDocument.id}
      document_type={displayDocument.tipo_doc}
      actionsSecondary={
        NeedReading ? (
          <Button
            onClick={() => {
              handleModal({
                enabled: true,
                content: (
                  <ConfirmReadModal document={document} documentType={DocumentMarkType.Personal} />
                ),
                type: ModalTypes.Custom,
              })
            }}
            endIcon={<CheckRounded />}
          >
            Leido
          </Button>
        ) : (
          <></>
        )
      }
      content={
        <>
          <Grid container>
            {displayDocument.fecha_documento ? (
              <Grid item xs>
                <Typography variant="caption" display="block" gutterBottom>
                  Fecha
                </Typography>
                <Typography variant="body1">
                  {getLocaleFromFullDate(displayDocument.fecha_documento, false)}
                </Typography>
              </Grid>
            ) : (
              <></>
            )}
            {displayDocument.fecha_fin_doc ? (
              <Grid item xs>
                <Typography variant="caption" display="block" gutterBottom>
                  Fecha fin
                </Typography>
                <Typography variant="body1">
                  {getLocaleFromFullDate(displayDocument.fecha_fin_doc, false)}
                </Typography>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
          {displayDocument.observaciones ? (
            <Box sx={{ mt: 1 }}>
              <Divider sx={{ my: 1 }} />
              <Typography variant="caption">Observaciones</Typography>
              <Typography>{displayDocument.observaciones}</Typography>
            </Box>
          ) : (
            <></>
          )}
        </>
      }
      actionsPrimary={
        <Button
          onClick={() => {
            handleModal({ type: ModalTypes.Loading, enabled: true })
            FilesHelper.downloadFile(displayDocument.id, document.tipo_doc).finally(() => {
              const date = new Date().toISOString()
              setDisplayDocument({ ...displayDocument, descargado: date })
              handleModal({ type: ModalTypes.Loading, enabled: false })
            })
          }}
          sx={{ ml: 'auto' }}
          startIcon={<Download />}
        >
          {displayDocument.mime_type ?? 'Descargar'}
        </Button>
      }
    />
  )
}

export default PersonalDocCard
