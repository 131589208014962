import { CircularProgress, Typography } from '@mui/material'
import Backdrop from '@mui/material/Backdrop'
import { useEffect, useState } from 'react'
import storage from 'redux-persist/lib/storage'
import ThemeManager from 'theming/ThemeManager'

const Logout = () => {
  const [openBackdrop, setOpenBackdrop] = useState(false)
  const Theme = new ThemeManager()

  useEffect(() => {
    setOpenBackdrop(true)
    storage.removeItem('persist:root')
    sessionStorage.removeItem('state')
    sessionStorage.removeItem('logged_redirect')
    // sessionStorage.clear()
    window.location.href = '/'
  }, [])

  console.log(Theme.pallete.primary.main)

  return (
    <Backdrop
      sx={{
        color: 'primary',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
      open={openBackdrop}
    >
      <CircularProgress />
      <Typography color={Theme.pallete.primary.contrastText}>Cerrando Sesión</Typography>
    </Backdrop>
  )
}

export default Logout
