import { NewspaperOutlined, Warning } from '@mui/icons-material'
import { Collapse, Paper, Tooltip, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { selectPortal } from 'features/portal/portalSlice'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import ComunicadoDetails from './ComunicadoDetails'

/**
 *
 * @param {Object} props
 * @param {NewsDTO} props.data
 * @param {boolean} props.requested
 */
const ComunicadoCard = ({ data, requested = false, ocultar_boton_lectura = false }) => {
  const portal = useSelector(selectPortal)
  const [imageError, setImageError] = useState(false)
  const [expanded, setExpanded] = useState(requested)
  return (
    <Paper
      elevation={0}
      sx={{
        // backgroundColor: 'background.alternative',
        // color: 'background.alternativeContrastText',
        borderRadius: '5px',
      }}
    >
      <Box
        onClick={() => {
          setExpanded(!expanded)
        }}
        sx={{
          borderRadius: '5px',
          p: 1,
          display: 'flex',
          width: '100%',
          gap: '1em',
          // backgroundColor: 'background.alternative',
          // color: 'primary.main',
          transition: '.3s',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'alt.main',
          },
        }}
      >
        <Box sx={{ aspectRatio: '1', width: '50px', height: '50px' }}>
          {imageError ? (
            <Box
              sx={{
                borderRadius: '5px',
                width: '100%',
                height: '100%',
                display: 'grid',
                placeItems: 'center',
                backgroundColor: 'background.default',
              }}
            >
              <NewspaperOutlined
                sx={{
                  fontSize: '2em',
                  color: 'background.default',
                  filter: 'brightness(80%)',
                }}
              />
            </Box>
          ) : (
            <img
              style={{ width: '100%', height: '100%', borderRadius: '5px' }}
              onError={() => {
                setImageError(true)
              }}
              src={`/assets/images/noticias/${data.portada_nombre}`}
              alt={data.titulo}
            />
          )}
        </Box>
        <Box>
          <Typography sx={{ fontSize: '.8em' }}>
            {new Date(data.fecha_publicacion).toLocaleString(
              portal?.locale?.replaceAll('-', '_') ?? 'es-ES',
              {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              },
            )}{' '}
            | Comunicado
          </Typography>
          <Typography sx={{ fontSize: '1.2em' }}>{data.titulo}</Typography>
        </Box>
        {data?.lectura_obligatoria === 'S' && !data?.leido ? (
          <Tooltip title="Obligada Lectura" sx={{ ml: 'auto' }}>
            <Warning color="primary" />
          </Tooltip>
        ) : (
          <></>
        )}
      </Box>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {expanded ? (
          <Box sx={{ p: 1 }}>
            {' '}
            {expanded ? (
              <ComunicadoDetails
                data={data}
                hideTitle={true}
                ocultar_boton_lectura={ocultar_boton_lectura}
              />
            ) : (
              <></>
            )}
          </Box>
        ) : (
          <></>
        )}
      </Collapse>
    </Paper>
  )
}

export default ComunicadoCard
