/**
 * @module HojasGasto
 */
import axios from 'api/axios'
import { useCallback, useEffect, useState } from 'react'

const useFetchHojasGastoLin = ({ id_hoja, encr }) => {
  const [response, setResponse] = useState([])
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const fetchData = useCallback(() => {
    if (loading) {
      return
    }
    setLoading(true)
    axios
      .get(`/hojasgastolin/${id_hoja}?encr=${encr}`)
      .then((request_response) => {
        const data = request_response.data
        setError(null)
        setResponse(data)
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id_hoja])

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id_hoja])

  return { response, error, loading }
}

export default useFetchHojasGastoLin
