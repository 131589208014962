//@ts-check
import { TaskAltOutlined, Visibility, Warning } from '@mui/icons-material'
import { Button, Tooltip } from '@mui/material'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/system'
import { getLocaleFromFullDate } from 'common/libs/fechas'
import { useContext } from 'react'
import { MimeTypes } from '../libs/MimeTypes'
// @ts-ignore
import { CustomModalContext } from 'common/contexts/CustomModalContext'
import { ModalTypes } from 'common/hooks/useCustomModal'
import { GlobalVariablesContext } from 'common/providers/GlobalVariablesProvider'
import DocumentViewer from './DocumentViewer'

/**
 * This is the base document for all types of documents
 *
 * @param {Object} props
 * @param {string} props.title - The title for the card
 * @param {string} props.subtitle - Text to show bellow the title
 * @param {string} props.downloaded - The last date the file has been downloaded
 * @param {string} [props.readDate] - Date that the file was marked as readed
 * @param {React.ReactNode} props.HeaderAvatar - This will be show on the top left of the card
 * @param {React.ReactNode} props.content - Content of the card
 * @param {React.ReactNode} props.actionsPrimary - The bottom right actions
 * @param {React.ReactNode} props.actionsSecondary - The bottom right actions
 * @param {string} [props.MIMEType] - The extension of the file
 * @param {boolean} [props.forced=false]- This will show a warn icon on the top right of the card
 * @param {number | string} [props.document_id]
 * @param {number | string} [props.document_type]
 * @param {string} [props.document_url] - URL to download the file
 * @returns {JSX.Element}
 */
export default function GenericDocumentCard({
  title,
  downloaded,
  readDate,
  HeaderAvatar,
  content,
  actionsSecondary,
  actionsPrimary,
  forced = false,
  MIMEType = 'default',
  document_id,
  document_type,
  document_url,
}) {
  const { handleModal } = useContext(CustomModalContext)
  const viewDocument = () => {
    handleModal({
      enabled: true,
      closable: true,
      type: ModalTypes.Custom,
      content: (
        <DocumentViewer
          document_id={document_id}
          document_type={document_type}
          document_url={document_url}
          forced={forced}
          title={title}
          readDate={readDate}
        />
      ),
    })
  }
  /**
   * @type {import("@phosphor-icons/react").Icon}
   */
  // @ts-ignore
  const MimeIcon = MimeTypes[MIMEType] || MimeTypes.default
  const { IS_GOTTENBERG_ACTIVE } = useContext(GlobalVariablesContext)
  return (
    <Card
      sx={{
        '& br': {
          height: '2px',
        },
        flex: 1,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        minHeight: '132px',
      }}
    >
      <Box sx={{ position: 'absolute', top: '1%', right: '1%', opacity: '0.06' }}>
        <MimeIcon weight="fill" size={125} />
      </Box>
      <CardHeader
        sx={{
          mb: 'auto',
          '& .MuiCardHeader-content': { display: 'flex', flexDirection: 'column' },
        }}
        avatar={HeaderAvatar ?? null}
        action={
          <Box sx={{ position: 'relative' }}>
            {forced && !readDate ? (
              <Tooltip title="Lectura obligatoria">
                <Warning color="error" />
              </Tooltip>
            ) : forced && readDate ? (
              <Tooltip title={`Leído el ${getLocaleFromFullDate(readDate)}`}>
                <TaskAltOutlined color="success" />
              </Tooltip>
            ) : (
              <></>
            )}
          </Box>
        }
        title={
          <Typography sx={{ fontWeight: forced && !readDate ? 'bold' : 'initial' }}>
            {title}
          </Typography>
        }
        subheader={
          <Typography variant="caption">
            {!downloaded
              ? 'Pendiente de descarga'
              : `Descargado el ${getLocaleFromFullDate(downloaded)}`}
          </Typography>
        }
      />
      {content ? <CardContent sx={{ py: 1 }}>{content}</CardContent> : <Box></Box>}
      {actionsPrimary || actionsSecondary ? (
        <CardActions
          disableSpacing
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Box sx={{ display: 'flex' }}>{actionsSecondary}</Box>
          <Box sx={{ display: 'flex' }}>
            {IS_GOTTENBERG_ACTIVE ? (
              <Button onClick={viewDocument} startIcon={<Visibility />}>
                Ver
              </Button>
            ) : (
              <></>
            )}
            {actionsPrimary}
          </Box>
        </CardActions>
      ) : (
        <></>
      )}
    </Card>
  )
}
