import { Divider, Typography } from '@mui/material'
import SolicitudesPendientes from '../components/SolicitudesPendientes'

const ListadoSolicitudesPendientes = () => {
  return (
    <>
      <Typography variant="h3">Solicitudes pendientes</Typography>
      <Divider sx={{ mb: 2 }} />
      <SolicitudesPendientes />
    </>
  )
}
export default ListadoSolicitudesPendientes
