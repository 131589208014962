//@ts-check
import axios from 'api/axios'
import ThemeManager from 'theming/ThemeManager'
const { useState, useEffect } = require('react')

/**
 * @module FetchHooks
 */

/**
 * Hook that fetch the my_users_data
 * @function
 * @param {number} iteration
 * @returns {import('common/types/connections_types').UseFetchHookResponse<Array<FichajesDTO>>}
 */
const useFetchLastFichajes = (iteration = 0) => {
  const [response, setResponse] = useState()
  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)

  const fetchData = () => {
    if (loading) {
      return
    }
    setLoading(true)
    axios
      .get(`/fichajes`)
      .then((/**@type {import('axios').AxiosResponse<FichajesDTO>}*/ request_response) => {
        const data = request_response.data
        // @ts-ignore
        setResponse(data)
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (!loading) fetchData()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iteration])

  return { response, error, loading }
}

export default useFetchLastFichajes
