/**
 * @module HojasGasto
 */
import axios from 'api/axios'
import { useCallback, useEffect, useState } from 'react'

const useFetchHojaGasto = ({ id_hoja, encr, iterator = 0 }) => {
  const [response, setResponse] = useState({})
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const fetchData = useCallback(() => {
    if (loading) {
      return
    }
    setLoading(true)
    axios
      .get(`/hojasgasto/${id_hoja}?encr=${encr}`)
      .then((request_response) => {
        const data = request_response.data
        setResponse(data)
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id_hoja, iterator])

  useEffect(() => {
    fetchData()
  }, [fetchData, id_hoja, iterator])

  return { response, error, loading }
}

export default useFetchHojaGasto
