//TODO: document this file with JSDOC
const pow = Math.pow
function easeOutQuart(x) {
  return 1 - pow(1 - x, 4)
}
const getElementPosition = (element) => element.offsetTop
/**
 * This class helps with the smooth scroll
 */
class ScrollHelper {
  static animateScroll({ targetPosition, initialPosition, duration }) {
    let start
    let position
    let animationFrame

    const requestAnimationFrame = window.requestAnimationFrame
    const cancelAnimationFrame = window.cancelAnimationFrame

    const maxAvailableScroll =
      document.documentElement.scrollHeight - document.documentElement.clientHeight

    const amountOfPixelsToScroll = initialPosition - targetPosition

    function step(timestamp) {
      if (start === undefined) {
        start = timestamp
      }

      const elapsed = timestamp - start

      const relativeProgress = elapsed / duration

      const easedProgress = easeOutQuart(relativeProgress)

      position = initialPosition - amountOfPixelsToScroll * Math.min(easedProgress, 1)

      window.scrollTo(0, position)
      if (initialPosition !== maxAvailableScroll && window.scrollY === maxAvailableScroll) {
        cancelAnimationFrame(animationFrame)
        return
      }
      if (elapsed < duration) {
        animationFrame = requestAnimationFrame(step)
      }
    }

    animationFrame = requestAnimationFrame(step)
  }
  static scrollTo = ({ id, ref = null, duration = 3000 }) => {
    const initialPosition = window.scrollY

    const element = ref ? ref.current : id ? document.getElementById(id) : null

    if (!element) {
      return
    }

    ScrollHelper.animateScroll({
      targetPosition: getElementPosition(element),
      initialPosition,
      duration,
    })
  }
}

export default ScrollHelper
