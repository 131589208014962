// @ts-check
import SimpleTable from 'common/components/SimpleTable'
import ThemeManager from 'theming/ThemeManager'
import { getLocaleFromFullDate } from 'common/libs/fechas'
import { Button, Chip, Divider, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { History, Map } from '@mui/icons-material'
import { tiposSalidaMap } from '../libs/tiposSalidaMap'

/**
 *
 * @module ControlPresencia
 *
 */

/**
 * @callback setFichajeActiveRecord
 * @param {FichajesDTO} item
 */

/**
 *
 * Listado de ultimos fichajes
 *
 * @param {object} props
 * @param {Array<FichajesDTO>} props.response - Listado de fichajes
 */
const LastFichajesTable = ({ response, setRecord }) => {
  const Theme = new ThemeManager()
  return (
    <Box
      sx={{
        // border: `2px solid ${Theme.pallete.primary.main}`,
        borderRadius: '5px',
        width: '100%',
      }}
    >
      <Divider sx={{ my: 2 }} textAlign="left">
        Últimos Fichajes
      </Divider>
      <SimpleTable
        header_cell_style={{ padding: '5px' }}
        table_style={{ width: '100%', borderCollapse: 'collapse', display: 'table' }}
        container_style={{
          marginBottom: '5px',
          borderRadius: '5px',
          // marginTop: '10px',
        }}
        generic_cell_style={{
          textAlign: 'left',
          padding: '15px 10px',
        }}
        hideHeader={true}
        header_style={{
          textAlign: 'left',
          borderRadius: '5px',
          padding: '10px',
        }}
        stripped={true}
        dataSource={response}
        fields={[
          {
            field: 'entrada_salida',
            title: 'Acción',
            customGetter: (
              /** @type {FichajesDTO} */
              item,
            ) => {
              return item.entrada_salida === 'S' ? (
                <Chip color="error" label="Salida" />
              ) : (
                <Chip color="success" label="Entrada" />
              )
            },
          },
          {
            field: 'fecha_terminal',
            title: 'Momento',
            customGetter: (
              /**@type {FichajesDTO}*/
              item,
            ) => {
              return getLocaleFromFullDate(item.fecha_terminal, true)
            },
          },
          // {
          //   field: 'none',
          //   title: 'events',
          //   customGetter: (item) => {
          //     return (
          //       <Button sx={{ ml: 'auto' }} onClick={() => setRecord(item)} startIcon={<Map />}>
          //         Ver en el mapa
          //       </Button>
          //     )
          //   },
          // },
        ]}
      />
    </Box>
  )
}

export default LastFichajesTable
