import axios from 'axios'
import { logOut, setToken } from 'features/users/stores/userSlice'

let store
export const injectStore = (_store) => {
  store = _store
}

export const PREVIOUS_ROUTE_STORE = 'PREVIOUS_ROUTE'

//const BASE_URL = window.loc'PREVIOUS_ROUTE'lace(':3000', ':8000')
const BASE_URL = window.location.origin.replace(':3000', ':8000')

const axiosPE = axios.create({
  baseURL: `${BASE_URL}/api/v1`,
  headers: { 'Content-Type': 'application/json' },
})

axiosPE.interceptors.request.use(
  (config) => {
    const nuevoToken = sessionStorage.getItem('nuevoToken')
    const token = nuevoToken || store?.getState().user.token
    sessionStorage.removeItem('nuevoToken')

    if (token && !config.headers['authorization']) {
      config.headers.authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => Promise.reject(error),
)

axiosPE.interceptors.response.use(
  (response) => response,
  async (error) => {
    let prevRequest = { ...error?.config }

    const endpoint = error?.config.url

    if (error?.response?.status === 401 && endpoint !== '/token/refresh/') {
      const ROUTE_TO_LOGIN = sessionStorage.getItem(PREVIOUS_ROUTE_STORE)

      if (
        !ROUTE_TO_LOGIN &&
        window.location.pathname !== '/' &&
        window.location.pathname !== '/recuperar'
      )
        sessionStorage.setItem(
          PREVIOUS_ROUTE_STORE,
          window.location.pathname + window.location.search,
        )
      // return
      const { refresh } = store?.getState().user

      const response = await axiosPE.post('/token/refresh/', { refresh })

      store?.dispatch(setToken({ token: response?.data?.access }))

      prevRequest.headers['authorization'] = `Bearer ${response?.data?.access}`

      axiosPE.request(prevRequest)
    }
    if (error?.response?.status === 401 && endpoint === '/token/refresh/') {
      store?.dispatch(logOut())
    }
    if (error.response.data) {
      error.message = error.response.data
    }
    return Promise.reject(error)
  },
)

export default axiosPE
