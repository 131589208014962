import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import useFetchHojasGastoLin from '../hooks/useFetchHojasGastoLin'
import { HojasGastoLinCards } from './HojasGastoLinCards'
import { HojasGastoLinTable } from './HojasGastoLinTable'

export const HojasGastoLin = ({
  viewType,
  disableAll,
  id,
  fechaInicio,
  fechaFin,
  handleChangeState,
  encr,
  showButton,
}) => {
  const [lineas, setLineas] = useState([])
  const { response, error, loading } = useFetchHojasGastoLin({ id_hoja: id, encr })

  useEffect(() => {
    if (response) setLineas(response.results)
  }, [response])
  return (
    <>
      <Box
        sx={{
          display: { xs: 'flex', lg: 'none' },
          justifyContent: 'center',
          textAlign: 'center',
          alignItems: 'center',
          alignContent: 'center',
          width: '100%',
          placeItems: 'center',
        }}
      >
        <HojasGastoLinCards
          lineas={lineas}
          setLineas={setLineas}
          loading={loading}
          disableAll={disableAll}
          id_hoja={id}
          fechaInicio={fechaInicio}
          fechaFin={fechaFin}
          showButton={showButton}
          handleChangeState={handleChangeState}
          encr={encr}
        />
      </Box>
      <Box
        sx={{
          display: { xs: 'none', lg: 'flex' },
          justifyContent: 'center',
          textAlign: 'center',
          width: '100%',
        }}
      >
        {viewType === 'TABLE' ? (
          <HojasGastoLinTable
            lineas={lineas}
            setLineas={setLineas}
            loading={loading}
            id_hoja={id}
            disableAll={disableAll}
            fechaInicio={fechaInicio}
            fechaFin={fechaFin}
            showButton={showButton}
            handleChangeState={handleChangeState}
            encr={encr}
          />
        ) : (
          <HojasGastoLinCards
            lineas={lineas}
            setLineas={setLineas}
            loading={loading}
            id_hoja={id}
            disableAll={disableAll}
            fechaInicio={fechaInicio}
            fechaFin={fechaFin}
            showButton={showButton}
            handleChangeState={handleChangeState}
            encr={encr}
          />
        )}
      </Box>
    </>
  )
}
