import CustomModal from 'common/components/CustomModal'
import useCustomModal, { ModalTypes } from 'common/hooks/useCustomModal'
import { createContext } from 'react'

/**
 * @module Common
 */

/**
 * @typedef CustomModalHandlerOptions
 * @prop {ModalTypes} type - ModalTypes enum
 * @prop {import('@emotion/react/types/jsx-namespace').ReactJSXElement | string}  [content] - The content to display
 * @prop {import('@emotion/react/types/jsx-namespace').ReactJSXElement | string}  [previousContent] - The previous content to display
 * @prop {import('@emotion/react/types/jsx-namespace').ReactJSXElement | array | string}  [confirmDialogButtons] - The previous content to display
 * @prop {boolean} [enabled] - Fully controll the state of the elementa, if not passed the default behavior will be to toggle the modal
 * @prop {boolean} [closable = false]
 * @prop {boolean} [reRenderParent = false]
 */

/**
 * @callback CustomModalHandler
 * @param {CustomModalHandlerOptions} options
 *
 */

/**
 * @typedef ModalContext
 * @prop {boolean} modal
 * @prop {ModalTypes} modalType
 * @prop {import('@emotion/react/types/jsx-namespace').ReactJSXElement | string}  modalContent - The content to display
 * @prop {CustomModalHandler} handleModal
 * @prop {boolean} closable
 */

/**
 * @type {import('react').Context<ModalContext>}
 */
let CustomModalContext

/**
 * @type {import('react').Context<ModalContext>}
 */
let { Provider } = (CustomModalContext = createContext())

let CustomModalProvider = ({ children }) => {
  const {
    modal,
    modalType,
    handleModal,
    modalContent,
    closable,
    previousContent,
    confirmDialogButtons,
    reRenderParent,
    returnToParent,
  } = useCustomModal()
  return (
    <Provider
      value={{
        modal,
        handleModal,
        modalContent,
        modalType,
        closable,
        previousContent,
        confirmDialogButtons,
        reRenderParent,
        returnToParent,
      }}
    >
      <CustomModal />
      {children}
    </Provider>
  )
}

export { CustomModalContext, CustomModalProvider }
