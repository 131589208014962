import axios from 'api/axios'
/**
 * Mark a document as readed
 *
 * @async
 * @param {string} sso - codigo del single sign on al que se quiere acceder
 * @throws {Error} Can throw an error if the axios request fails
 */
export async function fetchSSO(sso) {
  try {
    const response = await axios.get(`sso/?c=${sso}`)
    if (response.status !== 200) throw new Error('No se pudo conseguir el acceso')
    return response.data
  } catch (error) {
    throw error
  }
}
