import { Alert } from '@mui/material'
import { selectPortal } from 'features/portal/portalSlice'
import { useSelector } from 'react-redux'

const PasswordRequirements = () => {
  const portal = useSelector(selectPortal)
  return (
    <Alert variant="outlined" severity="info">
      La contraseña debe:
      <br />
      <br />
      <ul style={{ listStyle: 'inside' }}>
        <li style={{ listStyle: 'inside' }}>
          {' '}
          Tener al menos {portal?.portal?.longitud_password || 8} caracteres.
        </li>
        {portal?.portal?.obligar_mayusculas === 'S' ? (
          <li>Incluir al menos una mayúscula. </li>
        ) : (
          ''
        )}
        {portal?.portal?.obligar_numero === 'S' ? <li>Incluir al menos un número. </li> : ''}
        {portal?.portal?.obligar_simbolo === 'S' ? (
          <li>Incluir al menos un caracter especial. </li>
        ) : (
          ''
        )}
      </ul>
    </Alert>
  )
}

export default PasswordRequirements
