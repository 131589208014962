import { Visibility } from '@mui/icons-material'
import LockIcon from '@mui/icons-material/Lock'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import { Button } from '@mui/material'
import SimpleTable from 'common/components/SimpleTable'
import { CustomModalContext } from 'common/contexts/CustomModalContext'
import { ModalTypes } from 'common/hooks/useCustomModal'
import { enqueueSnackbar } from 'notistack'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import ThemeManager from 'theming/ThemeManager'
import { closeHojaGasto } from '../lib/HojasGastoController'
export const HojasGastoTable = ({ hojasGasto, loading, setIterator }) => {
  const Theme = new ThemeManager()
  const navigate = useNavigate()
  const { handleModal } = useContext(CustomModalContext)
  const handleClick = async ({ id }) => {
    try {
      handleModal({ type: ModalTypes.Loading })
      await closeHojaGasto({ id })
      handleModal({ enabled: false })

      enqueueSnackbar('Hoja cerrada correctamente', { variant: 'success' })
      setIterator(Math.random())
    } catch (e) {
      enqueueSnackbar('No ha sido posible cerrar la hoja')
      console.log(e.message)
    }
  }

  return (
    <SimpleTable
      dataSource={hojasGasto}
      header_style={{
        backgroundColor: Theme.pallete?.primary?.main,
        color: Theme.pallete.primary.contrastText,
      }}
      header_cell_style={{ padding: '10px' }}
      table_style={{ width: '100%', borderCollapse: 'collapse' }}
      container_style={{ borderRadius: '5px', marginBottom: '5px', marginTop: '10px' }}
      generic_cell_style={{
        textAlign: 'center',
        padding: '15px 10px',
      }}
      loading={loading}
      emptyText={'No hay hojas de gasto'}
      stripped={true}
      fields={[
        {
          field: '',
          title: '',
          customGetter: (item) =>
            item.status === '0500' ? (
              <LockOpenIcon
                sx={{ ':hover': { cursor: 'pointer' } }}
                onClick={() => handleClick({ id: item.id })}
              />
            ) : (
              <LockIcon />
            ),
        },
        {
          field: 'id',
          title: 'Id',
        },
        {
          field: 'd_periodo',
          title: 'Periodo',
        },
        {
          field: 'd_status',
          title: 'Estado',
        },
        {
          field: 'ejercicio',
          title: 'Ejercicio',
        },
        {
          field: 'fecha_inicio',
          title: 'Fecha Inicio',
        },
        {
          field: 'fecha_fin',
          title: 'Fecha Fin',
        },
        {
          field: 'd_tipo',
          title: 'Tipo',
        },
        {
          field: 'd_tipo_grupo',
          title: 'Grupo',
        },
        {
          field: '',
          title: '',
          customGetter: (item) => (
            <Button onClick={() => navigate(`/hojasgasto/${item.id}`)} startIcon={<Visibility />}>
              Ver
            </Button>
          ),
        },
      ]}
    />
  )
}
