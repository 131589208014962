import { Box } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { useState } from 'react'

const TeamCalendarDateFilters = ({
  loading = true,
  currentYear,
  year,
  month,
  selectorMode = 'tabs',
  sx = {},
  onYearChange = () => {},
  onMonthChange = () => {},
}) => {
  const yearArray = [currentYear - 1, currentYear, currentYear + 1]

  const [activeYear, setActiveYear] = useState(currentYear)
  const [activeMonth, setActiveMonth] = useState(month - 1)

  const handleYearChange = (event, newYear) => {
    setActiveYear(newYear)
    onYearChange(newYear)
  }

  const handleMonthChange = (event, newMonth) => {
    setActiveMonth(newMonth)
    onMonthChange(newMonth + 1)
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', ...sx }}>
      {selectorMode === 'tabs' ? (
        <Box>
          <Tabs
            value={activeYear}
            onChange={handleYearChange}
            variant="fullWidth"
            scrollButtons="auto"
            aria-label="year selector"
            key="year-selector"
          >
            {yearArray.map((y) => {
              return <Tab key={y} label={y} value={y} />
            })}
          </Tabs>
          <Tabs
            value={activeMonth}
            onChange={handleMonthChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="month selector"
            key="month-selector"
          >
            {Array(12)
              .fill()
              .map((m, i) => {
                const date = new Date(year, i, 1)
                return (
                  <Tab
                    key={i}
                    label={date.toLocaleDateString('es-ES', { month: 'long' })}
                    value={i}
                  />
                )
              })}
          </Tabs>
        </Box>
      ) : (
        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
          <FormControl key="fc1" variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <Select
              labelId="ejercicio-select"
              id="ejercicio-select-label"
              value={year}
              onChange={(e) => onYearChange(e.target.value)}
              label="Año"
              placeholder="Año"
              disabled={loading}
            >
              <MenuItem key={currentYear - 1} value={currentYear - 1}>
                {currentYear - 1}
              </MenuItem>
              <MenuItem key={currentYear} value={currentYear}>
                {currentYear}
              </MenuItem>
              <MenuItem key={currentYear + 1} value={currentYear + 1}>
                {currentYear + 1}
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl key="fc2" variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <Select
              labelId="periodo-select"
              id="periodo-select-label"
              value={month}
              onChange={(e) => onMonthChange(e.target.value)}
              label="Mes"
              placeholder="Mes"
              disabled={loading}
            >
              {Array(12)
                .fill()
                .map((m, i) => {
                  const date = new Date(year, i, 1)
                  return (
                    <MenuItem key={i} value={i}>
                      {date.toLocaleDateString('es-ES', { month: 'long' })}
                    </MenuItem>
                  )
                })}
            </Select>
          </FormControl>
        </Box>
      )}
    </Box>
  )
}

export default TeamCalendarDateFilters
