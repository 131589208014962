export const DarkModeTheme = {
  mode: 'dark',
  primary: {
    main: '#4783d1', //4783d1
  },
  secondary: {
    main: '#e07912',
  },
  error: {
    main: '#cc2b4b',
    light: '#f16e6e',
  },
  background: {
    default: '#17171f',
    paper: '#20232a',
    lighter: '#30333b',
    alternative: '#25303b',
    alternativeContrastText: '#efefef',
  },
}

export const LightModeTheme = {
  mode: 'light',
  primary: {
    main: '#426bba',
  },
  secondary: {
    main: '#ea8415',
  },
  error: {
    main: '#d32f2f',
  },
  background: {
    default: '#efefef',
    paper: '#f9f7f7',
    alternative: '#426bba',
    lighter: '#dcdde0',
    alternativeContrastText: '#efefef',
  },
}

export const selectTheme = (mode = 'light') => {
  return mode === 'dark' ? DarkModeTheme : LightModeTheme
}
