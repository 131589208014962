import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import axiosPE from '../../api/axios'

const initialState = {
    organigrama: [],
    statusOrganigrama: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
}

export const fetchOrganigrama = createAsyncThunk('/organigrama', async () => {
    const response = await axiosPE.get('/organigrama');
    return response.data;
})

export const organigramaSlice = createSlice({
    name: 'organigrama',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(fetchOrganigrama.pending, (state, action) => {
            state.statusOrganigrama = 'loading'
        })
            .addCase(fetchOrganigrama.rejected, (state, action) => {
                state.statusOrganigrama = 'failed'
                state.error = action.error.message
            })
            .addCase(fetchOrganigrama.fulfilled, (state, action) => {
                state.statusOrganigrama = 'succeeded'
                state.organigrama = action.payload
            })
    }
})

export const selectOrganigrama = (state) => state.organigrama.organigrama;
export const selectStatusOrganigrama = (state) => state.organigrama.statusOrganigrama;

export default organigramaSlice.reducer