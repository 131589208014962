/* eslint-disable react-hooks/exhaustive-deps */
import { Inventory2Outlined } from '@mui/icons-material'
import { Alert, AlertTitle, Badge, Button, Divider, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { numericElementMap } from 'common/libs/numericMap'
import { NotificationsSkeleton } from 'features/notifications/pages/NotificationsPage'
import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { DocumentNotificationContext } from '../providers/DocumentNotificationsProvider'
import HeaderLaboralCard from './HeaderLaboralCard'

/**
 * @module Documentation
 */

/**
 * The Menu to show the notifications on the header
 * @component
 *
 * @param {Object} props
 * @param {function} [props.closePopup]
 * @returns {JSX.Element}
 */
const HeaderLaboralNotifications = ({ closePopup = () => {} }) => {
  const { forceRefresh, response, loading, error } = useContext(DocumentNotificationContext)

  useEffect(() => {
    forceRefresh()
  }, [])

  const navigate = useNavigate()
  return (
    <Box
      sx={{
        boxSizing: 'border-box',
        width: {
          xs: '100%',
          sm: '40vw',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
          boxSizing: 'border-box',
          mb: 1,
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: {
              xs: '1.3em',
              // md: '2em',
            },
            fontWeight: 'bold',
          }}
        >
          Notificaciones Laborales
        </Typography>
        <Box sx={{}}>
          <Badge badgeContent={0}>
            <Inventory2Outlined
              style={{
                width: '100%',
              }}
            />
          </Badge>
        </Box>
      </Box>
      <Stack spacing={1}>
        <Divider sx={{ mb: 1 }} />
        {response.results && !loading ? (
          response.results.map((document, idx) => (
            <HeaderLaboralCard closePopup={closePopup} document={document} />
          ))
        ) : (
          <></>
        )}
        {!loading && response?.results?.length <= 0 ? (
          <Box sx={{ p: 2 }}>
            <Typography
              variant="h1"
              sx={{ opacity: 0.3, fontSize: '1.5em', fontWeight: 'bold', textAlign: 'center' }}
            >
              No hay notificaciones pendientes
            </Typography>
          </Box>
        ) : (
          <></>
        )}
        {loading ? (
          <>
            {numericElementMap(
              response?.count && response.count > 5 ? 5 : response.count,
              NotificationsSkeleton,
              () => {
                return {
                  width: 50 + Math.ceil(Math.random() * 30),
                  reverse: true,
                }
              },
            )}
          </>
        ) : (
          <></>
        )}
        {error && !loading ? (
          <Alert severity="error">
            <AlertTitle>Error de conexión</AlertTitle>No se han podido obtener las notificaciones
          </Alert>
        ) : (
          <></>
        )}
        <Button
          sx={{ mt: 2, display: 'block', width: '100%' }}
          disableElevation
          color="primary"
          variant="outlined"
          onClick={() => {
            closePopup()
            navigate('/doclaboral')
          }}
        >
          Ver todos los documentos
        </Button>
      </Stack>
    </Box>
  )
}

export default HeaderLaboralNotifications
