import { Alert, Button, Collapse, Divider, IconButton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { FilePdf, FileXls, Sliders } from '@phosphor-icons/react'
import { CustomModalContext } from 'common/contexts/CustomModalContext'
import useCurrentMenu from 'common/hooks/useCurrentMenu'
import { ModalTypes } from 'common/hooks/useCustomModal'
import FilesHelper from 'common/libs/Files'
import FilterSkeleton from 'features/solicitudes/components/skeletons/FilterSkeleton'
import { useContext, useEffect, useState } from 'react'
import InformesHelper from '../helpers/InformesHelper'
import { InformesContext } from '../providers/InformesProvider'
import FilterTextField from './FilterTextField'

const InformesFilter = ({ forced }) => {
  const { actualMatch } = useCurrentMenu()
  const {
    redirected,
    active: {
      current,
      structure,
      setActiveFilter,
      filterData,
      activeFilter,
      forceRefresh,
      setFilterData,
      setPage,
    },
  } = useContext(InformesContext)
  const [filters, setFilters] = useState([])
  const [filtersValues, setFilterValues] = useState({})
  const [validating, setValidating] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const { handleModal } = useContext(CustomModalContext)

  // NOTE: This will create an array for only the real filters
  useEffect(() => {
    setFilterData({})
    if (!structure?.response?.columnas) return
    setValidating(false)
    const newFilters = structure.response.columnas.filter((filter) => {
      return filter.campo_de_filtro === 'S'
    })

    if (newFilters.findIndex((element) => element.obligatorio === 'S') > -1) setExpanded(true)
    else setExpanded(false)

    setFilters(newFilters)
  }, [structure?.response?.columnas])

  return (
    <>
      <Box
        sx={{
          width: '100%',
        }}
      >
        {structure.loading ? (
          <FilterSkeleton />
        ) : (
          <Box
            sx={{
              width: '100%',
              display: structure.loading ? 'none' : 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            <Typography
              variant="h1"
              sx={{
                fontSize: '1.6em',
                mr: 4,
                mb: { xs: 3, md: 0 },
                width: { xs: '100%', md: 'auto' },
                textAlign: { xs: 'center', md: 'left' },
              }}
            >
              {structure?.loading ? '' : structure?.response?.nombre ?? actualMatch?.nombre ?? ''}
            </Typography>
            <Box sx={{ display: 'flex', gap: '8px', ml: 'auto' }}>
              <Button
                sx={{
                  color: 'action.active',
                  display: filters?.length > 0 && !redirected ? 'flex' : 'none',
                }}
                endIcon={<Sliders size={30} weight={expanded ? 'fill' : 'light'} />}
                onClick={() => setExpanded(!expanded)}
              >
                Filtros
              </Button>
              <Divider orientation="vertical" variant="middle" flexItem />
              <IconButton
                onClick={() => {
                  handleModal({ type: ModalTypes.Loading, enabled: true })
                  InformesHelper.fetchInforme({
                    tipo_salida: 'PDF',
                    end: 9999,
                    filter: activeFilter,
                    nombre_informe: current.nombre_informe,
                  })
                    .then(({ fichero }) => {
                      FilesHelper.blobDownload(fichero, 'pdf', `${current.nombre_informe}.pdf`)
                    })
                    .finally(() => {
                      handleModal({ enabled: false })
                    })
                }}
              >
                <FilePdf size={30} weight="light" />
              </IconButton>
              <IconButton
                sx={{ display: structure?.response?.permitir_excel === 'S' }}
                onClick={() => {
                  handleModal({ type: ModalTypes.Loading, enabled: true })
                  InformesHelper.fetchInforme({
                    tipo_salida: 'XLS',
                    end: 9999,
                    filter: activeFilter,
                    nombre_informe: current.nombre_informe,
                  })
                    .then(({ fichero }) => {
                      FilesHelper.blobDownload(fichero, 'xls', `${current.nombre_informe}.xls`)
                    })
                    .finally(() => {
                      handleModal({ enabled: false })
                    })
                }}
              >
                <FileXls size={30} weight="light" />
              </IconButton>
            </Box>
          </Box>
        )}
        <Collapse in={expanded}>
          {structure.loading ? (
            <></>
          ) : (
            <>
              {structure.error ? (
                <Alert severity="error">Ha ocurrido un error</Alert>
              ) : (
                <>
                  <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                    sx={{
                      display: filters?.length > 0 && !redirected ? 'flex' : 'none',
                      gap: '5px',
                      flexDirection: 'column',
                    }}
                  >
                    {filters?.map((filter, i) => {
                      return (
                        <FilterTextField
                          key={i}
                          validating={validating}
                          filtersValues={filtersValues}
                          setFiltersValues={setFilterValues}
                          filter={filter}
                        />
                      )
                    }) ?? <></>}

                    <Button
                      disabled={JSON.stringify(filterData) === JSON.stringify(activeFilter)}
                      variant="contained"
                      sx={{
                        ml: 'auto',
                        my: 2,
                        mb: 4,
                        display: 'block',
                        width: '100%',
                      }}
                      onClick={() => {
                        setValidating(true)
                        let errors = []
                        structure.response.columnas
                          .filter((filter) => {
                            return filter.campo_de_filtro === 'S' && filter.obligatorio === 'S'
                          })
                          .forEach((item) => {
                            if (item.tipo_filtro === 'I') {
                              if (!filterData[`${item.nombre_filtro}_DESDE`]) {
                              }
                              if (!filterData[`${item.nombre_filtro}_HASTA`])
                                errors.push(`${item.titulo_columna} HASTA`)
                            } else {
                              if (!filterData[item.nombre_columna]) errors.push(item.titulo_columna)
                            }

                            return
                          })

                        if (!errors.length > 0) {
                          setActiveFilter(filterData)
                          setPage(1)
                          forceRefresh()
                        }
                      }}
                    >
                      Consultar
                    </Button>
                  </Box>
                </>
              )}
            </>
          )}
        </Collapse>
      </Box>
    </>
  )
}

export default InformesFilter
