const { useState, useEffect } = require('react')

/**
 * The tipes of modal
 * @readonly
 * @enum {string}
 */
export const ModalTypes = {
  Loading: 'Loading',
  Custom: 'Custom',
  Confirm: 'Confirm',
}

/**
 *
 * @returns {import('common/contexts/CustomModalContext').ModalContext}
 */
const useCustomModal = () => {
  const [modal, setModal] = useState(false)
  const [closable, setClosable] = useState(false)
  const [modalContent, setModalContent] = useState('Im the modal content')
  const [modalType, setModalType] = useState(ModalTypes.Loading)
  const [previousContent, setPreviousContent] = useState('')
  const [confirmDialogButtons, setConfirmDialogButton] = useState([])
  const [reRenderParent, setReRenderParent] = useState(false)
  useEffect(() => {
    document.body.style.overflow = modal ? 'hidden' : 'initial'
    if (!modal) {
      setModalContent('')
      setClosable(false)
    }
  }, [modal])
  //TODO: ARREGLAR ESTO
  let returnToParent = ({ canClose = closable || true }) => {
    console.log(previousContent)
    if (previousContent) {
      setModalContent(previousContent)
      setPreviousContent('')
      setClosable(canClose)
      setReRenderParent(false)
      setModalType(ModalTypes.Custom)
    }
  }

  /**
   * Manage the modal state
   *
   * @param {CustomModalHandlerOptions} options
   */
  let handleModal = ({
    type = ModalTypes.Custom,
    content = false,
    enabled = !modal,
    closable = false,
    confirmDialogButtons = [],
    reRenderParent = false,
  }) => {
    setConfirmDialogButton(confirmDialogButtons)
    setReRenderParent(reRenderParent)

    if (type === ModalTypes.Confirm) setPreviousContent(modalContent)
    if (!enabled) setModalContent(null)
    setModal(enabled)
    setClosable(closable)
    if (type) setModalType(type)
    if (content) {
      setModalContent(content)
    }
  }

  return {
    modal,
    handleModal,
    modalContent,
    modalType,
    closable,
    previousContent,
    confirmDialogButtons,
    reRenderParent,
    returnToParent,
  }
}

export default useCustomModal
