/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'api/axios'
const { useState, useEffect, useCallback } = require('react')

/**
 * @module FetchHooks
 * @module DocumentacionLaboral
 */

/**
 * @typedef {import('common/types/connections_types').UseFetchHookResponse<Array<LaboralDocDTO>> | import('common/types/connections_types').UseFetchHookResponse<import('common/types/connections_types').PaginatedResponse<LaboralDocDTO>>} UseFetchLaboralResponseType
 */

/**
 * Fetch the laboral documentation for the current user
 * @param {Object} options
 * @param {UserToHandle} [options.user=null] - The user to handle
 * @param {number | string} [options.ejercicio=null] - The year to fetch
 * @param {string} [options.tipo_documento=null] - The tipe of document to fetch
 * @param {number} [options.iteration] - Changing this value force the relaunch with the currents conditions
 * @param {boolean} [options.only_unread=false] - Fetch only documments that has not been downloded
 * @param {boolean} [options.unforce_year=false] - If it is true will skip the year pagintion
 * @param {number} [options.page_size=6]
 * @example
 * const {response, loading, error} = useFetchLaboral()
 * @returns {import('common/types/connections_types').UseFetchHookResponse<Array<LaboralDocDTO>> | import('common/types/connections_types').UseFetchHookResponse<import('common/types/connections_types').PaginatedResponse<LaboralDocDTO>>}
 */
const useFetchLaboral = (
  {
    user = null,
    ejercicio = null,
    tipo_documento = null,
    iteration = null,
    only_unread = false,
    unforce_year = false,
    page_size = 6,
    paginate = false,
  },
  search = '',
) => {
  const [response, setResponse] = useState([])
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const fetchData = useCallback(() => {
    if (loading || (!ejercicio && !unforce_year)) {
      return
    }
    setLoading(true)
    setError(undefined)

    const urlParts = [
      '/doclaboral',
      `?user=${user?.usuario_web || ''}`,
      `&e=${ejercicio ?? new Date().getFullYear()}`,
      `&tipo=${tipo_documento ?? ''}`,
      `&nl=${only_unread ? 'S' : ''}`,
      `&u=${unforce_year ? 'S' : ''}`,
      `&page_size=${page_size ?? ''}`,
      `&p=${paginate ? 'S' : ''}`,
      `&s=${search ?? ''}`,
    ]

    axios
      .get(urlParts.join(''))
      .then((request_response) => {
        const data = request_response.data
        setResponse(data)
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
  }, [user, ejercicio, tipo_documento, only_unread, iteration, unforce_year, search])

  useEffect(() => {
    fetchData()
  }, [user, ejercicio, tipo_documento, only_unread, iteration, unforce_year, search])

  return { response, error, loading }
}

export default useFetchLaboral
