import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../../api/axios'

const initialState = {
    menus: [],
    status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
    error: null
}

export const fetchMenus = createAsyncThunk('/menus', async () => {
    const response = await axios.get('/menus');
    return response.data;
})

const ordenarMenu = (a, b) => {
    if (a.orden < b.orden) {
        return -1;
    } else if (a.orden > b.orden) {
        return 1;
    } else {
        return 0;
    }
};

export const menusSlice = createSlice({
    name: 'menus',
    initialState,
    reducers: {
        reloadMenus(state, action) {
            state.status = 'idle'
        }
    },
    extraReducers(builder) {
        builder.addCase(fetchMenus.pending, (state, action) => {
            state.status = 'loading'
        })
            .addCase(fetchMenus.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(fetchMenus.fulfilled, (state, action) => {
                state.status = 'succeeded'
                let menus = action.payload.menus.sort(ordenarMenu)
                menus.forEach(m => {
                    if (m.hijos?.length > 0) {
                        m.hijos = m.hijos.sort(ordenarMenu)
                    }
                });
                state.menus = action.payload.menus.sort(ordenarMenu)
            })
    }
})

export const selectMenus = (state) => state.menus.menus;
export const getMenusStatus = (state) => state.menus.status;

export const { reloadMenus } = menusSlice.actions

export default menusSlice.reducer
