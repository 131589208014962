import Theme from './collections/default/Controller'
import themes from './lib/Themes'

class ThemesFacade {
  themes = themes
  /** @type ThemeType */
  theme = 'default'
  /** @type ThemeController */
  activeTheme = null

  constructor() {
    const theme = process.env.REACT_APP_THEME || 'default'
    this.activeTheme = themes[theme]
  }

  /**
   * @returns {EdisaTheme}
   */
  activePallete() {
    const storedTheme = localStorage.getItem('theme') || 'light'

    const pallete =
      storedTheme === 'light' ? this.activeTheme?.LightPallete : this.activeTheme?.DarkPallete
    return pallete
  }
}

export default ThemesFacade
