import {
  consultaInicial,
  selectCamposSolicitud,
  selectDatosSolicitud,
  selectDescripcionesSolicitud,
  setCampoCargando,
  setDatoSolicitud,
} from 'features/solicitudes/solicitudesSlice'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import axios from 'api/axios'

import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import IconButton from '@mui/material/IconButton'
import Input from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'

import { Alert } from '@mui/material'
import CustomTable from 'common/components/CustomTable'

function ListOfValuesComponent({ idpk }) {
  const dispatch = useDispatch()
  const campos = useSelector(selectCamposSolicitud)
  const campo = campos.filter((c) => c.idpk === idpk)[0]

  const datos = useSelector(selectDatosSolicitud)
  const valor = datos[campo.idpk]

  const descripciones = useSelector(selectDescripcionesSolicitud)
  const descripcion = descripciones[campo.idpk]

  const [value, setValue] = useState(campo.valor_dfto || '')
  const [open, setOpen] = useState(false)
  const [lvLoading, setLvLoading] = useState(false)
  const [titulo, setTitulo] = useState('')
  const [etiqueta1, setEtiqueta1] = useState('')
  const [etiqueta2, setEtiqueta2] = useState('')
  const [registros, setRegistros] = useState([])
  const [error, setError] = useState('')

  const getValoresConsulta = () => {
    const valores = []
    for (const idpk in datos) {
      if (Object.hasOwnProperty.call(datos, idpk)) {
        const valor = datos[idpk]
        const field = campos.filter((c) => Number(c.idpk) === Number(idpk))[0]
        valores.push({ codigo_campo: field.codigo_campo, valor, tipo: field.tipo_dato })
      }
    }
    return valores
  }

  useEffect(() => {
    if (campo && campo.inicializar) {
      dispatch(setCampoCargando({ idpk: campo.idpk, cargando: true }))
      dispatch(consultaInicial({ idpk: campo.idpk, valores: [] }))
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    campos.forEach((c) => {
      if (c.depende_de && c.depende_de.includes(campo.codigo_campo) && valor) {
        const valores = getValoresConsulta()
        dispatch(consultaInicial({ idpk: c.idpk, valores }))
      }
    })
    setValue(descripcion)
    // eslint-disable-next-line
  }, [valor])

  const handleSearch = () => {
    if (valor) {
      setValue('')
      dispatch(setDatoSolicitud({ idpk: campo.idpk, valor: null }))
      return
    }
    setLvLoading(true)
    const valores = getValoresConsulta()
    axios
      .post(`/consultalov/${idpk}/`, { valores, busqueda: value })
      .then((request_response) => {
        const data = request_response.data
        /**@type {Array<LovItem>} */
        const registros = data.registros

        setRegistros(registros)
        setTitulo(data.titulo)
        setEtiqueta1(data.etiqueta_1)
        setEtiqueta2(data.etiqueta_2)

        if (registros.length === 1) return handleListItemClick(registros[0])

        setOpen(true)
      })
      .catch((error) => setError(error))
      .finally(() => setLvLoading(false))
  }

  /**
   * @param {LovItem} r - Valor de la lista de valores seleccionado
   */
  const handleListItemClick = (r) => {
    setValue(r.descripcion)
    setOpen(false)
    dispatch(setDatoSolicitud({ idpk: campo.idpk, valor: r.codigo, descripcion: r.descripcion }))
  }

  const handleOnChange = (e) => {
    setValue(e.target.value)
  }

  const handleOnBlur = (e) => {
    if (!valor) {
      handleSearch()
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  if (campo) {
    return error ? (
      <Alert severity="error">Se ha producido un error durante la consulta de datos</Alert>
    ) : (
      <>
        <FormControl variant="standard" error={campo.error}>
          <InputLabel htmlFor={`lv-${campo.idpk}`}>{campo.etiqueta}</InputLabel>
          <Input
            key={`lv-${campo.idpk}`}
            id={`lv-${campo.idpk}`}
            type="text"
            value={value}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            disabled={valor ? true : false}
            error={campo.error}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="abrir lista de valores" onClick={handleSearch}>
                  {lvLoading ? (
                    <CircularProgress size={20} />
                  ) : valor ? (
                    <ClearIcon color={campo.error ? 'error' : ''} />
                  ) : (
                    <SearchIcon color={campo.error ? 'error' : ''} />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText id={`lv-${campo.idpk}-ayuda`}>{campo.ayuda}</FormHelperText>
        </FormControl>
        <Dialog onClose={handleClose} open={open}>
          <DialogTitle>{titulo}</DialogTitle>
          <DialogContent>
            <CustomTable
              hideFooter={false}
              initialPageSize={12}
              dataSource={registros ?? []}
              checkbox={false}
              fields={[
                { value: 'codigo', key: etiqueta1, sortable: true, flex: 1 },
                { value: 'descripcion', key: etiqueta2, sortable: true, flex: 2 },
              ]}
              onClickEvent={(params, _, __) => {
                handleListItemClick(params.row)
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default ListOfValuesComponent
