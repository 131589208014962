import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Unstable_Grid2'
import DocumentacionCard from 'features/documentos/components/DocumentacionCard'
import ThemeManager from 'theming/ThemeManager'

const BuscadorDocumentacion = ({ cargando, resultados }) => {
  const Theme = new ThemeManager()

  const compact = false
  const FULL_WIDTH_ITEM = Theme.options?.documentacionOptions?.fullWidthItem
  const GRID_SPACING = Theme.options?.documentacionOptions?.spacing ?? 2
  const CustomCard = Theme.components?.DocumentacionCard

  return (
    <Box sx={{ bgcolor: 'background.paper', padding: 1 }}>
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {cargando ? (
          [...Array(5).keys()].map((index) => {
            return (
              <>
                <ListItem id={index} alignItems="flex-start">
                  <ListItemAvatar>
                    <Skeleton variant="rounded" width={40} height={40} />
                  </ListItemAvatar>

                  <ListItemText primary={<Skeleton />} secondary={<Skeleton />} />
                </ListItem>
                {index < 4 ? <Divider variant="middle" component="li" /> : <></>}
              </>
            )
          })
        ) : resultados ? (
          resultados.length === 0 ? (
            <Typography variant="body1">No se han encontrado resultados</Typography>
          ) : (
            <Box key="documentos" sx={{ flexGrow: compact ? 0 : 1 }}>
              <Grid container spacing={compact ? 0.1 : GRID_SPACING}>
                {resultados.length > 0 ? (
                  resultados?.map((document, idx) => (
                    <Grid
                      key={idx}
                      item
                      xs={12}
                      sm={FULL_WIDTH_ITEM ? 12 : 6}
                      md={FULL_WIDTH_ITEM ? 12 : 3}
                    >
                      {CustomCard ? (
                        <CustomCard document={document} compact={compact} />
                      ) : (
                        <DocumentacionCard document={document} compact={compact} />
                      )}
                    </Grid>
                  ))
                ) : (
                  <></>
                )}
              </Grid>
            </Box>
          )
        ) : (
          <></>
        )}
      </List>
    </Box>
  )
}

export default BuscadorDocumentacion
