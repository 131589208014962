import { Download } from '@mui/icons-material'
import { Alert, Box, Button, Divider, LinearProgress, Paper, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { CustomModalContext } from 'common/contexts/CustomModalContext'
import { ModalTypes } from 'common/hooks/useCustomModal'
import FilesHelper from 'common/libs/Files'
import ComunicadoCard from 'features/noticias/components/ComunicadoCard'
import { selectUsuario } from 'features/users/stores/userSlice'
import { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { markDocumentAsReaded } from '../libs/markDocumentAsReaded'
import { markNewsAsRead } from '../libs/markNewsAsRead'

/**
 *
 * @component
 * @param {Object} props
 * @param {LockDocument} props.data
 * @returns {JSX.Element}
 */
const LockDocumentNotification = ({ data }) => {
  const [currentDocument, setCurrentDocument] = useState(data)
  const { handleModal } = useContext(CustomModalContext)
  const user = useSelector(selectUsuario)
  const [loading, setLoading] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [downloading, setDownloading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  console.log(currentDocument)

  const texto_lectura =
    currentDocument.tipo_documento === 'Noticia'
      ? 'la noticia'
      : currentDocument.tipo_documento === 'Comunicado'
      ? 'el comunicado'
      : 'el documento'

  return (
    <Paper sx={{ p: 2 }}>
      {!confirm ? (
        <Stack spacing={2}>
          <Typography variant="h5" sx={{}}>
            Aviso
          </Typography>
          <Divider sx={{ my: 1 }} />
          <Typography>Estimado {user.nombre}</Typography>
          <Typography>
            Para proseguir con la navegación, es necesario que leas {` ${texto_lectura} `}
            <b>
              {currentDocument.tipo_documento === 'Noticia' ||
              currentDocument.tipo_documento === 'Comunicado'
                ? currentDocument.documento.titulo
                : currentDocument.tipo_documento === 'Corporativo'
                ? currentDocument.documento.nombre_archivo
                : currentDocument.documento.descripcion}
            </b>
            .
          </Typography>
          {!downloading ? (
            currentDocument.tipo_documento === 'Comunicado' ? (
              <ComunicadoCard
                data={currentDocument.documento}
                requested={true}
                ocultar_boton_lectura={true}
              />
            ) : (
              <Button
                onClick={async () => {
                  try {
                    setDownloading(true)
                    setErrorMessage('')

                    await FilesHelper.downloadFile(
                      currentDocument.documento.id,
                      currentDocument.documento.tipo_doc,
                      currentDocument.documento.url,
                    )
                  } catch (error) {
                    setErrorMessage('No se ha podido descargar el archivo.')
                  } finally {
                    setDownloading(false)
                  }
                }}
                startIcon={<Download />}
              >
                Descargar{' '}
                {currentDocument.tipo_documento === 'Corporativo'
                  ? currentDocument.documento.nombre_archivo
                  : currentDocument.documento.descripcion}
              </Button>
            )
          ) : (
            <></>
          )}
          {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : <></>}
          {downloading ? <LinearProgress /> : <></>}
          <Typography>
            El acceso al portal estará bloqueado hasta que completes esta lectura. Agradecemos tu
            cooperación y comprensión.
          </Typography>
          {loading ? (
            <LinearProgress />
          ) : (
            <Button onClick={() => setConfirm(true)} variant="outlined">
              ACEPTO HABER LEÍDO {` ${texto_lectura.toUpperCase()} `} Y COMPRENDER SU CONTENIDO
            </Button>
          )}
        </Stack>
      ) : (
        <>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            ¿Está seguro de que desea marcar como leído {` ${texto_lectura} `}?
            <br />
            <b>Con esta acción está reconociendo haber leído {` ${texto_lectura} `}.</b>
          </Typography>
          {loading ? <LinearProgress /> : <></>}
          <Box
            sx={{
              display: 'flex',
              gap: '1em',
              width: '100%',
              justifyContent: 'flex-end',
              pt: 1,
            }}
          >
            <Button
              disabled={false}
              onClick={async () => {
                try {
                  setLoading(true)
                  setErrorMessage('')
                  let res

                  if (
                    currentDocument.tipo_documento === 'Comunicado' ||
                    currentDocument.tipo_documento === 'Noticia'
                  ) {
                    res = await markNewsAsRead(
                      currentDocument.documento.id,
                      currentDocument.tipo_documento,
                      currentDocument.lectura,
                    )

                    if (!res.documento) {
                      return handleModal({ enabled: false, type: ModalTypes.Loading })
                    }
                    setCurrentDocument(res.last_pending)
                  } else {
                    res = await markDocumentAsReaded(
                      currentDocument.documento.id,
                      currentDocument.documento.tipo_doc,
                      currentDocument.lectura,
                    )

                    if (!res.last_pending) {
                      return handleModal({ enabled: false, type: ModalTypes.Loading })
                    }
                    setCurrentDocument(res.last_pending)
                  }
                  setLoading(false)
                  setConfirm(false)
                } catch (error) {
                  setLoading(false)
                  setConfirm(false)
                  setErrorMessage('No se ha podido marcar como leido')
                }
              }}
              variant="contained"
            >
              Si
            </Button>
            <Button disabled={false} onClick={() => setConfirm(false)} variant="outlined">
              No
            </Button>
          </Box>
        </>
      )}
      <></>
    </Paper>
  )
}

export default LockDocumentNotification
