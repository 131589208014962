import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material'
import 'dayjs/locale/es'

import TeamCalendarDates from '../components/TeamCalendarDates'

const TeamCalendarAccordeon = ({ team }) => {
  return team?.map((group, i) => (
    <Accordion defaultExpanded key={`team-acc-${i}`}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`group${i}-content`}
        id={`group${i}-header`}
        key={`group${i}-header`}
      >
        {group.name}
      </AccordionSummary>
      <AccordionDetails>
        {group.subteams.map((subteam, k) => {
          return (
            <Box key={`sub_group_${k}`}>
              {subteam.name && subteam.name !== 'NO_SUBTEAM' ? (
                <Accordion defaultExpanded key={`subteam-acc-${k}`}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`subgroup${i}-content`}
                    id={`subgroup${i}-header`}
                  >
                    {subteam.name}
                  </AccordionSummary>
                  <AccordionDetails>
                    {subteam.employees.map((e, j) => {
                      return <TeamCalendarDates key={`sub_dat_${j}`} employee={e} />
                    })}
                  </AccordionDetails>
                </Accordion>
              ) : (
                subteam.employees.map((e, j) => {
                  return <TeamCalendarDates key={`sub_dat_${j}`} employee={e} />
                })
              )}
            </Box>
          )
        })}
      </AccordionDetails>
    </Accordion>
  ))
}

export default TeamCalendarAccordeon
