import { ChevronRight, ExpandLess, ExpandMore, Menu } from '@mui/icons-material'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import { TreeItem, TreeView } from '@mui/lab'
import {
  Box,
  Button,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  SwipeableDrawer,
  Typography,
} from '@mui/material'
import { CustomModalContext } from 'common/contexts/CustomModalContext'
import { ModalTypes } from 'common/hooks/useCustomModal'
import { Fragment, useContext, useEffect, useState } from 'react'
import ThemeManager from 'theming/ThemeManager'
import { InformesContext } from '../providers/InformesProvider'
import '../styles/menuInforme.css'

export default function MenuInforme() {
  const { handleModal } = useContext(CustomModalContext)
  const {
    menu: menuResponse,
    setRedirected,
    active: {
      current,
      setCurrent,
      structure,
      setFilterData,
      setActiveFilter,
      setPage,
      forceRefresh,
    },
  } = useContext(InformesContext)
  const Theme = new ThemeManager()
  const [menuOppened, setMenuOppened] = useState(false)
  const [expandedMenus, setExpandedMenus] = useState({})

  const expandCollapseMenu = (codigo_menu) => {
    const newExpanded = { ...expandedMenus }
    newExpanded[codigo_menu] = !newExpanded[codigo_menu]
    setExpandedMenus(newExpanded)
  }

  useEffect(() => {
    handleModal({ enabled: menuResponse?.loading, type: ModalTypes.Loading })
  }, [menuResponse?.loading])

  /**
   *
   * @param {InformeMenu[]} menuToIterate
   */
  function iterateWideMenu(menuToIterate, isChild = false, index = 0) {
    if (!menuToIterate) return <></>

    const element = menuToIterate?.map((item) => {
      return (
        <>
          {item.children?.length <= 0 ? (
            <ListItemButton
              key={item.codigo_menu}
              sx={{
                border: 0,
                borderColor: 'grey.400',
                p: 0.5,
                pl: !isChild ? 2 : index * 3,
                pr: 1,
              }}
              onClick={() => {
                setCurrent(item)
              }}
            >
              <ListItemText primary={<Typography>{item.nombre}</Typography>} />
            </ListItemButton>
          ) : (
            <Fragment key={item.codigo_menu}>
              <ListItemButton
                color="primary"
                onClick={() => expandCollapseMenu(item.codigo_menu)}
                sx={{
                  // borderTop: 1,
                  // borderBottom: 1,
                  fontWeight: 'bold',
                  borderColor: 'grey.400',
                  p: 0.5,
                  pl: 1,
                  pr: 1,
                }}
              >
                <ListItemIcon>
                  {!expandedMenus[item.codigo_menu] ? <ExpandLess /> : <ExpandMore />}
                </ListItemIcon>
                <ListItemText primary={<Typography fontWeight="bold">{item.nombre}</Typography>} />
              </ListItemButton>
              <Collapse unmountOnExit orientation="vertical" in={!expandedMenus[item.codigo_menu]}>
                <List disablePadding>{iterateWideMenu(item.children, true, index + 1)}</List>
              </Collapse>
            </Fragment>
          )}
        </>
      )
    })
    return element
  }

  /**
   *
   * @param {InformeMenu[]} menuToIterate - [TODO:description]
   * @returns {[TODO:type]} [TODO:description]
   */
  function iterateMovilMenu(menuToIterate) {
    return menuToIterate?.map((item, idx) => {
      return (
        <>
          {item.children?.length <= 0 ? (
            <TreeItem
              onClick={() => {
                setCurrent(item)
                setRedirected(false)
                setFilterData({})
                setActiveFilter({})
                setMenuOppened(false)
                setPage(1)
                forceRefresh()
              }}
              nodeId={item.codigo_menu}
              label={item.nombre}
            />
          ) : (
            <TreeItem nodeId={item.codigo_menu} label={item.nombre}>
              {iterateMovilMenu(item.children)}
            </TreeItem>
          )}
        </>
      )
    })
  }
  function iterateMenus(menoToIterate) {
    return menoToIterate?.map((item, idx) => {
      return (
        <>
          {item.children?.length <= 0 ? (
            <Button
              key={idx}
              disabled={structure?.loading}
              onClick={() => {
                setCurrent(item)
                setRedirected(false)
                setFilterData({})
                setActiveFilter({})
                setPage(1)
                forceRefresh()
              }}
              sx={{ p: 3, whiteSpace: 'nowrap', textAlign: 'left', color: 'text.primary' }}
            >
              {item.nombre}
            </Button>
          ) : (
            <li key={idx} style={{ display: 'flex', flexDirection: 'column' }}>
              <Button
                disabled={structure?.loading}
                sx={{
                  color: 'text.primary',
                  p: 3,
                  textAlign: 'left',
                  whiteSpace: 'nowrap',
                  justifyContent: 'space-between',
                }}
                endIcon={<KeyboardArrowDown />}
              >
                {item.nombre}
              </Button>
              <ul>{iterateMenus(item.children)}</ul>
            </li>
          )}
        </>
      )
    })
  }
  function buildTree(menuItems) {
    const menuMap = {}
    const tree = []
    menuItems.forEach((item) => {
      menuMap[item.codigo_menu] = { ...item, children: [] }
    })

    menuItems.forEach((item) => {
      if (item.cod_padre && menuMap[item.cod_padre]) {
        menuMap[item.cod_padre].children.push(menuMap[item.codigo_menu])
      } else {
        tree.push(menuMap[item.codigo_menu])
      }
    })

    return tree
  }
  const [menu, setMenu] = useState()
  useEffect(() => {
    if (!menuResponse.response) return
    setMenu(buildTree(menuResponse.response))
  }, [menuResponse.response])
  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent)

  return (
    <>
      {current?.isNestedReport ? (
        <></>
      ) : current?.nombre_informe ? (
        <Box sx={{ mb: 3 }}>
          <Box sx={{ display: { xs: 'flex', sm: 'none' }, justifyContent: 'end' }}>
            <Button
              sx={{ display: 'flex' }}
              onClick={() => {
                setMenuOppened(!menuOppened)
              }}
              endIcon={<Menu />}
            >
              Menú{' '}
            </Button>
          </Box>

          <SwipeableDrawer
            anchor="right"
            open={menuOppened}
            onClose={() => setMenuOppened(false)}
            onOpen={() => setMenuOppened(true)}
          >
            <Box sx={{ width: '70vw' }}>
              <TreeView
                sx={{ mt: 3 }}
                aria-label="file system navigator"
                defaultCollapseIcon={<ExpandMore />}
                defaultExpandIcon={<ChevronRight />}
              >
                {iterateMovilMenu(menu)}
              </TreeView>
            </Box>
          </SwipeableDrawer>

          <Box sx={{ mb: 4, display: { xs: 'none', sm: 'initial' } }}>
            {!menuResponse.loading ? (
              <>
                <ul
                  className="menuInforme"
                  style={{
                    display: 'flex',
                    listStyle: 'none',
                    gap: '5px',
                    '--global-bg': Theme.pallete.background.default,
                  }}
                >
                  {iterateMenus(menu)}
                </ul>
              </>
            ) : (
              <Box sx={{ display: 'flex', gap: '3px' }}>
                <Skeleton>
                  <Button>Texto</Button>
                </Skeleton>
                <Skeleton>
                  <Button>Texto mas largo</Button>
                </Skeleton>
                <Skeleton>
                  <Button>Texto medio</Button>
                </Skeleton>
              </Box>
            )}
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            boxShadow: 1,
            p: 0,
            // border: 1,
            borderColor: 'grey.400',
            backgroundColor: 'background.paper',
          }}
        >
          {iterateWideMenu(menu)}
        </Box>
      )}
      {menuResponse?.loading ? (
        <>
          {current?.nombre_informe ? (
            <>
              <Box sx={{ display: 'flex', gap: '3px' }}>
                <Skeleton>
                  <Button>Texto</Button>
                </Skeleton>
                <Skeleton>
                  <Button>Texto mas largo</Button>
                </Skeleton>
                <Skeleton>
                  <Button>Texto medio</Button>
                </Skeleton>
              </Box>
            </>
          ) : (
            <List>
              <Skeleton width="100%" height="50px" />
              <Skeleton width="100%" height="35px" />
              <Skeleton width="100%" height="35px" />
              <Skeleton width="100%" height="35px" />
              <Skeleton width="100%" height="50px" />
              <Skeleton width="100%" height="35px" />
            </List>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  )
}
