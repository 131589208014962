import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { AuthProvider } from './context/AuthProvider';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux'
import { injectStore } from './api/axios';
import { fetchPortal } from './features/portal/portalSlice'
import { fetchMenus } from './features/portal/menusSlice'
import { persistor, store } from './app/store'
import { PersistGate } from 'redux-persist/integration/react';

injectStore(store)

store.dispatch(fetchPortal())
store.dispatch(fetchMenus())

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <AuthProvider>
            <Routes>
              <Route path="/*" element={<App />} />
            </Routes>
          </AuthProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);