import { Inventory2Outlined, NotificationsOutlined } from '@mui/icons-material'
import { Badge, IconButton, Stack, useMediaQuery } from '@mui/material'
import CustomPopup from 'common/components/CustomPopup'
import UserImage from 'common/components/UserImage'
import Buscador from 'features/buscador/components/Buscador'
import HeaderLaboralNotifications from 'features/documentos/components/HeaderLaboralNotification'
import { DocumentNotificationContext } from 'features/documentos/providers/DocumentNotificationsProvider'
import HeaderNotifications from 'features/notifications/components/HeaderNotifications'
import { NotificationsContext } from 'features/notifications/providers/NotificationsProvider'
import { useContext, useState } from 'react'
import ThemeManager from 'theming/ThemeManager'

/**
 * @module Layout
 */

/**
 * Enum representing different anchor types in the application.
 * @enum {string}
 */
const ANCHOR_TYPES = {
  /**
   * Represents notifications anchor type.
   * @type {string}
   */
  Notifications: 'Notifications',

  /**
   * Represents laboral notifications anchor type.
   * @type {string}
   */
  LaboralNotifications: 'LaboralNotifications',
}

const HeaderActions = () => {
  const [notificationsAnchor, setNotificationsAnchor] = useState(null)
  const [laboralNotificationsAnchor, setlaboralNotificationsAnchor] = useState(null)
  const { count: notificationsCount } = useContext(NotificationsContext)
  const { count: laboralNotificationsCount } = useContext(DocumentNotificationContext)

  /**
   * @param {React.MouseEvent<HTMLButtonElement,MouseEvent>} event
   * @param {ANCHOR_TYPES} anchorType
   */
  function setAnchor(event = null, anchorType) {
    if (!event) {
      setNotificationsAnchor(null)
      setlaboralNotificationsAnchor(null)
      return
    }
    if (anchorType === ANCHOR_TYPES.Notifications) setNotificationsAnchor(event.target)
    if (anchorType === ANCHOR_TYPES.LaboralNotifications)
      setlaboralNotificationsAnchor(event.target)
  }
  const Theme = new ThemeManager()

  const isGreaterThanSm = useMediaQuery(Theme.mui_theme.breakpoints.up('sm'))

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        width: '100%',
        justifyContent: {
          xs: 'flex-end',
          md: 'flex-start',
        },
      }}
    >
      <IconButton
        onClick={(event) => {
          setAnchor(event, ANCHOR_TYPES.Notifications)
        }}
        sx={{
          p: 1,
          display: {
            md: 'flex',
          },
          color: { xs: 'background.alternativeContrastText', md: 'initial' },
        }}
        aria-label="delete"
        size="large"
      >
        <Badge badgeContent={notificationsCount} color="secondary">
          <NotificationsOutlined fontSize="inherit" />
        </Badge>
      </IconButton>
      <CustomPopup
        anchorEl={notificationsAnchor}
        triggerClose={() => setAnchor(null)}
        Child={HeaderNotifications}
        anchorOrigin={{ vertical: 'bottom', horizontal: isGreaterThanSm ? 'left' : 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: isGreaterThanSm ? 'left' : 'right' }}
      />
      <IconButton
        onClick={(event) => {
          setAnchor(event, ANCHOR_TYPES.LaboralNotifications)
        }}
        sx={{
          display: {
            md: 'flex',
          },
          color: { xs: 'background.alternativeContrastText', sm: 'initial' },
        }}
        aria-label="delete"
        size="large"
      >
        <Badge badgeContent={laboralNotificationsCount} color="secondary">
          <Inventory2Outlined fontSize="inherit" />
        </Badge>
      </IconButton>
      <CustomPopup
        anchorEl={laboralNotificationsAnchor}
        triggerClose={() => setAnchor(null)}
        Child={HeaderLaboralNotifications}
        anchorOrigin={{ vertical: 'bottom', horizontal: isGreaterThanSm ? 'left' : 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: isGreaterThanSm ? 'left' : 'right' }}
      />
      <Buscador />
      <UserImage
        boxSx={{ display: { xs: 'initial', md: 'none', width: '60px' } }}
        // expanded={true}
        hideText={true}
      />
    </Stack>
  )
}

export default HeaderActions
