import { useCallback } from 'react'
import axios from 'api/axios'
import { useEffect, useState } from 'react'

/**
 * @module FetchHooks
 */

/**
 * Hook to fetch the worker data
 * @param {UserToHandle} [user] - The user to make the request
 * @return {import('common/types/connections_types').UseFetchHookResponse<FormationDTOArray>}
 */
const useFetchUserFormation = (user = null) => {
  const [response, setResponse] = useState(/**@type {WorkerDataDto}*/ null)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const fetchData = useCallback(() => {
    setLoading(true)
    setError(null)
    axios
      .get(`/formacionexterna/get/?user=${user?.usuario_web ?? ''}`)
      .then((response) => setResponse(response.data))
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
  }, [user?.usuario_web])

  useEffect(() => {
    fetchData()
  }, [user, fetchData])

  return { response, error, loading }
}

export default useFetchUserFormation
