// @ts-check
'use strict'
import { Route } from 'react-router-dom'
import NewsDetails from './pages/NewsDetails'

/** @type {ThemeRoutes} **/
const noticias_routes = [
  <Route key="forced_news_details" path="/noticias/:id" element={<NewsDetails />} />,
]

export default noticias_routes
