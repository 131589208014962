import {
  consultaInicial,
  executeFieldAction,
  selectCamposSolicitud,
  selectDatosSolicitud,
  setCampoCargando,
  setCampoError,
  setDatoSolicitud,
} from 'features/solicitudes/solicitudesSlice'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import CloseIcon from '@mui/icons-material/Close'
import { Box } from '@mui/material'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { esES } from '@mui/x-date-pickers/locales'
import 'dayjs/locale/es'
import { validate } from '../../libs/FieldResquestValidator'
import { getValoresConsulta } from '../../libs/SolicitudesHelpers'

function DateComponent({ idpk }) {
  const dispatch = useDispatch()
  const campos = useSelector(selectCamposSolicitud)
  const campo = campos.filter((c) => c.idpk === idpk)[0]

  const datos = useSelector(selectDatosSolicitud)
  const valor = datos[campo.idpk]

  const [validationError, setValidationError] = useState('')
  const [alertOpen, setAlertOpen] = useState(false)
  const [value, setValue] = useState(null)

  useEffect(() => {
    if (campo && campo.inicializar) {
      dispatch(setCampoCargando({ idpk: campo.idpk, cargando: true }))
      dispatch(consultaInicial({ idpk: campo.idpk, valores: [] }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setValue(valor)
    if (valor) {
      dispatch(setCampoCargando({ idpk: campo.idpk, cargando: false }))
    }
    // Lanzamos las queries iniciales que contienen a este campo
    campos.forEach((c) => {
      if (c.depende_de && c.depende_de.includes(campo.codigo_campo) && valor) {
        const valores = getValoresConsulta(datos, campos)
        dispatch(consultaInicial({ idpk: c.idpk, valores }))
      }
    })
    campo.acciones.forEach((action) => {
      if (action.valor_referencia === valor) {
        dispatch(executeFieldAction({ action }))
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valor])

  const handleError = (open, message, idpk) => {
    setAlertOpen(open)
    setValidationError(message)
    dispatch(setCampoError({ idpk: idpk, error: open }))
  }

  const handleChange = (newValue) => {
    try {
      validate(campo, newValue)
      dispatch(setDatoSolicitud({ idpk: campo.idpk, valor: newValue }))
      handleError(false, '', campo.idpk)
    } catch (error) {
      setValue(null)
      handleError(true, error.message, campo.idpk)
    }
  }

  if (campo && campo.visible === 'S') {
    const dates = validate(campo, true)

    return (
      <LocalizationProvider
        localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
        dateAdapter={AdapterDayjs}
        adapterLocale="es"
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <DatePicker
            label={`${campo.etiqueta}${campo.obligatorio === 'S' ? ' *' : ''}`}
            value={value}
            onChange={(v) => handleChange(v)}
            slotProps={{
              clearable: true,
              textField: {
                helperText: campo.ayuda,
                error: campo.error,
              },
            }}
            minDate={dates?.minDate ?? null}
            maxDate={dates?.maxDate ?? null}
          />
          <Collapse in={alertOpen}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setAlertOpen(false)
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
              severity="error"
            >
              <AlertTitle>Error</AlertTitle>
              {validationError}
            </Alert>
          </Collapse>
        </Box>
      </LocalizationProvider>
    )
  }
}

export default DateComponent
