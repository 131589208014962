import {
  AccountTree,
  Alarm,
  AppsOutage,
  Article,
  Badge,
  CalendarMonthOutlined,
  Campaign,
  DescriptionOutlined,
  Edit,
  FileCopyOutlined,
  FolderSharedOutlined,
  HomeOutlined,
  KeyRounded,
  Link,
  LocalAtm,
  LogoutOutlined,
  Newspaper,
  NotificationsActiveOutlined,
  OpenInNew,
  PeopleOutline,
  PermContactCalendarOutlined,
  PersonOutlineOutlined,
  QuizOutlined,
  RequestPageOutlined,
  Search,
  SourceOutlined,
} from '@mui/icons-material'
const IconsList = {
  home: HomeOutlined,
  user: PersonOutlineOutlined,
  logout: LogoutOutlined,
  calendar: CalendarMonthOutlined,
  teamcalendar: PermContactCalendarOutlined,
  documentos: SourceOutlined,
  nomina: RequestPageOutlined,
  documento: DescriptionOutlined,
  personal: FolderSharedOutlined,
  comunicacion: Campaign,
  article: Article,
  editar: Edit,
  noticias: Newspaper,
  enlaces: Link,
  preguntas: QuizOutlined,
  organigrama: AccountTree,
  users: PeopleOutline,
  solicitudes: AppsOutage,
  badge: Badge,
  firmaFicheros: FileCopyOutlined,
  fichajes: Alarm,
  notificaciones: NotificationsActiveOutlined,
  openinnew: OpenInNew,
  search: Search,
  key: KeyRounded,
  localatm: LocalAtm,
}

export default IconsList
