// @ts-check
import { selectMenus } from 'features/portal/menusSlice'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import FORCED_ROUTES from '../constants/forced_routes'

/**
 *
 *
 * @param {string} program - La ruta que necesitas encontrar
 * @param {MenuDtoArray} menu - La ruta que necesitas encontrar
 * @returns {boolean} Devuelve si la ruta está definida en el menú
 */
export function routeIsActive(program, menu) {
  let result = false
  menu.forEach(
    (
      /** @type MenuDto */
      item,
    ) => {
      const res = searchTree(item, program)
      if (res) result = true
      return
    },
  )

  return result
}

/**
 * Iterate over the menu to find a program
 *
 * @param {MenuDto} element - The current iteration item
 * @param {string} matchingTitle - The match title
 * @returns {MenuDto | null} A menu instance
 */
export function searchTree(element, matchingTitle) {
  const path = `/${element.camino || element.programa}`
  if (element.activo === 'S' && path === matchingTitle) {
    return element
  } else if (element.hijos != null) {
    var i
    var result = null
    for (i = 0; result == null && i < element.hijos.length; i++) {
      result = searchTree(element.hijos[i], matchingTitle)
    }
    return result
  }
  return null
}

/**
 * Check if the current urs is active on the menus
 *
 * @param {string} [route=null] - Force to check a single route
 * @returns {boolean} - The current route is active on the menu
 */
export const useRouteIsActive = (route) => {
  const menus = useSelector(selectMenus)
  // const menusStatus = useSelector(getMenusStatus)
  const url = useLocation()
  const [menuIsActive, setMenuIsActive] = useState(true)

  useEffect(() => {
    if (menus?.length === 0) return
    if (!menus) return
    const slash = url.pathname === '/'
    if (slash || FORCED_ROUTES.includes(url.pathname)) {
      setMenuIsActive(true)
      return
    }
    let programToFind = '/'
    const urlSlashes = url.pathname.split('/')
    if (urlSlashes.length > 1) programToFind = `/${urlSlashes[1]}`
    const active = routeIsActive(route ?? programToFind, menus)

    setMenuIsActive(slash || active)
  }, [menus, url])

  return menuIsActive
}
