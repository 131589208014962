// @ts-check
import NewsFilter from '../components/NewsFilter'
import { Box } from '@mui/system'
import React from 'react'
import NewsList from '../components/NewsList'
import ThemeManager from 'theming/ThemeManager'

/**
 * A component to show a list of News
 *
 * @param {Object} props
 * @param {number} [props.rowCount = 2] - The ammount of news per row
 * @param {number} [props.spacing = 2] - The ammount of space between news
 * @returns {import('react').ReactElement} the react element
 * @example
 *  const newsPerRow = 2
 *  return (
 *    <NewsList rowCount={newsPerRow} spacing={2}/>
 *  )
 */
const NewsPage = ({ rowCount = 2, spacing = 2 }) => {
  const Theme = new ThemeManager()
  const NewsTheme = Theme?.components?.NewsList
  const NewsComponent = Theme?.components?.NewsList
  return (
    <Box>
      {NewsComponent ? (
        <NewsComponent spacing={spacing} showFilter={true} />
      ) : (
        <NewsList spacing={spacing} showFilter={true} />
      )}
    </Box>
  )
}

export default NewsPage
