import { createTheme } from '@mui/system'
import ThemesFacade from './theme/ThemesFacade'

/**
 * @module Theming
 */

class ThemeManager {
    /**
     * The controller for the current theme
     * @type {ThemeController}
     * */
    activeTheme = null
    /**
     * The theme general options
     * @type {ThemeOptions}
     * */
    options = {}
    /**
     * The active color pallete
     * @type {EdisaTheme}
     * */
    pallete = null
    /**
     * A list of components to override the standard ones
     * @type {ThemeComponents}
     * */
    components = null
    /**
     * An object containing the RoutesController components for the theme can override the standar one
     */
    pages = null
    /**
     * The MUI component style to apply on the theme
     * @type {Record<string, any>}
     */
    components_style = null
    /**
     * The active color pallete
     * @type {Record<string, any>}
     */
    activePallete = null
    /**
     * Determine which sections or field will be hidden
     * @type {ThemeHideSections}
     * */
    hide = null
    /**@type {LogoComponents} */
    logos = {}
    /**
     * React routes that will be added to the RouteController
     * Take into account they will not be forced you still need to add them in mmenu
     * @example
     * import {Route} from "react-router-dom"
     * ...
     * const routes = [<Route path="/" element={<h1>Nueva Ruta</h1>} />]
     * @type {ThemeRoutes} */
    routes = []
    /**
     * The instance of the MUI Theme with all of its options
     * @type {import('@mui/material').ThemeOptions}
     * */
    mui_theme = null
    /**
     * La tipografía que se quiere utilizar en el portal web
     */
    typography = {}

    constructor() {
        const facade = new ThemesFacade()
        this.activeTheme = facade.activeTheme ?? {}
        this.options = facade.activeTheme?.options ?? {}
        this.components = facade.activeTheme?.components ?? {}
        this.pallete = facade?.activePallete()
        this.activePallete = facade.activePallete
        this.components_style = facade.activeTheme?.components_styles
        this.hide = facade.activeTheme.hide ?? {}
        this.activePallete = facade.activePallete ?? {}
        this.components_style = facade.activeTheme?.components_styles ?? {}
        this.logos = facade.activeTheme?.logos ?? {}
        this.routes = facade.activeTheme?.routes ?? []
        this.mui_theme = createTheme({
            palette: this.activePallete(),
            components: this.components_style,
        })
        this.typography = facade.activeTheme?.typography
    }
}

export default ThemeManager
