import { Grid, Paper } from '@mui/material'
import React from 'react'
import GeneralSettings from '../components/GeneralSettings'
import AccountSettings from '../components/AccountSettings'
import ThemeManager from 'theming/ThemeManager'

const Settings = ({}) => {
  const themeController = new ThemeManager()
  return (
    <>
      <Paper elevation={2} sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid sx={{ margin: 'auto' }} item xs={12} md={7}>
            <AccountSettings />
          </Grid>
          {themeController.hide.Options?.General?.Hide ? (
            <></>
          ) : (
            <Grid item xs={12} md={5}>
              <GeneralSettings />
            </Grid>
          )}
        </Grid>
      </Paper>
    </>
  )
}

export default Settings
