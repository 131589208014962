import { Box } from '@mui/material'
import CalendarioAnual from '../components/CalendarioAnual'
import WidgetsCalendario from '../components/WidgetsCalendario'

import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Unstable_Grid2'
import BotonSolicitudInterna from 'features/solicitudes/components/BotonSolicitudInterna'
import {
  selectVacionesPendientes,
  selectVacionesTipoDias,
} from 'features/solicitudes/solicitudesSlice'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

/**
 * @module Calendario
 */

/**
 *
 * @param {Object} props
 * @param {string} [props.requestUser = null] -The user that will make the request
 * @param {string | Date} [props.fechasSolicitud] - The request date
 * @param {boolean} [props.requestUser] -
 */
const Calendario = ({ vistaAmpliada = true, fechasSolicitud = [], requestUser = null }) => {
  const location = useLocation()
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear())
  const [user, setUser] = useState(requestUser ? requestUser : location?.state?.user ?? null)

  const vacacionesPendientes = useSelector(selectVacionesPendientes)
  const vacacionesTipoDias = useSelector(selectVacionesTipoDias)

  return (
    <Stack spacing={2}>
      <WidgetsCalendario useUser={[user, setUser]} currentYear={currentYear} />
      {vistaAmpliada ? (
        <>
          <Grid container spacing={2}>
            <Grid xs={12} md={3}></Grid>
            <Grid xs={12} md={3}></Grid>
            <Grid xs={12} md={3}>
              <BotonSolicitudInterna
                titulo="Solicitar Vacaciones"
                id_solicitud_pe="VAC01"
                buttonProps={{
                  sx: {
                    transition: '.3s',
                    textAlign: 'center',
                    width: {
                      xs: '100%',
                    },
                  },
                }}
              />
            </Grid>
            <Grid xs={12} md={3}>
              <BotonSolicitudInterna
                titulo="Solicitar Permiso"
                id_solicitud_pe="SIT01"
                buttonProps={{
                  sx: {
                    transition: '.3s',
                    textAlign: 'center',
                    width: {
                      xs: '100%',
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
          <Box>
            <Divider sx={{ marginBottom: '2rem', marginTop: '2rem' }} />
          </Box>
          <CalendarioAnual
            useUser={[user, setUser]}
            currentYear={currentYear}
            setCurrentYear={setCurrentYear}
          />
        </>
      ) : (
        <>
          <Divider sx={{ mt: 2, mb: 2 }} />
          {vacacionesPendientes ? (
            <CalendarioAnual
              useUser={[user, setUser]}
              currentYear={currentYear}
              setCurrentYear={setCurrentYear}
              vacacionesPendientes={vacacionesPendientes}
              vacacionesTipoDias={vacacionesTipoDias}
              allowSelection={true}
              fechasSolicitud={fechasSolicitud}
            />
          ) : null}
        </>
      )}
    </Stack>
  )
}

export default Calendario
