import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'

const CustomDialog = ({
  open,
  title,
  actionLeftText,
  actionRightText,
  actionLeft,
  actionRight,
  closeDialogEvent,
  description,
  content = '',
  customActions = null,
  maxWidth = null,
  scroll = 'paper',
  customHead = null,
  customHeadOnContent = true,
  contentMinWidth = '500px',
}) => {
  return (
    <>
      <Dialog
        open={open}
        maxWidth={maxWidth}
        onClose={closeDialogEvent}
        aria-labelledby="alert-dialog-title"
        scroll={scroll}
        aria-describedby="alert-dialog-description"
        overlayStyle={{ opacity: '.3' }}
      >
        {customHead && !customHeadOnContent ? (
          <DialogTitle>{customHead}</DialogTitle>
        ) : (
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        )}
        <DialogContent sx={{ minWidth: contentMinWidth }}>
          {customHeadOnContent && customHead ? customHead : <></>}
          <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
          {content}
        </DialogContent>
        <DialogActions>
          {customActions ? (
            customActions
              .filter((element) => (element ? true : false))
              .map((element, idx) =>
                element ? (
                  <Button key={`dialog_action: ${idx}`} onClick={element.click}>
                    {element.text}
                  </Button>
                ) : (
                  <></>
                ),
              )
          ) : (
            <>
              {actionLeftText ? <Button onClick={actionLeft}>{actionLeftText}</Button> : <></>}
              {actionRightText ? (
                <Button onClick={actionRight} autoFocus>
                  {actionRightText}
                </Button>
              ) : (
                <></>
              )}
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CustomDialog
