/**
 * @module HojasGasto
 */ import { GridView, TableRows } from '@mui/icons-material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Button, ButtonGroup, LinearProgress, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { SnackbarProvider } from 'notistack'
import { useState } from 'react'
import { HojasGastoAddModal } from '../components/HojasGastoAddModal'
import { HojasGastoCards } from '../components/HojasGastoCards'
import { HojasGastoTable } from '../components/HojasGastoTable'
import useFetchHojasGasto from '../hooks/useFetchHojasGasto'

export const HojasGastoPage = () => {
  const [viewType, setViewType] = useState('TABLE')
  const [page, setPage] = useState(1)
  const [iterator, setIterator] = useState(1)

  const { response, loading } = useFetchHojasGasto({ page, iterator })
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: '10px',
        }}
      >
        <Box sx={{ display: 'flex', gap: '5px', flexDirection: 'row' }}>
          <Typography variant="h1" sx={{ fontSize: '2em', mb: 2, fontWeight: 'bold' }}>
            Hojas de Gasto
          </Typography>
          <HojasGastoAddModal />
        </Box>
        <ButtonGroup sx={{ display: { xs: 'none', md: 'flex' } }}>
          <Button
            variant={viewType === 'TABLE' ? 'contained' : 'outlined'}
            onClick={() => {
              setViewType('TABLE')
            }}
          >
            <TableRows />
          </Button>
          <Button
            variant={viewType === 'TABLE' ? 'outlined' : 'contained'}
            onClick={() => {
              setViewType('GRID')
            }}
          >
            <GridView />
          </Button>
        </ButtonGroup>
      </Box>
      <Box
        sx={{
          display: { xs: 'flex', md: 'none' },
          justifyContent: 'center',
          textAlign: 'center',
          alignItems: 'center',
          alignContent: 'center',
          width: '100%',
          placeItems: 'center',
        }}
      >
        <HojasGastoCards hojasGasto={response?.results} setIterator={setIterator} />
      </Box>
      <Box
        sx={{
          display: { xs: 'none', md: 'flex' },
          justifyContent: 'center',
          textAlign: 'center',
          width: '100%',
        }}
      >
        {!loading ? (
          viewType === 'TABLE' ? (
            <HojasGastoTable
              hojasGasto={response.results}
              loading={loading}
              setIterator={setIterator}
            />
          ) : (
            <HojasGastoCards hojasGasto={response?.results} setIterator={setIterator} />
          )
        ) : (
          <Box sx={{ mt: 1, width: '100%' }}>
            <LinearProgress />
          </Box>
        )}
      </Box>
      {!loading ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
          }}
        >
          <Typography sx={{ height: '100%', marginTop: '5px' }}>
            {6 * (page - 1) + 1} - {6 * page > response.count ? response.count : 6 * page} de{' '}
            {response.count}
          </Typography>
          <Button disabled={page > 1 ? false : true} onClick={() => setPage(page - 1)}>
            <ArrowBackIosIcon />
          </Button>
          <Button
            disabled={response.next ? false : true}
            onClick={() => {
              const x = page
              setPage(1 + x)
            }}
          >
            <ArrowForwardIosIcon />
          </Button>
          <SnackbarProvider
            maxSnack={1}
            autoHideDuration={2000}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            variant={'error'}
          />
        </Box>
      ) : null}
    </Box>
  )
}
