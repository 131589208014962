import axios from 'api/axios'

export async function markNewsAsRead(newsId, newsType = 0, uri = null) {
  try {
    const url = uri ? uri.replace('/api/v1/', '') : 'comunicados/marcarlectura/'
    const response = await axios.post(url, {
      id: newsId,
      tipo: newsType,
    })
    if (response.status !== 200) throw new Error('No se pudo marcar el comunicado como leido')
    return response.data
  } catch (error) {
    throw error
  }
}
