import axios from 'api/axios'
function roundDown(number) {
  return Math.floor(number * 1000000) / 1000000
}

/**
 * The tipes of modal
 * @readonly
 * @enum {string}
 */
export const PresenceControlActions = {
  Entrada: 'E',
  Salida: 'S',
}

/**
 * Get The Geolocation Data Async
 *
 * @param {PositionOptions} options
 * @returns {Promise<GeolocationPosition>}
 */
export const getGeoLocation = (options) => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options)
  })
}

/**
 * Class to controll the presence control
 */
class FichajesController {
  /**
   * This method will create a new enry on the
   * presence control table acording to the action_tipe `rhp_tipo_salidas`
   *
   * @static
   * @async
   * @param {PresenceControlActions} tipo_accion - Accion Type to create
   * @param {string} [exit_code] - If is a exit type this param must be provided
   * @example
   * await FichajesController.create(PresenceControlActions.Entrada)
   * // Or if you want to leave
   * await FichajesController.create(PresenceControlActions.Salida, '00SA')
   * @throws {Error}
   * @returns {Promise<void>}
   */
  static async create(action_type = PresenceControlActions.Entrada, exit_code = undefined) {
    try {
      if (navigator.geolocation) {
        const geoPosition = await getGeoLocation()
        /**@type {import('leaflet').LatLngTuple} */
        const newPosition = [1, 1]
        newPosition[0] = geoPosition.coords.latitude
        newPosition[1] = geoPosition.coords.longitude

        const body = {
          entrada_salida: action_type,
          latitud: roundDown(newPosition[0]),
          longitud: roundDown(newPosition[1]),
          fecha_terminal: new Date(),
        }
        console.log(exit_code)
        if (exit_code) body['tipo_salida'] = exit_code

        const response = await axios.post('/fichajes/', body)
        return response.data
      }
    } catch (error) {
      console.log({ error })
      throw new Error('No se han podido obtener los fichajes')
    }
  }
}

export default FichajesController
