
import ListadoDocumentos from '../components/ListadoDocumentos';

function FirmaDocumentos() {
  return (
    <>
        <ListadoDocumentos />
    </>
  )
}

export default FirmaDocumentos