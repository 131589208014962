import { Divider, Typography } from '@mui/material'
import MisSolicitudes from '../components/MisSolicitudes'

export const ListadoMisSolicitudes = () => {
  return (
    <>
      <Typography variant="h3">Mis Solicitudes</Typography>
      <Divider sx={{ mb: 2 }} />
      <MisSolicitudes />
    </>
  )
}
export default ListadoMisSolicitudes
