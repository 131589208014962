import { GridView, TableRows } from '@mui/icons-material'
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material'
import { CustomModalContext } from 'common/contexts/CustomModalContext'
import { ModalTypes } from 'common/hooks/useCustomModal'
import { getLocaleFromFullDate } from 'common/libs/fechas'
import { enqueueSnackbar, SnackbarProvider } from 'notistack'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { HojasGastoLin } from '../components/HojasGastoLin'
import useFetchHojaGasto from '../hooks/useFetchHojaGasto'
import {
  closeHojaGasto,
  deleteHojaGasto,
  getDetalladaHoja,
  getFirmasHoja,
  openHojaGasto,
} from '../lib/HojasGastoController'

export const HojasGastoLinPage = ({ id_h, encr, disableAll = false }) => {
  const { id_hoja } = useParams()
  const id = id_hoja ? id_hoja : id_h
  const [iterator, setIterator] = useState(1)
  const [buttons, setButtons] = useState([])
  const [viewType, setViewType] = useState('TABLE')

  const { response, error, loading } = useFetchHojaGasto({
    id_hoja: id,
    encr,
    iterator,
  })

  const navegate = useNavigate()

  const { handleModal } = useContext(CustomModalContext)

  const handleClick = async () => {
    try {
      handleModal({ type: ModalTypes.Loading })
      const res = await closeHojaGasto({ id: response.id })
      handleModal({ enabled: false })
      if (res) setIterator(Math.random())
    } catch (e) {
      handleModal({ enabled: false })
      console.log(e.message)
    }
  }
  const handleDeleteClick = async () => {
    try {
      handleModal({ type: ModalTypes.Loading })
      const res = await deleteHojaGasto({ id: response.id })
      handleModal({ enabled: false })
      if (res) navegate('/hojasgasto/')
    } catch (e) {
      enqueueSnackbar('No se ha podido borrar la hoja')
      handleModal({ enabled: false })
      console.log(e.message)
    }
  }

  const handleDetalladaClick = async ({ id }) => {
    try {
      handleModal({ type: ModalTypes.Loading })
      await getDetalladaHoja({ id })
      handleModal({ enabled: false })
    } catch (e) {
      handleModal({ enabled: false })
      enqueueSnackbar('No se ha podido obtener la vista detallada')
      console.log(e.message)
    }
  }
  const handleFirmasClick = async ({ id }) => {
    try {
      handleModal({ type: ModalTypes.Loading })
      await getFirmasHoja({ id })
      handleModal({ enabled: false })
    } catch (e) {
      handleModal({ enabled: false })
      enqueueSnackbar('No se ha podido obtener la vista de firmas')
      console.log(e.message)
    }
  }
  const handleChangeState = () => {
    setIterator(iterator + 1)
  }

  const handleOpenClick = async ({ id }) => {
    try {
      handleModal({ type: ModalTypes.Loading })
      const res = await openHojaGasto({ id })
      handleModal({ enabled: false })
      if (!res) return
      setIterator(iterator + 1)
    } catch (error) {
      console.log(error.message)
    }
  }

  useEffect(() => {
    if (disableAll) return
    if (Object.keys(response).length > 0) {
      let buttonArray = []
      if (response.actions.includes('B')) {
        buttonArray.push(
          <Button
            variant="contained"
            sx={{ background: 'red', ':hover': { background: 'darkred' } }}
            onClick={() =>
              handleModal({
                type: ModalTypes.Confirm,
                closable: true,
                enabled: true,
                content: 'Está seguro de que desea borrar la hoja?',
                confirmDialogButtons: (
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      justifyContent: 'center',
                      textAlign: 'center',
                      gap: '5px',
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => {
                        handleDeleteClick()
                        handleModal({ enabled: false })
                      }}
                    >
                      Aceptar
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: 'red',
                        ':hover': { backgroundColor: 'darkred' },
                      }}
                      onClick={() => handleModal({ enabled: false })}
                    >
                      Rechazar
                    </Button>
                  </Box>
                ),
              })
            }
            key={'B'}
          >
            Borrar
          </Button>,
        )
      }
      if (response.actions.includes('CE')) {
        buttonArray.push(
          <Button
            variant="contained"
            sx={{ background: 'red', ':hover': { background: 'darkred' } }}
            onClick={handleClick}
            key={'CE'}
          >
            Cerrar Hoja
          </Button>,
        )
      }
      if (response.actions.includes('A')) {
        buttonArray.push(
          <Button
            variant="contained"
            sx={{ background: 'blue', ':hover': { background: 'darkblue' } }}
            onClick={() => handleOpenClick({ id: response?.id })}
            key={'A'}
          >
            Abrir Hoja
          </Button>,
        )
      }
      if (response.actions.includes('D')) {
        buttonArray.push(
          <Button
            variant="contained"
            sx={{ background: 'blue', ':hover': { background: 'darkblue' } }}
            onClick={() => handleDetalladaClick({ id: response.id })}
            key={'D'}
          >
            Detallada
          </Button>,
        )
      }
      if (response.actions.includes('F')) {
        buttonArray.push(
          <Button
            variant="contained"
            sx={{ background: 'blue', ':hover': { background: 'darkblue' } }}
            onClick={() => handleFirmasClick({ id: response.id })}
            key={'F'}
          >
            Firmas
          </Button>,
        )
      }
      setButtons(buttonArray)
    }
  }, [response])
  return loading ? (
    <Box sx={{ mt: 1, width: '100%' }}>
      <LinearProgress />
    </Box>
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: '10px',
        width: '100%',
      }}
    >
      <SnackbarProvider
        maxSnack={1}
        autoHideDuration={2000}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        variant={'error'}
      />
      <Grid
        container
        sx={{ width: '100%', placeItems: 'center', justifyContent: 'center' }}
        key={response?.id}
      >
        <Card sx={{ width: '100%' }}>
          <CardContent>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                gap: '10px',
                marginBottom: '10px',
              }}
            >
              <Typography>Id: {response?.id}</Typography>
              <Typography>Periodo: {response?.d_periodo}</Typography>
              <Typography>Estado: {response?.d_status}</Typography>
              <Typography>Ejercicio: {response?.ejercicio}</Typography>
              <Typography>Tipo: {response?.d_tipo}</Typography>
              <Typography>Grupo: {response?.d_tipo_grupo}</Typography>
            </Box>
            <Divider orientation="horizontal" variant="fullWidth" flexItem />
            <Grid container spacing={2}>
              <Grid item xs>
                <Typography variant="caption" display="block" gutterBottom>
                  Fecha inicio
                </Typography>
                <Typography variant="body1">
                  {getLocaleFromFullDate(response?.fecha_inicio, false)}
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="caption" display="block" gutterBottom>
                  Fecha fin
                </Typography>
                <Typography variant="body1">
                  {getLocaleFromFullDate(response?.fecha_fin, false)}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Box
              gap={2}
              sx={{
                width: '100%',
                margin: '5px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: { xs: 'center', sm: 'start' },
                flexWrap: { xs: 'wrap', sm: 'nowrap' },
              }}
            >
              {buttons}
              <ButtonGroup sx={{ display: { xs: 'none', lg: 'flex' } }}>
                <Button
                  variant={viewType === 'TABLE' ? 'contained' : 'outlined'}
                  onClick={() => {
                    setViewType('TABLE')
                  }}
                >
                  <TableRows />
                </Button>
                <Button
                  variant={viewType === 'TABLE' ? 'outlined' : 'contained'}
                  onClick={() => {
                    setViewType('GRID')
                  }}
                >
                  <GridView />
                </Button>
              </ButtonGroup>
            </Box>
          </CardActions>
        </Card>
      </Grid>
      <HojasGastoLin
        disableAll={disableAll}
        id={id}
        fechaInicio={response?.fecha_inicio}
        fechaFin={response?.fecha_fin}
        showButton={response?.actions?.includes('C')}
        handleChangeState={handleChangeState}
        encr={encr}
        viewType={viewType}
      />
    </Box>
  )
}
