import axios from 'api/axios'
const { useState, useEffect } = require('react')

const useFetchDocs = (trigger = null, setTrigger) => {
  const [response, setResponse] = useState([])
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const fetchData = () => {
    if (loading) {
      return
    }
    setLoading(true)

    axios
      .post('documentos/')
      .then((request_response) => {
        const data = request_response.data
        setResponse(data)
      })
      .catch((error) => setError(error))
      .finally(() => {
        setLoading(false)
        trigger = null
      })
  }

  useEffect(() => {
    if (!loading || (trigger !== null && trigger)) {
      fetchData()
      setTrigger(null)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger])

  return { response, error, loading }
}

export default useFetchDocs
