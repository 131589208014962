import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  LinearProgress,
  TextareaAutosize,
  Typography,
} from '@mui/material'
import { CustomModalContext } from 'common/contexts/CustomModalContext'
import { getLocaleFromFullDate } from 'common/libs/fechas'
import FilesHelper from 'common/libs/Files'
import { enqueueSnackbar } from 'notistack'
import { useContext } from 'react'
import { deleteHojaGastoLin } from '../lib/HojasGastoLinController'
import { HojasGastoLinAddModal } from './HojasGastoLinAddModal'
import { LinEditModalBody } from './LinEditModalBody'

export const HojasGastoLinCards = ({
  id_hoja,
  showLin = true,
  showButton = true,
  handleChangeState,
  fechaInicio,
  fechaFin,
  disableAll,
  lineas,
  setLineas,
  loading,
}) => {
  const { handleModal } = useContext(CustomModalContext)
  const handleDeleteClick = async ({ id_hoja, id_lin }) => {
    try {
      await deleteHojaGastoLin({ id_hoja, id_lin })
      enqueueSnackbar('Línea borrada correctamente', { variant: 'success' })
      handleChangeState()
    } catch (error) {
      enqueueSnackbar('No se ha podido borrar la línea')
      console.log(error.message)
    }
  }
  const handleDownloadClick = async ({ url }) => {
    try {
      FilesHelper.downloadFile(1, 0, url)
    } catch (error) {
      console.log(error.message)
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      {!disableAll ? (
        <HojasGastoLinAddModal
          id_hoja={id_hoja}
          showButton={showButton}
          updateState={(linea) => {
            setLineas([...lineas, linea])
          }}
          fechaInicio={fechaInicio}
          fechaFin={fechaFin}
        />
      ) : null}
      <Grid container spacing={2} sx={{ marginTop: '5px' }}>
        {lineas ? (
          lineas?.length > 0 ? (
            lineas.map((linea) => (
              <Grid
                xs={12}
                md={6}
                sx={{
                  width: '100%',
                }}
                key={linea.id_lin}
                item
              >
                <Card>
                  <CardContent sx={{ height: { md: '300px', lg: '200px' } }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between',
                        gap: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      <Typography sx={{ minWidth: '32%' }}>
                        <b>FECHA:</b> {getLocaleFromFullDate(linea.fecha, false)}
                      </Typography>
                      <Typography sx={{ minWidth: '32%' }}>
                        <b>CONCEPTO:</b> {linea.concepto}
                      </Typography>
                      <Typography sx={{ minWidth: '32%' }}>
                        <b>CANTIDAD:</b> {linea.cantidad}
                      </Typography>
                      <Typography sx={{ minWidth: '32%' }}>
                        <b>PRECIO:</b> {linea.precio}
                      </Typography>
                      <Typography sx={{ minWidth: '32%' }}>
                        <b>IMPORTE:</b> {linea.importe_interno}
                      </Typography>
                      <Box
                        sx={{
                          width: { xs: '100%', lg: '49%' },
                          textAlign: 'center',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Typography>
                          <b>DESCRIPCIÓN</b>
                        </Typography>
                        <TextareaAutosize
                          sx={{ width: '100%', textAling: 'center' }}
                          aria-label={linea.descripcion}
                          placeholder={linea.descripcion}
                          disabled
                          minRows={3}
                          maxRows={3}
                        />
                      </Box>
                      <Box
                        sx={{
                          width: { xs: '100%', lg: '49%' },
                          textAlign: 'center',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Typography sx={{ width: '100%', textAling: 'center' }}>
                          <b>OBSERVACIONES</b>
                        </Typography>
                        <TextareaAutosize
                          sx={{ width: '100%', textAling: 'center' }}
                          aria-label={linea.observaciones}
                          placeholder={linea.observaciones}
                          disabled
                          minRows={3}
                          maxRows={3}
                        />
                      </Box>
                    </Box>
                    <Box>
                      {linea.documentos.map((doc) => (
                        <Button onClick={() => handleDownloadClick({ url: doc.url })} key={doc.url}>
                          {doc.descripcion}
                        </Button>
                      ))}
                    </Box>
                  </CardContent>
                  <CardActions>
                    {showButton && !disableAll ? (
                      <Box
                        sx={{ display: 'flex', flexDirection: 'row', gap: '5px', flexWrap: 'wrap' }}
                      >
                        <Button
                          variant="contained"
                          onClick={() => {
                            const modalBody = (
                              <LinEditModalBody
                                id_hoja={id_hoja}
                                updateState={(linea) => {
                                  let lin = []
                                  lineas.forEach((line) => {
                                    if (line.id_lin !== linea.id_lin) lin.push(line)
                                  })
                                  lin.push(linea)
                                  setLineas([...lin])
                                }}
                                editObject={linea}
                                fechaInicio={fechaInicio}
                                fechaFin={fechaFin}
                              />
                            )
                            handleModal({
                              closable: true,
                              content: modalBody,
                            })
                          }}
                        >
                          Editar
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() =>
                            handleDeleteClick({ id_hoja: linea.id_hoja, id_lin: linea.id_lin })
                          }
                          sx={{ background: 'red', ':hover': { background: 'darkred' } }}
                        >
                          Borrar
                        </Button>
                      </Box>
                    ) : null}
                  </CardActions>
                </Card>
              </Grid>
            ))
          ) : (
            <Box
              sx={{ display: 'flex', width: '100%', justifyContent: 'center', textAlign: 'center' }}
            >
              <Typography>No hay líneas de hojas de gasto</Typography>
            </Box>
          )
        ) : (
          <Box sx={{ mt: 1, width: '100%' }}>
            <LinearProgress />
          </Box>
        )}
      </Grid>
    </Box>
  )
}
