// @ts-check
import { Folder, FolderOpen } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { alpha, Box } from '@mui/system'
import { getLocaleFromFullDate } from 'common/libs/fechas'
import { selectPortal } from 'features/portal/portalSlice'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ThemeManager from 'theming/ThemeManager'

/**
 * @module Documents
 */

/**
 * @component
 *
 * @param {Object} props
 * @param {LaboralDocDTO} props.document
 * @param {function} [props.closePopup]
 * @param {boolean} [props.reverse=false]
 * @returns {JSX.Element}
 */
const HeaderLaboralCard = ({ document, closePopup = () => {}, reverse = false }) => {
  const Theme = new ThemeManager()
  const navigate = useNavigate()
  const portal = useSelector(selectPortal)
  return (
    <Box
      onClick={() => {
        navigate(`/doclaboral`)
        closePopup()
      }}
      sx={{
        backgroundColor: document.descargado
          ? 'transparent'
          : alpha(Theme.pallete.primary.main, 0.05),
        cursor: 'pointer',
        transition: '.3s',
        // mb: 1,
        p: 1,
        borderRadius: 1,
        display: 'flex',
        flexDirection: !reverse ? 'row-reverse' : 'row',
        alignItems: 'center',
        gap: '15px',
        '&:hover': {
          backgroundColor: alpha(Theme.pallete.primary.main, 0.4),
        },
      }}
    >
      {document.descargado ? (
        <FolderOpen
          sx={{
            color: 'text.disabled',
          }}
        />
      ) : (
        <Folder
          sx={{
            color: 'primary.main',
          }}
        />
      )}
      <Box sx={{ width: '100%' }}>
        <Typography
          variant="subtitle1"
          sx={{ fontSize: '.6rem', textAlign: 'left', width: '100%' }}
        >
          {getLocaleFromFullDate(
            document.proceso_fecha,
            true,
            portal?.locale?.replace('_', '-') ?? 'es-Es',
          )}
        </Typography>
        <Typography sx={{ width: '100%' }} variant="subtitle1" textAlign="left">
          {document.d_rh_documento}
        </Typography>
      </Box>
    </Box>
  )
}

export default HeaderLaboralCard
