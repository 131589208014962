import { combineReducers, configureStore } from '@reduxjs/toolkit'
import menusSlice from 'features/portal/menusSlice'
import portalSlice from 'features/portal/portalSlice'
import solicitudesSlice from 'features/solicitudes/solicitudesSlice'
import userSlice from 'features/users/stores/userSlice'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'],
}

const userReducer = combineReducers({
  user: userSlice,
  portal: portalSlice,
  menus: menusSlice,
  solicitudes: solicitudesSlice,
})

const persistedReducer = persistReducer(persistConfig, userReducer)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: [thunk],
})

export const persistor = persistStore(store)
