import { Box, Skeleton } from '@mui/material'

const TeamCalendarHeaderSkeleton = () => {
  return (
    <Box key={'bppal'} sx={{ display: 'flex' }}>
      <Box key={'bsec1'} sx={{ padding: '5px', flex: '1 0 25%' }}>
        &nbsp;
      </Box>
      <Box
        key={'bsec2'}
        sx={{
          display: 'flex',
          flex: '1 0 75%',
          overflowX: 'auto',
          justifyContent: 'space-between',
        }}
      >
        {Array(30)
          .fill()
          .map((_, i) => (
            <Skeleton key={i} variant="rectangle" width={25} height={30} />
          ))}
      </Box>
    </Box>
  )
}

export default TeamCalendarHeaderSkeleton
