// @ts-check
import { useEffect, useState } from 'react'

import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency'
import EditIcon from '@mui/icons-material/Edit'
import { Grid, Typography } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'
import { getFechaFromISO } from 'common/libs/fechas'
import { selectPortal } from 'features/portal/portalSlice'
import BotonSolicitudInterna from 'features/solicitudes/components/BotonSolicitudInterna'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import ThemeManager from 'theming/ThemeManager'
import useFetchOtherData from '../hooks/useFetchOtherData.js'

/**
 * Visor de datos personaled del trabajador
 *
 * @param {Object} props
 * @param {WorkerDataDto} [props.datosTrabajador = null] - Worker data
 */
const Personales = ({ datosTrabajador = null }) => {
  const Theme = new ThemeManager()
  const fecha_nacimiento = new Date(
    datosTrabajador?.fecha_nacimiento?.replace(/(\d{2})-(\d{2})-(\d{4})/, '$2/$1/$3'),
  )
  let edad = new Date().getFullYear() - fecha_nacimiento.getFullYear()
  const location = useLocation()
  const [user, _] = useState(location?.state?.user ?? null)
  const { response, loading, error } = useFetchOtherData(user)
  const portal = useSelector(selectPortal)

  const [otrosDatos, setOtrosDatos] = useState()
  useEffect(() => setOtrosDatos(response), [response])
  return (
    <Stack pt={2} pb={2} spacing={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card sx={{ flexGrow: 1 }}>
            <CardHeader
              title="Datos Personales"
              subheader=""
              action={
                <BotonSolicitudInterna
                  titulo="Cambiar"
                  id_solicitud_pe="DPRUP"
                  buttonStartIcon={<EditIcon />}
                  buttonVariant="text"
                  buttonSize="small"
                />
              }
            />
            <CardContent>
              <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <ListItem>
                  <ListItemText primary="Nombre" secondary={`${datosTrabajador?.nombre ?? ''}`} />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Primer apellido"
                    secondary={`${datosTrabajador?.primer_apellido ?? ''}`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Segundo apellido"
                    secondary={`${datosTrabajador?.segundo_apellido ?? ''}`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Nombre abreviado"
                    secondary={`${datosTrabajador?.nombre_abreviado ?? ''}`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Sexo" secondary={datosTrabajador?.d_sexo ?? ''} />
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ flexGrow: 1 }}>
            <CardHeader title="Nacimiento" subheader="" />
            <CardContent>
              <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <ListItem>
                  <ListItemText
                    primary="Fecha nacimiento"
                    secondary={`${
                      fecha_nacimiento
                        ? getFechaFromISO(
                            datosTrabajador?.fecha_nacimiento,
                            portal?.locale?.replace('_', '-'),
                          )
                        : ''
                    }`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Edad" secondary={`${edad ?? ''}`} />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Lugar"
                    secondary={`${datosTrabajador?.lugar_nacimiento ?? ''}`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Provincia"
                    secondary={`${datosTrabajador?.d_provincia_nacim ?? ''}`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Estado"
                    secondary={`${datosTrabajador?.d_estado_nacim ?? ''}`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Nacionalidad"
                    secondary={`${datosTrabajador?.d_nacionalidad ?? ''}`}
                  />
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Stack direction="row" spacing={2}>
        <Card sx={{ flexGrow: 1 }}>
          <CardHeader title="Residencia" subheader="" />
          <CardContent>
            <Stack spacing={0}>
              <Stack direction="row" spacing={0}>
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                  <ListItem>
                    <ListItemText
                      primary="Tipo de vía"
                      secondary={`${datosTrabajador?.d_calle_plaza_avda ?? ''}`}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Dirección"
                      secondary={`${datosTrabajador?.direccion ?? ''}`}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Número" secondary={`${datosTrabajador?.numero ?? ''}`} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Piso" secondary={`${datosTrabajador?.piso ?? ''}`} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Puerta" secondary={`${datosTrabajador?.puerta ?? ''}`} />
                  </ListItem>
                </List>
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                  <ListItem>
                    <ListItemText
                      primary="Estado"
                      secondary={`${datosTrabajador?.d_estado ?? ''}`}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Provincia"
                      secondary={`${datosTrabajador?.d_provincia ?? ''}`}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Municipio"
                      secondary={`${datosTrabajador?.municipio ?? ''}`}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Localidad"
                      secondary={`${datosTrabajador?.localidad ?? ''}`}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Código Postal"
                      secondary={`${datosTrabajador?.codigo_postal ?? ''}`}
                    />
                  </ListItem>
                </List>
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                  <ListItem>
                    <ListItemText
                      primary="Carnet de conducir"
                      secondary={`${datosTrabajador?.carnet_conducir ?? ''}`}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Vehículo"
                      secondary={`${datosTrabajador?.vehiculo ?? ''}`}
                    />
                  </ListItem>
                </List>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Stack>
      <Stack direction="row" spacing={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Card sx={{ flexGrow: 1 }}>
              <CardHeader title="Contacto" subheader="" />
              <CardContent>
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                  <ListItem>
                    <ListItemText
                      primary="Teléfono 1º"
                      secondary={`${datosTrabajador?.telefono1 ?? ''}`}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Teléfono 2º"
                      secondary={`${datosTrabajador?.telefono2 ?? ''}`}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Móvil personal"
                      secondary={`${datosTrabajador?.movil_personal ?? ''}`}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Móvil profesional"
                      secondary={`${datosTrabajador?.movil_empresa ?? ''}`}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Email personal"
                      secondary={`${datosTrabajador?.e_mail_personal ?? ''}`}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Email" secondary={`${datosTrabajador?.e_mail ?? ''}`} />
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </Grid>

          {Theme.hide?.WorkerData?.Personal?.Emergency?.Hide ? (
            <></>
          ) : (
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  flexGrow: 1,
                  alignSelf: 'flex-start',
                  bgcolor: 'error.dark',
                  color: 'error.contrastText',
                }}
              >
                <CardHeader avatar={<ContactEmergencyIcon />} title="Emergencia" />
                <CardContent>
                  <List sx={{ width: '100%', bgcolor: 'error.dark', color: 'error.contrastText' }}>
                    <ListItem>
                      <ListItemText
                        color="inherit"
                        primary="Contacto de emergencia"
                        secondary={
                          <Typography>{`${
                            otrosDatos?.contacto_emergencia_nombre ?? ''
                          }`}</Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        color="inherit"
                        primary="Teléfono de emergencia"
                        secondary={
                          <Typography>{`${
                            otrosDatos?.contacto_emergencia_telefono ?? ''
                          }`}</Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Información adicional"
                        secondary={
                          <Typography>{`${otrosDatos?.texto_productor ?? ''}`}</Typography>
                        }
                      />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </Stack>
    </Stack>
  )
}

export default Personales
