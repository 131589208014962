import { Box, Skeleton } from '@mui/material'
import TeamCalendarDatesSkeleton from './TeamCalendarDatesSkeleton'
import TeamCalendarHeaderSkeleton from './TeamCalendarHeaderSkeleton'

const TeamCalendarSkeleton = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <TeamCalendarHeaderSkeleton key={1} />

      <TeamCalendarDatesSkeleton key={2} />

      <Skeleton key={11} variant="rectangular" sx={{ height: '24px', width: '100%', mt: 2 }} />
      <TeamCalendarDatesSkeleton key={3} />
      <TeamCalendarDatesSkeleton key={4} />
      <Skeleton key={21} variant="rectangular" sx={{ height: '24px', width: '100%', mt: 2 }} />
      <TeamCalendarDatesSkeleton key={5} />
    </Box>
  )
}

export default TeamCalendarSkeleton
