import axios from 'api/axios'
import ThemeManager from 'theming/ThemeManager'
const { useState, useEffect } = require('react')

/**
 * @module FetchHooks
 */

/**
 * Hook that fetch the coprporation docs for the user
 * @param {number} [page = 1]
 * @param {number | string} [type]
 * @param {number} [page_size]
 *
 * @returns {import('common/types/connections_types').UseFetchHookResponse<DocCorporativaDtoResponse>}
 */
const useFetchCorporativa = (
  page = 1,
  type = null,
  page_size,
  iteration = null,
  unread = false,
) => {
  const [response, setResponse] = useState([])
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const Theme = new ThemeManager()

  const page_size_to_use = page_size ?? Theme.options?.corporateDocsOptions?.pageSize ?? ''

  const fetchData = () => {
    if (loading) {
      return
    }

    setLoading(true)
    axios
      .get(
        `/doccorporativa?page_size=${page_size_to_use}&page=${page}&t=${type ?? ''}&u=${
          unread ? 'S' : 'N'
        }`,
      )
      .then((request_response) => {
        const data = request_response.data
        setResponse(data)
        setError('')
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, type, iteration])

  return { response, error, loading }
}

export default useFetchCorporativa
