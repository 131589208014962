import { Divider, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import AccesosDirectos from 'common/components/AccesosDirectos'
import NewsList from 'features/noticias/components/NewsList'
import VisorPreguntas from 'features/preguntas/pages/VisorPreguntas'

const Home = () => {
  return (
    <>
      <Box>
        <Typography variant="h1" sx={{ fontSize: '2em', mb: 2, fontWeight: 'bold' }}>
          Accesos Directos
        </Typography>
        <AccesosDirectos sx={{ mb: 2 }} />
      </Box>
      <Divider sx={{ mb: 3 }} />
      <Box>
        <Grid container spacing={3}>
          <Grid item md={8} xs={12}>
            <Typography variant="h1" sx={{ fontSize: '2em', mb: 2, fontWeight: 'bold' }}>
              Noticias
            </Typography>
            <NewsList showFilter={false} />
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography variant="h1" sx={{ fontSize: '2em', mb: 2, fontWeight: 'bold' }}>
              Preguntas Frecuentes
            </Typography>
            <VisorPreguntas />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default Home
