import { Avatar, Box, Divider, Tooltip, Typography } from '@mui/material'

const TeamCalendarDates = function ({ employee }) {
  return (
    <Box sx={{ display: 'flex', pb: 1 }}>
      <Tooltip
        title={
          <Box sx={{ display: 'flex', padding: 1 }}>
            <Avatar
              src={`/assets/images/${employee?.imagen}`}
              sx={{
                transition: '.3s',
                backgroundColor: 'secondary.main',
                aspectRatio: '4/4',
                width: '72px',
                height: '72px',
                mr: 2,
                color: 'secondary.contrastText',
              }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography key={1} variant="body1">
                {employee?.nombre}
              </Typography>
              <Typography key={2} variant="caption">
                {employee?.d_subempresa}
              </Typography>
              <Typography key={3} variant="caption">
                {employee?.d_centro}
              </Typography>
              <Typography key={4} variant="caption">
                {employee?.d_puesto}
              </Typography>
            </Box>
          </Box>
        }
      >
        <Box sx={{ padding: '5px', flex: '1 0 25%', display: 'flex', alignItems: 'center' }}>
          <Avatar
            src={`/assets/images/${employee?.imagen}`}
            sx={{
              transition: '.3s',
              backgroundColor: 'secondary.main',
              aspectRatio: '4/4',
              width: '30px',
              height: '30px',
              mr: 1,
              color: 'secondary.contrastText',
            }}
          />

          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography key={1} variant="body1">
              {employee?.nombre}
            </Typography>
          </Box>
        </Box>
      </Tooltip>
      <Box
        sx={{
          display: 'flex',
          flex: '1 0 75%',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          overflowX: 'auto',
        }}
      >
        {employee?.fechas_list.map((d, i) => {
          const dateSplitted = d.date.split(' ')[0].split('-')
          const date = new Date(
            Number(dateSplitted[0]),
            Number(dateSplitted[1]) - 1,
            Number(dateSplitted[2]),
          )
          const data = d.data.filter((data) => data.background_color)
          let style = {
            padding: '5px',
            width: '25px',
            fontSize: '14px',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
          }
          for (let i = 0; i < data.length; i++) {
            if (data[i].rendering === 'background') {
              style['background'] = `${data[0].background_color}`
              style['boxShadow'] = `${data[0].background_color} 0px -4px 0px 0px inset;`
            }
            if (data[i].rendering === 'border') {
              style['boxShadow'] = `${data[0].background_color} 0px -4px 0px 0px inset;`
            }
          }

          return data[0]?.titulo ? (
            <Tooltip
              key={i}
              title={
                <>
                  <Typography variant="caption">
                    {date.toLocaleDateString('es-ES', {
                      year: 'numeric',
                      month: 'long',
                      day: '2-digit',
                    })}
                  </Typography>
                  <Divider sx={{ backgroundColor: 'white', mb: 1 }} />
                  {data.map((dt, j) => (
                    <Typography key={j}>{dt.titulo}</Typography>
                  ))}
                </>
              }
            >
              <Box sx={style}>{date.getDate().toString().padStart(2, '0')}</Box>
            </Tooltip>
          ) : (
            <Box key={i} sx={style}>
              {date.getDate().toString().padStart(2, '0')}
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

export default TeamCalendarDates
