import useIsGottenbergActive from 'common/hooks/useIsGottenbergActive'
import React, { createContext } from 'react'

/**
 * @module Common
 */

/**
 * @typedef GlobalVariablesContext
 * @prop {boolean} IS_GOTTENBERG_ACTIVE
 */

/**
 * @type {import('react').Context<GlobalVariablesContext>}
 */
let GlobalVariablesContext

/**
 * @type {import('react').Context<GlobalVariablesContext>}
 */
let { Provider } = (GlobalVariablesContext = createContext())

let GlobalVarialbesProvider = ({ children }) => {
  const IS_GOTTENBERG_ACTIVE = useIsGottenbergActive()
  return (
    <Provider
      value={{
        IS_GOTTENBERG_ACTIVE,
      }}
    >
      {children}
    </Provider>
  )
}

export { GlobalVariablesContext, GlobalVarialbesProvider }
