import axios from 'api/axios'

export const createHojaGastoLin = async ({
  id_hoja,
  fecha,
  grupoGastos,
  concepto,
  descripcion,
  cantidad,
  precio,
  observaciones,
  documentos,
}) => {
  const body = {
    lin: {
      id_hoja: id_hoja,
      fecha: fecha,
      grupo_gastos: grupoGastos,
      concepto: concepto,
      descripcion: descripcion,
      cantidad: cantidad,
      precio: precio,
      observaciones: observaciones,
    },
    doc: documentos,
  }
  try {
    const response = await axios.post('/hojasgastolin/', body)
    return response.data
  } catch (e) {
    throw e
  }
}

export const deleteHojaGastoLin = async ({ id_hoja, id_lin }) => {
  try {
    const body = {
      id_hoja,
      id_lin,
    }

    const response = await axios.delete('/hojasgastolin/', { data: body })
    return response.data
  } catch (error) {
    throw error
  }
}

export const editHojaGastoLin = async ({
  id_hoja,
  id_lin,
  fecha,
  grupoGastos,
  concepto,
  descripcion,
  cantidad,
  precio,
  observaciones,
  documentos,
}) => {
  const body = {
    lin: {
      id_hoja,
      id_lin,
      fecha,
      grupo_gastos: grupoGastos,
      concepto,
      descripcion,
      cantidad,
      precio,
      observaciones,
    },
    doc: documentos,
  }
  try {
    const response = await axios.patch('/hojasgastolin/', body)
    return response.data
  } catch (e) {
    throw e
  }
}
