import axios from 'api/axios'
import useFetchFAQ from '../hooks/useFetchFAQ'

/**
 * Class to manage the FAQ CRUD
 * @class
 */
class FaqController {
  static get = useFetchFAQ

  /**
   * Function to create a question
   * @function
   * @param {QuestionDTO} question
   * @returns {Promise<QuestionDTO>}
   */
  static create = async (question) => {
    /** @type {QuestionDTO} */
    const pregunta = { texto_pregunta: question }
    try {
      /** @type {import('axios').AxiosResponse<LinksDTO>}*/
      const response = await axios.post('/preguntas/', pregunta)
      return response.data
    } catch (_) {
      return null
    }
  }

  /**
   * Function to update a link
   * @function
   * @param {QuestionDTO} question
   * @returns {Promise<QuestionDTO>}
   */
  static update = async (question) => {
    try {
      /** @type {import('axios').AxiosResponse<QuestionDTO>}*/
      const response = await axios.put(`/preguntas/${question.id}/`, question)
      return response.data
    } catch (_) {
      return null
    }
  }

  /**
   * Function to delete a link
   * @function
   * @param {number} id
   * @returns {Promise<QuestionDTO>}
   */
  static delete = async (id) => {
    try {
      /** @type {import('axios').AxiosResponse<LinksDTO>}*/
      const response = await axios.delete(`/preguntas/${id}/`)
      return response.data
    } catch (_) {
      return null
    }
  }
}

export default FaqController
