import Home from 'common/pages/Home'
import Logout from 'common/pages/Logout'
import Settings from 'features/ajustes/pages/Settings'
import Login from 'features/auth/pages/Login'

import Buscador from 'features/buscador/pages/Buscador'
import Calendario from 'features/calendario/pages/Calendario'
import TeamCalendar from 'features/calendario/pages/TeamCalendar'
import Fichajes from 'features/control_presencia/pages/Fichajes'
import DatosTrabajador from 'features/datos_trabajador/pages/DatosTrabajador'
import Model145 from 'features/datos_trabajador/pages/Model145'
import DifusionDocumentos from 'features/documentos/pages/DifusionDocumentos'
import DocCorporativa from 'features/documentos/pages/DocCorporativa'
import DocLaboral from 'features/documentos/pages/DocLaboral'
import DocPersonal from 'features/documentos/pages/DocPersonal'
import VisorEnlaces from 'features/enlaces/components/VisorEnlaces'
import EdicionEnlaces from 'features/enlaces/pages/EdicionEnlaces'
import FirmaDocumentos from 'features/firma_documentos/pages/FirmaDocumentos'
import { HojasGastoLinPage } from 'features/hojasgasto/pages/HojasGastoLinPage'
import { HojasGastoPage } from 'features/hojasgasto/pages/HojasGastoPage'
import Informes from 'features/informes/pages/Informes'
import Comunicados from 'features/noticias/pages/Comunicados'
import NewsPage from 'features/noticias/pages/NewsPage'
import NotificationsPage from 'features/notifications/pages/NotificationsPage'
import Organigrama from 'features/organigrama/pages/Organigrama'
import EdicionPreguntas from 'features/preguntas/pages/EditorPregunta'
import Preguntas from 'features/preguntas/pages/VisorPreguntas'
import SingleSignOn from 'features/singlesignon/pages/SingleSignOn'
import ListadoMisSolicitudes from 'features/solicitudes/pages/ListadoMisSolicitudes'
import ListadoSolicitudes from 'features/solicitudes/pages/ListadoSolicitudes'
import ListadoSolicitudesPendientes from 'features/solicitudes/pages/ListadoSolicitudesPendientes'
import Solicitud from 'features/solicitudes/pages/Solicitud'
import MyUsers from 'features/users/pages/MyUsers'
import ThemeManager from 'theming/ThemeManager'
import EdicionNoticias from '../../noticias_web/pages/EdicionNoticias'
import Noticias from '../../noticias_web/pages/Noticias'

const theme = new ThemeManager()

const ROUTE_COMPONENTS = {
  login: Login,
  home: Home,
  datostrabajador: DatosTrabajador,
  calendario: Calendario,
  calendarioequipo: TeamCalendar,
  doclaboral: DocLaboral,
  doccorporativa: DocCorporativa,
  docpersonal: DocPersonal,
  organigrama: Organigrama,
  visorNoticias: Noticias,
  editorNoticias: EdicionNoticias,
  visorPreguntas: Preguntas,
  editorPreguntas: EdicionPreguntas,
  visorEnlaces: VisorEnlaces,
  editorEnlaces: EdicionEnlaces,
  logout: Logout,
  // usuarios: ListaUsuarios ,
  usuarios: MyUsers,
  listasolicitudes: ListadoSolicitudes,
  solicitud: Solicitud,
  solicitudes: ListadoMisSolicitudes,
  pendientes: ListadoSolicitudesPendientes,
  firmaFicheros: FirmaDocumentos,
  settings: Settings,
  noticias: NewsPage,
  model145: Model145,
  fichajes: Fichajes,
  notificaciones: NotificationsPage,
  comunicados: Comunicados,
  informes: Informes,
  documentos: DifusionDocumentos,
  buscador: Buscador,
  sso: SingleSignOn,
  hojasgasto: HojasGastoPage,
  hojasgastolin: HojasGastoLinPage,
}

if (theme?.activeTheme?.pages) {
  Object.keys(theme.activeTheme.pages).forEach((key) => {
    if (theme.activeTheme.pages[key]) ROUTE_COMPONENTS[key] = theme.activeTheme.pages[key]
  })
}

export default ROUTE_COMPONENTS
