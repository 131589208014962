import { useContext, useState } from 'react'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Unstable_Grid2'
import CustomUserCard from 'features/users/components/CustomUserCard'
import { useLocation } from 'react-router-dom'

import useFetchLaboral from '../hooks/useFetchLaboral'
import LaboralDocumentCard from './LaboralDocumentCard'

/**
 *@module Documentacion
 *@module DocumentacionLaboral
 */

/**
 * Grid View for the Laboral Docs
 *
 * @param {Object} props
 * @param {string | number} [props.ejercicio] - The year to be fetched
 * @param {string} [props.document_type] - The tipe of document to fetch
 * @example
 * <Laboral />
 */
const Laboral = ({ ejercicio = null, document_type = null }) => {
  const location = useLocation()
  const [user, setUser] = useState(location?.state?.user ?? null)
  const { response, loading } = useFetchLaboral({ user, ejercicio, tipo_documento: document_type })

  return (
    <Box sx={{ width: '100%' }}>
      {user ? <CustomUserCard user={user} returnToUser={() => setUser(null)} /> : <></>}
      <Stack spacing={0}>
        <Box key="documentos" sx={{ flexGrow: 1, mt: 2 }}>
          <Grid container spacing={2}>
            {response?.length > 0 ? (
              response.map((document) => {
                return (
                  <Grid key={document.id} xs={12} sm={6} md={3}>
                    <LaboralDocumentCard document={document} />
                  </Grid>
                )
              })
            ) : loading ? (
              [...Array(12).keys()].map((i) => {
                return (
                  <Grid key={i} xs={12} sm={6} md={3}>
                    <Card>
                      <CardHeader
                        avatar={
                          <Skeleton animation="wave" variant="circular" width={40} height={40} />
                        }
                        title={
                          <Skeleton
                            animation="wave"
                            height={10}
                            width="80%"
                            style={{ marginBottom: 6 }}
                          />
                        }
                        subheader={<Skeleton animation="wave" height={10} width="40%" />}
                      />
                      <CardContent>
                        <Skeleton animation="wave" height={10} width="50%" />
                      </CardContent>
                      <CardActions disableSpacing>
                        <Skeleton animation="wave" variant="rectangular" height={30} width="35%" />
                      </CardActions>
                    </Card>
                  </Grid>
                )
              })
            ) : (
              <h1 style={{ opacity: 0.3, textAlign: 'center' }}>No se encontraron documentos</h1>
            )}
          </Grid>
        </Box>
      </Stack>
    </Box>
  )
}

export default Laboral
