import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { Box } from '@mui/system'

const DataTableSkeleton = () => {
  return (
    <Box>
      <TableContainer>
        <Table>
          <TableHead sx={{ borderBottom: 1 }}>
            <TableRowsLoader header rowsNum={1} columns={6} />
          </TableHead>
          <TableBody>
            <TableRowsLoader rowsNum={12} columns={6} />
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default DataTableSkeleton

const TableRowsLoader = ({ rowsNum, header = false, columns = 5 }) => {
  return [...Array(rowsNum)].map((row, index) => (
    <TableRow key={index}>
      <TableCell sx={{ p: 1, borderBottom: header ? 1 : 0 }} component="th" scope="row">
        <Skeleton animation="wave" variant="text" />
      </TableCell>
      {[...Array(columns - 1)].map((i, index) => (
        <TableCell key={index} sx={{ p: 1, borderBottom: header ? 1 : 0 }}>
          <Skeleton fontSize={header ? '1.2em' : '.9em'} animation="wave" variant="text" />
        </TableCell>
      ))}
    </TableRow>
  ))
}
