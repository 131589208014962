import { EscalatorWarning, Groups, Person2, TextSnippet } from '@mui/icons-material'
import { Button, CircularProgress, Grid } from '@mui/material'
import { Box } from '@mui/system'
import ScrollHelper from 'common/libs/ScrollHelper'
import Model145DescendantData from '../components/Model145DescendantData'
import Model145Header from '../components/Model145Header'
import Model145ParentsData from '../components/Model145ParentsData'
import Model145PensionsData from '../components/Model145PensionsData'
import Model145PerceptorData from '../components/Model145PerceptorData'
import useFetchModel145 from '../hooks/useFetchModel145'

const Model145 = () => {
  const { response, loading } = useFetchModel145()
  const sections = ['model145-intro', 'personal', 'descendants', 'ascendants', 'pensions']
  return (
    <>
      {!loading && response?.datos_trabajador?.dni ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1em' }}>
          <span id={sections[0]} />
          <Model145Header />
          <Grid container sx={{ width: '100%' }} spacing={1}>
            <Grid item xs={12} sm={3}>
              <Button
                sx={{ width: '100%' }}
                onClick={() => ScrollHelper.scrollTo({ id: sections[1], duration: 600 })}
                variant="contained"
                startIcon={<Person2 />}
              >
                Datos del perceptor
              </Button>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button
                sx={{ width: '100%' }}
                onClick={() => ScrollHelper.scrollTo({ id: sections[2], duration: 600 })}
                variant="contained"
                startIcon={<Groups />}
              >
                Hijos y descendientes
              </Button>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button
                sx={{ width: '100%' }}
                onClick={() => ScrollHelper.scrollTo({ id: sections[3], duration: 600 })}
                variant="contained"
                startIcon={<EscalatorWarning />}
              >
                Mayores
              </Button>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button
                sx={{ width: '100%' }}
                onClick={() => ScrollHelper.scrollTo({ id: sections[4], duration: 600 })}
                variant="contained"
                startIcon={<TextSnippet />}
              >
                Pensiones y Pagos
              </Button>
            </Grid>
          </Grid>
          <span id={sections[1]} />
          <Model145PerceptorData data={response} />
          <span id={sections[2]} />
          <Model145DescendantData data={response} />
          <span id={sections[3]} />
          <Model145ParentsData data={response} />
          <span id={sections[4]} />
          <Model145PensionsData data={response} />
        </Box>
      ) : (
        <>
          <Box
            sx={{
              height: '100%',
              width: '100%',
              display: 'flex',
              placeItems: 'center',
            }}
          >
            <CircularProgress sx={{ margin: 'auto' }} />
          </Box>
        </>
      )}
    </>
  )
}

export default Model145
