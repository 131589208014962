import { useEffect, useState } from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Stack from '@mui/material/Stack'
import useFetchProfessionalData from '../hooks/useFetchProfesionalData'
import { useLocation } from 'react-router-dom'
import ThemeManager from 'theming/ThemeManager'

const Profesionales = () => {
  const location = useLocation()
  const { response } = useFetchProfessionalData(location?.state?.user ?? null)
  const [datosProfesionales, setDatosProfesionales] = useState(response)
  const Theme = new ThemeManager()

  useEffect(() => {
    setDatosProfesionales(response)
  }, [response])

  return (
    <Stack direction="row" spacing={2} pt={2} pb={2}>
      <Card sx={{ flexGrow: 1 }}>
        <CardContent>
          <Stack spacing={0}>
            <Stack direction="row" spacing={0}>
              <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <ListItem>
                  <ListItemText
                    primary="Fecha alta"
                    secondary={`${datosProfesionales?.fecha_alta ?? ''}`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Fecha antigüedad"
                    secondary={`${datosProfesionales?.fecha_antiguedad ?? ''}`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Situación"
                    secondary={`${datosProfesionales?.situacion ?? ''}`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Centro"
                    secondary={`${datosProfesionales?.d_centro_trabajo ?? ''}`}
                  />
                </ListItem>
                {Theme.hide?.WorkerData?.Professional?.Service ? (
                  <></>
                ) : (
                  <ListItem>
                    <ListItemText
                      primary="Servicio"
                      secondary={`${datosProfesionales?.servicio ?? ''}`}
                    />
                  </ListItem>
                )}
              </List>
              <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <ListItem>
                  <ListItemText
                    primary="Convenio"
                    secondary={`${datosProfesionales?.d_convenio ?? ''}`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Categoría"
                    secondary={`${datosProfesionales?.d_categoria ?? ''}`}
                  />
                </ListItem>
                {Theme.hide?.WorkerData?.Professional.Contract ? (
                  <></>
                ) : (
                  <ListItem>
                    <ListItemText
                      primary="Contrato"
                      secondary={`${datosProfesionales?.d_contrato ?? ''}`}
                    />
                  </ListItem>
                )}
                {Theme.hide?.WorkerData?.Professional?.Hours ? (
                  <></>
                ) : (
                  <ListItem>
                    <ListItemText
                      primary="Horas"
                      secondary={`${datosProfesionales?.horas_contrato ?? ''}`}
                    />
                  </ListItem>
                )}
              </List>
            </Stack>
            <Stack direction="row" spacing={0}>
              <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                {Theme.hide?.WorkerData?.Professional?.PortionHours ? (
                  <></>
                ) : (
                  <ListItem>
                    <ListItemText
                      primary="Porción horas"
                      secondary={`${datosProfesionales?.porc_horas ?? ''}`}
                    />
                  </ListItem>
                )}
              </List>
              <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                {Theme.hide?.WorkerData?.Professional?.NetAmmount ? (
                  <></>
                ) : (
                  <ListItem>
                    <ListItemText
                      primary="Bruto pactado"
                      secondary={`${datosProfesionales?.bruto_pactado ?? ''}`}
                    />
                  </ListItem>
                )}
              </List>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  )
}

export default Profesionales
