import axios from 'api/axios'
const { useState, useEffect, useCallback } = require('react')

/**
 * @module FetchHooks
 * @module DocumentacionLaboral
 */

/**
 * Fetch the calendar data for the user
 * @param {string} [document_type]
 * @param {number} [iteration]
 * @returns {import('common/types/connections_types').UseFetchHookResponse<docLaboralMetaDTO>}
 */
const useFetchDocumentacionMeta = (iteration = 0) => {
  const [resmeta, setResmeta] = useState([])
  const [error, setError] = useState('')
  const [loadmeta, setLoadmeta] = useState(false)

  const fetchData = useCallback(() => {
    if (loadmeta) {
      return
    }

    setLoadmeta(true)
    axios
      .get(`/documentacion/meta/`)
      .then((request_response) => {
        const data = request_response.data
        setResmeta(data)
      })
      .catch((error) => setError(error))
      .finally(() => setLoadmeta(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iteration])

  useEffect(() => {
    fetchData()
  }, [fetchData, iteration])

  return { resmeta, error, loadmeta }
}

export default useFetchDocumentacionMeta
