// @ts-check
import { NewspaperOutlined, VisibilityOutlined } from '@mui/icons-material'
import { Box, Button, Link, Typography } from '@mui/material'
import { selectPortal } from 'features/portal/portalSlice'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

/**
 * A component to show a list of News
 *
 * @param {Object} props
 * @param {NewsDTO} props.news
 * @param {boolean} props.makeBanner
 * @returns {import('react').ReactElement} the react element
 *  @example
 *  const new = {...}
 *  return (
 *    <New news={new} />
 *  )
 */
const New = ({ news, makeBanner = false }) => {
  const viewDetails = () => {
    navigate(`/noticias/${news.id}`)
  }
  const portal = useSelector(selectPortal)
  const [imageError, setImageError] = useState(false)
  const navigate = useNavigate()
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column',
          md: makeBanner ? 'row-reverse' : 'column',
        },
        gap: makeBanner ? '10px' : '0',
      }}
    >
      <Box
        sx={{
          aspectRatio: '16/9',
          backgroundSize: 'cover',
          width: {
            xs: '100%',
            md: makeBanner ? '50%' : '100%',
          },
          mb: makeBanner ? 3 : '0',
          display: 'flex',
          flexDirection: 'column',
          cursor: 'pointer',
          position: 'relative',
          '&:hover': {},
        }}
      >
        <>
          <>
            {imageError ? (
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  display: 'grid',
                  placeItems: 'center',
                  backgroundColor: 'background.paper',
                }}
              >
                <NewspaperOutlined
                  sx={{
                    fontSize: '10em',
                    color: 'background.default',
                    filter: 'brightness(80%)',
                  }}
                />
              </Box>
            ) : (
              <img
                src={`/assets/images/noticias/${news.portada_nombre}`}
                alt={news.titulo}
                style={{
                  objectFit: 'cover',
                  aspectRatio: '16/9',
                  backgroundColor: 'inherit',
                }}
                onError={() => {
                  setImageError(true)
                }}
              />
            )}
          </>

          <Box
            onClick={viewDetails}
            sx={{
              fontWeight: 'bold',
              width: '100%',
              height: '100%',
              display: 'flex',
              textAlign: 'center',
              position: 'absolute',
              placeItems: 'center',
              fontSize: '1em',
              transition: '.3s ease-out',
              background:
                'radial-gradient(circle, rgba(0,0,0,0) 5%, rgba(0,0,0,0.3) 50%, rgba(0,0,0,.6) 100%)',
              opacity: '0',
              '&:hover': {
                fontSize: '5em',
                color: 'white',
                opacity: '1',
              },
            }}
          >
            <Typography
              sx={{
                width: '100%',
                fontSize: 'inherit',
                textShadow: '0px 0px 12px rgba(0,0,0,0.5)',
              }}
            >
              <VisibilityOutlined
                sx={{
                  fontSize: 'inherit',
                  textShadow: 1,
                  filter: 'drop-shadow(3px 5px 12px rgb(0 0 0 / 0.4))',
                }}
              />
            </Typography>
          </Box>
        </>
      </Box>
      <Box sx={{ flex: makeBanner ? 1 : 'unset' }}>
        <Typography sx={{ opacity: 0.8, mt: 1 }}>
          {new Date(news.fecha_publicacion).toLocaleString(
            portal?.locale?.replaceAll('-', '_') ?? 'es-ES',
            {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            },
          )}
        </Typography>
        <Link
          component="button"
          onClick={viewDetails}
          sx={{
            textDecoration: 'none',
            color: 'text.primary',
            transition: '.3s ease-in-out',
            '&:hover': { color: 'primary.main' },
            '&:focus': { color: 'primary.main' },
            textAlign: 'left',
          }}
        >
          <Typography
            sx={{
              fontSize: '1.4rem',
              opacity: 0.8,
              fontWeight: 'bold',
            }}
            variant="h2"
          >
            {news.titulo}
          </Typography>
        </Link>
        <Typography sx={{ mt: 1 }}>{news.contenido.slice(0, 200) + ' ...'}</Typography>
        <Button
          disableElevation
          onClick={viewDetails}
          sx={{
            ml: 'auto',
            mt: 2,
            display: 'block',
          }}
        >
          Leer Más{' '}
        </Button>
      </Box>
    </Box>
  )
}

export default New
