import axios from 'api/axios'
import ThemeManager from 'theming/ThemeManager'
const { useState, useEffect } = require('react')

/**
 * @module FetchHooks
 */

/**
 * Hook that fetch the coprporation docs for the user
 * @param {number} [page = 1]
 * @param {number | string} [type]
 * @param {number} [page_size]
 *
 * @returns {import('common/types/connections_types').UseFetchHookResponse<DocumentacionDtoResponse>}
 */
const useFetchDocumentacion = (
  page = 1,
  type = null,
  page_size,
  search = '',
  categoria = '',
  etiqueta = '',
  fecha_desde = null,
  fecha_hasta = null,
  orden = '',
  id = null,
) => {
  const [response, setResponse] = useState([])
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const Theme = new ThemeManager()

  const page_size_to_use = page_size ?? Theme.options?.documentacionOptions?.pageSize ?? ''

  const fetchData = () => {
    if (loading) {
      return
    }

    setLoading(true)
    axios
      .get(
        `/documentacion/?page_size=${page_size_to_use}&page=${page}&k=${type ?? ''}&s=${
          search || ''
        }&c=${categoria || ''}&t=${etiqueta || ''}&fd=${fecha_desde?.toISOString() || ''}&fh=${
          fecha_hasta?.toISOString() || ''
        }&o=${orden || ''}
          &i=${id || ''}`,
      )
      .then((request_response) => {
        const data = request_response.data
        setResponse(data)
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, type, search, categoria, etiqueta, fecha_desde, fecha_hasta, orden])

  return { response, error, loading }
}

export default useFetchDocumentacion
