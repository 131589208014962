import { Grid } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { InformesContext } from '../providers/InformesProvider'
import FilterListOfValues from './FilterListOfValues'
import FiltersCustomTextField, { FiltersValuePosition } from './FiltersCustomTextField'

/**
 *
 * @component
 *
 * @param {object} props
 * @param {InformeFilter} props.filter
 * @param {boolean} props.validating
 * @param {function} props.setFiltersValues
 * @param {object} props.filtersValues
 * @returns {JSX.Element}
 */
const FilterTextField = ({ filter, validating }) => {
  const Context = useContext(InformesContext)

  function changeFilter(newValue, pos) {
    const newFilter = Context.redirected ? { ...Context.active.filterData } : {}
    newFilter[pos] = newValue
    Context.active.setFilterData(newFilter)
  }

  useEffect(() => {
    const newFilter = { ...Context.active.filterData }
    if (filter.tipo_filtro === 'I') {
      newFilter[`${filter.nombre_filtro}${FiltersValuePosition.DESDE}`] = ''
      newFilter[`${filter.nombre_filtro}${FiltersValuePosition.HASTA}`] = ''
    } else newFilter[`${filter.nombre_filtro}`] = ''

    Context.active.setFilterData(newFilter)
  }, [filter])

  return (
    <Grid container spacing={2}>
      {filter.tipo_filtro !== 'I' ? (
        <Grid item xs={6}>
          {filter.lista_valores ? (
            <FilterListOfValues
              filter={filter}
              validating={validating}
              position={FiltersValuePosition.FIJO}
              changeFilter={changeFilter}
            />
          ) : (
            <FiltersCustomTextField
              filter={filter}
              validating={validating}
              position={FiltersValuePosition.FIJO}
              changeFilter={changeFilter}
            />
          )}
        </Grid>
      ) : (
        <>
          <Grid item xs={6}>
            {filter.lista_valores ? (
              <FilterListOfValues
                filter={filter}
                validating={validating}
                position={FiltersValuePosition.DESDE}
                changeFilter={changeFilter}
              />
            ) : (
              <FiltersCustomTextField
                filter={filter}
                validating={validating}
                position={FiltersValuePosition.DESDE}
                changeFilter={changeFilter}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            {filter.lista_valores ? (
              <FilterListOfValues
                filter={filter}
                validating={validating}
                position={FiltersValuePosition.HASTA}
                changeFilter={changeFilter}
              />
            ) : (
              <FiltersCustomTextField
                filter={filter}
                validating={validating}
                position={FiltersValuePosition.HASTA}
                changeFilter={changeFilter}
              />
            )}
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default FilterTextField
