/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @module Informes
 */

import { createContext, useEffect, useMemo, useState } from 'react'
import useFetchInformeStructure from '../hooks/useFetchInformeStrucutre'
import useFetchInformesMenu from '../hooks/useFetchInformesMenu'

/**
 * @typedef  InformesActive
 * @prop {InformeMenu} current
 * @prop {function} setCurrent
 * @prop {import('common/types/connections_types').UseFetchHookResponse<InformeStructureDTO>} structure
 * @prop {InformeFilterData} filterData
 * @prop {function} setFilterData
 * @prop {InformeFilterData} activeFilter
 * @prop {function} setActiveFilter
 * @prop {number} iteration
 * @prop {function} forceRefresh
 * @prop {number} page
 * @prop {boolean} hasForcedFilters
 * @prop {(newValue: number) => void} setPage
 * @prop {boolean} hasEmptyForced
 */

/**
 * @typedef InformesContext
 * @prop {boolean} redirected
 * @prop {function} setRedirected
 * @prop {InformesActive} active
 * @prop {import('common/types/connections_types').UseFetchHookResponse<Array<InformeMenu>>} menu
 */

/**
 * @type {React.Context<InformesContext>}
 */
let InformesContext

/**
 * @type {React.Context<InformesContext>}
 */
let { Provider } = (InformesContext = createContext())

const InformesProvider = ({ children }) => {
  const menu = useFetchInformesMenu()
  const [active, setActive] = useState(null)
  const [page, setPage] = useState(1)
  const [filterData, setFilterData] = useState({})
  const [iteration, setIteration] = useState(1)
  const [activeFilter, setActiveFilter] = useState({})
  const [hasForcedFilters, setHasForced] = useState(false)
  const structure = useFetchInformeStructure({ nombre_informe: active?.nombre_informe })
  const [redirected, setRedirected] = useState(false)
  const forceRefresh = () => {
    setIteration(iteration + 1)
  }

  const hasEmptyFilters = useMemo(() => {
    if (!structure?.response?.columnas) return false
    let errors = false
    structure.response.columnas
      .filter((filter) => {
        return filter.campo_de_filtro === 'S' && filter.obligatorio === 'S'
      })
      .forEach((item) => {
        if (item.tipo_filtro === 'I') {
          if (!activeFilter[`${item.nombre_filtro}_DESDE`]) errors = true
          if (!activeFilter[`${item.nombre_filtro}_HASTA`]) errors = true
        } else {
          if (!activeFilter[item.nombre_columna]) errors = true
        }

        if (errors) return
      })

    return errors
  }, [activeFilter, structure?.response?.columnas])

  useEffect(() => {
    setFilterData({})
    if (!structure?.response?.columnas) return
    const newFilters = structure.response.columnas.filter((filter) => {
      return filter.campo_de_filtro === 'S'
    })

    setHasForced(newFilters.findIndex((element) => element.obligatorio === 'S') > -1)
  }, [structure?.response?.columnas])

  useEffect(() => {
    if (!redirected) {
      setActiveFilter({})
      setFilterData({})
    }
    setPage(1)
    forceRefresh()
  }, [active?.current?.nombre_informe])

  return (
    <Provider
      value={{
        menu,
        redirected,
        setRedirected,
        active: {
          hasEmptyForced: hasEmptyFilters,
          hasForcedFilters,
          setPage,
          page,
          forceRefresh,
          current: active,
          setCurrent: setActive,
          iteration,
          structure,
          filterData,
          setFilterData,
          activeFilter,
          setActiveFilter,
        },
      }}
    >
      {children}
    </Provider>
  )
}

export { InformesContext, InformesProvider }
