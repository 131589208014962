import CloseIcon from '@mui/icons-material/Close'
import { Box } from '@mui/material'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import CircularProgress from '@mui/material/CircularProgress'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import {
  consultaInicial,
  executeFieldAction,
  selectCamposSolicitud,
  selectDatosSolicitud,
  setCampoCargando,
  setCampoError,
  setDatoSolicitud,
} from 'features/solicitudes/solicitudesSlice'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { numericValidation, validate } from '../../libs/FieldResquestValidator'
import { getValoresConsulta } from '../../libs/SolicitudesHelpers'

function TextComponent({ idpk }) {
  const inputReference = useRef(null)
  const dispatch = useDispatch()
  const campos = useSelector(selectCamposSolicitud)
  const campo = campos.filter((c) => c.idpk === idpk)[0]

  const datos = useSelector(selectDatosSolicitud)
  const valor = datos[campo.idpk]

  const [value, setValue] = useState(campo.valor_dfto ?? '')
  const [validationError, setValidationError] = useState('')
  const [alertOpen, setAlertOpen] = useState(false)

  useEffect(() => {
    if (campo && campo.inicializar) {
      dispatch(setCampoCargando({ idpk: campo.idpk, cargando: true }))
      dispatch(consultaInicial({ idpk: campo.idpk, valores: [] }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setValue(valor ?? '')
    if (valor) {
      dispatch(setCampoCargando({ idpk: campo.idpk, cargando: false }))
    }
    // Lanzamos las queries iniciales que contienen a este campo
    campos.forEach((c) => {
      if (c.depende_de && c.depende_de.includes(campo.codigo_campo) && valor) {
        const valores = getValoresConsulta(datos, campos)
        dispatch(consultaInicial({ idpk: c.idpk, valores }))
      }
    })
    campo.acciones.forEach((action) => {
      if (action.valor_referencia === valor) {
        dispatch(executeFieldAction({ action }))
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valor])

  const handleError = (open, message, idpk) => {
    setAlertOpen(open)
    setValidationError(message)
    dispatch(setCampoError({ idpk: idpk, error: open }))
  }

  const handleBlur = (e) => {
    try {
      let value = e.target.value

      if (campo.tipo_dato === 'N') {
        value = numericValidation(campo, e.target.value, true)
      }

      const newValue = validate(campo, value)
      if (newValue !== true) {
        dispatch(setDatoSolicitud({ idpk: campo.idpk, valor: newValue }))
      } else {
        dispatch(setDatoSolicitud({ idpk: campo.idpk, valor: value }))
      }
      handleError(false, '', campo.idpk)
    } catch (error) {
      handleError(true, error.message, campo.idpk)
      inputReference.current.focus()
      e.currentTarget.focus()
      e.target.focus()
    }
  }

  /**
   * This function will handle the sanitization of the numeric input:
   *     - Change decimal separator
   *     - Check the masking
   */
  const handleNumericChange = (e) => {
    try {
      const masked_value = numericValidation(campo, e.target.value)
      setValue(masked_value)
      handleError(false, '', campo.idpk)
    } catch (e) {
      handleError(true, e.message, campo.idpk)
    }
  }

  /**@typedef {CampoSolicitud} campo */
  if (campo) {
    const numerico = (
      <TextField
        ref={inputReference}
        key={campo.idpk}
        id={campo.codigo_campo}
        label={campo.etiqueta}
        variant="standard"
        required={campo.obligatorio === 'S'}
        helperText={campo.ayuda}
        fullWidth
        inputProps={{ inputMode: 'decimal', pattern: '[0-9]*' }}
        InputLabelProps={{ shrink: value ? true : false }}
        value={value}
        onChange={handleNumericChange}
        onBlur={handleBlur}
        InputProps={{
          startAdornment: campo.cargando ? <CircularProgress size={20} /> : null,
        }}
        error={campo.error}
      />
    )
    const alfanumerico = (
      <TextField
        ref={inputReference}
        key={campo.idpk}
        id={campo.codigo_campo}
        label={campo.etiqueta}
        variant="standard"
        required={campo.obligatorio === 'S'}
        helperText={campo.ayuda}
        fullWidth
        InputLabelProps={{ shrink: value ? true : false }}
        value={value}
        onChange={(event) => {
          setValue(event.target.value)
        }}
        onBlur={handleBlur}
        InputProps={{
          startAdornment: campo.cargando ? <CircularProgress size={20} /> : null,
        }}
        error={campo.error}
      />
    )
    const textArea = (
      <TextField
        ref={inputReference}
        key={campo.idpk}
        id={campo.codigo_campo}
        label={campo.etiqueta}
        variant="standard"
        required={campo.obligatorio === 'S'}
        helperText={campo.ayuda}
        fullWidth
        multiline
        rows={4}
        InputLabelProps={{ shrink: value ? true : false }}
        value={value}
        onChange={(event) => {
          setValue(event.target.value)
        }}
        onBlur={handleBlur}
        InputProps={{
          startAdornment: campo.cargando ? <CircularProgress size={20} /> : null,
        }}
        error={campo.error}
      />
    )
    const validationAlert = (
      <Collapse in={alertOpen}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlertOpen(false)
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
          severity="error"
        >
          <AlertTitle>Error</AlertTitle>
          {validationError}
        </Alert>
      </Collapse>
    )

    if (campo.tipo_dato === 'N') {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          {numerico}
          {validationAlert}
        </Box>
      )
    } else if (Number(campo.longitud_char) <= 100) {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          {alfanumerico}
          {validationAlert}
        </Box>
      )
    } else {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          {textArea}
          {validationAlert}
        </Box>
      )
    }
  }
}

export default TextComponent
