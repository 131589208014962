import { CircularProgress, Paper } from '@mui/material'
import { Box } from '@mui/system'
import { CustomModalContext } from 'common/contexts/CustomModalContext'
import { ModalTypes } from 'common/hooks/useCustomModal'
import { useContext } from 'react'
import { createPortal } from 'react-dom'

const CustomModal = () => {
  let {
    modalType,
    modalContent,
    handleModal,
    modal,
    closable,
    previousContent,
    confirmDialogButtons,
    reRenderParent,
  } = useContext(CustomModalContext)

  function hideModal(event) {
    if (closable) handleModal({ enabled: false })
  }

  return createPortal(
    <Box
      sx={{
        visibility: modal ? 'initial' : 'hidden',
        opacity: modal ? 1 : 0,
        transition: '.4s',
        pointerEvents: modalType === ModalTypes.Loading ? 'none' : 'all',
        position: 'fixed',
        p: 3,
        top: 0,
        width: '100vw',
        height: '100vh',
        left: 0,
        zIndex: 100,
      }}
    >
      {modalType === ModalTypes.Loading ? (
        <Box
          sx={{
            pointerEvents: 'none',
            position: 'fixed',
            bottom: '15px',
            right: '15px',
            width: '60px',
            height: '60px',
            backgroundColor: 'primary.main',
            borderRadius: '100%',
            display: 'flex',
            placeItems: 'center',
            color: 'primary.contrastText',
            boxShadow: 4,
          }}
        >
          <CircularProgress sx={{ color: 'primary.contrastText', m: 'auto' }} />
        </Box>
      ) : (
        <></>
      )}
      {modalType === ModalTypes.Custom ? (
        <>
          <Box
            onClick={hideModal}
            sx={{
              visibility: modal ? 'initial' : 'hidden',
              opacity: modal || modalType !== ModalTypes.Loading ? 1 : 0,
              transition: '.4s',
              position: 'fixed',
              p: 3,
              background: !modal ? 'initial' : 'rgba(0,0,0,.4)',
              top: 0,
              width: '100vw',
              height: '100vh',
              left: 0,
              zIndex: -1,
            }}
          ></Box>
          <Box
            sx={{
              display: modal ? 'initial' : 'none',
              position: { xs: 'initial', sm: 'fixed' },
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            {modalContent}
          </Box>
        </>
      ) : (
        <></>
      )}
      {modalType === ModalTypes.Confirm ? (
        <>
          <Box
            onClick={hideModal}
            sx={{
              visibility: modal ? 'initial' : 'hidden',
              opacity: modal || modalType !== ModalTypes.Loading ? 1 : 0,
              transition: '.4s',
              position: 'fixed',
              p: 3,
              background: !modal ? 'initial' : 'rgba(0,0,0,.4)',
              top: 0,
              width: '100vw',
              height: '100vh',
              left: 0,
              zIndex: -1,
            }}
          ></Box>
          <Box
            sx={{
              display: modal ? 'initial' : 'none',
              position: { xs: 'initial', sm: 'fixed' },
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <Paper sx={{ p: 2 }}>
              <p>{modalContent}</p>
              <Box>{confirmDialogButtons}</Box>
            </Paper>
          </Box>
        </>
      ) : (
        <></>
      )}
    </Box>,
    document.querySelector('#modal-root'),
  )
}

export default CustomModal
