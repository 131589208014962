import { DinnerDining, MedicalServices, Stop } from '@mui/icons-material'

/**
 * @type { import('@mui/material/OverridableComponent').OverridableComponent<import('@mui/material').SvgIconTypeMap<{}, "svg">>}
 */
export const tiposSalidaMap = {
  '00SA': Stop,
  '03': MedicalServices,
  '05': DinnerDining,
  DEFAULT: Stop,
}
