import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { default as axios, default as axiosPE } from 'api/axios'
import storage from 'redux-persist/lib/storage'

const sessionState = JSON.parse(sessionStorage.getItem('state'))
const stateVacio = { user: null, token: null }
const initialState = sessionState || stateVacio

export const userLogin = createAsyncThunk('/login', async (loginData) => {
  const response = await axios.post('/login', loginData, {
    headers: { 'Content-Type': 'application/json' },
  })
  return response.data
})
export const authToken = createAsyncThunk('/authtoken/', async (loginData) => {
  console.log({ loginData })
  const response = await axios.post('/authtoken/', loginData, {
    headers: { 'Content-Type': 'application/json' },
  })
  return response.data
})

export const cambiarUsuario = createAsyncThunk('/cambiauser', async (user) => {
  let response = await axiosPE.get(`/usuario/cambiar/${user.id}/`)
  sessionStorage.setItem('nuevoToken', response.data.token)
  return {
    token: response.data.token,
    refresh: response.data.refresh,
    user,
  }
})

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const { user, accessToken } = action.payload
      state.user = user
      state.token = accessToken
      sessionStorage.setItem('state', JSON.stringify(state))
    },
    setToken: (state, action) => {
      const { token } = action.payload
      state.token = token
      sessionStorage.setItem('state', JSON.stringify(state))
    },
    logOut: (state, action) => {
      storage.removeItem('persist:root')
      sessionStorage.removeItem('state')
      window.location.href = '/'
    },
  },
  extraReducers(builder) {
    builder
      .addCase(userLogin.fulfilled, (state, action) => {
        state.user = action.payload.user
        state.token = action.payload.token
        state.refresh = action.payload.refresh
        sessionStorage.setItem('state', JSON.stringify(state))
      })
      .addCase(authToken.fulfilled, (state, action) => {
        state.user = action.payload.user
        state.token = action.payload.token
        state.refresh = action.payload.refresh
        sessionStorage.setItem('state', JSON.stringify(state))
      })
      .addCase(cambiarUsuario.fulfilled, (state, action) => {
        storage.removeItem('persist:root')
        state.user = action.payload.user
        state.token = action.payload.token
        state.refresh = action.payload.refresh
        window.location.pathname = '/'
      })
  },
})

export const { setCredentials, setToken, logOut } = userSlice.actions
export const selectUsuario = (state) => state?.user?.user
export const selectToken = (state) => state.token

export default userSlice.reducer

export const selectCurrentUser = (state) => state.user.user
export const selectCurrentToken = (state) => state.user.token
