import { Skeleton, Grid, Box } from '@mui/material'
import ThemeManager from 'theming/ThemeManager'

const Theme = new ThemeManager()

const NewsDetailsSkeleton = () => {
  return (
    <Grid
      container
      spacing={3}
      sx={{
        flexDirection: {
          md: Theme?.options?.newsOptions?.reverseDetails ? 'row' : 'row-reverse',
          xs: 'row',
        },
      }}
    >
      <Grid item xs={12} lg={4}>
        <Skeleton
          variant="rounded"
          sx={{
            aspectRatio: '16 / 9',
            display: 'flex',
            width: '100%',
            height: 'auto',
            mb: 1,
          }}
        />
        <Box
          sx={{
            mt: 0,
            display: 'flex',
            flexGap: '3px',
            flexWrap: 'wrap',
            textAlign: 'right',
            justifyContent: 'flex-end',
          }}
        >
          {[...Array(3).keys()].map((i) => (
            <Skeleton
              key={i}
              sx={{ mt: 0, mb: 1, mr: '3px' }}
              variant="rounded"
              width="10ch"
              height="2em"
            />
          ))}
        </Box>
      </Grid>
      <Grid item xs={12} lg={8}>
        <Skeleton sx={{ mt: 0 }} variant="rounded" width="30%" height="1em" />
        <Skeleton sx={{ mt: 1 }} variant="rounded" width="20%" height="2.2em" />
        <Skeleton sx={{ mt: 0, fontSize: '2.5em' }} variant="text" width="40%" />
        <Skeleton sx={{ mt: 0, fontSize: '2.5em' }} variant="text" width="25ch" />
        {[...Array(8).keys()].map((i) => (
          <Skeleton key={i} sx={{ mt: 0 }} variant="text" width="100%" />
        ))}
        <br></br>
        {[...Array(6).keys()].map((i) => (
          <Skeleton key={i} sx={{ mt: 0 }} variant="text" width="100%" />
        ))}
      </Grid>
    </Grid>
  )
}

export default NewsDetailsSkeleton
