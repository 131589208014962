import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { CardActionArea } from '@mui/material'
import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Unstable_Grid2'
import { SnackbarProvider, closeSnackbar, enqueueSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { resetSolicitud } from '../solicitudesSlice'

import {
  fetchListaSolicitudes,
  selectListaSolicitudesDisponibles,
  selectListaSolicitudesStatus,
  setSolicitudSeleccionada,
} from '../solicitudesSlice'

const ListaSolicitudes = ({ tipo = null, subtipo = null }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [openBackdrop, setOpenBackdrop] = useState(true)

  const solicitudes = useSelector(selectListaSolicitudesDisponibles)
  const loading = useSelector(selectListaSolicitudesStatus)

  useEffect(() => {
    dispatch(fetchListaSolicitudes())
    dispatch(setSolicitudSeleccionada({}))
  }, [dispatch])

  useEffect(() => {
    if (tipo && subtipo && solicitudes) {
      try {
        const solicitud = solicitudes.filter((s) => s.tipo === tipo && s.subtipo === subtipo)[0]
        if (solicitud) {
          dispatch(resetSolicitud())
          navigate('/solicitud', { state: { solicitud } })
        } else {
          setOpenBackdrop(false)
          enqueueSnackbar(`No se ha podido abrir la solicitud`)
        }
      } catch (error) {
        console.error(error)
        setOpenBackdrop(false)
        enqueueSnackbar(`No se ha podido abrir la solicitud`)
      }
    }
  }, [tipo, subtipo, solicitudes, dispatch, navigate])

  const handleClick = (e) => {
    const solicitud = solicitudes.filter(
      (s, i) => Number(i) === Number(e.currentTarget.dataset.solicitud),
    )[0]
    dispatch(resetSolicitud())
    navigate('/solicitud', { state: { solicitud } })
  }

  return (
    <Box key="solicitudes" sx={{ flexGrow: 1 }}>
      <SnackbarProvider
        maxSnack={1}
        autoHideDuration={6000}
        action={(snackbarId) => (
          <IconButton onClick={() => closeSnackbar(snackbarId)} aria-label="cerrar alerta">
            <CloseRoundedIcon />
          </IconButton>
        )}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        variant={'error'}
        preventDuplicate={true}
      />
      {!loading ? (
        <Stack spacing={2}>
          {solicitudes.map((solicitud, id) => {
            return (
              <Card key={id}>
                <CardActionArea data-solicitud={id} onClick={handleClick}>
                  <CardContent>
                    <Typography variant="h5" component="div">
                      {solicitud.descripcion}
                    </Typography>
                    {solicitud.ayuda ? (
                      <Typography variant="body2" color="text.secondary">
                        <div dangerouslySetInnerHTML={{ __html: solicitud.ayuda }}></div>
                      </Typography>
                    ) : null}
                  </CardContent>
                </CardActionArea>
                {/* <CardActions>
                  <Button size="small" color="primary">
                    Share
                  </Button>
                </CardActions> */}
              </Card>
            )
          })}
        </Stack>
      ) : tipo && subtipo ? (
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1,
            flexDirection: 'column',
            gap: '20px',
          }}
          open={openBackdrop}
        >
          <CircularProgress color="inherit" />
          <Typography>Cargando solicitud</Typography>
        </Backdrop>
      ) : (
        <Grid container spacing={2}>
          {[...Array(12).keys()].map((i) => {
            return (
              <Grid key={i} xs={12} sm={12} md={12}>
                <Card>
                  <CardContent>
                    <Skeleton key="s-titulo" animation="wave" height={30} width="100%" />
                    <Skeleton key="s-descri" animation="wave" height={10} width="100%" />
                  </CardContent>
                </Card>
              </Grid>
            )
          })}
        </Grid>
      )}
    </Box>
  )
}

export default ListaSolicitudes
