import axios from 'api/axios'
const { useState, useEffect, useCallback } = require('react')

/**
 * @module FetchHooks
 * @module DocumentacionLaboral
 */

/**
 * Fetch the calendar data for the user
 * @param {string} [document_type]
 * @param {number} [iteration]
 * @returns {import('common/types/connections_types').UseFetchHookResponse<docLaboralMetaDTO>}
 */
const useFetchLaboralMeta = (document_type = null, iteration = 0) => {
  const [response, setResponse] = useState([])
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const fetchData = useCallback(() => {
    if (loading) {
      return
    }

    setLoading(true)
    axios
      .get(`/doclaboral/meta?tipo=${document_type ?? ''}`)
      .then((request_response) => {
        const data = request_response.data
        setResponse(data)
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document_type, iteration])

  useEffect(() => {
    fetchData()
  }, [document_type, fetchData, iteration])

  return { response, error, loading }
}

export default useFetchLaboralMeta
