import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import { selectPortal } from 'features/portal/portalSlice'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const BuscadorComunicados = ({ cargando, resultados }) => {
  // @ts-ignore
  const portal = useSelector(selectPortal)

  const navigate = useNavigate()
  const viewDetails = (e) => {
    navigate(`/comunicados`, { state: { requested: parseInt(e.currentTarget.dataset.comunicado) } })
  }

  return (
    <Box sx={{ bgcolor: 'background.paper', padding: 1 }}>
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {cargando ? (
          [...Array(5).keys()].map((index) => {
            return (
              <>
                <ListItem id={index} alignItems="flex-start">
                  <ListItemAvatar>
                    <Skeleton variant="rounded" width={40} height={40} />
                  </ListItemAvatar>

                  <ListItemText primary={<Skeleton />} secondary={<Skeleton />} />
                </ListItem>
                {index < 4 ? <Divider variant="middle" component="li" /> : <></>}
              </>
            )
          })
        ) : resultados ? (
          resultados.length === 0 ? (
            <Typography variant="body1">No se han encontrado resultados</Typography>
          ) : (
            resultados.map((data, index) => {
              return (
                <>
                  <ListItem
                    id={index}
                    alignItems="flex-start"
                    data-comunicado={data.id}
                    onClick={viewDetails}
                    sx={{ cursor: 'pointer' }}
                  >
                    {/*
                    <ListItemAvatar>
                      <Avatar
                        alt="Comunicado"
                        variant="rounded"
                        src={`/assets/images/noticias/${data.portada_nombre}`}
                      />
                    </ListItemAvatar>
                    */}
                    <ListItemText
                      primary={data.titulo}
                      secondary={
                        <>
                          <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            {data.contenido.slice(0, 200) + ' ...'}
                          </Typography>
                          <Typography sx={{ fontSize: '.8em' }}>
                            {new Date(data.fecha_publicacion).toLocaleString(
                              portal?.locale?.replaceAll('-', '_') ?? 'es-ES',
                              {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                              },
                            )}
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                  {index < data.length - 1 ? <Divider variant="middle" component="li" /> : <></>}
                </>
              )
            })
          )
        ) : (
          <></>
        )}
      </List>
    </Box>
  )
}

export default BuscadorComunicados
