import { TextField } from '@mui/material'
import React, { useContext } from 'react'
import { FilterTypes } from '../constants/Filter'
import { InformesContext } from '../providers/InformesProvider'

/**
 * Enum for tri-state values.
 * @readonly
 * @enum {string}
 */
export const FiltersValuePosition = {
  HASTA: '_HASTA',
  DESDE: '_DESDE',
  FIJO: '',
}

/**
 *
 * @param {Object} props
 * @param {InformeFilter} props.filter
 * @param {boolean} props.validating
 * @param {(newValue: any, pos: string) => void} props.changeFilter
 * @param {FiltersValuePosition} props.position
 */
export default function FiltersCustomTextField({ filter, validating, changeFilter, position }) {
  const Context = useContext(InformesContext)

  return (
    <TextField
      sx={{ mb: 1 }}
      InputLabelProps={filter.data_type === 'DATE' ? { shrink: true } : {}}
      InputProps={{ maxLength: 12 }}
      error={
        filter.obligatorio === 'S' &&
        validating &&
        !Context.active.filterData[`${filter.nombre_filtro}${position}`]
      }
      value={Context.active.filterData[`${filter.nombre_filtro}${position}`]}
      variant="standard"
      onChange={(e) => {
        if (parseInt(filter.longitud_maxima) >= e.target.value.length) {
          changeFilter(e.target.value, `${filter.nombre_filtro}${position}`)
        } else {
          return
        }
      }}
      type={FilterTypes[filter.data_type]}
      // type="date"
      label={`${filter.titulo_columna} ${position.length > 0 ? position.slice(1) : position}`}
      required={filter.obligatorio === 'S'}
      fullWidth
    />
  )
}
