import { Box, Skeleton } from '@mui/material'

const TeamCalendarDatesSkeleton = () => {
  return (
    <Box sx={{ display: 'flex', pb: 1 }}>
      <Box key={1} sx={{ padding: '5px', flex: '1 0 25%', display: 'flex' }}>
        <Skeleton key={'sk_1'} variant="circular" width={30} height={30} sx={{ mr: 1 }} />

        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Skeleton key={1} variant="rectangular" height={24} width={200} sx={{ mb: 1 }} />
          <Skeleton key={2} variant="rectangular" height={16} sx={{ mb: 1 }} />
          <Skeleton key={3} variant="rectangular" height={16} sx={{ mb: 1 }} />
          <Skeleton key={4} variant="rectangular" height={8} />
        </Box>
      </Box>
      <Box
        key={2}
        sx={{
          display: 'flex',
          flex: '1 0 75%',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          overflowX: 'auto',
        }}
      >
        {Array(30)
          .fill()
          .map((_, i) => (
            <Skeleton key={i} variant="rectangle" width={25} height={30} />
          ))}
      </Box>
    </Box>
  )
}

export default TeamCalendarDatesSkeleton
