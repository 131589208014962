import { Visibility } from '@mui/icons-material'
import LockIcon from '@mui/icons-material/Lock'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Typography,
} from '@mui/material'
import { getLocaleFromFullDate } from 'common/libs/fechas'
import { enqueueSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { closeHojaGasto } from '../lib/HojasGastoController'

export const HojasGastoCards = ({ hojasGasto, setIterator }) => {
  const navigate = useNavigate()

  const handleClick = async ({ id }) => {
    try {
      await closeHojaGasto({ id })
      enqueueSnackbar('Hoja cerrada correctamente', { variant: 'success' })
      setIterator(Math.random())
    } catch (e) {
      enqueueSnackbar('No ha sido posible cerrar la hoja')
      console.log(e.message)
    }
  }

  return (
    <Grid container spacing={2} sx={{ marginTop: '5px' }}>
      {hojasGasto
        ? hojasGasto.map((hojaGasto) => {
            return (
              <Grid xs={12} md={4} key={hojaGasto.id} item>
                <Card>
                  <CardContent>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between',
                        gap: '10px',
                      }}
                    >
                      {hojaGasto.status === '0500' ? (
                        <LockOpenIcon
                          sx={{
                            ':hover': { cursor: 'pointer' },
                            display: 'flex',
                            textAlign: 'center',
                            justifyContent: 'center',
                          }}
                          onClick={() => handleClick({ id: hojaGasto.id })}
                        />
                      ) : (
                        <LockIcon />
                      )}
                      <Typography>Id: {hojaGasto.id}</Typography>
                      <Typography>Periodo: {hojaGasto.d_periodo}</Typography>
                      <Typography>Estado: {hojaGasto.d_status}</Typography>
                      <Typography>Ejercicio: {hojaGasto.ejercicio}</Typography>
                      <Typography>Tipo: {hojaGasto.d_tipo}</Typography>
                      <Typography>Grupo: {hojaGasto.d_tipo_grupo}</Typography>
                    </Box>
                    <Divider orientation="horizontal" variant="fullWidth" flexItem />
                    <Grid container spacing={2}>
                      <Grid item xs>
                        <Typography variant="caption" display="block" gutterBottom>
                          Fecha inicio
                        </Typography>
                        <Typography variant="body1">
                          {getLocaleFromFullDate(hojaGasto.fecha_inicio, false)}
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography variant="caption" display="block" gutterBottom>
                          Fecha fin
                        </Typography>
                        <Typography variant="body1">
                          {getLocaleFromFullDate(hojaGasto.fecha_fin, false)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider orientation="horizontal" variant="fullWidth" flexItem />
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        textAlign: 'center',
                        justifyContent: 'center',
                      }}
                    ></Box>
                  </CardContent>
                  <CardActions>
                    <Button
                      onClick={() => navigate(`/hojasgasto/${hojaGasto.id}`)}
                      startIcon={<Visibility />}
                    >
                      Ver
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            )
          })
        : null}
    </Grid>
  )
}
