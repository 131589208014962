import { Check } from '@mui/icons-material'
import { Box, Button, LinearProgress, Modal, Typography } from '@mui/material'
import useFetchComunicadosDetails from 'features/comunicados/hooks/useFetchComunicadoDetails'
import { markComunicadoAsReaded } from 'features/comunicados/libs/markComunicadoAsReaded'
import NewsDetailsMedia from 'features/noticias/components/NewsDetailsMedia'
import { selectPortal } from 'features/portal/portalSlice'
import { enqueueSnackbar } from 'notistack'
import { useState } from 'react'
import { useSelector } from 'react-redux'

/**
 *
 * @param {Object} props
 * @param {NewsDTO} props.data
 */
const ComunicadoDetails = ({ data, hideTitle = false, ocultar_boton_lectura = false }) => {
  const { response, loading, error } = useFetchComunicadosDetails(data.id)
  const portal = useSelector(selectPortal)
  const [isMarking, setIsMarking] = useState(false)
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [showData, setShowData] = useState(data)
  return (
    <Box
      sx={{ backgroundColor: 'background.paper', color: 'text.primary', p: 1, borderRadius: '5px' }}
    >
      {loading && !response ? (
        <LinearProgress />
      ) : (
        <>
          {response ? (
            <Box>
              {!hideTitle ? (
                <>
                  <Typography>
                    {new Date(response.fecha_publicacion).toLocaleString(
                      portal?.locale?.replaceAll('-', '_') ?? 'es-ES',
                      {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      },
                    )}{' '}
                    | Comunicado
                  </Typography>
                  <Typography variant="h3" sx={{ fontSize: '1.6em', color: 'primary.main', mb: 1 }}>
                    {response.titulo}
                  </Typography>
                </>
              ) : (
                <></>
              )}

              <Box
                sx={{
                  '& p': {
                    display: 'flex',
                    flexDirection: 'column',
                    // alignItems: 'center',
                    // width: '100%',
                  },
                  '& img': {
                    margin: 'auto',
                    maxWidth: '100%',
                    height: 'auto',
                  },
                }}
                dangerouslySetInnerHTML={{ __html: response?.contenido ?? '' }}
              ></Box>
              <NewsDetailsMedia news={response} />
              {showData.lectura_obligatoria === 'S' &&
              !showData?.leido &&
              !ocultar_boton_lectura ? (
                <Button
                  sx={{ ml: 'auto', display: 'flex' }}
                  disableElevation
                  onClick={handleOpen}
                  startIcon={<Check />}
                >
                  Marcar como leido
                </Button>
              ) : (
                <></>
              )}

              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box
                  sx={{
                    position: 'absolute',
                    transition: '.3s',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    minWidth: '30%',
                    width: {
                      xs: '95%',
                      md: '30%',
                    },
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 1,
                  }}
                >
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    Marcar como leido
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    ¿Está seguro de que desea marcar como leído este comunicado?
                    <br />
                    <b>Con esta acción está reconociendo haber leído el documento completo.</b>
                  </Typography>
                  {isMarking ? <LinearProgress /> : <></>}
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '1em',
                      width: '100%',
                      justifyContent: 'flex-end',
                      pt: 1,
                    }}
                  >
                    <Button
                      disabled={isMarking}
                      onClick={async () => {
                        try {
                          setIsMarking(true)
                          await markComunicadoAsReaded(showData.id)
                          setShowData({
                            ...showData,
                            lectura_obligatoria: 'S',
                            leido: 'S',
                          })

                          handleClose()
                        } catch (error) {
                          enqueueSnackbar('No se pudo marcar como leido el comunicado', {
                            variant: 'error',
                          })
                          setIsMarking(false)
                        }
                      }}
                      variant="contained"
                    >
                      Si
                    </Button>
                    <Button disabled={isMarking} onClick={handleClose} variant="outlined">
                      No
                    </Button>
                  </Box>
                </Box>
              </Modal>
            </Box>
          ) : (
            <></>
          )}
        </>
      )}
    </Box>
  )
}

export default ComunicadoDetails
