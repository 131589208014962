import { ArrowUpward, Groups } from '@mui/icons-material'
import { Card, CardContent, CardHeader, IconButton, Paper, Typography } from '@mui/material'
import CustomTable from 'common/components/CustomTable'
import SimpleTable from 'common/components/SimpleTable'
import ScrollHelper from 'common/libs/ScrollHelper'
import ThemeManager from 'theming/ThemeManager'

/**
 *
 * @param {Object} props
 * @param {Model145DTO} props.data - The user to show the data
 */
const Model145DescendantData = ({ data }) => {
  const descendants = data?.irpf_da_descendentes ?? []
  const Theme = new ThemeManager()

  return (
    <Paper>
      <Card>
        <CardHeader
          avatar={<Groups />}
          sx={{
            backgroundColor: 'background.lighter',
          }}
          title="Hijos y descendientes"
          action={
            <IconButton onClick={() => ScrollHelper.scrollTo({ id: 'model145-intro' })}>
              <ArrowUpward />
            </IconButton>
          }
        />
        <CardContent>
          <Typography sx={{ my: 1 }} variant="subtitle1">
            {
              'Datos de los hijos o descendientes menores de 25 años (o mayores de dicha edad si son discapacitados) que conviven con Vd. y quien no tienen rentas anuales superiores a 8.000 euros.'
            }
          </Typography>
          <SimpleTable
            dataSource={descendants}
            header_style={{
              backgroundColor: Theme.pallete?.primary?.main,
              color: Theme.pallete.primary.contrastText,
            }}
            stripped={true}
            header_cell_style={{ padding: '10px' }}
            table_style={{ width: '100%', borderCollapse: 'collapse' }}
            container_style={{ borderRadius: '5px', marginBottom: '5px', marginTop: '10px' }}
            generic_cell_style={{
              textAlign: 'center',
              padding: '15px 10px',
            }}
            fields={[
              { title: 'Año nacimiento', field: 'ejer_nacimiento' },
              {
                title: 'Año de adopcion o recogimiento',
                field: 'ejer_adopcion',
              },
              {
                title: 'Grado igual o superior al 33% e inferior al 65%',
                field: 'disability_1',
                customGetter: (row) => {
                  return row.tipo_discapacidad === '01' ? 'Si' : 'No'
                },
              },
              {
                title: 'Grado igual o superior al 65%',
                field: 'disability_2',
                customGetter: (row) => {
                  return row.tipo_discapacidad === '02' ? 'Si' : 'No'
                },
              },
              {
                title:
                  'Además, tiene acreditada la necesidad de ayuda de terceras personas o movilidad reducida',
                field: 'disability_3',
                customGetter: (row) => {
                  return row.tipo_discapacidad === '03' ? 'Si' : 'No'
                },
              },
            ]}
          />
        </CardContent>
      </Card>
    </Paper>
  )
}

export default Model145DescendantData
