import { Folder, FolderSharp, Source } from '@mui/icons-material'
import ArrowBack from '@mui/icons-material/ArrowBack'
import { Badge, Button, Grid, IconButton, Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'
import useCurrentMenu from 'common/hooks/useCurrentMenu'
import React, { useState, useEffect } from 'react'
const ONLY_COUNT = false

const FolderView = ({ tree, setCurrentLevel }) => {
  const [currentTree, setCurrentTree] = useState(tree)
  const [currentPath, setCurrentPath] = useState('')
  const [path, setPath] = useState([])
  const { actualMatch, currentMenu } = useCurrentMenu()

  useState(() => {
    setPath([])
  }, [])

  useEffect(() => {
    if (!path.length > 0) return setCurrentTree(tree)
    let newLevel = tree
    path.forEach((pos) => {
      newLevel = newLevel[pos] ?? null
    })
    setCurrentTree(newLevel?.hijos ?? [])
    setCurrentPath(newLevel)
    setCurrentLevel(newLevel)
  }, [path])

  useEffect(() => {
    // if (!currentTree?.length > 0) setCurrentTree(tree)
    setPath([])
  }, [tree])

  const handleFolderClick = (element, idx) => {
    // if (element.ultimo_nivel !== 'S' && element.num_doc > 0) {
    //    return setPath([...path, idx])
    // }
    setPath([...path, idx])
    setCurrentLevel(element)
  }

  return (
    <Box sx={{ p: 2 }}>
      {path.length > 0 ? (
        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
          <ArrowBack
            color="primary"
            onClick={() => {
              let newPath = path.slice(0, -1)
              let level = null
              newPath.slice(0, -1).forEach((element, idx) => {
                level = tree[element]
              })
              console.log(newPath)
              setCurrentLevel(level)
              setPath(newPath)
            }}
            sx={{ transform: 'translateY(-2px)', mr: 2, cursor: 'pointer' }}
          />
          <Typography>{currentPath?.descripcion ?? 'Regresar'}</Typography>
        </Box>
      ) : (
        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
          <Folder color="primary" sx={{ transform: 'translateY(-2px)', mr: 2 }} />
          <Typography>
            {currentMenu?.nombre}: {actualMatch?.nombre}
          </Typography>
        </Box>
      )}
      {tree && currentTree ? (
        <Box
          container
          spacing={1}
          flexGrow={1}
          sx={{ display: 'flex', gap: '5px', overflowX: 'auto' }}
        >
          {currentTree.map((element, idx) => {
            return element.num_doc === 0 && ONLY_COUNT ? (
              <></>
            ) : (
              <Box item key={idx} md={2} sm={3} xs={6} xl={1}>
                <Button
                  disabled={element.num_doc === 0}
                  onClick={() => {
                    handleFolderClick(element, idx)
                  }}
                  sx={{ p: 2, width: '100%', height: '100%' }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyItems: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <Badge color="secondary" badgeContent={element.num_doc}>
                      <FolderSharp sx={{ fontSize: '6em' }} />
                    </Badge>
                    <Typography
                      color={element.num_doc === 0 ? 'text.disabled' : 'text.primary'}
                      variant="title"
                      sx={{ height: '100%' }}
                    >
                      {element.descripcion}
                    </Typography>
                  </Box>
                </Button>
              </Box>
            )
          })}
        </Box>
      ) : (
        <></>
      )}
    </Box>
  )
}

export default FolderView
