import React, { useEffect, useState } from 'react'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import InputLabel from '@mui/material/InputLabel'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import TextField from '@mui/material/TextField'

import 'dayjs/locale/es'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import CustomTable from 'common/components/CustomTable'
import { useSnackbar } from 'notistack'
import useFetchNews from '../hooks/useFetchLinks'
import LinksController from '../libs/LinksController'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const EditorEnlace = () => {
  const { response, loading: isLoading, error } = useFetchNews()
  const [enlaces, setEnlaces] = useState(response)

  useEffect(() => setEnlaces(response), [response])

  const ahora = new Date()

  const [editing, setEditing] = useState(0)

  const [descripcion, setDescripcion] = useState('')
  const [visible, setVisible] = useState(true)
  const [orden, setOrden] = useState(1)
  const [url, setUrl] = useState('')
  const [icono, setIcono] = useState('')
  const [empresa, setEmpresa] = React.useState([])

  const [openDialog, setOpenDialog] = React.useState(false)

  // TODO: lo mismo que noticias y preguntas, hay que darle una vuelta
  const permisos = []
  const { enqueueSnackbar } = useSnackbar()

  const handleDescripcion = (e) => setDescripcion(e.target.value)
  const handleVisible = (e) => setVisible(e.target.checked)
  const handleOrden = (e) => setOrden(e.target.value)
  const handleUrl = (e) => setUrl(e.target.value)
  const handleIcono = (e) => setIcono(e.target.value)
  const handleEmpresa = (event) => {
    const {
      target: { value },
    } = event
    setEmpresa(typeof value === 'string' ? value.split(',') : value)
  }

  const handleSelectEnlace = (id) => {
    if (id > 0) {
      const enlace = enlaces.filter((n) => n.id === id)[0]
      setDescripcion(enlace.descripcion)
      setVisible(enlace.visible)
      setOrden(enlace.orden)
      setUrl(enlace.url)
      setIcono(enlace.icono)
      setEmpresa(enlace.rh_cif.split(','))
    }
    setEditing(id)
  }

  const handleOpenDialog = () => {
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleEliminarEnlace = async () => {
    const removedLink = await LinksController.delete(editing)
    if (removedLink) {
      enqueueSnackbar('Enlace eliminado satisfactoriamente', { variant: 'success' })
      const index = enlaces.findIndex((link) => link.id === removedLink.id)
      const newState = [...enlaces]
      newState.splice(index, 1)

      setEnlaces(newState)
    } else {
      enqueueSnackbar('Ha ocurrido un error y no se pudo eliminar el enlace', {
        variant: 'error',
      })
    }
    // dispatch(deleteEnlace(editing))
    setEditing(0)
  }

  const handleSave = async (e) => {
    // @type {LinksDTO}
    const enlace = {
      id: editing,
      descripcion: descripcion,
      visible: visible ? 'S' : 'N',
      orden: orden,
      url: url,
      icono: icono,
      rh_cif: empresa.join(','),
    }
    if (editing > 0) {
      const updatedLink = await LinksController.update(enlace)

      if (updatedLink) {
        enqueueSnackbar('Enlace modificado satisfactoriamente', { variant: 'success' })
        const newState = [...enlaces]
        const index = newState.findIndex((enlace) => enlace.id === updatedLink.id)
        newState[index] = updatedLink
        setEnlaces(newState)
      } else {
        enqueueSnackbar('Ha ocurrido un error y no se pudo guardar el enlace', {
          variant: 'error',
        })
      }
    } else {
      const newLink = await LinksController.create(enlace)
      if (newLink) {
        enqueueSnackbar('Enlace creado satisfactoriamente', { variant: 'success' })
        setEnlaces([...enlaces, newLink])
      } else {
        enqueueSnackbar('Ha ocurrido un error y no se pudo guardar el enlace', {
          variant: 'error',
        })
      }

      // dispatch(createEnlace(enlace))
    }
    setEditing(0)
  }

  if (editing === 0) {
    return (
      <>
        <Button onClick={() => handleSelectEnlace(-1)}>Crear nuevo</Button>
        <CustomTable
          dataSource={enlaces}
          showToolbar={true}
          initialPageSize={15}
          density="compact"
          fields={[
            { key: 'ID', value: 'id', flex: 0.5 },
            { key: 'Usuario', value: 'usuario_web', flex: 1 },
            { key: 'Descripcion', value: 'descripcion', flex: 1 },
            { key: 'Url', value: 'url', flex: 1 },
            { key: 'Visible', value: 'visible', flex: 0.5 },
            { key: 'Orden', value: 'orden', flex: 0.5 },
          ]}
          onClickEvent={(event) => handleSelectEnlace(event.row.id)}
        />
      </>
    )
  }

  return (
    <Box component="form" noValidate autoComplete="off">
      <Card>
        <CardContent>
          <Stack spacing={2}>
            <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
              <FormGroup sx={{ flexGrow: 1 }}>
                <TextField
                  id="descripcion"
                  label="Descripcion"
                  variant="outlined"
                  value={descripcion}
                  onChange={handleDescripcion}
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  control={<Switch />}
                  label="Visible"
                  checked={visible === 'S' || visible === true}
                  onChange={handleVisible}
                />
              </FormGroup>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
              <FormGroup sx={{ flexGrow: 1 }}>
                <TextField
                  id="url"
                  label="Url"
                  variant="outlined"
                  value={url}
                  onChange={handleUrl}
                />
              </FormGroup>
              {/**
              <FormGroup>
                <TextField
                  id="icono"
                  label="Icono"
                  variant="outlined"
                  value={icono}
                  onChange={handleIcono}
                />
              </FormGroup>
            */}
            </Stack>
            <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
              <FormControl fullWidth>
                <InputLabel id="empresas-label">Empresa</InputLabel>
                <Select
                  label="Empresa"
                  labelId="empresas-label"
                  id="empresas-label"
                  multiple
                  value={empresa}
                  onChange={handleEmpresa}
                  input={<OutlinedInput label="Empresa" />}
                  renderValue={(selected) => selected?.join(',')}
                  MenuProps={MenuProps}
                >
                  {permisos.map((p) =>
                    p.rh_cif ? (
                      <MenuItem key={p.id} value={p.rh_cif}>
                        <Checkbox checked={empresa.indexOf(p.rh_cif) > -1} />
                        <ListItemText primary={p.d_rh_cif} />
                      </MenuItem>
                    ) : null,
                  )}
                </Select>
              </FormControl>
              <FormGroup sx={{ flexGrow: 1 }}>
                <TextField
                  id="orden"
                  label="Orden"
                  variant="outlined"
                  value={orden}
                  onChange={handleOrden}
                />
              </FormGroup>
            </Stack>
            <Stack direction="row" spacing={2}>
              <Button variant="contained" disableElevation color="primary" onClick={handleSave}>
                Guardar
              </Button>
              <Button
                variant="contained"
                color="secondary"
                disableElevation
                onClick={() => setEditing(0)}
              >
                Cancelar
              </Button>
              <Button variant="contained" color="error" disableElevation onClick={handleOpenDialog}>
                Eliminar
              </Button>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Eliminar Notiica'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Está seguro de querer eliminar este enlace?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>No</Button>
          <Button onClick={handleEliminarEnlace} autoFocus>
            Sí
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default EditorEnlace
