import {
  consultaInicial,
  executeFieldAction,
  selectCamposSolicitud,
  selectDatosSolicitud,
  setDatoSolicitud,
} from 'features/solicitudes/solicitudesSlice'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import axios from 'api/axios'

import { Alert, Box, Skeleton } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import FormLabel from '@mui/material/FormLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'

function RadioComponent({ idpk }) {
  const dispatch = useDispatch()
  const campos = useSelector(selectCamposSolicitud)
  const campo = campos.filter((c) => c.idpk === idpk)[0]

  const datos = useSelector(selectDatosSolicitud)
  const valor = datos[campo.idpk]

  const [value, setValue] = useState('')
  const [options, setOptions] = useState([])
  const [lvLoading, setLvLoading] = useState(true)
  const [error, setError] = useState('')

  const getValoresConsulta = () => {
    const valores = []
    for (const idpk in datos) {
      if (Object.hasOwnProperty.call(datos, idpk)) {
        const valor = datos[idpk]
        const field = campos.filter((c) => Number(c.idpk) === Number(idpk))[0]
        valores.push({ codigo_campo: field.codigo_campo, valor, tipo: field.tipo_dato })
      }
    }
    return valores
  }

  useEffect(() => {
    if (campo && campo.inicializar) {
      dispatch(consultaInicial({ idpk: campo.idpk, valores: [] }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (options.length === 0) {
      setLvLoading(true)
      const valores = getValoresConsulta()
      axios
        .post(`/consultalov/${idpk}/`, { valores, busqueda: value })
        .then((request_response) => {
          const data = request_response.data
          setOptions(data.registros)
        })
        .catch((error) => setError(error))
        .finally(() => setLvLoading(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options])

  useEffect(() => {
    setValue(valor)
    // Lanzamos las queries iniciales que contienen a este campo
    campos.forEach((c) => {
      if (c.depende_de && c.depende_de.includes(campo.codigo_campo) && valor) {
        const valores = getValoresConsulta()
        dispatch(consultaInicial({ idpk: c.idpk, valores }))
      }
    })
    campo.acciones.forEach((action) => {
      if (action.valor_referencia === valor) {
        dispatch(executeFieldAction({ action }))
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valor])

  const handleChange = (e) => {
    const descripcion = options.filter((o) => o.codigo === e.target.value)[0].descripcion
    dispatch(setDatoSolicitud({ idpk: campo.idpk, valor: e.target.value, descripcion }))
  }

  if (campo) {
    if (lvLoading) {
      return (
        <FormControl>
          <FormLabel id={`r-${campo.idpk}`}>{campo.etiqueta}</FormLabel>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Skeleton variant="circular" width={25} height={25} />
              <Skeleton variant="rounded" width={180} height={20} />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Skeleton variant="circular" width={25} height={25} />
              <Skeleton variant="rounded" width={180} height={20} />
            </Box>
          </Box>
        </FormControl>
      )
    } else {
      return error ? (
        <Alert>Error cargando los datos</Alert>
      ) : (
        <FormControl required={campo.obligatorio === 'S'}>
          <FormLabel id={`r-${campo.idpk}`}>{campo.etiqueta}</FormLabel>
          <RadioGroup
            row
            aria-labelledby={campo.etiqueta}
            name={campo.codigo_campo}
            value={value}
            onChange={handleChange}
            color={campo.error ? 'error' : ''}
          >
            {options.map((o) => (
              <FormControlLabel
                key={o.codigo}
                value={o.codigo}
                control={<Radio />}
                label={o.descripcion}
              />
            ))}
          </RadioGroup>
          <FormHelperText>{campo.ayuda}</FormHelperText>
        </FormControl>
      )
    }
  }
}

export default RadioComponent
