import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import axiosPE from '../../api/axios'

const initialState = {
  portal: {},
  status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
}

export const fetchPortal = createAsyncThunk('/portal', async () => {
  const response = await axiosPE.get('/portal')
  return response.data
})

export const portalSlice = createSlice({
  name: 'portal',
  initialState,
  reducers: {
    setPortal: (state, action) => {
      console.log(action.payload)
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPortal.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchPortal.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.portal = action.payload.portal
      })
      .addCase(fetchPortal.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
  },
})

export const { setPortal } = portalSlice.actions
export const selectPortal = (state) => state.portal

export default portalSlice.reducer
