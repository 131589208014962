import axios from 'api/axios'

/**
 * @readonly
 * @enum {string}
 *
 */
export const DocumentMarkType = {
  Corporate: 'doccorporativa',
  Personal: 'docpersonal',
  3: 'doccorporativa',
  4: 'docpersonal',
}

/**
 * Mark a document as readed
 *
 * @async
 * @param {number} document_id - The document id
 * @param {int} [document_type] - Tipo de documento
 * @throws {Error} Can throw an error if the axios request fails
 */
export async function markDocumentAsReaded(document_id, document_type = 0, uri = null) {
  try {
    const url = uri ? uri.replace('/api/v1/', '') : 'documentacion/lectura/ '
    const response = await axios.post(url, {
      id: document_id,
      t: document_type,
    })
    if (response.status !== 200) throw new Error('No se pudo marcar el archivo como leido')
    return response.data
  } catch (error) {
    throw error
  }
}
