import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import ThemeController from 'theming/ThemeManager' // importar el controlador general
import DefaultLoginBackGround from '../components/LoginBackground'
import DefaultLoginForm from '../components/LoginForm'
import DefaultRecoveryPasswordForm from '../components/RecoveryPasswordForm'

const Login = () => {
  const themeController = new ThemeController() // instanciar el controlador
  const LoginBackground = themeController?.components
    ? themeController?.components['LoginBackground'] ?? DefaultLoginBackGround
    : DefaultLoginBackGround

  const LoginForm = themeController?.components
    ? themeController?.components['LoginForm'] ?? DefaultLoginForm
    : DefaultLoginForm
  const RecoveryPasswordForm = themeController?.components
    ? themeController?.components['RecoveryPasswordForm'] ?? DefaultRecoveryPasswordForm
    : DefaultRecoveryPasswordForm

  const location = useLocation()
  const [loading, setLoading] = useState(false)
  return (
    <>
      <LoginBackground loading={loading}>
        {location?.state?.recovery ? (
          <RecoveryPasswordForm />
        ) : (
          <LoginForm loadingParentCallback={setLoading} />
        )}
      </LoginBackground>
    </>
  )
}

export default Login
