import axios from 'api/axios'
import { useCallback, useEffect, useState } from 'react'

/**
 * Returns a boolean that will say if Gottenberg is active
 *
 * @returns boolean
 */
const useIsGottenbergActive = () => {
  const [isActive, setIsActive] = useState(false)
  const fetchIsActive = useCallback(async () => {
    try {
      const { status } = await axios.get('conversor')
      setIsActive(status === 200)
    } catch (error) {
      setIsActive(false)
    }
  }, [])

  useEffect(() => {
    fetchIsActive()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isActive
}

export default useIsGottenbergActive
