import { Typography } from '@mui/material'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { authToken } from 'features/users/stores/userSlice'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

const TokenAuth = () => {
  const dispatch = useDispatch()

  const [searchParams, setSearchParams] = useSearchParams()

  console.log(searchParams?.get('jwt'))

  const tokenLogin = async (token, to) => {
    await dispatch(authToken({ token })).unwrap()

    window.location.href += `${to}`
  }

  useEffect(() => {
    const token = searchParams?.get('jwt')
    const to = searchParams?.get('to')
    tokenLogin(token, to)
  }, [searchParams])

  return (
    <Backdrop
      sx={{
        flexDirection: 'column',
        gap: '20px',
      }}
      open={true}
    >
      <CircularProgress color="inherit" />
      <Typography>Autentificando usuario</Typography>
    </Backdrop>
  )
}

export default TokenAuth
