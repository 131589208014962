import { Drafts, Mail } from '@mui/icons-material'
import { alpha, Box, Typography } from '@mui/material'
import { getLocaleFromFullDate } from 'common/libs/fechas'
import { selectPortal } from 'features/portal/portalSlice'
import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ThemeManager from 'theming/ThemeManager'
import NotificationsController from '../lib/NotificationsController'
import { NotificationsContext } from '../providers/NotificationsProvider'

/**
 *
 * @module Notifications
 */

/**
 * @component
 *
 * @param {Object} props
 * @param {NotificationsDTO} props.notification
 * @param {boolean} props.reverse
 */
export const NotificationCard = ({ notification, closePopup = () => {}, reverse = false }) => {
  const notificationsContext = useContext(NotificationsContext)
  const Theme = new ThemeManager()
  console.log(notification.url)
  const portal = useSelector(selectPortal)
  const navigate = useNavigate()
  return (
    <Box
      onClick={() => {
        const url = notification.url.startsWith('/') ? notification.url : `/${notification.url}`
        navigate(url)
        NotificationsController.markAsRead(notification.id).then(() => {
          if (notificationsContext?.forceRefresh) notificationsContext.forceRefresh()
        })
        closePopup()
      }}
      sx={{
        backgroundColor: notification.fecha_lectura
          ? 'transparent'
          : alpha(Theme.pallete.primary.main, 0.05),
        cursor: 'pointer',
        transition: '.3s',
        // mb: 1,
        p: 1,
        borderRadius: 1,
        flexDirection: !reverse ? 'row-reverse' : 'row',
        display: 'flex',
        alignItems: 'center',
        gap: '15px',
        '&:hover': {
          backgroundColor: alpha(Theme.pallete.primary.main, 0.4),
        },
      }}
    >
      {notification.fecha_lectura ? (
        <Drafts
          sx={{
            color: 'text.disabled',
          }}
        />
      ) : (
        <Mail
          sx={{
            color: 'primary.main',
          }}
        />
      )}
      <Box sx={{ width: '100%' }}>
        <Typography
          variant="subtitle1"
          sx={{ fontSize: '.6rem', textAlign: 'left', width: '100%' }}
        >
          {getLocaleFromFullDate(
            notification.fecha_creacion,
            true,
            portal?.locale?.replace('_', '-') ?? 'es-Es',
          )}
        </Typography>
        <Typography sx={{ width: '100%' }} variant="title" textAlign="left">
          {notification.texto}
        </Typography>
      </Box>
    </Box>
  )
}

export default NotificationCard
