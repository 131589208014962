import {
  FilePdf,
  File,
  FileXls,
  FileText,
  FileDoc,
  MicrosoftWordLogo,
  MicrosoftExcelLogo,
  FilePpt,
  MicrosoftPowerpointLogo,
} from '@phosphor-icons/react'

/**
 * Enum for the MIME Types icons.
 * @readonly
 * @enum {import("@phosphor-icons/react").Icon}
 */
export const MimeTypes = {
  default: File,
  pdf: FilePdf,
  ppt: FilePpt,
  ppts: MicrosoftPowerpointLogo,
  pptx: MicrosoftPowerpointLogo,
  xls: FileXls,
  xslx: MicrosoftExcelLogo,
  doc: FileDoc,
  docx: MicrosoftWordLogo,
  txt: FileText,
}
