/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from 'react'
import axios from 'api/axios'

/**
 * @module FetchHooks
 */
/**
 * @module Informes
 */

/**
 *
 * @param {Object} props
 * @param {string} props.nombre_informe
 * @param {number} [props.page=1]
 * @param {number} props.limit
 * @returns {import('common/types/connections_types').UseFetchHookResponse<InformeStructureDTO>} [TODO:description]
 */
const useFetchInformeData = ({
  nombre_informe,
  filtro = null,
  page = 1,
  limit = 12,
  iteration,
}) => {
  const [response, setResponse] = useState()
  const [error, setError] = useState()
  const [loading, setLoading] = useState()

  useEffect(() => {
    if (filtro === null) setResponse(null)
  }, [filtro])

  const fetchData = useCallback(() => {
    const start = (page - 1) * limit + 1
    const end = start + (limit - 1)
    if (loading || !nombre_informe || filtro === null) return
    setLoading(true)
    axios
      .post(`informes/datos/`, {
        informe: nombre_informe,
        filtros: filtro,
        inicio: start,
        fin: end,
      })
      .then((request_response) => {
        setResponse(request_response.data)
        setError(null)
      })
      .catch((request_error) => setError(request_error))
      .finally(() => setLoading(false))
  }, [nombre_informe, filtro, page, iteration])

  useEffect(() => {
    if (!loading && nombre_informe && filtro !== null) fetchData()
  }, [fetchData, nombre_informe, filtro, page, iteration])

  return { response, error, loading }
}

export default useFetchInformeData
