import { CheckRounded, Download } from '@mui/icons-material'
import { Box, Button, LinearProgress, Modal, Typography } from '@mui/material'
import { CustomModalContext } from 'common/contexts/CustomModalContext'
import { ModalTypes } from 'common/hooks/useCustomModal'
import FilesHelper from 'common/libs/Files'
import { enqueueSnackbar } from 'notistack'
import { useContext, useState } from 'react'
import { markDocumentAsReaded } from '../libs/markDocumentAsReaded'
import ConfirmReadModal from './ConfirmReadModal'
import GenericDocumentCard from './GenericDocumentCard'

/**
 * Component that renders and download a corporate document
 *
 * @param {Object} props
 * @param {DocCorporativaDto} props.document - The document to show
 */
const CorporateDocsCard = ({ document }) => {
  const [isMarking, setIsMarking] = useState(false)
  const [displayDocument, setDisplayDocument] = useState(document)
  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false)
  const [NeedReading, SetNeedsReading] = useState(displayDocument.obligatorio && !document.leido)
  const { handleModal } = useContext(CustomModalContext)
  function markedCallback(_, date) {
    SetNeedsReading(false)
    setDisplayDocument({ ...displayDocument, leido: date, obligatorio: true })
  }

  return (
    <>
      <GenericDocumentCard
        document_id={displayDocument.id}
        document_type={displayDocument.tipo_doc}
        MIMEType={displayDocument.mime_type}
        forced={displayDocument.obligatorio}
        title={displayDocument.descripcion}
        downloaded={displayDocument.descargado}
        readDate={displayDocument.leido}
        actionsSecondary={
          NeedReading ? (
            <Button
              onClick={() => {
                handleModal({
                  enabled: true,
                  content: (
                    <ConfirmReadModal document={displayDocument} SetNeedsReading={markedCallback} />
                  ),
                  type: ModalTypes.Custom,
                })
              }}
              endIcon={<CheckRounded />}
            >
              Leido
            </Button>
          ) : (
            <></>
          )
        }
        actionsPrimary={
          <>
            {displayDocument.id && displayDocument.tipo_doc && document.tipo_doc > 0 ? (
              <Button
                onClick={async () => {
                  handleModal({ type: ModalTypes.Loading, enabled: true })
                  await FilesHelper.downloadFile(displayDocument.id, document.tipo_doc).finally(
                    () => {
                      const date = new Date().toISOString()
                      setDisplayDocument({ ...displayDocument, descargado: date })
                      handleModal({ type: ModalTypes.Loading, enabled: false })
                    },
                  )
                }}
                startIcon={<Download />}
              >
                {displayDocument.mime_type.toUpperCase()}
              </Button>
            ) : (
              <></>
            )}
          </>
        }
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            transition: '.3s',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            minWidth: '30%',
            width: {
              xs: '95%',
              md: '30%',
            },
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Marcar como leido
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            ¿Está seguro de que desea marcar como leído este documento?
            <br />
            <b>Con esta acción está reconociendo haber leído el documento completo.</b>
          </Typography>
          {isMarking ? <LinearProgress /> : <></>}
          <Box
            sx={{
              display: 'flex',
              gap: '1em',
              width: '100%',
              justifyContent: 'flex-end',
              pt: 1,
            }}
          >
            <Button
              disabled={isMarking}
              onClick={async () => {
                try {
                  setIsMarking(true)
                  await markDocumentAsReaded(displayDocument.id, displayDocument.tipo_doc)
                  SetNeedsReading(false)
                  setDisplayDocument({
                    ...displayDocument,
                    obligatorio: false,
                  })
                  displayDocument.obligatorio = false
                  setIsMarking(false)
                  handleClose()
                } catch (error) {
                  enqueueSnackbar('No se pudo marcar como leido el documento', {
                    variant: 'error',
                  })
                  setIsMarking(false)
                }
              }}
              variant="contained"
            >
              Si
            </Button>
            <Button disabled={isMarking} onClick={handleClose} variant="outlined">
              No
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default CorporateDocsCard
