import axios from 'api/axios'
export const deleteHojaGastoLinDoc = async ({ id_doc, delete_url }) => {
  try {
    const body = { id_doc }

    const url = delete_url.includes('/api/v1') ? delete_url.split('/api/v1')[1] : delete_url
    const response = await axios.delete(url, { data: body })
    return response
  } catch (error) {
    throw error
  }
}
