import axios from 'api/axios'
export class DocumentsController {
  /**
   * Mark a document as readed
   *
   * @async
   * @param {number} document_id - The document id
   * @param {int} [document_type] - Tipo de documento
   * @throws {Error} Can throw an error if the axios request fails
   */
  static async markDocumentAsReaded(document_id, document_type = 0) {
    try {
      const response = await axios.post(`documentacion/lectura/ `, {
        id: document_id,
        t: document_type,
      })
      if (response.status !== 200) throw new Error('No se pudo marcar el archivo como leido')
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async getLastUnreaded() {
    const response = await axios.get('bloqueantes/')
    return response.data
  }
}

export default DocumentsController
