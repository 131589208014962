import { Box } from '@mui/material'
import InformeData from '../components/InformeData'
import MenuInforme from '../components/MenuInforme'
import { InformesProvider } from '../providers/InformesProvider'

const Informes = ({ object: forced_id }) => {
  return (
    <Box>
      <InformesProvider>
        {!forced_id ? <MenuInforme /> : <></>}
        <InformeData forced={forced_id} />
      </InformesProvider>
    </Box>
  )
}

export default Informes
