import { Folder } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import useCurrentMenu from 'common/hooks/useCurrentMenu'
import Corporativa from '../components/Corporativa'

const DocCorporativa = ({ object }) => {
  const { actualMatch, currentMenu } = useCurrentMenu()
  return (
    <>
      <Box sx={{ ml: 0, mb: 2, display: 'flex', alignItems: 'center' }}>
        <Folder color="primary" sx={{ transform: 'translateY(-2px)', mr: 2 }} />
        <Typography>
          {currentMenu?.nombre}: {actualMatch?.nombre}
        </Typography>
      </Box>
      <Corporativa document_type={object ?? null} />
    </>
  )
}

export default DocCorporativa
