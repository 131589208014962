// @ts-check
import { Box, Chip, Grid, Typography } from '@mui/material'
import useFetchNewsDetails from 'features/noticias/hooks/useFetchNewsDetails'
import { selectPortal } from 'features/portal/portalSlice'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import ThemeManager from 'theming/ThemeManager'
import NewsDetailsExtra from '../components/NewsDetailsExtra'
import NewsDetailsMedia from '../components/NewsDetailsMedia'
import NewsDetailsSkeleton from '../components/NewsDetailsSkeleton'

const NewsDetails = () => {
  const { id } = useParams()
  const { response: news, loading, error } = useFetchNewsDetails(id ?? undefined)
  const portal = useSelector(selectPortal)
  const navigate = useNavigate()
  const Theme = new ThemeManager()
  const ThemeNewsDetails = Theme?.components?.NewsDetails

  useEffect(() => {
    if (error) {
      navigate('/noticias')
    }
  }, [error])

  return (
    ThemeNewsDetails ?? (
      <>
        {news ? (
          <Grid
            container
            spacing={3}
            sx={{
              flexDirection: {
                md: Theme?.options?.newsOptions?.reverseDetails ? 'row' : 'row-reverse',
                xs: 'row',
              },
              mt: { xs: 8, md: 'initial' },
            }}
          >
            <Grid item xs={12} lg={4}>
              <NewsDetailsExtra news={news} />
              <Box
                sx={{
                  display: {
                    xs: 'none',
                    lg: 'block',
                  },
                }}
              >
                <NewsDetailsMedia news={news} />
              </Box>
            </Grid>
            <Grid item xs={12} lg={8}>
              <Typography>
                {new Date(news.fecha_publicacion).toLocaleString(
                  portal?.locale?.replaceAll('-', '_') ?? 'es-ES',
                  {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  },
                )}
              </Typography>

              <Box
                sx={{
                  display: 'flex',
                  flexGap: '3px',
                  // justifyContent: 'flex-end',
                  flexWrap: 'wrap',
                }}
              >
                {news.categorias.map(({ descripcion }, index) => {
                  return (
                    <Chip
                      key={index}
                      sx={{
                        mr: '4px',
                      }}
                      label={descripcion}
                      variant="outlined"
                    />
                  )
                })}
              </Box>
              <Typography
                variant="h1"
                sx={{
                  fontSize: '2em',
                  mb: 2,
                  mt: 1,
                  fontWeight: 'bold',
                }}
              >
                {news?.titulo ?? ''}
              </Typography>
              <Box
                sx={{
                  '& p': {
                    display: 'block',
                    marginBottom: 1,
                  },
                  '& ul, & ol': {
                    padding: 'revert',
                    marginBottom: 1,
                  },
                  '& img': {
                    maxWidth: '100%',
                  },
                }}
                dangerouslySetInnerHTML={{ __html: news?.contenido ?? '' }}
              ></Box>
              <Box
                sx={{
                  display: {
                    xs: 'block',
                    lg: 'none',
                  },
                }}
              >
                <NewsDetailsMedia news={news} />
              </Box>
            </Grid>
          </Grid>
        ) : (
          <NewsDetailsSkeleton />
        )}
      </>
    )
  )
}

export default NewsDetails
