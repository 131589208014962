import useFetchNews from '../hooks/useFetchLinks'
import axios from 'api/axios'

/**
 * Class to manage the links CRUD
 * @class
 */
class LinksController {
  static get = useFetchNews

  /**
   * Function to create a link
   * @function
   * @param {LinksDTO} link
   * @returns {Promise<LinksDTO>}
   */
  static create = async (link) => {
    try {
      /** @type {import('axios').AxiosResponse<LinksDTO>}*/
      const response = await axios.post('/enlaces/', link)
      return response.data
    } catch (_) {
      return null
    }
  }

  /**
   * Function to update a link
   * @function
   * @param {LinksDTO} link
   * @returns {Promise<LinksDTO>}
   */
  static update = async (link) => {
    try {
      /** @type {import('axios').AxiosResponse<LinksDTO>}*/
      const response = await axios.put(`/enlaces/${link.id}/`, link)
      return response.data
    } catch (_) {
      return null
    }
  }

  /**
   * Function to delete a link
   * @function
   * @param {number} link
   * @returns {Promise<LinksDTO>}
   */
  static delete = async (id) => {
    try {
      /** @type {import('axios').AxiosResponse<LinksDTO>}*/
      const response = await axios.delete(`/enlaces/${id}/`)
      return response.data
    } catch (_) {
      return null
    }
  }
}

export default LinksController
