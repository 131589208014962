import { DesktopWindows, PersonOff, Feed, LocalAtm, Source } from '@mui/icons-material'
import CalendarMonth from '@mui/icons-material/CalendarMonth'
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
import { Link, useLocation } from 'react-router-dom'

/**
 * A popup menu for the my users table
 * @param {Object} props
 * @param {boolean} props.show - Determines when to show the popup
 * @param {HTMLElement } [props.anchorElement] - Determines where to show the popup -- Use this or the anchor reference to create a context menu
 * @param {UserToHandle} props.user - The user to handle
 * @param {function} props.handleClose
 * @param {function} [props.returnToUser] - If passed shows a button to return to the user to clear the state and execute this function as a callback
 * @param {boolean} [props.currentIsOwner=false] - The actual user is owner
 * @param {string} [props.anchorReference=null] - Reference to create context menu
 * @param {Object} [props.anchorPosition=null] - The position for the context menu
 */
export default function MyUsersPopup({
  user,
  handleClose,
  anchorElement = null,
  anchorPosition = null,
  returnToUser = null,
  currentIsOwner = false,
}) {
  const anchorData = anchorElement
    ? {
        anchorEl: anchorElement,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      }
    : {
        anchorReference: 'anchorPosition',
        anchorPosition: anchorPosition,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }
  const stateToReturn = currentIsOwner ? null : { user }
  return (
    <Menu
      id="menu-myusers"
      {...anchorData}
      keepMounted
      componentsProps={{
        root: {
          onContextMenu: (e) => {
            e.preventDefault()
            handleClose()
          },
        },
      }}
      open={!!anchorElement || !!anchorPosition}
      onClose={handleClose}
    >
      <CustomMenuLink
        to="/calendario"
        Icon={CalendarMonth}
        text="Ver Calendario"
        state={stateToReturn}
      />
      <CustomMenuLink to="/datos" Icon={DesktopWindows} text="Ver ficha" state={stateToReturn} />
      <MenuItem onClick={handleClose}>
        <ListItemIcon>
          <Feed fontSize="small" />
        </ListItemIcon>
        <ListItemText>Ver Modelo 145</ListItemText>
      </MenuItem>
      <CustomMenuLink
        to="/doclaboral"
        Icon={LocalAtm}
        text="Ver Nomina"
        state={{ ...stateToReturn, tipeDoc: 'NOM' }}
      />

      <CustomMenuLink to="/docpersonal" Icon={Source} text="Ver Documentos" state={stateToReturn} />
      {returnToUser ? (
        <CustomMenuItem
          Icon={PersonOff}
          onClick={() => {
            window.history.replaceState({}, document.title)
            returnToUser()
            handleClose()
          }}
          text="Ver mi usuario"
        />
      ) : (
        ''
      )}
    </Menu>
  )
}

const CustomMenuItem = ({ Icon, text, onClick }) => {
  return (
    <MenuItem color="text.primary" onClick={onClick}>
      <ListItemIcon>
        <Icon fontSize="small" />
      </ListItemIcon>
      <ListItemText> {text}</ListItemText>
    </MenuItem>
  )
}

const CustomMenuLink = ({ Icon, text, to, state }) => {
  return (
    <MenuItem>
      <Link
        to={to}
        style={{ display: 'flex', color: 'inherit', textDecoration: 'none' }}
        state={state}
      >
        <Icon sx={{ mr: 2, color: 'inherit' }} fontSize="small" />
        <ListItemText>{text}</ListItemText>
      </Link>
    </MenuItem>
  )
}
