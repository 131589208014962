import { HistoryEdu } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import BotonSolicitudInterna from 'features/solicitudes/components/BotonSolicitudInterna'
import ThemeManager from 'theming/ThemeManager'

const Model145Header = () => {
  const ThemeController = new ThemeManager()
  /**
   * @type {InternalRequestTexts}
   */
  const requestButtonText =
    ThemeController?.options?.model145Options?.modificationRequestButtonTexts ?? {}
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: 'center',
          gap: 1,
          mb: 2,
        }}
      >
        <BotonSolicitudInterna
          noAvailableText={requestButtonText?.noAvailableText ?? ''}
          preButtonText={
            requestButtonText?.preButtonText ??
            'LA INFORMACIÓN QUE HAY A CONTINUACIÓN ES INFORMATIVA. PARA REALIZAR CAMBIOS EN SU MODELO 145, PINCHE EN'
          }
          postButtonText={requestButtonText?.postButtonText ?? ''}
          titulo="Solicitud de modificación de datos"
          id_solicitud_pe="14500"
          buttonProps={{
            color: 'success',
            endIcon: <HistoryEdu />,
          }}
          buttonSize="large"
        />
      </Box>
      <Box>
        <Box
          sx={{
            fontSize: '1em',
            p: 0,
            display: 'flex',
            flexDirection: {
              xs: 'column-reverse',
              md: 'row',
            },
            border: '1px solid',
          }}
        >
          <Box
            xs={11}
            sx={{
              p: 2,
              width: '100%',
              display: 'flex',
              alignItems: 'left',
              justifyItems: 'left',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography variant="h1" sx={{ fontSize: '1.6rem', fontWeight: 'regular' }}>
              {
                'Impuesto sobre la Renta de las Personas Físicas Retenciones sobre rendimientos del trabajo'
              }
            </Typography>
            <Typography variant="h1" sx={{ mt: 1, fontSize: '1.8rem', fontWeight: 'bold' }}>
              {'Comunicación de datos al pagador(artículo 88 del Reglamento del IRPF)'}
            </Typography>
          </Box>
          <Box
            xs={1}
            sx={{
              p: 2,
              aspectRatio: 1,
              display: 'flex',
              placeItems: 'center',
              flexDirection: 'column',
              borderLeft: {
                xs: 'none',
                md: '1px solid',
              },
              borderBottom: {
                xs: '1px solid',
                md: 'none',
              },
            }}
          >
            <Typography variant="subtitle2" sx={{ fontSize: '1.0rem', fontWeight: 'regular' }}>
              Modelo
            </Typography>
            <Typography variant="h1" sx={{ fontSize: '3.0rem', fontWeight: 'bold' }}>
              145
            </Typography>
          </Box>
        </Box>
      </Box>
      <Typography sx={{ mt: 1 }}>
        Si prefiere no comunicar a la empresa o entidad pagadora alguno de los datos a que se
        refiere este modelo, la retención que se le practique podría resultar superior a la
        procedente. En tal caso, podrá recuperar la diferencia, si procede, al presentar su
        declaración del IRPF correspondiente al ejercicio de que se trate.
        <br />
        <br style={{ lineHeight: '5px' }} />
        <b>Atención:</b> la inclusión de datos falsos, incompletos o inexactos en esta comunicación,
        así como la falta de comunicación de variaciones en los mismos que, de haber sido conocidas
        por el pagador, hubieran determinado una retención superior, constituye infracción
        tributaria sancionable con multa del 35 al 150 por 100 de las cantidades que se hubieran
        dejado de retener por esta causa. (Artículo 205 de la Ley 58/2003, de 17 de diciembre,
        General Tributaria).
      </Typography>
    </Box>
  )
}

export default Model145Header
