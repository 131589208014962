import { Button } from '@mui/material'
import CustomTable from 'common/components/CustomTable'
import { useEffect, useState } from 'react'
import useFetchMyUsers from '../hooks/useFetchMyUsers'
import { ArrowDropDown } from '@mui/icons-material'
import MyUsersPopup from '../components/MyUsersPopup'

const MyUsers = () => {
  const { response, loading, error } = useFetchMyUsers()
  const [usuarios, setUsuarios] = useState(response)
  const [currentUser, setCurrentUser] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [contextMenu, setContextMenu] = useState(null)
  const [contextMenuUser, setContextMenuUser] = useState(/** @type {UserToHandle} */ null)

  const handleContextMenu = (event) => {
    event.preventDefault()
    const selectedRow = usuarios.find(
      (user) => user.id === parseInt(event.currentTarget.getAttribute('data-id')),
    )
    setContextMenuUser({
      id: selectedRow.id,
      email: selectedRow.email_usuario_web,
      centro: selectedRow.desc_centro,
      nombre: selectedRow.nombre,
      subempresa: selectedRow.desc_subempresa,
      usuario_web: selectedRow.usuario_web,
    })
    setContextMenu(contextMenu === null ? { mouseX: event.clientX, mouseY: event.clientY } : null)
  }

  useEffect(() => {
    setUsuarios(response)
  }, [response])

  const handleClose = () => {
    setAnchorEl(null)
    setContextMenu(null)
  }

  return (
    <>
      <div style={{ width: '100%' }}>
        <CustomTable
          handleContextMenu={handleContextMenu}
          hideFooter={false}
          density="compact"
          loading={loading}
          initialPageSize={20}
          showToolbar={true}
          dataSource={usuarios ?? []}
          fields={[
            { value: 'usuario_web', key: 'Usuario', width: 150, sortable: true },
            { value: 'nombre', key: 'Nombre', width: 150, sortable: true, flex: 1.5 },
            { value: 'email_usuario_web', key: 'Email', width: 150, sortable: true, flex: 1 },
            {
              value: 'desc_centro',
              key: 'Subempresa - Centro',
              width: 150,
              sortable: true,
              flex: 2,
              customGetter: (params) => {
                return `${params.row.desc_subempresa} - ${params.row.desc_centro}`
              },
            },
            {
              value: 'actions',
              key: 'Acciones',
              width: 150,
              filterable: false,
              sortable: false,
              renderCell: (user) => {
                return (
                  <div>
                    <Button
                      key={`${user.id} user`}
                      endIcon={<ArrowDropDown />}
                      onClick={(e) => {
                        setAnchorEl(e.target)
                        e.stopPropagation()
                        /** @type {UserToHandle} */
                        const currentUser = {
                          id: user.id,
                          nombre: user.nombre,
                          email: user.email_usuario_web,
                          centro: user.desc_centro,
                          subempresa: user.desc_subempresa,
                          usuario_web: user.usuario_web,
                        }
                        setCurrentUser(currentUser)
                      }}
                    >
                      Acciones
                    </Button>
                  </div>
                )
              },
            },
          ]}
        />
        <MyUsersPopup anchorElement={anchorEl} handleClose={handleClose} user={currentUser} />
        <MyUsersPopup
          anchorPosition={
            contextMenu
              ? {
                  top: contextMenu.mouseY,
                  left: contextMenu.mouseX,
                }
              : undefined
          }
          handleClose={handleClose}
          user={contextMenuUser}
        />
      </div>
    </>
  )
}

export default MyUsers
