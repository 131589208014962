import axios from 'api/axios'
const { useState, useEffect, useCallback } = require('react')

const useFetchAccesosDirectos = (codigo) => {
  const [response, setResponse] = useState([])
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const fetchData = useCallback(() => {
    if (loading) {
      return
    }

    setLoading(true)
    const uri = codigo ? `?c=${codigo}` : ''
    axios
      .get(`/accesosdirectos/${uri}`)
      .then((request_response) => {
        const data = request_response.data
        setResponse(data)
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return { response, error, loading }
}

export default useFetchAccesosDirectos
