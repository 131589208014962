/**
* @module Common
* /

/**
 * Devuelve una fecha formateada desde un string ISO de base de datos
 * @param {string} fecha - Fecha en formato ISO
 * @param {string} iso - The iso format
 * @param {boolean} useLocalLang - The iso format
 * @returns {string} Fecha formateada
 */
export const getFechaFromISO = (
  fecha,
  iso = 'es-Es',
  options = { year: 'numeric', month: 'long', day: 'numeric' },
  useLocalLang = false,
) => {
  const userLang = navigator.language || navigator.userLanguage
  const fixed_date = fecha?.includes('-') ? fecha?.split('-').map((i) => +i) ?? '' : ''
  if (!fixed_date) return fixed_date
  const date = new Date(fixed_date[0], fixed_date[1] - 1, fixed_date[2])
  return date.toLocaleDateString(useLocalLang ? userLang : iso, options)
}

export const DATE_LOCALE_OPTIONS = { year: 'numeric', month: 'long', day: 'numeric' }
/** @type {Intl.DateTimeFormatOptions} */
export const DATETIME_LOCALE_OPTIONS = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
}

/**
 * This function can transform a date string into a human
 * readable string
 *
 * @param {string} date - The date to transform
 * @param {boolean} [isDateTime] - You will show the datetime
 * @param {string} [iso] - The iso to lacale
 * @param {Intl.DateTimeFormatOptions}  options - The options for the final date
 * @returns {string} Returns a string with a humman readable date
 */
export const getLocaleFromFullDate = (date, isDateTime = true, iso = 'es-Es', options = null) => {
  if (!date) return ''
  const dividedDate =
    date?.includes('-') && date.length <= 9 ? date.split('-').map((i) => +i) : null
  const transformedDate = dividedDate
    ? new Date(dividedDate[0], dividedDate[1] - 1, dividedDate[2])
    : new Date(date)

  return transformedDate.toLocaleString(
    iso,
    options ?? isDateTime ? DATETIME_LOCALE_OPTIONS : DATE_LOCALE_OPTIONS,
  )
}
