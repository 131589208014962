import {
  Avatar,
  Card,
  CardHeader,
  IconButton,
  Typography,
  CircularProgress,
  CardContent,
  Alert,
} from '@mui/material'
import { Box } from '@mui/system'
import { GridMoreVertIcon } from '@mui/x-data-grid'
import { useState } from 'react'
import MyUsersPopup from './MyUsersPopup'

/**
 * @module Users.Components
 */

/**
 * Component to show the custom user on the calendar
 * @param {Object} config - The component props
 * @param {UserToHandle} config.user - The user to display
 * @param {boolean} [config.loading] - Is loading data
 * @param {boolean} [config.isCustom] - True if is not the authenticated user
 * @param {CustomUserMessage} [config.message] - A message to show at the button
 * @param {boolean} [config.returnToUser] - Shows a button to display the user data on the popup menu
 */
const CustomUserCard = ({
  user,
  loading = false,
  isCustom = false,
  message = null,
  returnToUser = false,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <>
      <Card>
        <CardHeader
          sx={{ position: 'relative' }}
          avatar={
            <>
              {loading ? (
                <CircularProgress color="secondary" />
              ) : (
                <Avatar
                  sx={{ backgroundColor: 'secondary.main' }}
                  alt={`${user?.nombre}`}
                  aria-label="trabajador"
                />
              )}
            </>
          }
          action={
            <IconButton onClick={(e) => setAnchorEl(e.target)} aria-label="settings">
              <GridMoreVertIcon />
            </IconButton>
          }
          title={
            <Box sx={{ display: 'flex' }}>
              <Typography sx={{ mr: 2 }} variant="title">{`${user?.nombre ?? '--'}`}</Typography>
              {isCustom ? (
                <Typography variant="subtitle2" sx={{ textTransform: 'uppercase' }} color="primary">
                  Usuario Personalizado
                </Typography>
              ) : (
                <></>
              )}
            </Box>
          }
          subheader={
            <Box>
              <Typography variant="caption">{`${user?.subempresa} - ${user?.centro}`}</Typography>
            </Box>
          }
          titleTypographyProps={{}}
          subheaderTypographyProps={{}}
        />
        {message ? (
          <CardContent>
            <Alert severity={message?.type}>{message?.text}</Alert>
          </CardContent>
        ) : (
          <></>
        )}
      </Card>
      <MyUsersPopup
        returnToUser={returnToUser}
        anchorElement={anchorEl}
        handleClose={handleClose}
        user={user}
      />
    </>
  )
}

export default CustomUserCard
