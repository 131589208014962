import { Paper, Typography } from '@mui/material'
import logo from 'assets/images/logo.svg'
import useQuery from 'common/hooks/useQuery'
import DefaultLoginBackGround from '../components/LoginBackground'
import DefaultLoginForm from '../components/LoginForm'
import DefaultRecoveryPasswordForm from '../components/RecoveryPasswordForm'
import PasswordForm from '../components/PasswordForm'
import { Link } from 'react-router-dom'
import ThemeManager from 'theming/ThemeManager'
import Logo from 'common/components/Logo'

const ResetPassword = () => {
  const query = useQuery()

  const themeController = new ThemeManager() // instanciar el controlador
  const LoginBackground = themeController?.components
    ? themeController?.components['LoginBackground'] ?? DefaultLoginBackGround
    : DefaultLoginBackGround

  const custom_logo = themeController.logos['Large'] ? (
    <Logo variant="Large" />
  ) : (
    <img src={logo} alt="Logo" style={{ maxHeight: '40px', width: '100%' }} />
  )

  return (
    <LoginBackground loading={false}>
      <Paper sx={{ backgroundColor: 'background.paper', p: 3 }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Link to="/">{custom_logo}</Link>
        </div>
        <div
          style={{
            minWidth: '25ch',
            width: '100%',
            mx: 1,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h5" sx={{ m: 3 }}>
            Establecer nueva contraseña
          </Typography>
        </div>

        <PasswordForm token={query.get('t')} buttonWidth="100%" variant="standard" />
      </Paper>
    </LoginBackground>
  )
}

export default ResetPassword
