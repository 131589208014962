import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'api/axios'

import dayjs from 'dayjs'

const initialState = {
  loading: false,
  error: '',
  disponibles: [],
  seleccionada: {},
  loadingCampos: false,
  errorCampos: '',
  campos: [],
  estructura: [],
  datos: [],
  descripciones: [],
  vacaciones: {
    pendientes: null,
    tipo_dias: null,
  },
}

//recupera lista de solicitudes
export const fetchListaSolicitudes = createAsyncThunk('/listasolicitudes', async () => {
  const response = await axios.get('/listasolicitudes/')
  return response.data
})

//recupera lista de solicitudes
export const fetchCamposSolicitudes = createAsyncThunk(
  '/campossolicitudes',
  async ({ tipo, subtipo }) => {
    const response = await axios.get(`/campossolicitudes/${tipo}/${subtipo}/`)
    return response.data
  },
)

//ejecutar las consultas iniciales
export const consultaInicial = createAsyncThunk('/consultainicial', async ({ idpk, valores }) => {
  const response = await axios.post(`/consultainicial/${idpk}/`, { valores })
  return response.data
})

export const solicitudesSlice = createSlice({
  name: 'solicitudes',
  initialState,
  reducers: {
    setDatosVacaciones: (state, action) => {
      state.vacaciones = action.payload
    },
    setSolicitudSeleccionada: (state, action) => {
      state.seleccionada = action.payload
    },
    setDatoSolicitud: (state, action) => {
      const { idpk, valor, descripcion } = action.payload

      state.datos[idpk] = valor
      state.descripciones[idpk] = descripcion

      // Borramos los campos dependientes de este
      const campo = state.campos.filter((c) => Number(c.idpk) === Number(idpk))[0]

      if (campo?.cmp_dependientes) {
        campo.cmp_dependientes
          .replace(/:/gm, '')
          .split(',')
          .forEach((codigo_campo) => {
            const dependiente = state.campos.filter((c) => c.codigo_campo === codigo_campo)[0]
            state.datos[dependiente.idpk] = null
          })
      }
    },
    setDatosSolicitud: (state, action) => {
      state.datos = action.payload
    },
    setCampoCargando: (state, action) => {
      const { idpk, cargando } = action.payload
      let campo = state.campos.filter((c) => Number(c.idpk) === Number(idpk))[0]
      campo.cargando = cargando
    },
    setCampoError: (state, action) => {
      const { idpk, error } = action.payload
      let campo = state.campos.filter((c) => Number(c.idpk) === Number(idpk))[0]
      campo.error = error
    },
    addSolicitudALista: (state, action) => {
      const solicitud = action.payload
      if (
        !state.disponibles.filter(
          (s) => s?.tipo === solicitud?.tipo && s?.subtipo === solicitud?.subtipo,
        )
      ) {
        state.disponibles.push(solicitud)
      }
    },
    executeFieldAction: (state, action) => {
      const fieldAction = action.payload.action

      let campo = state.campos.filter(
        (c) => c.codigo_campo === fieldAction.campo_destino.replace(':', ''),
      )[0]

      // It gets the data from the structure that is registered the field in
      let block = state.estructura
        .map((b) => {
          const f = b.campos.filter((c) => {
            return c.idpk === campo.idpk
          })
          if (f) return f
          return null
        })
        .filter((f) => f.length)[0]

      switch (fieldAction.accion) {
        case 'VN':
          campo.visible = 'N'
          block[0].visible = 'N'
          break
        case 'VS':
          campo.visible = 'S'
          block[0].visible = 'S'
          break
        case 'OP':
          campo.obligatorio = 'N'
          break
        case 'OB':
          campo.obligatorio = 'S'
          break
        case 'BR':
          setDatoSolicitud({ idpk: campo.idpk, valor: null, descripcion: null })
          break

        default:
          break
      }
    },
    resetSolicitud: (state, action) => {
      state.campos = []
      state.estructura = []
      state.datos = []
      state.descripciones = []
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchListaSolicitudes.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchListaSolicitudes.rejected, (state, action) => {
        state.error = action.error.message
        state.loading = false
      })
      .addCase(fetchListaSolicitudes.fulfilled, (state, action) => {
        state.error = ''
        state.loading = false
        state.disponibles = action.payload
      })
      .addCase(fetchCamposSolicitudes.pending, (state) => {
        state.loadingCampos = true
      })
      .addCase(fetchCamposSolicitudes.rejected, (state, action) => {
        state.errorCampos = action.error.message
        state.loadingCampos = false
      })
      .addCase(fetchCamposSolicitudes.fulfilled, (state, action) => {
        state.errorCampos = ''
        state.loadingCampos = false

        let datos = {}
        let descripciones = {}
        action.payload.forEach((c) => {
          if (c.tipo_dato !== 'S') {
            datos[c.idpk] = null
            descripciones[c.idpk] = null
          }
          c.cargando = false
          c.error = false
        })

        const estructura = []
        let separadores = action.payload.filter((c) => c.tipo_dato === 'S' && c.visible === 'S')

        if (separadores.length === 0) {
          estructura.push({
            idpk: -1,
            campos: [],
          })
          action.payload.forEach((c) => {
            estructura[0].campos.push({
              idpk: c.idpk,
              tipo_dato: c.tipo_dato,
              lv_codigo: c.lv_codigo,
              lv_tipo: c.lv_tipo,
              visible: c.visible,
            })
          })
        } else {
          //Insert all fields previous to the first divider
          let fields = []
          for (let i = 0; i < action.payload.length; i++) {
            const field = action.payload[i]
            if (field.tipo_dato === 'S') {
              break
            }
            fields.push({
              idpk: field.idpk,
              tipo_dato: field.tipo_dato,
              lv_codigo: field.lv_codigo,
              lv_tipo: field.lv_tipo,
              visible: field.visible,
            })
          }
          if (fields.length > 0) {
            estructura.push({
              idpk: null,
              campos: fields,
            })
          }

          separadores.forEach((s) => {
            let campos = []

            let iniciar = false
            for (let i = 0; i < action.payload.length; i++) {
              const campo = action.payload[i]
              if (iniciar && campo.tipo_dato === 'S' && campo.codigo_campo !== s.codigo_campo) {
                break
              }
              if (iniciar) {
                campos.push({
                  idpk: campo.idpk,
                  tipo_dato: campo.tipo_dato,
                  lv_codigo: campo.lv_codigo,
                  lv_tipo: campo.lv_tipo,
                  visible: campo.visible,
                })
              }
              if (campo.codigo_campo === s.codigo_campo) {
                iniciar = true
              }
            }
            estructura.push({
              idpk: s.idpk,
              campos: campos,
            })
          })
        }
        state.campos = action.payload
        state.estructura = estructura
        state.datos = datos
        state.descripciones = descripciones
      })
      .addCase(consultaInicial.pending, (state) => {
        state.loading = true
      })
      .addCase(consultaInicial.rejected, (state, action) => {
        state.error = action.error.message
        state.loading = false
      })
      .addCase(consultaInicial.fulfilled, (state, action) => {
        state.error = ''
        state.loading = false

        const idpk = action.payload.idpk
        const tipo = state.campos.filter((c) => Number(c.idpk) === Number(idpk))[0].tipo_dato
        const mascara_formato = state.campos.filter((c) => Number(c.idpk) === Number(idpk))[0]
          .mascara_formato

        const valor =
          tipo === 'D' || tipo === 'DH1' || tipo === 'DH2'
            ? dayjs(action.payload.valor, mascara_formato)
            : action.payload.valor
        action.payload.valor = valor
        if (!action.payload.descripcion) action.payload.descripcion = null

        solicitudesSlice.caseReducers.setDatoSolicitud(state, action)

        action.payload = {
          idpk,
          cargando: false,
        }

        solicitudesSlice.caseReducers.setCampoCargando(state, action)
      })
  },
})

export const {
  setSolicitudSeleccionada,
  setDatoSolicitud,
  setDatosSolicitud,
  setDatosVacaciones,
  setCampoCargando,
  setCampoError,
  addSolicitudALista,
  resetSolicitud,
  executeFieldAction,
} = solicitudesSlice.actions

export const selectListaSolicitudesStatus = (state) => state.solicitudes.loading
export const selectListaSolicitudesError = (state) => state.solicitudes.error
export const selectListaSolicitudesDisponibles = (state) => state.solicitudes.disponibles
export const selectSolicitudSeleccionada = (state) => state.solicitudes.seleccionada
export const selectCamposStatus = (state) => state.solicitudes.loadingCampos
export const selectCamposError = (state) => state.solicitudes.errorCampos
export const selectCamposSolicitud = (state) => state.solicitudes.campos
export const selectEstructuraSolicitud = (state) => state.solicitudes.estructura
export const selectDatosSolicitud = (state) => state.solicitudes.datos
export const selectDescripcionesSolicitud = (state) => state.solicitudes.descripciones
export const selectVacionesPendientes = (state) => state.solicitudes.vacaciones.pendientes
export const selectVacionesTipoDias = (state) => state.solicitudes.vacaciones.tipo_dias

export default solicitudesSlice.reducer
