import SearchIcon from '@mui/icons-material/Search'
import { IconButton } from '@mui/material'
import TextField from '@mui/material/TextField'
import { Box } from '@mui/system'
import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const Buscador = () => {
  const navigate = useNavigate()
  const inputRef = useRef(null)

  const [visible, setVisible] = useState(false)
  const [valor, setValor] = useState('')

  const handleClick = () => {
    setVisible(!visible)
    setTimeout(() => {
      inputRef.current.focus()
    }, 100)
  }

  const keyPressed = (e) => {
    if (e.keyCode === 13) {
      navigate('/buscador', { state: { busqueda: valor } })

      setValor('')
      setVisible(false)
    }
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <IconButton
        onClick={handleClick}
        sx={{
          display: {
            md: 'flex',
          },
          color: { xs: 'background.alternativeContrastText', sm: 'initial' },
        }}
        aria-label="delete"
        size="large"
      >
        <SearchIcon />
      </IconButton>
      <TextField
        placeholder="Buscar..."
        value={valor}
        onChange={(e) => setValor(e.target.value)}
        onKeyDown={keyPressed}
        variant="standard"
        fullWidth
        ref={inputRef}
        sx={{
          display: visible ? 'block' : 'none',
          minWidth: {
            md: '300px',
          },
        }}
      />
    </Box>
  )
}

export default Buscador
