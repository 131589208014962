import ListaSolicitudes from '../components/ListaSolicitudes'

const ListadoSolicitudes = ({ object }) => {
  if (object) {
    const datos = object?.split('|')
    return <ListaSolicitudes tipo={datos[0]} subtipo={datos[1]} />
  }
  return <ListaSolicitudes />
}

export default ListadoSolicitudes
