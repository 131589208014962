import { Button, Divider, IconButton, Skeleton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { FilePdf, Sliders } from '@phosphor-icons/react'
import useCurrentMenu from 'common/hooks/useCurrentMenu'
import { InformesContext } from 'features/informes/providers/InformesProvider'
import { useContext } from 'react'

const FilterSkeleton = () => {
  const { actualMatch } = useCurrentMenu()
  const {
    active: { structure },
  } = useContext(InformesContext)
  return (
    <Box sx={{ display: 'flex', gap: '8px', ml: 'auto' }}>
      <Skeleton>
        <Typography
          variant="h1"
          sx={{
            fontSize: '1.6em',
            mr: 4,
            mb: { xs: 3, md: 0 },
            width: { xs: '100%', md: 'auto' },
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          {structure?.response?.nombre ?? actualMatch?.nombre ?? ''}
        </Typography>
      </Skeleton>
      <Box sx={{ display: 'flex', gap: '8px', ml: 'auto' }}>
        <Skeleton>
          <Button endIcon={<Sliders size={30} />}>Filtros</Button>
        </Skeleton>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Skeleton>
          <IconButton>
            <FilePdf size={30} weight="light" />
          </IconButton>
        </Skeleton>
        <Skeleton>
          <IconButton>
            <FilePdf size={30} weight="light" />
          </IconButton>
        </Skeleton>
      </Box>
    </Box>
  )
}

export default FilterSkeleton
