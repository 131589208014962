import { DocumentNotificationContext } from 'features/documentos/providers/DocumentNotificationsProvider'
import { NotificationsContext } from 'features/notifications/providers/NotificationsProvider'
import { selectMenus } from 'features/portal/menusSlice'
import { routeIsActive } from 'features/routing/libs/routeIsActive'
import { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import notifyUnreadedComunicados from './libs/notifyUnreadComunicados'
import notifyUnreadedDocs from './libs/notifyUnreadedDocs'

const AutomationController = () => {
  const menu = useSelector(selectMenus)
  const [launched, setLaunched] = useState(false)
  const { launchModal, forceRefresh: refreshDocumentNotifications } = useContext(
    DocumentNotificationContext,
  )
  const { forceRefresh: refreshNotifications } = useContext(NotificationsContext)

  const navigate = useNavigate()

  const navigateTo = (url) => {
    navigate(url)
  }

  useEffect(() => {
    if (menu.length < 2 || launched) return
    refreshNotifications()
    refreshDocumentNotifications()
    if (!launched) {
      setLaunched(true)
      notifyUnreadedDocs(menu, navigateTo)
      notifyUnreadedComunicados(menu, navigateTo)
      const corporateIsActive =
        routeIsActive('/doccorporativa', menu) || routeIsActive('/docpersonal', menu)
      if (corporateIsActive) launchModal()
      launchModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu])

  return <></>
}

export default AutomationController
