import { Box, Grid, Skeleton } from '@mui/material'
import Button from '@mui/material/Button'
import useFetchAccesosDirectos from 'common/hooks/useFetchAccesosDirectos'
import SSOButton from 'features/singlesignon/components/SSOButton'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import IconsList from 'common/constants/icons_map'

const AccesosDirectos = ({ codigo = null, sx = null }) => {
  const [accesos, setAccesos] = useState([])

  const { response, loading } = useFetchAccesosDirectos(codigo)
  useEffect(() => {
    if (!loading && response) {
      setAccesos([...response])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  return (
    <Box sx={sx}>
      <Grid container>
        {loading
          ? Array(4)
              .fill()
              .map((_, i) => (
                <Grid key={i} item xs={6} md={3}>
                  <Skeleton variant="rectangular" width={200} height={20} />
                </Grid>
              ))
          : accesos.map((a, i) => {
              const Icon = a.icono ? IconsList[a.icono] : null
              return (
                <Grid key={i} item xs={6} md={3}>
                  {a.codigo_sso ? (
                    <SSOButton
                      sso={a.codigo_sso}
                      texto={a.titulo}
                      target={a.nueva_ventana === 'S' ? '_blank' : '_self'}
                      startIcon={Icon ? <Icon /> : null}
                    />
                  ) : (
                    <Button
                      variant="text"
                      component={Link}
                      to={a.url}
                      color="primary"
                      size="large"
                      target={a.nueva_ventana === 'S' ? '_blank' : '_self'}
                      startIcon={Icon ? <Icon /> : null}
                    >
                      {a.titulo}
                    </Button>
                  )}
                </Grid>
              )
            })}
      </Grid>
    </Box>
  )
}

export default AccesosDirectos
