import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Select,
  TextField,
} from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import CommentsStepper from './ComentsStepper'
import Button from '@mui/material/Button'
import React from 'react'

import CloudUploadIcon from '@mui/icons-material/CloudUpload'

import DocsController from '../libs/DocsController'
import { styled } from '@mui/material/styles'

const acciones = {
  F: {
    title: 'Firmar documento',
    text: '¿Estás seguro de que deseas firmar este documento? Si es así, introduce observaciones',
    actionTrue: 'Confirmar',
    actionFalse: 'Cancelar',
  },
  R: {
    title: 'Rechazar documento',
    text: '¿Estás seguro de que deseas rechazar este documento? Si es así, introduce observaciones',
    actionTrue: 'Confirmar',
    actionFalse: 'Cancelar',
  },
}

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`

const DocumentDialogs = ({
  setErrorStatus,
  docData,
  modalOpen,
  setModalOpen,
  action,
  modalComentariosOpen,
  setModalComentariosOpen,
  setTrigger,
}) => {
  const [observaciones, setObservaciones] = React.useState('')
  const [authMethod, setAuthMethod] = React.useState('')
  const [authToken, setAuthToken] = React.useState('')
  const [tokenInputVisible, setTokenInputVisible] = React.useState(false)
  const [selectedFile, setSelectedFile] = React.useState(null)

  const handleCloseModal = () => {
    setObservaciones('')
    setTokenInputVisible(false)
    setAuthToken('')
    setModalOpen(false)
    setModalComentariosOpen(false)
    setSelectedFile(null)
  }

  const getTokenAction = async () => {
    setTokenInputVisible(true)
    let response = await DocsController.sendToken(
      authMethod || docData.tipo_df_preferido || docData.tipos_df_list[0]?.tipo || ''
    )

    setErrorStatus({
      error: true,
      code: response.status,
      msg: response.data,
    })
  }

  const handleSubmitForm = async (isObs) => {
    if (isObs) {
      sendData()
    } else {
      const reader = new FileReader()

      reader.onload = (e) => {
        const binaryData = e.target.result
        sendData(binaryData)
      }

      reader.readAsBinaryString(selectedFile)
    }
  }

  const sendData = async (file_data) => {
    let formData = new FormData()
    if (file_data !== undefined) {
      formData.append('file', selectedFile)
      formData.append('file_content', btoa(file_data)) //Archivo en base64
      formData.append('sube_fichero', selectedFile !== null ? 'S' : 'N')
      formData.append('nombre', selectedFile.name)
    }
    formData.append('modo_firma', docData.modo_firma)
    formData.append('tipo_gestion', action)
    formData.append('token', authToken)
    formData.append('id_documento', docData.id_documento)
    formData.append('observaciones', observaciones)

    let response = await DocsController.sign(formData)
    setTrigger(true)
    setErrorStatus({
      error: true,
      code: response.status,
      msg: response.data,
    })
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    setSelectedFile(file)
  }

  return (
    <>
      <Dialog
        open={modalOpen}
        onClose={() => {
          handleCloseModal()
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{acciones[action]?.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {acciones[action]?.text}
          </DialogContentText>
          <TextField
            autoFocus
            required
            multiline
            margin="dense"
            id="name"
            label="Observaciones"
            type="text"
            fullWidth
            variant="standard"
            onChange={(event) => {
              setObservaciones(event.target.value)
            }}
          />
          {docData?.permitir_adjuntar_documentos === 'S' &&
          docData.modo_firma === 'S' &&
          action === 'F' ? (
            <>
              <Button
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon />}
                href="#file-upload"
                sx={{ marginTop: '1rem' }}
              >
                Seleccionar archivo
                <VisuallyHiddenInput type="file" name="file" onChange={handleFileChange} />
              </Button>
              {selectedFile !== undefined && selectedFile !== null ? (
                <p style={{ marginTop: '1rem', color: 'gray' }}>
                  Archivo seleccionado: {selectedFile.name}
                </p>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
          {docData?.modo_firma === 'S' ? (
            <>
              <DialogContentText id="alert-dialog-description" sx={{ marginTop: '2rem' }}>
                Escoge un método de verificación:
              </DialogContentText>
              <FormControl sx={{ minWidth: 120 }}>
                <Select
                  defaultValue={
                    docData.tipo_df_preferido !== undefined
                      ? docData.tipo_df_preferido
                      : docData.tipos_df_list[0]?.tipo || ''
                  }
                  variant="standard"
                  onChange={(e) => {
                    setAuthMethod(e.target.value)
                  }}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {docData.tipos_df_list.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.tipo}>
                        {item.nombre}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
              <Button
                sx={{ marginLeft: '2rem' }}
                onClick={() => {
                  getTokenAction()
                }}
                autoFocus
              >
                Enviar token
              </Button>
              {tokenInputVisible ? (
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  id="name"
                  label="Código de confirmación"
                  type="text"
                  fullWidth
                  variant="standard"
                  onChange={(event) => {
                    setAuthToken(event.target.value)
                  }}
                />
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleCloseModal()
            }}
            autoFocus
          >
            {acciones[action]?.actionFalse}
          </Button>
          <Button
            onClick={() => {
              handleSubmitForm()
              handleCloseModal()
            }}
            disabled={
              docData?.modo_firma === 'N'
                ? observaciones?.trim().length <= 0
                : observaciones?.trim().length <= 0 ||
                  authToken?.trim().length <= 0 ||
                  (docData.permitir_adjuntar_documentos === 'S'
                    ? action === 'F'
                      ? selectedFile === null
                      : selectedFile !== null
                    : selectedFile !== null)
            }
            sx={{ color: 'red' }}
          >
            {acciones[action]?.actionTrue}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={modalComentariosOpen}
        onClose={() => {
          handleCloseModal()
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Observaciones</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
        >
          <div style={{ overflow: 'auto' }}>
            <CommentsStepper
              commentsChat={docData?.linea_observaciones_chat_list}
              commentsObs={docData?.linea_observaciones_documento_list}
            />
          </div>
          <h3 style={{ marginTop: '2rem' }}>Añadir observación</h3>
          <TextField
            autoFocus
            required
            multiline
            margin="dense"
            id="name"
            label="Observaciones"
            type="text"
            fullWidth
            variant="standard"
            onChange={(event) => {
              setObservaciones(event.target.value)
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleCloseModal()
            }}
            autoFocus
          >
            Cerrar
          </Button>
          <Button
            disabled={observaciones?.trim().length <= 0}
            onClick={() => {
              handleSubmitForm(true)
              handleCloseModal()
            }}
            autoFocus
            sx={{ color: 'red' }}
          >
            Enviar observación
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DocumentDialogs
