import { CustomModalProvider } from 'common/contexts/CustomModalContext'
import { GlobalVarialbesProvider } from 'common/providers/GlobalVariablesProvider'
import { DocumentNotificationProvider } from 'features/documentos/providers/DocumentNotificationsProvider'
import { NotificationsProvider } from 'features/notifications/providers/NotificationsProvider'

/**
 * @module Common
 */

/**
 * This element is only for prevent execive
 * nesting on the main App
 *
 * @returns {JSX.Element} - This element is a wrapper for all the app 'Probiders'
 */
const ProvidersNest = ({ children }) => (
  <GlobalVarialbesProvider>
    <CustomModalProvider>
      <NotificationsProvider>
        <DocumentNotificationProvider>{children}</DocumentNotificationProvider>
      </NotificationsProvider>
    </CustomModalProvider>
  </GlobalVarialbesProvider>
)

export default ProvidersNest
