/**
 * @type {EdisaTheme}
 */
export const Dark = {
  mode: 'dark',
  text: {
    primary: '#dedede',
  },
  menu: {
    background: '#4783d1',
    text: '#fff',
  },
  primary: {
    main: '#4783d1', //4783d1
    contrastText: '#fff',
  },
  secondary: {
    main: '#1B3F6F',
  },
  accent: { main: '#1757AB' },
  error: {
    main: '#cc2b4b',
    light: '#f16e6e',
  },
  background: {
    menu: '#25303b',
    menuContrast: '#fff',
    default: '#17171f',
    paper: '#20232a',
    lighter: '#30333b',
    alternative: '#25303b',
    alternativeContrastText: '#efefef',
  },
}

/**
 * @type {EdisaTheme}
 */
export const Light = {
  mode: 'light',
  menu: {
    background: '#4783d1',
    text: '#fff',
  },
  primary: {
    main: '#426bba',
    contrastText: '#fff',
  },
  text: {
    primary: '#2b2b2b',
  },
  secondary: {
    main: '#8FB4E4',
  },
  accent: { main: '#5394E8' },
  error: {
    main: '#d32f2f',
  },
  background: {
    menu: '#426bba',
    menuContrast: '#fff',
    default: '#efefef',
    paper: '#f9f7f7',
    alternative: '#426bba',
    lighter: '#dcdde0',
    alternativeContrastText: '#efefef',
    nav: 'transparent',
  },
}

export const selectTheme = (mode = 'light') => {
  return mode === 'dark' ? Dark : Light
}
