import { useEffect, useState } from 'react'
import Personales from '../subpages/Personales.js'
import Profesionales from '../subpages/Profesionales.js'
import Bancarios from '../subpages/Bancarios.js'
import Curriculum from '../subpages/Curriculum'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone'
import WorkTwoToneIcon from '@mui/icons-material/WorkTwoTone'
import AccountBalanceTwoToneIcon from '@mui/icons-material/AccountBalanceTwoTone'
import SchoolTwoToneIcon from '@mui/icons-material/SchoolTwoTone'
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone'
import Organigrama from 'features/organigrama/pages/Organigrama'
import TabPanel from '../components/TabPanel.js'
import WorkerData from '../components/WorkerData.js'
import { useLocation } from 'react-router-dom'
import useFetchWorkerData from '../hooks/useFetchWorkerData.js'
import ThemeManager from 'theming/ThemeManager.js'

const DatosTrabajador = () => {
  const Theme = new ThemeManager()
  const location = useLocation()
  const [user] = useState(location?.state?.user ?? null)
  const { response } = useFetchWorkerData(user)

  const [datos, setDatos] = useState(response)
  useEffect(() => {
    setDatos(response)
  }, [response])

  const [value, setValue] = useState(0)

  const handleChange = (_, newValue) => {
    setValue(newValue)
  }

  return (
    <>
      <Box sx={{ marginTop: '63px' }}>
        <Card sx={{ width: '100%', overflow: 'initial' }}>
          <WorkerData datos={datos} />
          <CardActions disableSpacing sx={{ justifyContent: 'flex-end' }}>
            <Tabs
              value={value}
              variant="scrollable"
              onChange={handleChange}
              aria-label="secciones datos trabajador"
              allowScrollButtonsMobile={true}
            >
              <Tab icon={<PersonOutlineTwoToneIcon />} iconPosition="start" label="Personal" />
              {Theme.hide?.WorkerData?.Professional?.Hide ? (
                <></>
              ) : (
                <Tab icon={<WorkTwoToneIcon />} iconPosition="start" label="Profesional" />
              )}
              {Theme.hide?.WorkerData?.Bank?.Hide ? (
                <></>
              ) : (
                <Tab icon={<AccountBalanceTwoToneIcon />} iconPosition="start" label="Bancarios" />
              )}
              {Theme.hide?.WorkerData?.Curriculum?.Hide ? (
                <></>
              ) : (
                <Tab icon={<SchoolTwoToneIcon />} iconPosition="start" label="Curriculum" />
              )}
              {Theme.hide?.WorkerData?.Organigram?.Hide ? (
                <></>
              ) : (
                <Tab icon={<AccountTreeTwoToneIcon />} iconPosition="start" label="Organigrama" />
              )}
            </Tabs>
          </CardActions>
        </Card>
      </Box>

      <TabPanel value={value} index={0}>
        <Personales datosTrabajador={datos} />
      </TabPanel>
      {Theme.hide?.WorkerData?.Professional?.Hide ? (
        <></>
      ) : (
        <TabPanel value={value} index={1}>
          <Profesionales />
        </TabPanel>
      )}
      {Theme.hide?.WorkerData?.Bank?.Hide ? (
        <></>
      ) : (
        <TabPanel value={value} index={2}>
          <Bancarios datosTrabajador={datos} />
        </TabPanel>
      )}
      {Theme.hide?.WorkerData?.Curriculum?.Hide ? (
        <></>
      ) : (
        <TabPanel value={value} index={3}>
          <Curriculum />
        </TabPanel>
      )}
      {Theme.hide?.WorkerData?.Organigram?.Hide ? (
        <></>
      ) : (
        <TabPanel value={value} index={4}>
          <Organigrama />
        </TabPanel>
      )}
    </>
  )
}

export default DatosTrabajador
