import { useSelector } from 'react-redux'
import { selectCamposSolicitud } from 'features/solicitudes/solicitudesSlice'

import Divider from '@mui/material/Divider'
import Chip from '@mui/material/Chip'

function DividerComponent({ idpk }) {
  const campos = useSelector(selectCamposSolicitud)
  const campo = campos.filter(c => c.idpk === idpk)[0]
  if(campo) {
    return (
      <Divider textAlign="left">
        <Chip label={campo.etiqueta} />
      </Divider>
    )
  }
}

export default DividerComponent
