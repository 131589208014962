import { MoreVert } from '@mui/icons-material'
import { IconButton, Menu, MenuItem } from '@mui/material'
import { CustomModalContext } from 'common/contexts/CustomModalContext'
import { ModalTypes } from 'common/hooks/useCustomModal'
import FilesHelper from 'common/libs/Files'
import { enqueueSnackbar } from 'notistack'
import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import InformesHelper from '../helpers/InformesHelper'
import { InformesContext } from '../providers/InformesProvider'

/**
 * @param {object} props
 * @param {InformePluginConfig} props.plugin
 */
const InformePluginButton = ({ plugin, element, onClick }) => {
  const { active, setRedirected } = useContext(InformesContext)
  const { handleModal } = useContext(CustomModalContext)
  const navigate = useNavigate()

  async function callProgram(filters) {
    switch (plugin.programa_llamado) {
      case 'efactura':
        handleModal({ type: ModalTypes.Loading, enabled: true })
        await InformesHelper.obtenerEfactura({
          numero_factura: filters.NUMERO_FACTURA,
          ejercicio: filters.EJERCICIO,
          numero_serie: filters.NUMERO_SERIE,
        })
          .then((data) => {
            if (Array.isArray(data.registros)) {
              for (var i = 0; i < data.registros.length; i++) {
                var registro = data.registros[i]
                FilesHelper.blobDownload(registro.archivo, 'pdf', registro.nombre_archivo)
              }
            } else {
              FilesHelper.blobDownload(
                data.registros.registro.archivo,
                'pdf',
                data.registros.registro.nombre_archivo,
              )
            }
          })
          .catch(() => {
            enqueueSnackbar('La petición ha fallado', { variant: 'error' })
          })
          .finally(() => {
            handleModal({ type: ModalTypes.Loading, enabled: false })
          })

        break
      default:
        break
    }
  }

  function redirect(filtersToAply) {
    active.setCurrent({
      nombre_informe: plugin.informe_llamado,
      nombre: plugin.descripcion,
      isNestedReport: true,
    })
    active.setActiveFilter(filtersToAply)
    active.setFilterData(filtersToAply)
    navigate('/informes')
    setRedirected(true)
  }

  const click = async () => {
    onClick()
    const filtersToAply = {}

    plugin.filtros.forEach((value) => {
      let position = value.parametro
      var originalName = value.parametro
      if (originalName.startsWith('F_')) {
        position = position.slice(2)
      }

      filtersToAply[position] =
        value.tipo_filtro === 'C' ? value.valor_parametro : element[value.valor_parametro]
    })

    if (plugin.informe_llamado) {
      redirect(filtersToAply)
    }
    if (plugin.report_llamado) {
      handleModal({ enabled: true, type: ModalTypes.Loading })
      InformesHelper.getReport(plugin.report_llamado, filtersToAply)
        .then(({ fichero, nombre }) => FilesHelper.blobDownload(fichero, 'pdf', nombre))
        .finally(() => {
          handleModal({ enabled: false, type: ModalTypes.Loading })
        })
    }
    if (plugin.programa_llamado) {
      callProgram(filtersToAply)
    }
  }

  return <MenuItem onClick={click}>{plugin.descripcion}</MenuItem>
}

const InformesActionButton = ({ plugins, element }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  function handleClose() {
    setAnchorEl(null)
  }
  return (
    <>
      <IconButton
        sx={{ p: 0, width: '30px', height: '30px' }}
        onClick={(event) => {
          setAnchorEl(event.target)
        }}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {plugins.map((item, idx) => {
          return (
            <InformePluginButton key={idx} plugin={item} element={element} onClick={handleClose} />
          )
        })}
      </Menu>
    </>
  )
}

export default InformesActionButton
