import { Button, LinearProgress, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { CustomModalContext } from 'common/contexts/CustomModalContext'
import { enqueueSnackbar } from 'notistack'
import { useContext, useState } from 'react'
import { DocumentMarkType, markDocumentAsReaded } from '../libs/markDocumentAsReaded'

const ConfirmReadModal = ({
  document: displayDocument,
  SetNeedsReading,
  documentType = DocumentMarkType.Corporate,
}) => {
  const { handleModal } = useContext(CustomModalContext)
  const [isMarking, setIsMarking] = useState(false)
  return (
    <Box
      sx={{
        transition: '.3s',
        minWidth: '30%',
        width: {
          xs: '95%',
          md: '80%',
        },
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 1,
      }}
    >
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Marcar como leido
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        ¿Está seguro de que desea marcar como leído este documento?
        <br />
        <b>Con esta acción está reconociendo haber leído el documento completo.</b>
      </Typography>
      {isMarking ? <LinearProgress /> : <></>}
      <Box
        sx={{
          display: 'flex',
          gap: '1em',
          width: '100%',
          justifyContent: 'flex-end',
          pt: 1,
        }}
      >
        <Button
          disabled={isMarking}
          onClick={async () => {
            try {
              setIsMarking(true)
              const data = await markDocumentAsReaded(displayDocument.id, displayDocument.tipo_doc)
              displayDocument.obligatorio = false
              setIsMarking(false)
              console.log(data)
              if (SetNeedsReading) SetNeedsReading(true, data.fecha)
              handleModal({ enabled: false })
            } catch (error) {
              enqueueSnackbar('No se pudo marcar como leido el documento', {
                variant: 'error',
              })
              setIsMarking(false)
            }
          }}
          variant="contained"
        >
          Si
        </Button>
        <Button
          disabled={isMarking}
          onClick={() => {
            handleModal({ enabled: false })
          }}
          variant="outlined"
        >
          No
        </Button>
      </Box>
    </Box>
  )
}

export default ConfirmReadModal
