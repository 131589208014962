// @ts-check
import { Circle, Marker, Popup, MapContainer, TileLayer } from 'react-leaflet'
import { useEffect, useState } from 'react'
import ThemeManager from 'theming/ThemeManager'
import { Box } from '@mui/system'
import { CircularProgress } from '@mui/material'
import { Icon } from 'leaflet'
import '../test.css'
import 'leaflet/dist/leaflet.css'

/**
 * @module ControlPresencia
 */

/** @type {import('leaflet').LatLngTuple}*/
const START_POS = [0, 0]

const CustomMap = ({ forced_position = null, forced_accuracy = null, zoom = null }) => {
  const [position, setPosition] = useState(START_POS)
  const [geolocation, setGeoLocation] = useState(null)
  const [accuracy, setAccuracy] = useState(1000)
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (geoPosition) => {
          /**@type {import('leaflet').LatLngExpression} */
          const newPosition = [1, 1]
          newPosition[0] = geoPosition.coords.latitude
          newPosition[1] = geoPosition.coords.longitude
          // @ts-ignore
          setGeoLocation(geoPosition)
          setAccuracy(geoPosition.coords.accuracy)
          // @ts-ignore
          setPosition(newPosition)
          // map.setView(newPosition, 15)
        },
        (error) => {
          throw error
        },
      )
    }
  }, [])
  return geolocation ? (
    <MapComponent
      position={forced_position ?? position}
      zoom={zoom ?? 15}
      radius={forced_accuracy ?? accuracy}
    />
  ) : (
    <Box sx={{ width: '100%', height: '100%', display: 'flex', placeItems: 'center' }}>
      <CircularProgress />
    </Box>
  )
}

/**
 *
 * @component
 * @param {Object} props
 * @param {import('leaflet').LatLngTuple} props.position - The senter location
 * @param {number} [props.zoom=15] - The zoom of the map
 * @param {number} props.radius - The radius of the circle. You should use the accuracy of the location
 * @returns {JSX.Element}
 */
const MapComponent = ({ position, zoom = 15, radius }) => {
  const Theme = new ThemeManager()
  const marker_icon = new Icon({
    iconUrl: '/marker-icon.png',
    // iconAnchor: position,
    className: 'map-test',
    iconSize: [25, 41],
  })
  return (
    <MapContainer
      className="map"
      center={position}
      zoom={zoom}
      minZoom={zoom}
      maxZoom={zoom}
      scrollWheelZoom={false}
      dragging={false}
      zoomControl={false}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Circle center={position} radius={radius} color={Theme.pallete.primary.main} />
      <Marker icon={marker_icon} position={position}>
        <Popup>
          {position[0]} {position[1]}
        </Popup>
      </Marker>
    </MapContainer>
  )
}

export default CustomMap
