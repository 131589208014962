import { CheckRounded, Download } from '@mui/icons-material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Modal,
  Typography,
} from '@mui/material'
import { CustomModalContext } from 'common/contexts/CustomModalContext'
import { ModalTypes } from 'common/hooks/useCustomModal'
import FilesHelper from 'common/libs/Files'
import { getFechaFromISO, getLocaleFromFullDate } from 'common/libs/fechas'
import { enqueueSnackbar } from 'notistack'
import { useContext, useState } from 'react'
import { markDocumentAsReaded } from '../libs/markDocumentAsReaded'
import ConfirmReadModal from './ConfirmReadModal'
import DocumentCard from './DocumentCard'

/**
 * Component that renders and download a document
 *
 * @param {Object} props
 * @param {DocumentacionDto} props.document - The document to show
 */
const DocumentacionCard = ({ document }) => {
  const [isMarking, setIsMarking] = useState(false)
  const [displayDocument, setDisplayDocument] = useState(document)
  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false)
  const [NeedReading, SetNeedsReading] = useState(
    displayDocument.lectura_obligatoria && !document.descargado,
  )

  const { handleModal } = useContext(CustomModalContext)
  function markedCallback(_, date) {
    SetNeedsReading(false)
    setDisplayDocument({ ...displayDocument, descargado: date, obligatorio: true })
  }

  return (
    <>
      <DocumentCard
        document_id={displayDocument.id}
        document_type={displayDocument.tipo_doc}
        document_url={displayDocument.url}
        MIMEType={displayDocument.mime_type}
        forced={displayDocument.lectura_obligatoria}
        title={displayDocument.titulo}
        downloaded={displayDocument.descargado}
        readDate={displayDocument.descargado}
        subtitle={displayDocument.d_categoria}
        chip={displayDocument.d_tipo}
        actionsSecondary={
          <>
            <Button
              onClick={() => {
                handleModal({
                  enabled: true,
                  content: (
                    <Card>
                      <CardHeader title={displayDocument.titulo} />
                      <CardContent>
                        <Box
                          dangerouslySetInnerHTML={{ __html: displayDocument?.observaciones ?? '' }}
                        />
                      </CardContent>
                      <CardActions>
                        <Button
                          disabled={isMarking}
                          onClick={() => {
                            handleModal({ enabled: false })
                          }}
                          variant="text"
                        >
                          Cerrar
                        </Button>
                      </CardActions>
                    </Card>
                  ),
                  type: ModalTypes.Custom,
                })
              }}
              startIcon={<InfoOutlinedIcon />}
            >
              Info.
            </Button>
            {NeedReading ? (
              <Button
                onClick={() => {
                  handleModal({
                    enabled: true,
                    content: (
                      <ConfirmReadModal
                        document={displayDocument}
                        SetNeedsReading={markedCallback}
                      />
                    ),
                    type: ModalTypes.Custom,
                  })
                }}
                endIcon={<CheckRounded />}
              >
                Leído
              </Button>
            ) : (
              <></>
            )}
          </>
        }
        actionsPrimary={
          <>
            {displayDocument.id && displayDocument.tipo_doc && document.tipo_doc > 0 ? (
              <Button
                onClick={async () => {
                  handleModal({ type: ModalTypes.Loading, enabled: true })
                  await FilesHelper.downloadFile(
                    displayDocument.id,
                    document.tipo_doc,
                    document.url,
                  ).finally(() => {
                    const date = new Date().toISOString()
                    setDisplayDocument({ ...displayDocument, descargado: date })
                    handleModal({ type: ModalTypes.Loading, enabled: false })
                  })
                }}
                startIcon={<Download />}
              >
                {displayDocument.mime_type.toUpperCase()}
              </Button>
            ) : (
              <></>
            )}
          </>
        }
        content={
          <List sx={{ width: '100%' }} dense={true}>
            <ListItem>
              <ListItemText
                primary={getFechaFromISO(displayDocument.fecha_publicacion)}
                secondary="Fecha publicación"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  !displayDocument.descargado
                    ? 'Pendiente de descarga'
                    : `Descargado el ${getLocaleFromFullDate(displayDocument.descargado)}`
                }
                secondary="Fecha descarga"
              />
            </ListItem>
          </List>
        }
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            transition: '.3s',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            minWidth: '30%',
            width: {
              xs: '95%',
              md: '30%',
            },
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Marcar como leído
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            ¿Está seguro de que desea marcar como leído este documento?
            <br />
            <b>Con esta acción está reconociendo haber leído el documento completo.</b>
          </Typography>
          {isMarking ? <LinearProgress /> : <></>}
          <Box
            sx={{
              display: 'flex',
              gap: '1em',
              width: '100%',
              justifyContent: 'flex-end',
              pt: 1,
            }}
          >
            <Button
              disabled={isMarking}
              onClick={async () => {
                try {
                  setIsMarking(true)
                  await markDocumentAsReaded(displayDocument.id, displayDocument.tipo_doc)
                  SetNeedsReading(false)
                  setDisplayDocument({
                    ...displayDocument,
                    obligatorio: false,
                  })
                  displayDocument.obligatorio = false
                  setIsMarking(false)
                  handleClose()
                } catch (error) {
                  enqueueSnackbar('No se pudo marcar como leido el documento', {
                    variant: 'error',
                  })
                  setIsMarking(false)
                }
              }}
              variant="contained"
            >
              Sí
            </Button>
            <Button disabled={isMarking} onClick={handleClose} variant="outlined">
              No
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default DocumentacionCard
