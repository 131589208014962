import { ReactComponent as MainLogo } from 'assets/images/logo.svg'
import ThemeManager from 'theming/ThemeManager'

const themeController = new ThemeManager()

/** @type {import('common/types/logos_types').LogoComponents} */
const logos = {
  Large: MainLogo,
  NavBar: MainLogo,
  Header: MainLogo,
}

if (themeController?.logos) {
  Object.keys(themeController.logos).forEach((key) => {
    if (themeController.logos[key]) logos[key] = themeController.logos[key]
  })
}

export default logos
