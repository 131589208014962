import axios from 'api/axios'
import FilesHelper from 'common/libs/Files'

const downloadNewsFile = async (id) => {
  try {
    /** @type {import('axios').AxiosResponse<NewsMediaFile>} */
    const response = await axios.get(`/noticias/archivo/${id}`)
    FilesHelper.blobDownload(
      response.data.fichero,
      response.data.mime_type,
      response.data.nombre_archivo,
    )
  } catch (error) {
    throw error
  }
}

export default downloadNewsFile
