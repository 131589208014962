// @ts-check
/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'api/axios'
import { useState, useEffect, useCallback } from 'react'

/**
 * @module FetchHooks
 */

/**
 * @module Notifications
 */

/**
 *
 * @param {Object} options
 * @param {number} [options.page=1] - The pagination page to fetch
 * @param {boolean} [options.all=false] - Get all of the notifications if false will only retrieve the unread ones
 * @param {number} [options.page_size=12] - The pagination size
 * @param {number} [options.iteration=0] - Parameter to force the relaunch of the query
 * @example
 * ...
 * const {response, loading, error} = useFetchNotifications()
 * // Or you can pass some options
 * const {response, loading, error} = useFetchNotifications({
 *     page = 1, all = false, page_size = 12
 * })
 * ...
 * @returns {import('common/types/connections_types').UseFetchHookResponse<NotificationsResponse | undefined>}
 */
const useFetchNotifications = ({ page = 1, all = false, page_size = 12, iteration = 0 }) => {
  const [response, setResponse] = useState()
  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)

  const fetchData = useCallback(() => {
    if (loading) return
    setLoading(true)
    setError(undefined)

    axios
      .get(`/notificaciones?page_size=${page_size ?? ''}&t=${all ? 'S' : 'N'}&page=${page}`)
      .then((request_response) => {
        const data = request_response.data
        setResponse(data)
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
  }, [page, all, page_size, iteration])

  useEffect(() => {
    if (!loading) fetchData()
  }, [page, all, page_size, iteration])

  return { response, loading, error }
}

export default useFetchNotifications
