/**
 * @module Informes
 */

import axios from 'api/axios'

/**
 * A helper class for fetching informes and processing data.
 */
class InformesHelper {
  /**
   * Fetches informe data based on provided options.
   * @async
   * @param {Object} options - The options for fetching the informe.
   * @param {string} options.nombre_informe - The name of the informe to fetch.
   * @param {("DATA"|"XLS"|"PDF")} options.tipo_salida - The type of output ('DATA' or 'XLS').
   * @param {number} [options.start=1] - The starting index for fetching informe data.
   * @param {number} [options.end=9999] - The ending index for fetching informe data.
   * @param {object} options.filter - The filter object to be applied.
   * @returns {Promise<any>} A promise that resolves with the fetched informe data.
   * @throws {Error} Throws an error if there is an issue fetching the informe data.
   */
  static async fetchInforme({
    nombre_informe,
    tipo_salida = 'XLS',
    start = 1,
    end = 9999,
    filter,
  }) {
    try {
      const options = {
        informe: nombre_informe,
        filtros: filter,
        inicio: start,
        fin: end,
      }

      if (tipo_salida !== 'DATA') {
        options['tipo_salida'] = tipo_salida
      }

      const { data } = await axios.post(`informes/datos/`, options)
      return data
    } catch (error) {
      throw new Error(`Error fetching informe data: ${error.message}`)
    }
  }
  /**
   * Retrieves e-factura data based on the provided parameters.
   * @param {Object} params - Parameters for retrieving e-factura data.
   * @param {(number|string)} params.ejercicio - The fiscal year of the e-factura.
   * @param {(number|string)} params.numero_serie - The serial number of the e-factura.
   * @param {(number|string)} params.numero_factura - The invoice number of the e-factura.
   * @returns {Promise<Object>} A promise that resolves to the e-factura data.
   * @throws {Error} If an error occurs while fetching the e-factura data.
   */
  static async obtenerEfactura({ ejercicio, numero_serie, numero_factura }) {
    try {
      const { data } = await axios.post('informes/efactura/', {
        ejercicio,
        numero_serie,
        numero_factura,
      })
      return data
    } catch (error) {
      throw new Error(`Error fetching informe data: ${error.message}`)
    }
  }

  /**
   * @async
   * @static
   * Retrieves a report based on the specified module and filter criteria.
   * @param {string} module - The module for which the report is generated.
   * @param {Object.<string, any>} filter - The filter criteria to apply to the report.
   *                                         An object where keys are filter names and values are filter values.
   * @returns {Promise<Object>} A promise that resolves to the report data.
   * @throws {Error} If an error occurs while fetching the report data.
   */
  static async getReport(module, filter) {
    try {
      /** @type {{ nombre: string, valor: any }[]} */
      const filters = []
      // Convert filter object into an array of filter objects
      Object.keys(filter).forEach((key) => {
        filters.push({ nombre: key, valor: filter[key] })
      })
      const { data } = await axios.post('informes/report/', { modulo: module, parametros: filters })
      return data
    } catch (error) {
      throw new Error(`Error fetching report data: ${error.message}`)
    }
  }

  /**
   *
   * @return {Promise<InformesLOV>}
   */
  static async getListOfValues(informe, list_of_values) {
    try {
      const { data } = await axios.get('lovs/', {
        params: {
          informe,
          lista_valores: list_of_values,
        },
      })
      return data
    } catch (error) {
      throw error
    }
  }
}
export default InformesHelper
