import { Menu } from '@mui/material'
import { Box } from '@mui/system'

/**
 * Element to use as a holder for the custom menus
 * @component
 *
 * @param {Object} props
 * @param {JSX.Element} props.Child - The Actual Menu to display
 * @param {Element} props.anchorEl - The anchor Elemenent if it's is defined then the menu will be oppened
 * @param {function} [props.triggerClose] - The function to close the menu
 * @param {import('@mui/material').SxProps} [props.sx]
 * @param {import('@mui/material').SxProps} [props.boxSx]
 * @param {import('@mui/material').PopoverOrigin} [props.anchorOrigin]
 * @param {import('@mui/material').PopoverOrigin} [props.transformOrigin]
 * @returns {JSXElementConstructor}
 */
const CustomPopup = ({
  Child,
  anchorEl,
  triggerClose = () => {},
  sx = {},
  anchorOrigin = {
    vertical: 'top',
    horizontal: 'right',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'right',
  },
  boxSx = { p: 1 },
}) => {
  return (
    <Menu
      id="common_menu"
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      sx={{
        ...sx,
      }}
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={triggerClose}
      MenuListProps={{
        'aria-labelledby': 'common_popup',
      }}
    >
      <Box sx={boxSx}>
        <Child closePopup={triggerClose} />
      </Box>
    </Menu>
  )
}

export default CustomPopup
