// @ts-check
import axios from 'api/axios'
const { useState, useEffect } = require('react')

/**
 * @module FetchHooks
 */

/**
 * Hook that fetch the details of a news
 * @function
 * @param {string} [id]
 * @link http://localhost:8000/api/v1/noticias/:id
 * @returns {import('common/types/connections_types').UseFetchHookResponse<NewsDetailsDTO>}
 */
const useFetchNewsDetails = (id) => {
  const [response, setResponse] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const fetchData = () => {
    if (loading || !id) {
      return
    }
    setLoading(true)
    axios
      .get(`/noticias/${id}`)
      .then((/**@type {import('axios').AxiosResponse<NewsDetailsDTO>}*/ request_response) => {
        const data = request_response.data
        // @ts-ignore
        setResponse(data)
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (!loading) fetchData()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])
  // @ts-ignore
  return { response, error, loading }
}

export default useFetchNewsDetails
