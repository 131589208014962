//@ts-check
import axios from 'api/axios'
const { useState, useEffect } = require('react')

/**
 * @module FetchHooks
 */

/**
 * Hook that fetch the the FAQ
 * @function
 * {@link http://localhost:8000/api/v1/preguntas}
 * @returns {import('common/types/connections_types').UseFetchHookResponse<QuestionDTOArray>}
 */
const useFetchFAQ = () => {
  const [response, setResponse] = useState([])
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const fetchData = () => {
    if (loading) {
      return
    }
    setLoading(true)
    axios
      .get('/preguntas')
      .then((/**@type {import('axios').AxiosResponse<QuestionDTOArray>}*/ request_response) => {
        const data = request_response.data
        setResponse(data)
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (!loading) fetchData()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { response, error, loading }
}

export default useFetchFAQ
