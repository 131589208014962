import { useState, useEffect } from 'react'

import { styled } from '@mui/material/styles'
import MuiAccordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import useFetchFAQ from '../hooks/useFetchFAQ'
import FaqController from '../libs/FaqController'
import { enqueueSnackbar } from 'notistack'

const Accordion = styled((props) => <MuiAccordion square {...props} />)(({ theme }) => ({
  '& .MuiAccordionSummary-content': {
    flexDirection: 'column',
  },
}))

const VisorPreguntas = () => {
  const { response } = useFetchFAQ()
  const [preguntas, setPreguntas] = useState(response)

  useEffect(() => setPreguntas(response), [response])

  const [expanded, setExpanded] = useState(false)
  const [pregunta, setPregunta] = useState('')

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const handlePregunta = (e) => {
    setPregunta(e.target.value)
  }

  const handleClick = async () => {
    const newQuestion = await FaqController.create(pregunta)
    if (newQuestion) {
      enqueueSnackbar('Pregunta creada satisfactoriamente', { variant: 'success' })
      setPreguntas([...preguntas, newQuestion])
    } else {
      enqueueSnackbar('Ha ocurrido un error y no se pudo guardar la pregunta', {
        variant: 'error',
      })
    }
    setPregunta('')
  }

  return (
    <div>
      <TextField
        sx={{ width: '100%', mb: 2 }}
        id="pregunta"
        label="Escribe tu pregunta"
        variant="standard"
        value={pregunta}
        onChange={handlePregunta}
      />
      {pregunta !== '' && pregunta.length > 5 ? (
        <Stack>
          <Typography>Si no encuentras respuesta a tu pregunta puedes</Typography>
          <Button variant="contained" sx={{ mb: 2 }} onClick={handleClick}>
            Realizar pregunta
          </Button>
        </Stack>
      ) : null}
      {preguntas?.length === 0 ? (
        <Typography variant="body1">No hay preguntas, haz la tuya.</Typography>
      ) : (
        preguntas?.map((p) => {
          return pregunta.split(' ').some((v) => p.texto_pregunta.includes(v)) ? (
            <Accordion key={p.id} expanded={expanded === p.id} onChange={handleChange(p.id)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography variant="body1">{p.texto_pregunta}</Typography>
                <Typography variant="body2">
                  {p.usuario_pregunta} · {p.fecha_pregunta}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{p.texto_respuesta}</Typography>
                <Typography>
                  {p.usuario_respuesta} · {p.fecha_respuesta}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ) : null
        })
      )}
    </div>
  )
}

export default VisorPreguntas
