import { enqueueSnackbar } from 'notistack'
import axios from 'api/axios'

const { routeIsActive } = require('features/routing/libs/routeIsActive')

/**
 * Notify the user if he has unreaded documents
 *
 * @param {Array<MenuDto>} menus
 * @param {NavigateFunction} navigate
 */

async function notifyUnreadedComunicados(menus, navigateTo) {
  const isRouteActive = routeIsActive('/comunicados', menus)
  if (!isRouteActive) return
  try {
    const response = await axios.get('/comunicados/obligatorios')
    if (response?.data?.length > 0) {
      const length = response.data.length
      enqueueSnackbar(`Tiene ${length} comunicados de obligada lectura sin leer`, {
        variant: 'warning',
        SnackbarProps: {
          style: {
            cursor: 'pointer',
          },
          onClick: () => navigateTo('/comunicados'),
        },
        autoHideDuration: 10000,
        action: <></>,
      })
    }
  } catch (error) {}
}

export default notifyUnreadedComunicados
