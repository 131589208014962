import SaveAltIcon from '@mui/icons-material/SaveAlt'
import {
  Box,
  Button,
  FormControl,
  FormGroup,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { CustomModalContext } from 'common/contexts/CustomModalContext'
import { ModalTypes } from 'common/hooks/useCustomModal'
import { getLocaleFromFullDate } from 'common/libs/fechas'
import FilesHelper from 'common/libs/Files'
import dayjs from 'dayjs'
import { enqueueSnackbar } from 'notistack'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import useFetchGruposGastos from '../hooks/useFetchGruposGastos'
import useFetchOptionsHojasGastoLin from '../hooks/useFetchOptionsHojasGastoLin'
import { editHojaGastoLin } from '../lib/HojasGastoLinController'
import { deleteHojaGastoLinDoc } from '../lib/HojasGastoLinDocController'

export const LinEditModalBody = ({
  id_hoja,
  updateState,
  editObject = null,
  fechaInicio,
  fechaFin,
}) => {
  const [fecha, setFecha] = useState({})
  const [grupoGastos, setGrupoGastos] = useState('')
  const [grupoGastosArrayPosition, setGrupoGastosArrayPosition] = useState(0)
  const [concepto, setConcepto] = useState('')
  const [conceptArrayPosition, setConceptArrayPosition] = useState(0)
  const [descripcion, setDescripcion] = useState('')
  const [observaciones, setObservaciones] = useState('')
  const [cantidad, setCantidad] = useState('1')
  const [precio, setPrecio] = useState('0')
  const [importe, setImporte] = useState(0)
  const [documents, setDocuments] = useState([])

  const { handleModal, previousContent } = useContext(CustomModalContext)

  const [editError, setEditError] = useState(false)
  const [fechaError, setFechaError] = useState('')
  const [grupoGastoError, setGrupoGastoError] = useState('')
  const [documentsError, setDocumentsError] = useState('')
  const [descripcionError, setDescripcionError] = useState('')
  const [conceptoError, setConceptoError] = useState('')
  const [precioError, setPrecioError] = useState('')
  const [cantidadError, setCantidadError] = useState('')

  const {
    response: grupos_gastos,
    error: grupos_gastos_error,
    loading: grupos_gastos_loading,
  } = useFetchGruposGastos()

  const { response, loading } = useFetchOptionsHojasGastoLin()

  useEffect(() => {
    if (!editObject) return
    setFecha(dayjs(editObject.fecha + 'T00:00:00'))
    setGrupoGastos(editObject.grupo_gastos)
    setDescripcion(editObject.descripcion)
    if (editObject.observaciones) setObservaciones(editObject.observaciones)
    setPrecio(editObject.precio)
    setCantidad(editObject.cantidad)
  }, [editObject])

  useEffect(() => {
    if (!grupos_gastos || !response) return
    let grupoGastoPosition

    for (let i = 0; i < grupos_gastos.length; i++) {
      if (grupos_gastos[i].codigo === grupoGastos) {
        grupoGastoPosition = i
        break
      }
    }
    setGrupoGastosArrayPosition(grupoGastoPosition)
    let concept
    let arrayPosition
    for (let i = 0; i < grupos_gastos[grupoGastoPosition]?.conceptos.length; i++) {
      if (grupos_gastos[grupoGastoPosition]?.conceptos[i].concepto == editObject.concepto) {
        concept = grupos_gastos[grupoGastoPosition].conceptos[i].concepto
        arrayPosition = i
        break
      }
    }
    if (concept !== undefined && arrayPosition !== undefined) {
      setConcepto(concept)
      setConceptArrayPosition(arrayPosition)
    }
  }, [grupos_gastos, response])

  const handleDownloadClick = async ({ url }) => {
    try {
      FilesHelper.downloadFile(1, 0, url)
    } catch (error) {
      console.log(error.message)
    }
  }
  const handleClick = async () => {
    try {
      let error = false

      if (Object.keys(fecha).length < 1) {
        error = true
        setFechaError('La fecha es obligatoria')
      } else {
        if (
          new Date(fechaInicio + 'T00:00:00') <= new Date(fecha) &&
          new Date(fecha) <= new Date(fechaFin + 'T00:00:00')
        ) {
          setFechaError('')
        } else {
          error = true
          setFechaError(
            'La fecha debe estar entre ' +
              getLocaleFromFullDate(fechaInicio, false) +
              ' y ' +
              getLocaleFromFullDate(fechaFin, false),
          )
        }
      }
      if (!grupoGastos) {
        error = true
        setGrupoGastoError('El grupo de gastos es obligatorio')
      } else {
        setGrupoGastoError('')
      }

      if (
        grupos_gastos[grupoGastosArrayPosition].conceptos[conceptArrayPosition].obligar_adjunto ===
          'S' &&
        documents.length < 1 &&
        editObject.documentos.length < 1
      ) {
        error = true
        setDocumentsError('Para este concepto los documentos son obligatorios')
      } else {
        setDocumentsError('')
      }

      if (!descripcion) {
        error = true
        setDescripcionError('La descripción es obligatoria')
      } else {
        setDescripcionError('')
      }

      if (!concepto) {
        setConceptoError('El concepto es obligatorio')
        error = true
      } else {
        setConceptoError('')
      }

      if (parseFloat(precio) <= 0) {
        error = true
        setPrecioError('El precio no puede ser menor o igual a cero')
      } else {
        setPrecioError('')
      }
      if (cantidad <= 0) {
        error = true
        setCantidadError('La cantidad no puede ser menor o igual a cero')
      } else {
        setCantidadError('')
      }

      if (error) {
        setEditError(true)
        return
      } else {
        setEditError(false)
      }

      const res = await editHojaGastoLin({
        id_hoja: parseInt(id_hoja),
        id_lin: editObject.id_lin,
        fecha: fecha.format('YYYY-MM-DD'),
        grupoGastos: grupoGastos,
        concepto,
        descripcion,
        cantidad: parseInt(cantidad),
        precio: parseFloat(precio),
        observaciones,
        documentos: documents,
      })
      updateState(res)
      enqueueSnackbar('Línea editada correctamente', { variant: 'success' })
      handleModal({ enabled: false })
    } catch (error) {
      enqueueSnackbar('No se ha podido editar la línea')
      console.log(error.message)
    }
  }

  useEffect(() => {
    if (cantidad && precio) {
      setImporte(parseFloat(cantidad) * parseFloat(precio))
    } else {
      setImporte(0)
    }
  }, [cantidad, precio])

  useEffect(() => {
    if (!concepto) return
    let position = 0
    for (let i = 0; i < grupos_gastos.length; i++) {
      for (let j = 0; j < grupos_gastos[i].conceptos.length; j++) {
        if (grupos_gastos[i]?.conceptos[j]?.concepto == concepto) {
          setConceptArrayPosition(j)
          position = j
          break
        }
      }
    }
    if (grupos_gastos[grupoGastosArrayPosition]?.conceptos[position]?.precio) {
      setPrecio(grupos_gastos[grupoGastosArrayPosition]?.conceptos[position]?.precio)
    } else {
      if (!concepto) {
        setPrecio(0)
      } else {
        if (
          grupos_gastos[grupoGastosArrayPosition]?.conceptos[position]?.precio_maximo &&
          parseFloat(precio) >
            parseFloat(grupos_gastos[grupoGastosArrayPosition]?.conceptos[position]?.precio_maximo)
        ) {
          setPrecio(grupos_gastos[grupoGastosArrayPosition]?.conceptos[position]?.precio_maximo)
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [concepto])

  const convert = async (acceptedFiles) => {
    let doc = []
    for (let i = 0; i < acceptedFiles.length; i++) {
      const file = acceptedFiles[i]
      const result = await FilesHelper.fileToBase64(file)
      doc = [...doc, { nombre_archivo: result.name, data: result.base64 }]
    }
    return doc
  }

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const data = await convert(acceptedFiles)
      setDocuments([...documents, ...data])
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setDocuments],
  )
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 999,
  })
  const files = documents.map((file) => {
    return <li key={file.nombre_archivo}>{file.nombre_archivo}</li>
  })

  const handleDeleteDocumentClick = async ({ id_doc, delete_url }) => {
    try {
      const response = await deleteHojaGastoLinDoc({ id_doc, delete_url })
      if (!response) return
      editObject.documentos = editObject.documentos.filter((doc) => doc.id_doc !== id_doc)
    } catch (error) {
      console.log(error.message)
    }
  }

  return (
    <Box
      sx={{
        bgcolor: 'background.paper',
        border: '1px',
        borderRadius: '0.5rem',
        boxShadow: 24,
        p: 4,
        maxHeight: '90vh',
        overflow: { xs: 'scroll', md: 'initial' },
      }}
    >
      {!loading && !grupos_gastos_loading ? (
        <Grid
          container
          spacing={2}
          sx={{
            textAlign: 'center',
            placeItems: 'center',
          }}
        >
          <Grid item xs={12}>
            {editError ? (
              <Typography sx={{ color: 'red', width: '100%' }}>
                Algunos datos son incorrectos
              </Typography>
            ) : null}
          </Grid>
          <Grid item xs={12} md={6}>
            <DatePicker
              format="YYYY-MM-DD"
              label="Fecha Inicio"
              value={fecha}
              error={fechaError ? true : false}
              onChange={(e) => setFecha(e)}
              sx={{ width: '100%' }}
            />
            {fechaError ? (
              <Typography sx={{ color: 'red', width: '100%' }}>{fechaError}</Typography>
            ) : null}
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl sx={{ width: '100%' }} error={grupoGastoError ? true : false}>
              <InputLabel>Grupo Gastos</InputLabel>
              <Select
                value={grupoGastos}
                label="Grupo Gastos"
                onChange={(e) => {
                  setGrupoGastos(e.target.value)
                  for (let i = 0; i < grupos_gastos.length; i++) {
                    if (grupos_gastos[i].codigo === e.target.value) {
                      setGrupoGastosArrayPosition(i)
                      break
                    }
                  }
                  setConcepto('')
                }}
              >
                {response?.grupos_gastos?.map((grupo) => (
                  <MenuItem value={grupo.codigo} key={grupo.id}>
                    {grupo.nombre}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {grupoGastoError ? (
              <Typography sx={{ color: 'red', width: '100%' }}>{grupoGastoError}</Typography>
            ) : null}
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl sx={{ width: '100%' }} error={conceptoError ? true : false}>
              <InputLabel>Concepto</InputLabel>
              <Select
                value={concepto}
                label="Concepto"
                onChange={(e) => setConcepto(e.target.value)}
              >
                {grupos_gastos[grupoGastosArrayPosition]?.conceptos.map((concepto) => (
                  <MenuItem value={concepto.concepto} key={concepto.concepto}>
                    {concepto.nombre}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {conceptoError ? (
              <Typography sx={{ color: 'red', width: '100%' }}>{conceptoError}</Typography>
            ) : null}
          </Grid>
          <Grid item xs={12} md={6}>
            <FormGroup required sx={{ width: '100%' }}>
              <TextField
                label="Descripcion"
                variant="outlined"
                value={descripcion}
                type="textarea"
                onChange={(e) => setDescripcion(e.target.value)}
              />
            </FormGroup>
            {descripcionError ? (
              <Typography sx={{ color: 'red', width: '100%' }}>{descripcionError}</Typography>
            ) : null}
          </Grid>
          <Grid item xs={12} md={6}>
            <FormGroup sx={{ width: '100%' }}>
              <TextField
                label="Observaciones"
                variant="outlined"
                value={observaciones}
                type="textarea"
                onChange={(e) => setObservaciones(e.target.value)}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormGroup sx={{ width: '100%' }}>
              <TextField
                error={cantidadError ? true : false}
                label="Cantidad"
                variant="outlined"
                value={cantidad}
                type="number"
                disabled={!response.max_importe_control}
                onChange={(e) => setCantidad(e.target.value)}
              />
            </FormGroup>
            {cantidadError ? (
              <Typography sx={{ color: 'red', width: '100%' }}>{cantidadError}</Typography>
            ) : null}
          </Grid>
          <Grid item xs={12} md={6}>
            <FormGroup sx={{ width: '100%' }}>
              <TextField
                error={precioError ? true : false}
                label="Precio"
                variant="outlined"
                value={precio}
                type="number"
                disabled={
                  grupos_gastos[grupoGastosArrayPosition]?.conceptos[conceptArrayPosition]?.precio
                    ? true
                    : false
                }
                onChange={(e) => {
                  if (!e.target.value) {
                    setPrecio(e.target.value)
                    return
                  }
                  if (
                    !grupos_gastos[grupoGastosArrayPosition]?.conceptos[conceptArrayPosition]
                      ?.precio_maximo ||
                    parseFloat(
                      grupos_gastos[grupoGastosArrayPosition]?.conceptos[conceptArrayPosition]
                        ?.precio_maximo,
                    ) >= parseFloat(e.target.value)
                  ) {
                    setPrecio(e.target.value)
                  }
                }}
              />
            </FormGroup>
            {precioError ? (
              <Typography sx={{ color: 'red', width: '100%' }}>{precioError}</Typography>
            ) : null}
          </Grid>
          <Grid item xs={12} md={6}>
            <FormGroup sx={{ width: '100%' }}>
              <TextField
                label="Importe"
                variant="outlined"
                value={importe}
                type="number"
                disabled
                sx={{ width: '100%' }}
                onChange={(e) => setImporte(e.target.value)}
              />
            </FormGroup>
          </Grid>
          {editObject.documentos.length > 0 ? (
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                {editObject.documentos.map((doc) => (
                  <Box key={doc.id_doc}>
                    <Button onClick={() => handleDownloadClick({ url: doc.url })}>
                      {doc.descripcion}
                    </Button>
                    <Button
                      disabled={
                        grupos_gastos[grupoGastosArrayPosition]?.conceptos[conceptArrayPosition]
                          ?.obligar_adjunto === 'S' && editObject?.documentos?.length < 2
                          ? true
                          : false
                      }
                      onClick={() => {
                        handleModal({
                          type: ModalTypes.Confirm,
                          content: 'Se procederá a borrar el documento indicado, está seguro?',
                          enabled: true,
                          reRenderParent: true,
                          closable: true,
                          confirmDialogButtons: [
                            <Box
                              sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                justifyContent: 'center',
                                textAlign: 'center',
                                gap: '5px',
                              }}
                            >
                              <Button
                                variant="contained"
                                onClick={() => {
                                  handleDeleteDocumentClick({
                                    id_doc: doc.id_doc,
                                    delete_url: doc.delete_url,
                                  })
                                  handleModal({
                                    content: previousContent,
                                    closable: true,
                                    enabled: true,
                                    type: ModalTypes.Custom,
                                  })
                                }}
                              >
                                Aceptar
                              </Button>
                              <Button
                                variant="contained"
                                sx={{
                                  backgroundColor: 'red',
                                  ':hover': { backgroundColor: 'darkred' },
                                }}
                                onClick={() =>
                                  handleModal({
                                    content: previousContent,
                                    closable: true,
                                    enabled: true,
                                    type: ModalTypes.Custom,
                                  })
                                }
                              >
                                Rechazar
                              </Button>
                            </Box>,
                          ],
                        })
                      }}
                    >
                      Eliminar
                    </Button>
                  </Box>
                ))}
              </Box>
            </Grid>
          ) : null}

          <Grid item xs={12}>
            <Stack spacing={2} sx={{ width: '100%' }}>
              <Box
                component="span"
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  border: '2px dashed',
                  backgroundColor: 'background.default',
                  overflow: 'scroll',
                  width: '100%',
                }}
              >
                <div {...getRootProps({ className: 'dropzone' })}>
                  <input {...getInputProps()} />
                  <SaveAltIcon />
                  <p>Arrastre aquí ficheros o haga click para seleccionar</p>
                  <aside>
                    <h4>Ficheros</h4>
                    <ul>{files}</ul>
                  </aside>
                </div>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={() => setDocuments([])}
              sx={{
                backgroundColor: 'blue',
                width: '100%',
                ':hover': { backgroundColor: 'darkblue' },
              }}
            >
              Borrar documentos
            </Button>
            {documentsError ? (
              <Typography sx={{ color: 'red', width: '100%' }}>{documentsError}</Typography>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={handleClick}
              sx={{
                backgroundColor: 'green',
                width: '100%',
                ':hover': { backgroundColor: 'darkgreen' },
              }}
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      ) : (
        <LinearProgress sx={{ width: '200px' }} />
      )}
    </Box>
  )
}
