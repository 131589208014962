import axiosPE from 'api/axios'

export function convertirArchivosABase64(archivos) {
  const promesas = []
  for (let i = 0; i < archivos.length; i++) {
    const archivo = archivos[i]
    const promesa = new Promise((resolve, reject) => {
      const lector = new FileReader()
      lector.onload = () => {
        const contenidoBase64 = btoa(lector.result.toString())
        resolve(contenidoBase64)
      }
      lector.onerror = (error) => {
        reject(error)
      }
      lector.readAsBinaryString(archivo)
    })
    promesas.push(promesa)
  }
  return Promise.all(promesas)
}

export const descargarDocumento = async (tipo, id, view = false) => {
  const viewDocument = view
  try {
    const response = await axiosPE.get(
      `/descargararchivo/?t=${tipo}&f=${id}&v=${viewDocument ? 'S' : 'N'}`,
    )
    if (viewDocument) {
      return response.data
    }

    const binary = window.atob(response.data.fichero.replace(/\s/g, ''))
    const len = binary.length
    const buffer = new ArrayBuffer(len)
    const view = new Uint8Array(buffer)

    for (let i = 0; i < len; i += 1) {
      view[i] = binary.charCodeAt(i)
    }

    const blob = new Blob([view], { type: response.data.mime_type })
    const url = URL.createObjectURL(blob)

    const a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    a.href = url
    a.download = response.data.nombre_archivo

    a.click()
  } catch (err) {
    console.error(err)
  }
}
