import IconButton from '@mui/material/IconButton'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import FormControl from '@mui/material/FormControl'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import CircularProgress from '@mui/material/CircularProgress'
import { useCallback, useContext, useMemo, useState } from 'react'
import { InformesContext } from '../providers/InformesProvider'
import InformesHelper from '../helpers/InformesHelper'
import { CustomModalContext } from 'common/contexts/CustomModalContext'
import { ModalTypes } from 'common/hooks/useCustomModal'
import CustomTable from 'common/components/CustomTable'
import { Box } from '@mui/system'
import { Typography } from '@mui/material'

/**
 *
 * @param {Object} props
 * @param {InformeFilter} props.filter
 * @param {boolean} props.validating
 * @param {(newValue: any, pos: string) => void} props.changeFilter
 * @param {FiltersValuePosition} props.position
 */
const FilterListOfValues = ({ filter, validating, changeFilter, position }) => {
  const [loading, setLoading] = useState(false)
  const Context = useContext(InformesContext)
  const [text, setText] = useState('')
  const { handleModal } = useContext(CustomModalContext)

  const value = useMemo(
    () => Context.active.filterData[`${filter.nombre_filtro}${position}`] ?? '',
    [Context?.active?.filterData, filter?.nombre_filtro, position],
  )

  const getLov = useCallback(async () => {
    if (text) {
      setText('')
      changeFilter('', `${filter?.nombre_filtro}${position}`)
      return
    }
    setLoading(true)
    try {
      const response = await InformesHelper.getListOfValues(
        Context?.active?.current?.nombre_informe,
        filter?.lista_valores,
      )
      /** @type {CustomTableGridOptions[]} */
      const newStructure =
        response?.columnas?.map((item, index) => {
          return { key: item.etiqueta, value: `${item.tipo}${index + 1}`, flex: 1 }
        }) ?? []

      handleModal({
        type: ModalTypes.Custom,
        enabled: true,
        closable: true,
        content: (
          <Box
            sx={{
              px: 1,
              pt: 1,
              width: '60vw',
              minWidth: '60vw',
              backgroundColor: 'background.default',
              '& .MuiDataGrid-toolbarContainer > .MuiTextField-root': {
                width: '100%',
              },
              '& .MuiDataGrid-toolbarContainer > button': {
                display: 'none !important',
                backgroundColor: 'red !important',
              },
            }}
          >
            <CustomTable
              onClickEvent={({ row: dataRow }, _, __) => {
                const tipo_columna =
                  response?.columnas[parseInt(response?.columna_retorno) - 1].tipo
                const newValue = dataRow[`${tipo_columna}1`] ?? ''
                const text = `${newValue} | ${dataRow[`${tipo_columna}2`]}` ?? ''
                setText(text)
                changeFilter(newValue, `${filter?.nombre_filtro}${position}`)
                handleModal({ enabled: false })
              }}
              showToolbar
              rowid="NUMERO_FILA"
              fields={newStructure}
              dataSource={response.registros}
            />
          </Box>
        ),
      })

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }, [Context?.active, filter.lista_valores, handleModal])

  const error = useMemo(
    () =>
      filter.obligatorio === 'S' &&
      validating &&
      !Context.active.filterData[`${filter.nombre_filtro}${position}`],
    [Context?.active?.filterData, position, filter?.obligatorio, filter?.nombre_filtro, validating],
  )

  return (
    <>
      <FormControl variant="standard" error={error} fullWidth>
        <InputLabel>{`${filter.titulo_columna} ${
          position.length > 0 ? position.slice(1) : position
        }`}</InputLabel>
        <Input
          type="text"
          value={text}
          disabled={loading}
          onChange={() => {
            return
          }}
          error={error}
          endAdornment={
            <InputAdornment position="end">
              <IconButton aria-label="abrir lista de valores" onClick={getLov}>
                {loading ? (
                  <CircularProgress size={20} />
                ) : text ? (
                  <ClearIcon color={error ? 'error' : ''} />
                ) : (
                  <SearchIcon color={error ? 'error' : ''} />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    </>
  )
}

export default FilterListOfValues
