import useFetchNews from '../hooks/useFetchWebNews'
import axios from 'api/axios'

/**
 * Class to manage the news CRUD
 * @class
 */
class NewsController {
  static get = useFetchNews

  /**
   * Function to create a news
   * @function
   * @param {NewsDTO} news
   * @returns {Promise<NewsDTO>}
   */
  static create = async (news) => {
    try {
      /** @type {import('axios').AxiosResponse<NewsDTO>}*/
      const response = await axios.post('/noticias/', news)
      return response.data
    } catch (_) {
      return null
    }
  }

  /**
   * Function to update a news
   * @function
   * @param {NewsDTO} news
   * @returns {Promise<NewsDTO>}
   */
  static update = async (news) => {
    try {
      /** @type {import('axios').AxiosResponse<LinksDTO>}*/
      const response = await axios.put(`/noticias/${news.id}/`, news)
      return response.data
    } catch (_) {
      return null
    }
  }

  /**
   * Function to delete a news
   * @function
   * @param {number} newsId
   * @returns {Promise<LinksDTO>}
   */
  static delete = async (newsId) => {
    try {
      /** @type {import('axios').AxiosResponse<LinksDTO>}*/
      const response = await axios.delete(`/noticias/${newsId}/`)
      return response.data
    } catch (_) {
      return null
    }
  }
}

export default NewsController
