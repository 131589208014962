import { Download } from '@mui/icons-material'
import { Button, Typography } from '@mui/material'
import { Box } from '@mui/system'
import CustomTable from 'common/components/CustomTable'
import { CustomModalContext } from 'common/contexts/CustomModalContext'
import { ModalTypes } from 'common/hooks/useCustomModal'
import { getFechaFromISO } from 'common/libs/fechas'
import { selectPortal } from 'features/portal/portalSlice'
import { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { descargarDocumento } from 'tool/funciones'
import useFetchLaboral from '../hooks/useFetchLaboral'

/**
 * @module Documentacion
 * @module DocumentacionLaboral
 */

/**
 * Table view for the Laboral Docs
 * This is an independent view and will
 * no take the object from the menu
 *
 * @param {object} props
 * @param {number | string} [props.ejercicio] -  The year to be fetched
 * @param {string} [props.document_type ]- The tipe of document to fetch
 * @example
 * <LaboralTableView />
 */
const LaboralTableView = ({ ejercicio = null, document_type = null }) => {
  const location = useLocation()
  const [user] = useState(location?.state?.user ?? null)
  const { response, loading } = useFetchLaboral({ user, ejercicio, tipo_documento: document_type })
  const portal = useSelector(selectPortal)
  const { handleModal } = useContext(CustomModalContext)

  return (
    <CustomTable
      sx={{ mt: 2 }}
      hideHeader={false}
      dataSource={response ?? []}
      showToolbar={false}
      height="fit-content"
      initialPageSize={15}
      loading={loading}
      density={'comfort'}
      fields={[
        {
          value: 'd_rh_documento',
          key: 'Documento',
          sortable: false,
          renderCell: ({ descargado, d_rh_documento }) => {
            return (
              <Typography fontWeight={descargado ? 'initial' : 'bold'}>{d_rh_documento}</Typography>
            )
          },
          filterable: false,
          flex: 1.5,
          width: 150,
        },
        {
          sortable: false,
          filterable: false,
          value: 'fecha_inicio',
          renderCell: ({ descargado, nom_inicio }) => {
            return (
              <Typography fontWeight={descargado ? 'initial' : 'bold'}>
                {getFechaFromISO(nom_inicio, portal?.locale?.replaceAll('-', '_') ?? 'es-ES')}
              </Typography>
            )
          },
          // customGetter: (field) =>
          //   getFechaFromISO(field.row.nom_inicio, portal?.locale?.replaceAll('-', '_') ?? 'es-ES'),
          key: 'Fecha Inicio',
          width: 150,
          flex: 1,
        },
        {
          value: 'fecha_fin',
          // customGetter: (field) =>
          //   getFechaFromISO(field.row.nom_fin, portal?.locale?.replaceAll('-', '_') ?? 'es-ES'),
          renderCell: ({ descargado, nom_fin }) => {
            return (
              <Typography fontWeight={descargado ? 'initial' : 'bold'}>
                {getFechaFromISO(nom_fin, portal?.locale?.replaceAll('-', '_') ?? 'es-ES')}
              </Typography>
            )
          },
          key: 'Fecha Fin',
          width: 150,
          flex: 1,
        },
        {
          value: 'actions',
          key: 'Descargar',
          width: 150,
          flex: 0.7,
          renderCell: (document) => {
            return (
              <Box sx={{ width: '100%', display: 'flex', placeItems: 'center', gap: '2px' }}>
                <Button
                  sx={{ width: '100%' }}
                  startIcon={<Download />}
                  onClick={() => {
                    handleModal({ type: ModalTypes.Loading, enabled: true })
                    descargarDocumento(document.tipo_doc_principal, document.id).finally(() => {
                      handleModal({ type: ModalTypes.Loading, enabled: false })
                    })
                  }}
                >
                  PDF
                </Button>
                {document.has_xml > 0 ? (
                  <Button
                    sx={{ width: '100%' }}
                    color="warning"
                    onClick={() => {
                      handleModal({ type: ModalTypes.Loading, enabled: true })
                      descargarDocumento(document.tipo_doc_secundario, document.id).finally(() => {
                        handleModal({ type: ModalTypes.Loading, enabled: false })
                      })
                    }}
                    startIcon={<Download />}
                  >
                    XML
                  </Button>
                ) : (
                  <></>
                )}
              </Box>
            )
          },
        },
      ]}
    />
  )
}

export default LaboralTableView
