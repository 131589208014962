import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'

/**
 * @module DatosTrabajador
 */

/**
 * Component to show the bank data for the Worker
 * @param {Object} props
 * @param {WorkerDataDto} props.datosTrabajador
 */
const Bancarios = ({ datosTrabajador }) => {
  return (
    <Stack pt={2} pb={2} spacing={2}>
      <Card sx={{ flexGrow: 1 }}>
        <CardContent>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <ListItem>
              <ListItemText
                primary="Banco"
                secondary={`${datosTrabajador?.d_banco_trab ?? '--'}`}
              />
            </ListItem>
            <ListItem>
              <ListItemText primary="IBAN" secondary={`${datosTrabajador?.iban_trab ?? '--'}`} />
            </ListItem>
          </List>
        </CardContent>
      </Card>
    </Stack>
  )
}

export default Bancarios
